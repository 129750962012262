var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Banner", {
        staticClass: "banner_class",
        attrs: { page: "marketing", location: "header" }
      }),
      _c("Main", { staticClass: "mt-20 md:mt-0" }),
      _c(
        "div",
        { staticClass: "page", staticStyle: { "margin-top": "0 !important" } },
        [
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _vm._l(_vm.sections, function(section, index) {
                return _c(
                  "div",
                  { key: "section_" + index, staticClass: "my-10" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "uppercase text-3xl",
                        staticStyle: { "font-family": "UniSans-Bold" }
                      },
                      [_vm._v(" " + _vm._s(section.title) + " ")]
                    ),
                    _c("div", { staticClass: "mt-1 text-sm" }, [
                      _vm._v(
                        " " +
                          _vm._s(section.description) +
                          " " +
                          _vm._s(_vm.sections.tiles) +
                          " "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "Swiper",
                          {
                            attrs: {
                              options: _vm.getSwiperOption(section.design_type)
                            }
                          },
                          [
                            _vm._l(section.tiles, function(tile, index) {
                              return _c(
                                "SwiperSlide",
                                { key: "tile_" + section.title + "_" + index },
                                [
                                  _c("img", {
                                    staticClass: "w-full px-1",
                                    attrs: {
                                      src: tile.image,
                                      alt: "daysOfLivePoker-img"
                                    }
                                  })
                                ]
                              )
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "swiper-button-prev",
                                attrs: { slot: "button-prev" },
                                slot: "button-prev"
                              },
                              [
                                _c("ArrowButton", {
                                  attrs: { direction: "left" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "swiper-button-next",
                                attrs: { slot: "button-next" },
                                slot: "button-next"
                              },
                              [
                                _c("ArrowButton", {
                                  attrs: { direction: "right" }
                                })
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              _c("div", { staticClass: "my-10" }, [
                _c(
                  "div",
                  {
                    staticClass: "uppercase text-3xl text-center",
                    staticStyle: { "font-family": "UniSans-Bold" }
                  },
                  [_vm._v(" Select a plan ")]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "stretch flex flex-col md:flex-row  justify-center"
                  },
                  _vm._l(_vm.plans, function(p, i) {
                    return _c("register-plan", {
                      key: i,
                      staticClass: "mx-auto",
                      attrs: { plan: p },
                      on: {
                        selectPlan: function($event) {
                          return _vm.selectPlan(p)
                        }
                      }
                    })
                  }),
                  1
                )
              ]),
              _c("div", { staticClass: "my-10" }, [
                _c(
                  "div",
                  {
                    staticClass: "uppercase text-3xl text-center",
                    staticStyle: { "font-family": "UniSans-Bold" }
                  },
                  [_vm._v(" stream on your favorite devices ")]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "grid grid-cols-2 md:grid-cols-3 mt-4 gap-4 md:gap-24 text-2xl text-center"
                  },
                  _vm._l(_vm.devices, function(device, i) {
                    return _c("div", { key: "device_" + i }, [
                      _c("img", {
                        staticClass: "w-full",
                        attrs: { src: device, alt: "on-demand-img" }
                      })
                    ])
                  }),
                  0
                )
              ]),
              _c("div", { staticClass: "my-10" }, [
                _c("div", { staticClass: "uppercase text-3xl text-center" }, [
                  _vm._v(" frequently asked questions ")
                ]),
                _c(
                  "div",
                  { staticClass: " mt-4 flex flex-col gap-3" },
                  _vm._l(_vm.faq, function(item, index) {
                    return _c("FrequentlyAskedQuestion", {
                      key: "faq_" + index,
                      attrs: { question: item.question, answer: item.answer }
                    })
                  }),
                  1
                )
              ])
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }