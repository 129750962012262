var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("ul", { staticClass: "list-options" }, [
      _vm.loggedIn
        ? _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/account" } }, [
                _vm._v("Profile")
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "li",
        [_c("router-link", { attrs: { to: "/search" } }, [_vm._v("Search")])],
        1
      ),
      _vm.loggedIn
        ? _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/my-list" } }, [
                _vm._v("My List")
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/terms-and-conditions" } }, [
            _vm._v("Terms and Conditions")
          ])
        ],
        1
      ),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/privacy-policy" } }, [
            _vm._v("Privacy Policy")
          ])
        ],
        1
      ),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/about" } }, [
            _vm._v("About PokerGO")
          ])
        ],
        1
      ),
      _c(
        "li",
        [_c("router-link", { attrs: { to: "/faq" } }, [_vm._v("FAQ")])],
        1
      ),
      _vm._m(0),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/faq#contact" } }, [
            _vm._v("Contact Us")
          ])
        ],
        1
      ),
      _vm._m(1),
      _vm._m(2),
      _vm.loggedIn
        ? _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/logout" } }, [
                _vm._v("Sign out")
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.loggedIn
        ? _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/login" } }, [
                _vm._v("Sign in")
              ])
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "account" }, [_vm._v(_vm._s(this.email))]),
      _c(
        "div",
        { staticClass: "btn-subscribe" },
        [
          !_vm.isPaidOrPending()
            ? _c(
                "router-link",
                {
                  attrs: {
                    to: _vm.loggedIn ? "/select-plan" : "/register/select-plan"
                  }
                },
                [_vm._v(" Subscribe ")]
              )
            : this.subscription.store === "stripe" &&
              this.subscription.interval !== "training_annual"
            ? _c(
                "router-link",
                { attrs: { to: "/account/change-plan/checkout" } },
                [_vm._v(" Upgrade ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "version" }, [
        _vm._v("Version " + _vm._s(_vm.version))
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.pokergonews.com/press-releases/",
            target: "_blank"
          }
        },
        [_vm._v(" Press Releases ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        { attrs: { href: "https://shop.pokergo.com/", target: "_blank" } },
        [_vm._v(" Shop Now ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        { attrs: { href: "https://playpokergo.com/", target: "_blank" } },
        [_vm._v(" Play Now ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }