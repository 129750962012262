<script>
import PlayBtn from './PlayBtn.vue'

export default {
  name: 'TrainingVideoPlayer',
  components: { PlayBtn },
  props: {
    bgGradient: {
      type: Boolean,
      default: false
    },
    videoClass: {
      type: Boolean,
      default: false
    },
    showVideoInitially: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { player: undefined, showPlayer: false }
  },
  mounted() {
    if (this.showVideoInitially) {
      this.showPlayer = true
      this.initPlayer()
    }
  },
  methods: {
    initPlayer() {
      if (!this.$refs.videoPlayerRef) {
        console.log('videoPlayerRef not found')
        return
      }

      this.player = window.jwplayer(this.$refs.videoPlayerRef)

      try {
        this.player.setup({
          playlist: 'https://cdn.jwplayer.com/v2/media/WWyWCUlI',
          playbackRateControls: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
          autostart: true,
          playlistIndex: 0,
          repeat: false
        })
        this.player.on('ready', () => {
          this.showPlayer = true
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<template>
  <div class="relative mx-auto">
    <div
      v-show="showPlayer"
      :class="{ 'player-swiper-class md:-ml-64': videoClass }"
    >
      <div ref="videoPlayerRef" class="w-full" />
    </div>

    <div class="flex justify-center" v-show="!showPlayer">
      <div class="relative img-shadow rounded-lg">
        <div class="text-center md:w-max">
          <img
            class="w-full"
            src="@/assets/images/training/video-thumbnails/main-video-thumbnail.png"
          />
        </div>

        <div
          v-if="bgGradient"
          class="linear-bg-gradient absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center cursor-pointer"
        />

        <slot />

        <div
          class="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center cursor-pointer"
          @click="initPlayer"
        >
          <PlayBtn />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.player-swiper-class {
  width: 1000px;
}

/* For mobile screens */
@media (max-width: 768px) {
  .player-swiper-class {
    width: 100%;
  }
}
</style>
