<template>
  <div class="page">
    <div class="container centered">
      <div class="narrow-text-container">
        <div v-html="document.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FrequentlyAskedQuestions',
  data() {
    return {
      scrollTo: new String(window.location.hash),
      scrollToObj: undefined
    }
  },
  computed: {
    ...mapGetters(['getDocuments']),
    document() {
      const document = this.getDocuments.filter((item) => item.label === 'faq')
      if (document.length > 0) {
        return document[0]
      } else {
        return {
          id: 'faq',
          label: 'Frequently Asked Questions',
          content: ''
        }
      }
    }
  },
  methods: {
    ...mapActions(['GET_DOCUMENT'])
  },
  async beforeMount() {
    // await this.$store.dispatch('property/getDocument', 'faq')
    await this.$store.dispatch('GET_DOCUMENT', 'faq')
  },
  mounted() {
    if (this.scrollTo != null && this.scrollTo != '') {
      const tag = this.scrollTo.split('#')[1]
      const tagCheck = setInterval(() => {
        if (document.getElementById(tag)) {
          window.scrollTo(
            0,
            document.getElementById(tag).getBoundingClientRect().top - 105
          )
          clearInterval(tagCheck)
        }
      }, 500)
    }
  },
  head() {
    return {
      title: {
        inner: 'Frequently Asked Questions | PokerGO'
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Browse our FAQ to find answers to all of your PokerGO questions. Learn more about troubleshooting problems and how to contact our customer service team.'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.narrow-text-container {
  width: 100%;
  max-width: 800px;
}
</style>
