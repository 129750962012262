<template>
  <header class="register-header">
    <span @click="$emit('back')" class="back-home">
      <font-awesome-icon :icon="['fas', 'arrow-left']" class="icon-back" />
      {{ label }}
    </span>
    <router-link class="title" to="/">
      <img src="@/assets/images/Logo-Horizontal-NoNeon.svg" />
    </router-link>
  </header>
</template>

<script lang="js">
export default {
  name: 'SimpleHeader',
  props: {
    label: {
      type: String,
      required: false,
      default: 'Back Home'
    },
  }
}
</script>
<style scoped lang="scss">
.register-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid $light-gray-30;
  height: 5rem;

  .title {
    width: 20%;
    max-width: 156px;
    min-width: 130px;
    img {
      width: 100%;
    }
  }
  .back-home {
    position: absolute;
    left: 2rem;
    top: 2rem;
    font-family: 'UniSans';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    color: #ffffff;
    cursor: pointer;

    .icon-back {
      opacity: 0.4;
      margin-right: 0.5rem;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 420px) {
    .back-home {
      left: 5px;
      font-size: 12px;
      transition: 0.3s ease;
      .icon-back {
        font-size: 12px;
        margin-right: 5px;
      }
    }
  }
}
</style>
