import Vue from 'vue'
import VueRouter from 'vue-router'

import About from '../views/about.vue'
import AccountPage from '../views/account/index.vue'
import Faq from '../views/faq.vue'
import ForgotPassword from '../views/forgot-password.vue'
import Login from '../views/login.vue'
import Logout from '../views/logout.vue'
import PrivacyPolicy from '../views/privacy-policy.vue'
import Register from '../views/register/index.vue'
import RegisterComplete from '../views/register/complete.vue'
import ResetPassword from '../views/reset-password.vue'
import TermAndConditions from '../views/terms-and-conditions.vue'
import ChangePassword from '../views/account/changepassword.vue'
import CancelOption from '../views/account/canceloption.vue'
import CancelConfirm from '../views/account/cancelconfirm.vue'
import CancelSpecialOffer from '../views/account/cancelspecialoffer.vue'
import CancelNewContent from '../views/account/cancenewcontent.vue'
import CancelIOS from '../views/account/cancel-store/ios.vue'
import CancelPaypal from '../views/account/cancel-store/paypal.vue'
import CancelStore from '../views/account/cancel-store/index.vue'
import CancelAndroid from '../views/account/cancel-store/android.vue'
import CancelFireTV from '../views/account/cancel-store/fireTV.vue'
import CancelRoku from '../views/account/cancel-store/roku.vue'
import CancelTVOS from '../views/account/cancel-store/tvos.vue'
import Upgrade from '../views/upgrade'
import UpgradeComplete from '../views/upgrade/complete'
import Amazon from '../views/checkout/amazon/_id.vue'
import Collections from '../views/collections/_id.vue'
import Videos from '../views/videos/_id.vue'
import VideoEmbed from '../views/embed.vue'
import ErrorPage from '../views/error.vue'
import NProgress from 'nprogress'
import Game from '../views/game.vue'

import * as WatchViews from '@/views/Watch'
import * as routerMiddlewares from './routerMiddlewares'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: WatchViews.Home,
    name: 'Home',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/free-experience',
    redirect: '/',
    component: WatchViews.FreeExperience,
    name: 'FreeExperience',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/training',
    component: WatchViews.Training,
    name: 'Training',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/options',
    component: WatchViews.Options,
    name: 'Options',
    meta: {
      requiresAuth: false,
      layout: 'default-no-footer'
    }
  },
  {
    path: '/preview',
    component: WatchViews.HomePreview,
    name: 'HomePreview',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/my-list',
    component: WatchViews.MyList,
    name: 'My-List',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/account',
    component: AccountPage,
    name: 'Account',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/on-demand',
    component: WatchViews.OnDemand,
    name: 'Demand',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/faq',
    component: Faq,
    name: 'Faq',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'Forgot-password',
    meta: {
      requiresAuth: false,
      layout: 'login-layout'
    }
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    meta: {
      requiresAuth: false,
      layout: 'login-layout'
    }
  },
  {
    path: '/login/:code',
    component: Login,
    name: 'LoginCode',
    meta: {
      requiresAuth: false,
      layout: 'login-layout'
    }
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    name: 'PrivacyPolicy',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/register',
    component: Register,
    name: 'Create an Account',
    beforeEnter: (to, from, next) => {
      let isAuthed = router.app.$store.getters.isAuthenticated
      if (isAuthed) {
        next({ path: '/select-plan' })
      } else {
        next()
      }
    },
    meta: {
      requiresAuth: false,
      layout: 'register-layout',
      description:
        'Create an account to watch 100+ days of live poker events and TV shows exclusively on PokerGO.'
    }
  },
  {
    path: '/register/verify-email',
    component: Register,
    name: 'Verify Email',
    meta: {
      requiresAuth: false,
      layout: 'register-layout',
      description:
        'Create an account to watch 100+ days of live poker events and TV shows exclusively on PokerGO.'
    }
  },
  {
    path: '/register/select-plan',
    alias: '/select-plan',
    component: Register,
    name: 'Select a Plan',
    meta: {
      requiresAuth: false,
      layout: 'register-layout',
      description:
        'Select a plan to watch 100+ days of live poker events and TV shows exclusively on PokerGO.'
    }
  },
  {
    path: '/register/select-plan/:productType',
    alias: '/select-plan/:productType',
    component: Register,
    name: 'Payment Method',
    meta: {
      requiresAuth: true,
      layout: 'register-layout',
      description:
        'Complete checkout to watch 100+ days of live poker events and TV shows exclusively on PokerGO.'
    }
  },
  {
    path: '/register/select-plan/:productType/complete',
    alias: '/select-plan/:productType/complete',
    component: RegisterComplete,
    name: 'Purchase Complete - Thank You',
    meta: {
      requiresAuth: false,
      layout: 'register-layout',
      description:
        'Thank you for subscribing to PokerGO! You can now watch 100+ days of live poker events and TV shows exclusively on PokerGO.'
    }
  },
  {
    path: '/register/select-plan/:productType/amazon',
    alias: '/select-plan/:productType/amazon',
    component: Register,
    name: 'Pay with Amazon',
    meta: {
      requiresAuth: false,
      layout: 'default-no-footer'
    }
  },
  {
    path: '/register/:code',
    component: Register,
    name: 'RegisterCode',
    meta: {
      requiresAuth: false,
      layout: 'register-layout'
    }
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    name: 'ResetPassword',
    meta: {
      requiresAuth: false,
      layout: 'login-layout'
    }
  },
  {
    path: '/schedule',
    component: WatchViews.Schedule,
    name: 'Schedule',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/search',
    component: WatchViews.Search,
    name: 'Search',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/terms-and-conditions',
    component: TermAndConditions,
    name: 'TermsAndConditions',
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/account/changepassword',
    component: ChangePassword,
    name: 'AccountChangepassword',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/change-plan/checkout',
    component: Upgrade,
    name: 'Upgrade',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/change-plan/checkout/:productType',
    component: UpgradeComplete,
    name: 'UpgradeComplete',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/cancel-store/ios',
    component: CancelIOS,
    name: 'CancelIOS',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/cancel-store/android',
    component: CancelAndroid,
    name: 'CancelAndroid',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/cancel-store/roku',
    component: CancelRoku,
    name: 'CancelRoku',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/cancel-store/paypal',
    component: CancelPaypal,
    name: 'CancelPaypal',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/cancel-store/tvos',
    component: CancelTVOS,
    name: 'CancelTVOS',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/cancel-store/firetv',
    component: CancelFireTV,
    name: 'CancelFireTV',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/cancel-store/index',
    component: CancelStore,
    name: 'CancelStore',
    meta: {
      requiresAuth: true,
      layout: 'register-layout'
    }
  },
  {
    path: '/account/cancel',
    component: CancelOption,
    name: 'AccountCancelReason',
    meta: {
      requiresAuth: true,
      layout: 'register-layout'
    }
  },
  {
    path: '/account/cancel/confirm',
    component: CancelConfirm,
    name: 'CancelConfirm',
    meta: {
      requiresAuth: true,
      layout: 'register-layout'
    }
  },
  {
    path: '/account/cancel/special',
    component: CancelSpecialOffer,
    name: 'CancelSpecialOffer',
    meta: {
      requiresAuth: true,
      layout: 'register-layout'
    }
  },
  {
    path: '/account/cancel/newcontent',
    component: CancelNewContent,
    name: 'CancelNewContent',
    meta: {
      requiresAuth: true,
      layout: 'register-layout'
    }
  },
  {
    path: '/collections/:id?',
    component: Collections,
    name: 'CollectionsId',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/videos/:id?',
    component: Videos,
    name: 'VideosId',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/embed',
    component: VideoEmbed,
    name: 'VideoEmbed',
    meta: {
      requiresAuth: false,
      layout: 'embed-layout'
    }
  },
  {
    path: '/error',
    component: ErrorPage,
    name: 'error',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/logout',
    component: Logout,
    name: 'Logout',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/checkout/amazon',
    component: Amazon,
    name: 'CheckoutAmazonId',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/game',
    component: Game,
    name: 'Game',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: 'error',
    component: ErrorPage
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  base: '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  try {
    await routerMiddlewares.setDocumentMetaData(
      to,
      from,
      next,
      router.app.$store
    )
    const runNextMiddleware = await routerMiddlewares.handlePromoCodeQuery(
      to,
      from,
      next,
      router.app.$store
    )
    if (!runNextMiddleware) {
      return
    }
    await routerMiddlewares.handleAuthentication(
      to,
      from,
      next,
      router.app.$store
    )
    next()
  } catch (error) {
    console.error('Error during navigation:', error)
    next(false) // Stop navigation in case of error
  }
})

router.beforeResolve((to, from, next) => {
  // if this is not the first time that vue has launched
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
