<template>
  <div class="register flex-column-center">
    <h1>
      Thank you <span>{{ formatNameUser }}</span> for subscribing!
    </h1>
    <h5>
      You have unlocked PokerGO, including 100+ days of live events, exclusive
      poker shows & more!
    </h5>
    <h5 v-if="isPaypal" class="paypal-notation">
      It may take up to 5 minutes if you paid by credit card on Paypal to access
      premium content but if you paid by bank account through Paypal it can take
      up to 48 hours to clear your bank.
    </h5>

    <div class="details flex-column-center">
      <div class="title">SUBSCRIPTION DETAILS:</div>
      <div class="content">
        <div class="head">
          <div class="list-item">
            <div class="item">
              <div class="edit-info" @click="goToAccount">
                <span>
                  <img
                    v-if="!nameInitial"
                    src="@/assets/images/account-hover-icon.png"
                    alt=""
                    height="50"
                  />
                  <span v-else>{{ nameInitial }}</span>
                </span>
                <div class="CSSBadge">
                  <div class="CSSBadge-number">
                    <font-awesome-icon
                      :icon="['fas', 'pen']"
                      class="icon-edit"
                    />
                  </div>
                </div>
              </div>
              <div class="info">
                <div class="name">{{ formatNameUser }}</div>
                <div class="email">{{ email }}</div>
              </div>
            </div>
            <div v-if="plan.name" class="item">
              <span class="plan-label">
                {{ plan.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="content-subscriber">
          <div class="list-item">
            <div class="item">
              <span>{{ plan.symbol }}{{ planPrice }}</span>
              <span>Purchase Price</span>
            </div>
            <div class="item">
              <span>{{ plan.symbol + plan.price }}</span>
              <span>Renewal Price</span>
            </div>
            <div class="item">
              <span>Credit Card</span>
              <span>Payment Options</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="glowing-btn h-12 px-12 mt-1"
        v-if="!isPaypal"
        @click="startWatching"
      >
        <div class="">Start watching</div>
      </div>
    </div>
    <div class="apps flex-column-center">
      <div class="logos-row">
        <a
          href="https://apps.apple.com/us/app/pokergo-stream-poker-tv/id1235783484"
        >
          <img src="@/assets/images/download-applestore.png" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.pokercentral.poker&hl=en_US&gl=US"
        >
          <img src="@/assets/images/download-googleplay.png" />
        </a>
      </div>

      <div class="logos-row">
        <img src="@/assets/images/logos/logo-iphone.svg" />
        <img src="@/assets/images/logos/logo-ipad.svg" />
        <img src="@/assets/images/logos/logo-appleTV.svg" />
        <img src="@/assets/images/logos/logo-android.svg" />
        <img src="@/assets/images/logos/logo-roku.svg" />
        <img src="@/assets/images/logos/logo-amazonfiretv.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'RegisterComplete',
  data() {
    return {
      isPaypal: false,
      isCreditCard: false,
      isAmazon: false,
      type: '',
      planPrice: 0
    }
  },
  computed: {
    ...mapState({
      plans: (state) => state.checkout.plans,
      stripePaymentInfor: (state) => state.checkout.stripePaymentInfor
    }),
    ...mapGetters(['getEmail', 'firstName', 'lastName']),
    email() {
      return this.getEmail || ''
    },
    nameInitial() {
      const firstNameInitial = this.firstName ? this.firstName[0] : ''
      const lastNameInitial = this.lastName ? this.lastName[0] : ''

      return `${firstNameInitial + lastNameInitial}`.toUpperCase()
    },
    plan() {
      return this.planIDX > -1 ? this.plans[this.planIDX] : {}
    },
    planIDX() {
      return this.plans.findIndex(
        (p) => this.$route.params.productType === p.product_type
      )
    },
    isValidFullName() {
      return !!(this.firstName || this.lastName)
    },
    formatNameUser() {
      return this.isValidFullName ? `${this.firstName} ${this.lastName}` : ''
    }
  },
  methods: {
    startWatching() {
      const loginRedirectURL = localStorage.getItem('loginRedirectURL')
      if (loginRedirectURL) {
        this.$router.push({ path: loginRedirectURL })
        localStorage.removeItem('loginRedirectURL')
      } else if (this.$store.state.videoId)
        window.location.href = `/videos/${this.$store.state.videoId}`
      else window.location.href = '/'
    },
    goToAccount() {
      this.$router.push('/account')
    },
    tapGetQueryParams() {
      var r = {}
      var t = function(r) {
        return decodeURIComponent(r.replace(/\+/g, ' '))
      }
      var n = location.search.substring(1).split('&')
      for (var e in n) {
        var a = n[e].toString().split('=')
        a.length > 1 && (r[t(a[0])] = t(a[1]))
      }
      return r
    }
  },
  mounted() {
    if (this.$route.query.paypal) {
      this.isPaypal = true
      this.type = 'Paypal'

      // save data to localStorage for hiding 'subscribe' btn
      const currentTime = moment().unix()
      localStorage.setItem('paypalPurchaseTime', currentTime)

      var params = this.tapGetQueryParams()

      var amount = this.plan.price
      var subId = params.subscription_id || ''
      // eslint-disable-next-line
      tap('create', '27685-23c0ca', { integration: 'paypal' })
      // eslint-disable-next-line
      tap('conversion', subId, parseFloat(amount), { customer_id: this.email })

      return
    }
    if (this.$route.query.merchant === 'amazon') {
      this.isAmazon = true
      this.type = 'Amazon'
      return
    }

    if (this.$route.query.couponPrice) {
      this.planPrice = this.$route.query.couponPrice
    } else {
      this.planPrice = this.plan.price
    }

    if (this.planPrice) {
      dataLayer.push({
        event: 'conversion',
        send_to: 'AW-853562997/HiASCMGB1v0CEPWsgZcD',
        value: Number(this.planPrice),
        currency: 'USD',
        transaction_id: this.plan?.id || ''
      })

      console.log('pushed event for conversion')
    }

    this.isCreditCard = true
    this.type = 'Credit Card'

    // eslint-disable-next-line
    tap('create', '27685-23c0ca', { integration: 'stripe' })
    // eslint-disable-next-line
    tap(
      'conversion',
      this.stripePaymentInfor.stripeChargeId,
      parseFloat(this.stripePaymentInfor.amount),
      { customer_id: this.stripePaymentInfor.stripeCustomerId }
    )

    //Remove trackdesk query from localstorage
    localStorage.removeItem('trackdesk')
  }
}
</script>
<style scoped lang="scss">
.register {
  background-color: #10171f !important;
  color: white;
  padding-top: 35px;
  h1 {
    color: #ffffff;
    font-family: 'UniSans-Bold';
    font-size: 40px;
    letter-spacing: 0;
    line-height: 49px;
    text-align: center;
    span {
      text-transform: capitalize;
    }
  }
  h2 {
    font-family: 'UniSans-Bold-Italic';
    font-size: 1.8rem;
    text-align: center;
  }
  h5 {
    width: 90%;
    margin: 0 auto;
    font-family: 'UniSans';
    opacity: 0.7;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    text-align: center;
    max-width: 400px;
  }

  .details {
    margin: 20px 0;
    max-width: 600px;
    width: 100%;
    .title {
      font-family: 'UniSans-Bold';
      text-transform: uppercase;
      padding-top: 1rem;
      padding-bottom: 1rem;
      width: 100%;
    }
    .content {
      width: 100%;
      background-color: $input-grey;
      color: $dark-grey;
      justify-content: space-around;
      margin-bottom: 20px;
      font-size: 20px;
      padding: 20px;
      border-radius: 6px;
      .head {
        width: 100%;
        .list-item {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-family: 'UniSans';
          .item {
            display: flex;

            .plan-label {
              display: flex;
              align-items: center;
              background-color: rgba(159, 38, 181, 0.1);
              color: #9f26b5;
              font-family: 'UniSans-Bold';
              padding: 0 20px;
              border-radius: 50px;
            }
            .edit-info {
              margin-right: 1rem;
              height: 3em;
              width: 3em;
              position: relative;
              z-index: 1;

              span {
                position: absolute;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-family: 'UniSans-Bold';
                font-size: 14px;
                letter-spacing: 0;
                line-height: 21px;

                span {
                  color: #2aadef;
                  background-image: radial-gradient(
                    circle,
                    #d4eefb,
                    #d4eefb 66%,
                    transparent 66%
                  );
                  padding: 15px;
                }
              }
            }
            .info {
              display: flex;
              flex-direction: column;
              justify-content: center;
              .name {
                font-family: 'UniSans-Bold';
                color: #10181f;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 21px;
                text-transform: capitalize;
              }
              .email {
                opacity: 0.4;
                color: #10181f;
                font-family: 'UniSans';
                font-size: 15px;
                letter-spacing: 0;
                line-height: 21px;
                text-align: right;
              }
            }
          }
        }
      }
      .content-subscriber {
        margin-top: 2rem;
        font-size: 14px;
        width: 60%;
        padding-bottom: 1rem;
        .list-item {
          display: flex;
          justify-content: space-between;
          .item {
            span:nth-child(1) {
              font-family: 'UniSans-Bold';
              color: #10181f;
              opacity: 1;
            }
            span {
              display: block;
              opacity: 0.5;
              color: #10181f;
              font-family: 'UniSans';
              font-size: 13px;
              letter-spacing: 0;
              line-height: 21px;
            }
          }
        }
      }
      .col {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
      }
    }
    .register-button {
      width: 50%;
      min-width: 250px;
      color: #ffffff;
      font-family: 'UniSans-SemiBold';
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      h6 {
        width: 250px;
        text-align: center;
        opacity: 0.5;
        color: #ffffff;
        font-family: 'UniSans-SemiBold';
        font-size: 12px;
        letter-spacing: 0.25px;
        text-align: center;
      }
    }
    .btn-start {
      background: #9f26b5;
      padding: 15px 60px;
      border-radius: 4px;
      .btn {
        display: block;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        padding: 0;
        margin: 0;
      }
      .btn-text {
        width: 250px;
        display: inline-block;
        h6 {
          opacity: 0.5;
          color: #ffffff;
          font-family: 'UniSans-SemiBold';
          font-size: 12px;
          text-align: center;
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .apps {
    padding-bottom: 50px;
    .logos-row {
      max-width: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      padding-bottom: 50px;
      img {
        margin: 0 20px;
      }
    }
  }
  .paypal-notation {
    color: #0cf5a3;
  }
}
.App {
  display: inline-block;
  font-size: 80px; // Icon Size
  width: 14px;
  height: 14px;
  letter-spacing: 0;
  position: relative;
  border-radius: 20%;
  background: linear-gradient(45deg, #fabbfd, #00b7b5);
  box-shadow: 0 0 40px rgba(#000, 0.15);
}
.CSSBadge {
  box-sizing: border-box;
  color: white;
  font-family: sans-serif;
  font-size: 20px;
  letter-spacing: -1px;
  position: absolute;
  bottom: 100%;
  right: 0;
  min-width: 1em;
  padding-left: 0.2em;
  padding-right: 0.2em;
  height: 1em;
  margin-bottom: -25px;
  margin-right: -10px;
  opacity: 0.89;
  background-color: #ffffff;
  border-radius: 1em;
  text-align: center;
  line-height: 1em;

  &-number {
    font-size: 10px;
    color: #10181f;
    opacity: 0.4;
  }
}
.CSSBadge:hover {
  transform: scale(1.2);
  cursor: pointer;
  transition-duration: 0.2s;
}
</style>
