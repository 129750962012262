var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header"),
      _c("div", [_vm._t("default")], 2),
      _c("Footer"),
      _c("notifications", {
        attrs: { group: "messages", position: "bottom right" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }