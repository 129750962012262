<script>
import TrainingVideoPlayer from './TrainingVideoPlayer.vue'

export default {
  name: 'DesktopMain',
  components: { TrainingVideoPlayer }
}
</script>

<template>
  <TrainingVideoPlayer :bgGradient="true" :showVideoInitially="true">
    <div
      class="absolute right-0 bottom-1 md:bottom-7 left-0 flex justify-center items-center cursor-pointer"
    >
      <div class="text-center">
        <div class="text-sm md:text-6xl mb-1 md:mb-3">
          Elevate Your Game
        </div>
        <div class="opacity-50 text-xs md:text-xl">
          Unlock your poker potential with our<br />
          new poker training package.
        </div>
      </div>
    </div>
  </TrainingVideoPlayer>
</template>
