import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script'
import Vue2TouchEvents from 'vue2-touch-events'
import 'vue-progress-path/dist/vue-progress-path.css'
import VueProgress from 'vue-progress-path'
import lineClamp from 'vue-line-clamp'
import { library } from '@fortawesome/fontawesome-svg-core'
import VueCookies from 'vue-cookies'
import ClickOutside from 'vue-click-outside'

import {
  faBookOpen,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCheck,
  faChevronCircleRight,
  faChevronCircleLeft,
  faChevronDown,
  faChevronUp,
  faDice,
  faHandLizard,
  faPlus,
  faTrashAlt,
  faSearch,
  faSpinner,
  faStar,
  faTimes,
  faThList,
  faPlay,
  faPause,
  faUser,
  faClone,
  faPaperPlane,
  faEllipsisV,
  faFlag,
  faBan,
  faSmileBeam,
  faKeyboard,
  faPen,
  faArrowLeft,
  faRightToBracket,
  faFilter,
  faSortDown,
  faGraduationCap,
  faGear
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import App from './App.vue'
import router from './router'
import store from './store'
import optimize from 'google-optimize-service'
optimize.preventFlicker()
const experiment = optimize.get()
Vue.prototype.$experiment = experiment

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Bugsnag.start({
  apiKey: '975b55afd75bd3ad0538025be0f7c22a',
  appVersion: '2.5.3',
  releaseStage: process.env.ENVIRONMENT_NAME,
  plugins: [new BugsnagPluginVue()]
})

library.add(faBookOpen)
library.add(faCheck)
library.add(faChevronCircleRight)
library.add(faChevronCircleLeft)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faDice)
library.add(faHandLizard)
library.add(faPlus)
library.add(faSearch)
library.add(faTrashAlt)
library.add(faSpinner)
library.add(faStar)
library.add(faTimes)
library.add(faThList)
library.add(faCaretDown)
library.add(faCaretUp)
library.add(faCaretRight)
library.add(faPlay)
library.add(faPause)
library.add(faUser)
library.add(faClone)
library.add(faPaperPlane)
library.add(faEllipsisV)
library.add(faFlag)
library.add(faBan)
library.add(faTimes)
library.add(faSmileBeam)
library.add(faKeyboard)
library.add(faPen)
library.add(faArrowLeft)
library.add(faInstagram)
library.add(faYoutube)
library.add(faTwitter)
library.add(faFacebookF)
library.add(faRightToBracket)
library.add(faFilter)
library.add(faSortDown)
library.add(faGraduationCap)
library.add(faGear)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(Vue2TouchEvents)
Vue.use(VueProgress)
Vue.use(lineClamp)
Vue.use(LoadScript)

Vue.directive('click-outside', ClickOutside)

Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
Vue.prototype.$bugsnag = Bugsnag
Vue.prototype.$recaptureKey = process.env.RECAPTCHA_KEY

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
