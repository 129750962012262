<template>
  <div class="video-page-wrapper">
    <div class="mobile_margin"></div>
    <div
      :class="`${entitled ? 'back-home' : 'back-home back-home-not-entitled'}`"
      v-if="collectionId"
    >
      <router-link :to="`/collections/${collectionId}`">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="icon-back" />
        Back to collection
      </router-link>
    </div>
    <div
      v-if="entitled"
      class="page container"
      style="margin-top: 0px !important;"
    >
      <div class="video">
        <div class="player-container">
          <div>
            <video-player
              :key="$route.fullPath"
              v-if="fetched && onAir"
              v-show="!trailerPlaying"
              :entitled="entitled"
              :id="routeId"
              :overlay-image="overlayImage()"
              :autoplay="!hasTrailer"
              @streamEnded="streamLive = false"
              @streamStarted="streamLive = true"
              @onPlayerPlay="isPlayerPlaying = true"
              @onPlayerEnded="onPlayerEnded()"
              :video-data="videoData"
              :trailerPlayering="trailerPlaying"
            />
          </div>
          <trailer-player
            v-if="trailerPlaying && hasTrailer && trailerFetched"
            :id="`trailer-${routeId}`"
            :key="`trailer-key-${routeId}`"
            :video-source="trailerVideoSource"
            @close="closeTrailerVideo"
            @end="endTrailerVideo"
          />
          <img
            v-if="!onAir && !trailerPlaying && thumbnailImage"
            :src="thumbnailImage"
            class="overlay"
          />
          <CountdownTimer
            v-if="!onAir && fetched && !trailerPlaying"
            :airDate="airDate"
            :videoType="type"
          />
        </div>
        <PlayerActionButtons
          ref="playerActionButtons"
          :prev-video-id="prevVideoId"
          :next-video-id="nextVideoId"
          :nextVideoOnAir="nextVideoOnAir"
          :isPlayerPlaying="isPlayerPlaying"
        />
        <div class="video-info" v-if="isShowDetail">
          <div class="body-header">
            <h1 class="body-title">{{ title }}</h1>
          </div>
          <div class="description">
            <div class="duration">
              <b> Episode 1 </b> <span>|</span> 2:36:02
            </div>
            <div class="v-cv">
              <button
                v-if="hasTrailer"
                class="trailer-button"
                @click="setTrailer"
              >
                <font-awesome-icon
                  class="circled"
                  v-if="!trailerPlaying"
                  icon="play"
                />
                <font-awesome-icon
                  class="circled"
                  v-if="trailerPlaying"
                  icon="pause"
                />
                {{ trailerPlaying ? 'Playing...' : 'Watch Trailer' }}
              </button>
              <button
                v-if="loggedIn"
                class="my-list-button"
                @click="setFavorite"
              >
                <font-awesome-icon
                  class="circled"
                  v-if="favorite"
                  icon="check"
                />
                <font-awesome-icon
                  class="circled"
                  v-if="!favorite"
                  icon="plus"
                />
                {{ favorite ? 'remove' : 'my list' }}
              </button>
            </div>
            <p v-if="subheading !== ''" class="subheading">
              {{ subheading }}
            </p>
            <p>{{ description }}</p>
          </div>
        </div>
      </div>
      <Chat
        v-if="type === 'live' && !hideChat"
        :videoId="videoId"
        @clicked="onClickChild"
        @hide-chat="handleHideChat"
      />
      <div
        class="video-list"
        ref="videoList"
        v-touch:swipe.up="previousColumn"
        v-touch:swipe.down="nextColumn"
      >
        <div class="up-button" @click="previousColumn" v-if="currentIdx > 0">
          <div class="icon">
            <font-awesome-icon icon="chevron-up" class="up-list" />
          </div>
        </div>
        <div class="list-container" ref="videoContainer">
          <div
            class="entity"
            v-for="(entity, i) in this.slicedShowTiles"
            :key="entity.id"
            ref="videoTile"
            :style="positionStyle(i)"
          >
            <div class="thumbnail">
              <!-- Temporary thumbnail -->
              <img :src="overlayImage(entity)" />
            </div>
            <div class="info">
              <div class="episode-title">{{ entity.title }}</div>
              <div class="duration">
                <b> Episode 1 </b> <span>|</span> 2:36:02
              </div>
            </div>
          </div>
        </div>
        <div
          class="down-button"
          @click="nextColumn"
          v-if="sideTiles.length - currentIdx >= currentlyVisible * 0.5"
        >
          <div class="icon">
            <font-awesome-icon icon="chevron-down" class="down-list" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!entitled" class="subscribe-container">
      <div
        class="video-detail"
        :class="!onAir && fetched && isMobile ? 'video-detail-countdown' : ''"
      >
        <div
          v-if="!onAir && fetched && isMobile"
          class="mobile-countdown-wrapper"
        >
          <div class="mobile-countdown">
            <CountdownTimer
              :airDate="airDate"
              :videoType="type"
              type="not-subscribe-mobile"
            />
          </div>
        </div>
        <div class="video-img">
          <img :src="thumbnailImage" />
          <trailer-player
            v-if="trailerPlaying && hasTrailer && trailerFetched"
            class="subscribe-trailer"
            :id="`trailer-${routeId}`"
            :key="`trailer-key-${routeId}`"
            :video-source="trailerVideoSource"
            @close="closeTrailerVideo"
            @end="endTrailerVideo"
          />
          <CountdownTimer
            v-if="!onAir && fetched && !isMobile && !trailerPlaying"
            :airDate="airDate"
            :videoType="type"
            type="not-subscribe-desktop"
          />
          <div class="btn-trailer-wrapper" v-if="isMobile">
            <button
              v-if="hasTrailer"
              class="trailer-button"
              @click="setTrailer"
            >
              <font-awesome-icon
                class="circled"
                v-if="!trailerPlaying"
                icon="play"
              />
              <font-awesome-icon
                class="circled"
                v-if="trailerPlaying"
                icon="pause"
              />
              {{ trailerPlaying ? 'Playing...' : 'Watch Trailer' }}
            </button>
            <button v-if="loggedIn" class="my-list-button" @click="setFavorite">
              <font-awesome-icon class="circled" v-if="favorite" icon="check" />
              <font-awesome-icon class="circled" v-if="!favorite" icon="plus" />
              {{ favorite ? 'remove' : 'my list' }}
            </button>
            <br />
          </div>
        </div>
        <div class="video-desc">
          <p class="video-title">{{ title }}</p>
          <p v-if="subheading" class="subheading">{{ subheading }}</p>
          <div v-if="!isMobile" class="btn-trailer-wrapper">
            <button
              v-if="hasTrailer"
              class="trailer-button"
              @click="setTrailer"
            >
              <font-awesome-icon
                class="circled"
                v-if="!trailerPlaying"
                icon="play"
              />
              <font-awesome-icon
                class="circled"
                v-if="trailerPlaying"
                icon="pause"
              />
              {{ trailerPlaying ? 'Playing...' : 'Watch Trailer' }}
            </button>
            <button v-if="loggedIn" class="my-list-button" @click="setFavorite">
              <font-awesome-icon class="circled" v-if="favorite" icon="check" />
              <font-awesome-icon class="circled" v-if="!favorite" icon="plus" />
              {{ favorite ? 'remove' : 'my list' }}
            </button>
            <br />
          </div>
          <p v-if="!isMobile" class="mb-0">{{ description }}</p>
        </div>
      </div>
      <div class="content-area">
        <p class="notation-title">
          {{ headerText }}
        </p>
        <p class="notation-desc">
          Watch 100+ days a year of LIVE poker tournaments, including the WSOP
          and enjoy exclusive, NEW poker shows such as No Gamble, No Future &
          High Stakes Poker!
        </p>
        <div class="btn-wrapper">
          <div class="glowing-btn px-5 h-14 my-1" @click="handleNavigation">
            {{ actionBtnText }}
          </div>

          <div @click="gotoLogin" v-if="!loggedIn" class="btn-signin">
            SIGN IN
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import moment from 'moment'
import Vue from 'vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import TrailerPlayer from '@/components/TrailerPlayer.vue'
import CountdownTimer from '@/components/CountdownTimer'
import { General } from '@/library/general'
import { mapGetters, mapState } from "vuex";
import Chat from '@/components/Chat/index.vue'
import PlayerActionButtons from "../../components/PlayerActionButtons";
import { authentication } from "@/api/authentication";

export default Vue.extend({
  name: 'VideoPage',
  components: {
    PlayerActionButtons,
    VideoPlayer,
    TrailerPlayer,
    CountdownTimer,
    Chat
  },
  data() {
    return {
      air_date: '',
      currentIdx: 0,
      currentlyVisible: 0,
      description: '',
      entityEntitled: true,
      entitlementsRule: 'none',
      endDate: '',
      favorite: false,
      hasTrailer: false,
      trailerPlaying: false,
      fetched: false,
      trailerFetched: false,
      images: [],
      onAir: null,
      pre_air_images: [],
      publish_date: '',
      sideTiles: [],
      streamUpcoming: false,
      streamLive: false,
      streamEnded: false,
      streamEndTime: moment(),
      subheading: '',
      title: '',
      type: '',
      videoData: {},
      trailerVideoSource: {},
      airDate: null,
      videoId: this.$route.params.id,
      isChecked: true,
      chatEnabled: false,
      hideChat: false,
      prevVideoId:'',
      nextVideoId:'',
      collectionId:'',
      nextVideoOnAir: null,
      isPlayerPlaying: false,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user','isSubscriber']),
    ...mapState({
      isEmailVerified: (state) => state.user.isEmailVerified,
    }),
    isMobile() {
      return window.innerWidth <= 768
    },
    isShowDetail() {
      return !this.isMobile || (this.isMobile && !this.chatEnabled)
    },
    entitled() {
      return (this.entityEntitled && this.isAuthenticated && (this.isSubscriber || this.isEmailVerified))
    },
    videoList() {
      return this.$refs.videoList
    },
    routeId() {
      return this.$route.params.id
    },
    slicedShowTiles() {
      return this.sideTiles//.slice(this.currentIdx, (this.currentlyVisible || this.sideTiles.length) + this.currentIdx)
    },
    loggedIn() {
      return this.isAuthenticated
    },
    thumbnailImage() {

      let imageThumbnail = ''
      if(this.pre_air_images){
        const imagePreAir = this.pre_air_images.filter(image => image.label === 'SD')
        if (imagePreAir && imagePreAir.length) {
          imageThumbnail = imagePreAir[0].url
        }
        if (imageThumbnail.length) {
          return imageThumbnail
        }
      }

      const image = this.images.filter(image => image.label === 'SD')
      if (image && image.length)
        return image[0].url

      return ''
    },
    headerText(){
      if (this.entitlementsRule === 'none') {
        if (!this.isAuthenticated) {
          return 'CREATE A FREE ACCOUNT TO UNLOCK VIDEO';
        } else if (!this.isSubscriber && !this.isEmailVerified) {
          return 'VERIFY YOUR EMAIL TO UNLOCK VIDEO';
        }
      } else {
        if (!this.isSubscriber) {
          return 'SUBSCRIBE TO UNLOCK VIDEO';
        } else {
          return 'UPGRADE TO UNLOCK VIDEO';
        }
      }
      return '';
    },
    actionBtnText(){
      if (this.entitlementsRule === 'none') {
        if (!this.isAuthenticated) {
          return 'REGISTER NOW';
        } else if (!this.isSubscriber && !this.isEmailVerified) {
          return 'VERIFY NOW';
        }
      } else {
        if (!this.isSubscriber) {
          return 'SUBSCRIBE NOW';
        } else {
          return 'UPGRADE NOW';
        }
      }
      return '';
    },
  },
  async mounted() {
    this.fetchVideo()
  },
  watch: {
    description() {
      let metaDesc = document.querySelector('head meta[name="description"]')
      metaDesc.setAttribute('content', this.description)
      metaDesc = document.querySelector('head meta[property="og:description"]')
      metaDesc.setAttribute('content', this.description)
    },
    title() {
      document.title = this.title + ' | PokerGO'
    },
    routeId(newValue, oldValue){
      this.fetched=false
      if(newValue){
        this.fetchVideo()
      }
    }
  },
  methods: {
    async handleNavigation(){
      this.setLoginRedirectUrl()
      let path = ''
      if (this.entitlementsRule === 'none') {
        if (!this.isAuthenticated) {
          path = '/register'
          localStorage.setItem('redirectTriggerPath','/register')
        } else if (!this.isSubscriber && !this.isEmailVerified) {
          await authentication.requestEmailCode()
          localStorage.setItem('redirectTriggerPath','/register')
          path = '/register/verify-email'
        }
      } else {
        if (!this.isSubscriber) {
          path = '/register/select-plan'
        } else {
          path = '/account/change-plan/checkout?plan=training_annual'
        }
      }
      this.$router.push({ path })
    },
    fetchVideo(){
      /**
       * Save videoID into store when user is not authenticated.
       * This value will be used to redirect url after user subscribe
       */
      if (!this.isAuthenticated) {
        this.$store.commit('setVideoId', this.routeId)
      }

      //await this.sideTilesData();
      let payload = {
        type: 'videos',
        id: this.routeId,
      }

      this.$store.dispatch('collections/GetOneEntity',payload)
        .then((res) => {
          if (res.status < 300) {
            this.prevVideoId=res.data.data?.pre_video?.id
            this.nextVideoId=res.data.data?.post_video?.id
            this.collectionId=res.data.data?.collection_video_other?.parent_collection_id
            const date = new Date(res.data.data.published_at)
            this.airDate = new Date(res.data.data.aired_at)
            const endDate = new Date(res.data.data.ended_at)
            this.streamEndTime = moment(endDate)

            const now = new Date()
            this.onAir = this.airDate <= now

            const nextVideoOnAirDate = res.data.data?.post_video?.aired_at
            this.nextVideoOnAir = nextVideoOnAirDate ? new Date(nextVideoOnAirDate) <= now : false

            this.title = res.data.data.title
            this.description = res.data.data.description
            this.subheading = res.data.data.subheading
            this.images = res.data.data.images
            this.pre_air_images = res.data.data.pre_air_images
            this.type = res.data.data.type
            this.favorite = res.data.data.favorite
            this.hasTrailer = !!res.data.data.trailer_source

            this.entityEntitled = res.data.data.entitled
              ? res.data.data.entitled
              : false

            this.entitlementsRule = res.data.data.entitlements_rule

            this.publish_date =
              date.getMonth() + 1 + '-' + date.getDate() + '-' + date.getFullYear()
            this.air_date =
              date.getMonth() + 1 + '-' + date.getDate() + '-' + date.getFullYear()
            if (now < this.airDate) {
              this.streamUpcoming = true
            } else if (now > this.airDate && now < endDate) {
              this.streamLive = true
            } else {
              this.streamEnded = true
            }
            this.videoData = res
            this.fetched = true

            // Set trailerSource to trailerVideo
            if (this.hasTrailer) {
              this.trailerVideoSource = res.data.data.trailer_source
              this.trailerFetched = true
            }
          } else {
            if (res.statusCode === 404) {
              this.$bugsnag.notify(new Error('Video not found'))
            } else {
              this.$bugsnag.notify(new Error(`${res.statusCode}: ${res.title}`))
            }
          }
        })

      // get data if chat enabled for this video
      if (this.isAuthenticated) {
        this.$store.dispatch('videos/GetStatusData', this.routeId).then((res) => {
          if (!res.data) return
          this.chatEnabled = res.data.setting.chat_enabled
        })
      }
    },
    handleHideChat(){
      this.hideChat = true
    },
    setTrailer() {
      this.trailerPlaying = !this.trailerPlaying
    },
    closeTrailerVideo() {
      this.trailerPlaying = false
    },
    endTrailerVideo() {
      this.trailerPlaying = false
    },
    evalCurrentlyVisible () {
      if (this.$refs.videoList && this.$refs.videoTile) {
        this.currentlyVisible = Math.ceil(this.$refs.videoList.clientHeight / this.$refs.videoTile[0].clientHeight)
      } else {
        this.currentlyVisible = 2
      }
    },
    getTimeToStreamEnd() {
      const now = moment()
      return moment.duration(this.streamEndTime.diff(now)).asSeconds()
    },
    nextColumn() {
      this.evalCurrentlyVisible()
      this.currentIdx = this.currentIdx + Math.ceil(this.currentlyVisible / 2)
    },
    positionStyle(index) {
      this.evalCurrentlyVisible()
      const tileHeight = (window.clientWidth <= 768 ? 70 : 90) + 10
      if (index < this.currentIdx) {
        return { top: '-200px' }
      }
      if (index > this.currentIdx + this.currentlyVisible) {
        return { top: 'calc(100% + '+ tileHeight + 'px)' }
      }
      return { top: (index - this.currentIdx) * tileHeight + 'px' }
    },
    previousColumn() {
      this.evalCurrentlyVisible()
      this.currentIdx = Math.max(this.currentIdx - Math.ceil(this.currentlyVisible / 2), 0)
    },
    setFavorite() {
      let payload = {
        type: 'videos',
        id: this.routeId,
      }
      if (!this.favorite) {
        this.$store.dispatch('collections/PostFavorite',payload)
          .then(() => {
            this.favorite = true
          })
      } else {
        this.$store.dispatch('collections/DeleteFavorite',payload)
          .then(() => {
            this.favorite = false
          })
      }
    },
    overlayImage(entity) {
      entity = entity || this
      let tmp = General.getOverlayImage(
        entity.air_date,
        entity.pre_air_images,
        entity.images
      )
      return tmp
    },
    onClickChild (value) {
      this.isChecked = value
    },
    gotoLogin(){
      this.setLoginRedirectUrl()
      this.$router.push({ path: '/login' })
    },
    setLoginRedirectUrl(){
      localStorage.setItem('loginRedirectURL',window.location.pathname)
    },
    onPlayerEnded(){
        this.isPlayerPlaying = false
        this.$refs.playerActionButtons.autoNext()
    }
  }
})
</script>
<style lang="scss" scoped>
.duration {
  width: 150px;
  margin-bottom: 1rem;
  display: none;
  justify-content: space-between;
}
.up-button,
.down-button {
  cursor: pointer;
  position: absolute;
  left: 50%;
  z-index: 10;
  @media (max-width: 786px) {
    position: static;
    display: flex;
    justify-content: center;
  }
}
.up-button {
  top: -15px;
}
.down-button {
  bottom: -15px;
}
.up-list {
  position: absolute;
  @media (max-width: 786px) {
    position: static;
  }
  top: -15px;
  left: 79%;
  font-size: 25px;
  color: black;
  filter: drop-shadow(0px 0px 2px white);

  &:hover {
    color: #bb00e8;
    filter: drop-shadow(0px 0px 5px white);
  }
}

.down-list {
  position: absolute;
  @media (max-width: 786px) {
    position: static;
  }
  top: -15px;
  left: 83%;
  font-size: 25px;
  color: black;
  filter: drop-shadow(0px 0px 2px white);

  &:hover {
    color: #bb00e8;
    filter: drop-shadow(0px 0px 5px white);
  }
}

.body-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  .body-title {
    font-family: 'UniSans-SemiBold';
    margin-top: 0;
    margin-bottom: 0;
    @media (max-width: 786px) {
      font-size: 2em;
    }
  }
}

.video-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
}

.container {
  position: relative;
  padding: 0 !important;
  margin: 0px 0;
  @media (max-width: 786px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }
  .video {
    width: 1000px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    @media (max-width: 786px) {
      max-width: 100%;
      margin-top: 0px;
    }
  }

  .video-list {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(100% - 310px);
    width: 310px;
    flex-direction: column;
    margin: 0 1rem;
    max-height: 800px;
    @media (max-width: 786px) {
      position: static;
      max-width: 400px;
    }
    .list-container {
      overflow: hidden;
      position: relative;
      height: 800px;
      .entity {
        position: absolute;
        display: flex;
        max-width: 100%;
        transition: 0.5s;
        .thumbnail {
          margin-right: 10px;
          img {
            height: 90px;
            @media (max-width: 786px) {
              height: 70px;
            }
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          max-height: 100%;
          padding-top: 0.6rem;
          .episode-title {
            max-height: 2.4rem;
            margin-bottom: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            font-size: 16px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.video-info {
  width: 100%;
  max-width: 1000px;
  padding: 20px;
}

.subheading {
  color: $poker-accent;
  font-weight: bold;
  font-size: 18px;
}

.player-container {
  position: relative;
  width: 100%;
  max-width: 1000px;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: -10px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 0;
  p {
    font-size: 15px;
    line-height: 22px;
    padding-top: 10px;
  }
}

.overlay {
  width: 100%;
  height: 100%;
}
.v-cv {
  display: flex;
}

/* subscribe page style for not-loggedin user */
.mb-0 {
  margin-bottom: 0;
}

.subscribe-container {
  padding-top: 20px;

  .video-detail {
    max-width: 100vw;
    position: relative;
    // margin-top: 105px;
    padding: $shrink-fit;
    display: flex;
    align-self: flex-start;
    margin-bottom: 20px;

    .video-img {
      position: relative;
      margin-right: 20px;

      img {
        width: 350px;
      }

      .subscribe-trailer {
        position: absolute;
        top: 0;
        left: 0;
        width: 350px;
      }
    }

    .video-desc {
      .video-title {
        font-family: 'UniSans-SemiBold';
        font-size: 2em;
      }
      .btn-trailer-wrapper {
        display: flex;
        margin-bottom: 20px;
      }
      .trailer-button,
      .my-list-button {
        min-width: 130px;
      }
    }
  }

  .content-area {
    width: 100vw;
    padding: 50px;
    text-align: center;
    background: url('../../assets/images/locked-desktop.jpg') no-repeat;
    background-size: cover;
    height: 800px;

    .notation-title {
      font-family: 'UniSans-Bold-Italic';
      font-size: 2em;
    }

    .notation-desc {
      width: 60%;
      margin: auto;
      font-size: 1.5em;
    }

    .btn-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      font-family: 'UniSans-SemiBold';

      .btn-subscribe {
        background: $poker-accent;
        padding: 13px 40px 10px 40px;
        width: 250px;
        border-radius: 50px;
        outline: none;
        border: none;
        color: white;
      }

      .btn-signin {
        margin-top: 20px;
        color: white;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 786px) {
  .subscribe-container {
    padding-top: 0px;
    .video-detail {
      padding: 10px;
      margin-bottom: 0px;

      .mobile-countdown-wrapper {
        position: absolute;
        height: 60px;
        width: 100%;
        top: 0px;
        left: 0;
        margin-top: -60px;

        .mobile-countdown {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
      .video-img {
        margin-right: 5px;

        img {
          width: 160px;
        }

        .subscribe-trailer {
          position: absolute;
          top: 0;
          left: 0;
          width: 160px;
        }

        .btn-trailer-wrapper {
          display: flex;
          height: 30px;
          margin-top: 10px;

          button {
            font-size: 12px;
            padding: 3px 4px 0 4px;
            height: 30px;
            flex: none;
          }

          .trailer-button {
            margin-right: 5px;
          }
        }
      }

      .video-desc {
        .video-title {
          font-size: 1em;
        }
        .subheading {
          font-size: 14px;
        }
      }
    }
    .video-detail.video-detail-countdown {
      margin-top: 70px;
    }
    .content-area {
      background: url('../../assets/images/locked-mobile.jpg') no-repeat;
      background-size: cover;
      height: 700px;
      padding: 50px 10px;

      .notation-title {
        font-size: 1.25em;
      }

      .notation-desc {
        width: 95%;
        font-size: 1em;
      }
    }
  }
}

.back-home {
  max-width: 1000px;
  font-family: 'UniSans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
  margin: 20px auto 0px auto;
  @media (max-width: 1000px) {
    margin: 15px 0px 0px 10px;
  }
  @media (max-width: 768px) {
    margin: 15px 0px 20px 10px;
  }
  .icon-back {
    opacity: 0.4;
    margin-right: 0.5rem;
    font-size: 16px;
  }
}

.back-home-not-entitled {
  max-width: 100vw;
  padding: 0 max(calc(50% - 700px), 1.5rem) !important;

  @media (max-width: 768px) {
    margin: 0px;
    padding: 15px 15px 5px 10px !important;
  }
}

.mobile_margin {
  @media (max-width: 768px) {
    height: 1px;
    margin-top: 82px;
  }
}
</style>
