var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "radio" }, [
    _c("input", {
      attrs: { type: "radio", name: _vm.name, id: _vm.id },
      domProps: { value: _vm.value, checked: _vm.isChecked },
      on: {
        change: function($event) {
          return _vm.$emit("change", _vm.value)
        }
      }
    }),
    _c("label", { attrs: { for: _vm.id } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }