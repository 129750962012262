<template>
  <div class="page">
    <div>
      <Banner class="banner_class" page="on-demand" location="header" />
      <div class="controls">
        <div class="two-buttons">
          <button
            :class="{ active: viewType === 'grid' }"
            @click="setView('grid')"
          >
            <div class="icon grid"></div>
            <b>GRID</b>
          </button>
          <button
            :class="{ active: viewType === 'list' }"
            @click="setView('list')"
          >
            <div class="icon list"></div>
            <b>LIST</b>
          </button>
        </div>
        <span v-if="viewType === 'grid'">SORT BY</span>
        <div class="two-buttons" v-if="viewType === 'grid'">
          <button
            :class="{ active: gridSort === 'default' }"
            @click="gridSort = 'default'"
          >
            <b>POPULAR</b>
          </button>
          <button
            :class="{ active: gridSort === 'a-z' }"
            @click="gridSort = 'a-z'"
          >
            <b>A-Z</b>
          </button>
        </div>
      </div>
      <div v-if="viewType === 'list'" class="list-container">
        <Row
          v-for="entity in collections"
          :key="entity.id"
          :type="entity.type"
          :title="entity.title"
          :collectionID="entity.id"
          :collection-entities="entity.entities"
        />
      </div>
      <div v-if="viewType === 'grid'" class="grid-container">
        <small-card
          v-for="(entity, idx) in sortedEntities"
          :key="idx + entity.id"
          :entity-id="entity.id"
          :entity-type="entity.type"
          :entity-data="entity.images ? entity : undefined"
          :show-title="true"
          :no-reload="true"
          style="position: relative;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Row from '@/components/Row.vue'
import SmallCard from '@/components/SmallCard.vue'
import Banner from '../../components/Banner.vue'

export default {
  name: 'OnDemand',
  components: { Banner, SmallCard, Row },
  data() {
    return {
      description: '',
      collections: [],
      gridEntities: [],
      gridSort: 'default',
      title: '',
      viewType: 'grid',
      views: { grid: undefined, list: undefined }
    }
  },
  computed: {
    sortedEntities() {
      return this.gridSort === 'default'
        ? this.gridEntities
        : [...this.gridEntities].sort((a, b) => a.title.localeCompare(b.title))
    },
    demandViewData() {
      return this.$store.state.demand.viewData
    }
  },
  methods: {
    setView(type) {
      this.viewType = type
      // this.title = this.views[type].title
      // this.description = this.views[type].description
    },
    async getData() {
      const resGrid = await this.$store.dispatch(
        'demand/GetDemandViewData',
        'grid'
      )
      if (resGrid === undefined || resGrid.data === undefined) {
        console.log('Cannot get grid view')
        setTimeout(() => this.getData(), 3000)
        return
      }
      this.views.grid = resGrid.data.data
      this.gridEntities = resGrid.data.data.collection_list

      const resList = await this.$store.dispatch(
        'demand/GetDemandViewData',
        'list'
      )
      if (resList === undefined || resList.data === undefined) {
        console.log('Cannot get list view')
        setTimeout(() => this.getData(), 3000)
        return
      }
      this.views.list = resList.data.data
      this.collections = resList.data.data.collection_list
    }
  },
  async mounted() {
    // reset videoId on OnDemand view
    this.$store.commit('setVideoId', null)

    document.title = this.$route.name + ' | PokerGO'
    this.getData()
  }
}
</script>
<style scoped lang="scss">
.page {
  flex-flow: row;
  margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
  .controls {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    line-height: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
    }
    .two-buttons {
      margin: 1rem;
      width: 184px;
      border: 2px solid #bababa;
      border-radius: 5px;
      height: 35px;
      line-height: 35px;
      display: flex;
      flex-direction: row;
      button {
        justify-content: center;
        display: flex;
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        padding-top: 1px;
        align-items: center;
        cursor: pointer;
        width: 50%;
        height: 100%;
        border: none !important;
        background-color: #111820;
        color: #bababa;
        .icon {
          height: 22px;
          width: 22px;
          margin-right: 8px;
          &.grid {
            background: url('../../assets/images/grid-icon-light.svg') no-repeat;
          }
          &.list {
            background: url('../../assets/images/list-icon-dark.svg') no-repeat;
          }
        }
        &.active {
          background-color: #bababa;
          color: #111820;
          .grid {
            background: url('../../assets/images/grid-icon-dark.svg') no-repeat;
          }
          .list {
            background: url('../../assets/images/list-icon-light.svg') no-repeat;
          }
        }
        &:focus {
          outline: none !important;
        }
      }
    }
  }
  .list-container {
    margin: 0 0 60px 0;
    @media (max-width: 768px) {
      margin: 20px 0;
    }
  }
  .grid-container {
    margin: 60px 0;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      margin: 10px 0;
    }
    .small-card {
      padding: 15px;
      @media (max-width: 768px) {
        padding: 5px;
      }
    }
  }
}

.banner_class {
  margin-bottom: 30px;
  @media (max-width: 971px) {
    margin-bottom: 0px;
  }
}
</style>
