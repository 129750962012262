<template>
  <div class="page">
    <div class="container min-height-700">
      <Banner class="banner_class" page="search" location="header" />
      <div class="flex items-center justify-between">
        <div>
          <span v-if="showDidYouMean">
            Did you mean:
            <span
              @click="handleDidYouMeanClick"
              class="font-bold hover:underline cursor-pointer"
            >
              {{ didYouMean }}
            </span>
          </span>
        </div>
        <SearchFilters @handleFiltersChange="handleFiltersChange" />
      </div>
      <div v-if="loading" class="search-wrapper">
        <loading-progress
          :progress="60"
          :indeterminate="true"
          :hide-background="true"
          size="50"
          rotate
          fillDuration="2"
          rotationDuration="1"
        />
      </div>
      <div v-for="(entity, index) in entities" :key="entity.id" class="mb-4">
        <LongCard
          @click="handleSearchResultsClick(entity, index)"
          :entity-type="entity.type"
          :entity-id="entity.id"
          :entity-data="entity"
          :entity-lockbypass="true"
        />

        <div class="-mt-5" v-if="showMeta">
          SearchScore - {{ entity.score }}, PopularityScore -{{
            entity.popular_number
          }}, Date - {{ entity.date }}, Tags - {{ entity.tags }}
        </div>
      </div>

      <div v-if="entities.length === 0 && searched" class="no-results">
        <p>Bad Flop. Please check your spelling or try a new search.</p>
      </div>
    </div>
  </div>
</template>

<script>
import LongCard from '@/components/LongCard'
import { mapGetters, mapState } from 'vuex'
import Banner from '@/components/Banner.vue'
import SearchFilters from '@/components/SearchFilters.vue'
import api from '@/api'

export default {
  name: 'Search',
  components: {
    SearchFilters,
    Banner,
    LongCard
  },
  data() {
    return {
      entities: [],
      didYouMean: '',
      querySessionId: '',
      searchQuery: '',
      searched: false,
      loading: false,
      filters: {
        sortField: '',
        sortDirection: ''
      }
    }
  },
  computed: {
    ...mapGetters(['getPropertyId', 'isSubscriber']),
    ...mapState({
      searchKeyword: (state) => state.other.searchKeyword,
      subscription: (state) => state.user.subscription
    }),
    showMeta() {
      return process.env.ENVIRONMENT_NAME === 'development'
    },
    property() {
      return this.getPropertyId
    },
    showDidYouMean() {
      if (this.didYouMean) {
        return (
          this.didYouMean.toLowerCase() !== this.searchKeyword.toLowerCase()
        )
      }
      return false
    }
  },
  async mounted() {
    const query = this.$route.query.q
    if (query) {
      this.$store.commit('setSearchKeyword', query)
    }
    this.searchQuery = this.searchKeyword
    await this.search()
  },
  methods: {
    handleSearchResultsClick(entity, index) {
      const payload = {
        sz: `${window.screen.width}x${window.screen.height}`,
        ua: navigator.userAgent,
        sw: this.searchQuery,
        pn: '1',
        qsid: this.querySessionId,
        cli: `${index + 1}`,
        clurl: this.getClurl(entity),
        title: entity.title,
        dt: this.getDeviceType()
      }

      api.search.publishSearchTrack(payload)
    },
    getClurl(entity) {
      let path
      const { type, id } = entity
      if (type === 'video' || type === 'vod') {
        path = 'video/' + id
      }
      if (type === 'collection' || type === 'playlist') {
        path = 'collection/' + id
      }
      return path
    },
    getDeviceType() {
      const ua = navigator.userAgent.toLowerCase()
      const isTablet = /tablet|ipad|playbook|silk/i.test(ua)
      const isMobile = /mobile|android|iphone|ipod/i.test(ua)

      if (isTablet) {
        return 'tablet'
      } else if (isMobile) {
        return 'mobile'
      } else {
        return 'desktop'
      }
    },
    handleDidYouMeanClick() {
      this.$store.commit('setSearchKeyword', this.didYouMean)
      this.$router.push(`/search?q=${this.didYouMean}`)
      this.didYouMean = ''
    },
    handleFiltersChange(filters) {
      this.filters = filters
      this.search()
    },
    async search() {
      this.searched = false
      this.loading = true
      if (this.searchQuery.length < 3) {
        this.entities = []
        this.searched = true
        this.loading = false
        return
      }
      this.entities = []
      const payload = {
        ...this.filters,
        sortField: this.filters.sortDirection ? 'Date' : '',
        query: this.searchQuery
      }
      const response = await this.$store.dispatch('search/SearchQuery', payload)
      this.searched = true
      this.loading = false

      dataLayer.push({
        event: 'pageview',
        page_title: 'search',
        page_location: 'search',
        page_path: '/search?q=' + this.searchQuery
      })

      if (response.status === 200) {
        // const videos = response.data.data.videos?.videos || []
        // videos.forEach((v) => ((v.video_type = v.type), (v.type = 'video')))
        // const collections = response.data.data.collections || []
        // collections.forEach(
        //   (c) => ((c.collection_type = c.type), (c.type = 'collection'))
        // )
        this.entities = response.data.items || []
        this.didYouMean = response.data.did_you_mean
        this.querySessionId = response.data.query_session_id
      }
    }
  },
  watch: {
    searchQuery() {
      if (this.searchQuery === '') {
        this.searched = false
        this.entities = []
      }
    },
    searchKeyword() {
      this.searchQuery = this.searchKeyword
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  padding: 0 20px 20px 20px;
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .container {
    margin-top: 8rem;
  }
}
@media screen and (max-width: 480px) {
  .container {
    margin-top: 10rem;
  }
}

.search-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-results {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
  .two-buttons {
    margin: 1rem;
    width: 250px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    height: 40px;
    line-height: 35px;
    display: flex;
    flex-direction: row;
    button {
      justify-content: center;
      display: flex;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      padding-top: 1px;
      align-items: center;
      cursor: pointer;
      width: 50%;
      height: 100%;
      border: none !important;
      background-color: transparent;
      color: #ffffff;
      .icon {
        height: 22px;
        width: 22px;
        margin-right: 8px;
        &.grid {
          background: url('../../assets/images/grid-icon-light.svg') no-repeat;
        }
        &.list {
          background: url('../../assets/images/list-icon-dark.svg') no-repeat;
        }
      }
      &.active {
        background-color: $poker-accent;
      }
      &:focus {
        outline: none !important;
      }
    }
  }
}

.banner_class {
  margin-top: 0px;
  margin-bottom: 35px;
  @media (max-width: 971px) {
    margin-top: 0px;
  }
}
</style>
