var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col items-center" },
    [
      _c("div", { staticClass: "text-center mb-10 text-2xl max-w-xl mx-5" }, [
        _vm._v(
          " Step into the VIP circle and transform your poker experience with unparalleled access to knowledge, strategy, and the poker community. "
        )
      ]),
      _c("div", { staticClass: "text-center mb-10 text-2xl max-w-xl mx-5" }, [
        _vm._v(
          " This is more than just a membership; it’s your ticket to mastering the game. "
        )
      ]),
      _vm.showBtn && !this.isSubscriber
        ? _c(
            "router-link",
            {
              staticClass: "glowing-btn w-80 h-14 text-base mb-4",
              attrs: {
                to: {
                  name: "Select a Plan",
                  query: { plan: "training_annual" }
                }
              }
            },
            [_vm._v(" Join PokerGO VIP ")]
          )
        : _vm.showBtn && this.subscription.store === "stripe"
        ? _c(
            "router-link",
            {
              staticClass: "glowing-btn w-80 h-14 text-base mb-4",
              attrs: {
                to: {
                  name: "Upgrade",
                  query: { plan: "training_annual" }
                }
              }
            },
            [_vm._v(" Upgrade to PokerGO VIP ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }