var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer", class: { "pb-1": _vm.isMobile } },
    [
      !_vm.isMobile
        ? _c("div", { staticClass: "footer-row" }, [
            _c("div", { staticClass: "footer-links" }, [
              _c(
                "div",
                { staticClass: "footer-navlinks" },
                _vm._l(_vm.footerLinks, function(category, index) {
                  return _c(
                    "ul",
                    { key: index },
                    [
                      _c("p", { staticClass: "category-header" }, [
                        _vm._v(_vm._s(category.text))
                      ]),
                      _vm._l(category.subLinks, function(
                        subLink,
                        indexSubLink
                      ) {
                        return _c(
                          "li",
                          {
                            key: indexSubLink,
                            staticClass: "un-numbered-list"
                          },
                          [
                            subLink.blanc
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: subLink.linkTo,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(subLink.text) + " ")]
                                )
                              : _c(
                                  "router-link",
                                  { attrs: { to: subLink.linkTo } },
                                  [_vm._v(" " + _vm._s(subLink.text) + " ")]
                                )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              ),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "footer-logos" }, [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("img", {
                      staticClass: "footer-img",
                      attrs: { src: require("@/assets/images/logo.png") }
                    })
                  ]),
                  _vm._m(1)
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "footer-social" },
                _vm._l(_vm.socialIcons, function(socialIcon, indexSocial) {
                  return _c(
                    "a",
                    {
                      key: indexSocial,
                      staticClass: "imgicon-container",
                      attrs: { href: socialIcon.link_address, target: "_blank" }
                    },
                    [
                      _c("i", {
                        staticClass: "imgicon",
                        class: socialIcon.icon
                      })
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "copyright" }, [
                _vm._v(
                  " ©" + _vm._s(_vm.year) + " PokerGO®. All Rights Reserved. "
                )
              ])
            ])
          ])
        : _vm._e(),
      _c("client-only", [_c("cookie-law", { attrs: { theme: "pokergo" } })], 1)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-downloads" }, [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href:
              "https://apps.apple.com/us/app/pokergo-stream-poker-tv/id1235783484"
          }
        },
        [
          _c("img", {
            staticClass: "footer-img",
            attrs: { src: require("@/assets/images/download-applestore.png") }
          })
        ]
      ),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href:
              "https://play.google.com/store/apps/details?id=com.pokercentral.poker&hl=en_US&gl=US"
          }
        },
        [
          _c("img", {
            staticClass: "footer-img",
            attrs: { src: require("@/assets/images/download-googleplay.png") }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center logo-right" }, [
      _c("img", {
        staticClass: "footer-img w-44",
        attrs: { src: require("@/assets/images/Get-In-The-Game.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }