var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-login" }, [
    _c("div", { staticClass: "login-inner" }, [
      _c(
        "div",
        { staticClass: "login-box" },
        [
          _c("br"),
          _c("router-link", { staticClass: "btn-home", attrs: { to: "/" } }, [
            _c("i", { staticClass: "fas fa-arrow-left" }),
            _vm._v(" "),
            _c("span", [_vm._v("Home")])
          ]),
          _c(
            "form",
            {
              staticClass: "form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.login($event)
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("div", { staticClass: "logo-login" }, [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/Logo-PKG-1.png"),
                          alt: ""
                        }
                      })
                    ])
                  ]),
                  _c("br"),
                  _c("br"),
                  _c("br"),
                  _c("h2", [_vm._v("Start streaming today!")]),
                  _c("p", [
                    _vm._v(
                      " Login in now to enjoy the top poker streaming app in the world "
                    )
                  ]),
                  _c("div", { staticClass: "text-field" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user,
                          expression: "user"
                        }
                      ],
                      attrs: {
                        id: "username-input",
                        placeholder: "Email Address",
                        type: "email",
                        required: ""
                      },
                      domProps: { value: _vm.user },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.user = $event.target.value
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "text-field" }, [
                    (_vm.showPassword ? "text" : "password") === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.pass,
                              expression: "pass"
                            }
                          ],
                          attrs: {
                            id: "password-input",
                            placeholder: "Password",
                            required: "",
                            type: "checkbox"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.pass)
                              ? _vm._i(_vm.pass, null) > -1
                              : _vm.pass
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.pass,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.pass = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.pass = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.pass = $$c
                              }
                            }
                          }
                        })
                      : (_vm.showPassword ? "text" : "password") === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.pass,
                              expression: "pass"
                            }
                          ],
                          attrs: {
                            id: "password-input",
                            placeholder: "Password",
                            required: "",
                            type: "radio"
                          },
                          domProps: { checked: _vm._q(_vm.pass, null) },
                          on: {
                            change: function($event) {
                              _vm.pass = null
                            }
                          }
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.pass,
                              expression: "pass"
                            }
                          ],
                          attrs: {
                            id: "password-input",
                            placeholder: "Password",
                            required: "",
                            type: _vm.showPassword ? "text" : "password"
                          },
                          domProps: { value: _vm.pass },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.pass = $event.target.value
                            }
                          }
                        }),
                    _c(
                      "span",
                      {
                        staticClass: "eye-icon",
                        on: {
                          click: function($event) {
                            _vm.showPassword = !_vm.showPassword
                          }
                        }
                      },
                      [
                        !_vm.showPassword
                          ? _c("img", {
                              staticStyle: { height: "20px" },
                              attrs: {
                                src: require("@/assets/images/eye-solid.svg"),
                                alt: ""
                              }
                            })
                          : _vm._e(),
                        _vm.showPassword
                          ? _c("img", {
                              staticStyle: { height: "20px" },
                              attrs: {
                                src: require("@/assets/images/eye-slash-solid.svg"),
                                alt: ""
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ]),
                  _c("br"),
                  _vm.errorMessage !== ""
                    ? _c(
                        "span",
                        { staticClass: "error-message" },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "times" } }),
                          _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass: "forgot-password",
                  attrs: { to: "/forgot-password" }
                },
                [_vm._v("Forgot Password")]
              ),
              _c("br"),
              _c("br"),
              _c("div", { staticClass: "line" }),
              _c("br"),
              _c(
                "button",
                {
                  staticClass: "glowing-btn h-12 w-full mt-1",
                  attrs: { type: "submit" }
                },
                [_vm._v(" Submit ")]
              ),
              _c("br"),
              _c("br"),
              _c("span", { staticClass: "isAccount" }, [
                _vm._v("Don't you have an account yet?")
              ]),
              _c(
                "router-link",
                {
                  staticClass: "subscribe",
                  attrs: { to: "/register/select-plan" }
                },
                [_vm._v(" Subscribe ")]
              ),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _vm._m(0)
            ],
            1
          ),
          _c("br")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-footer" }, [
      _c("p", [_vm._v("Customer Service is available to help!")]),
      _c("p", [
        _vm._v(
          " CALL US at 1-866-476-5374 | Monday - Friday, 7am to 5pm PT or email support@pokergo.com "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }