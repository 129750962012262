export const ProductTypes = Object.freeze({
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  ANNUAL: 'annual',
  TRAINING_ANNUAL: 'training_annual',
  ANNUAL_DISCOUNT: 'annual_discount',
  QUARTERLY_DISCOUNT: 'quarterly_discount'
})

export const basePlans = {
  [ProductTypes.MONTHLY]: {
    name: 'Monthly',
    price: 19.99,
    priceDescription: 'Billed Monthly',
    product_type: ProductTypes.MONTHLY,
    frequency: 'month',
    planDetails: [
      {
        title: '',
        details: [
          {
            icon: 'check',
            text:
              'Full access to PokerGO livestreaming: Enjoy 100+ days of premier poker events such as the WSOP, Super High Roller Bowl, Poker Masters, and more.'
          },
          {
            icon: 'check',
            text:
              '24/7 on-demand streaming: Watch classic WSOP episodes dating back to 1973, High Stakes Poker, No Gamble, No Future, and more.'
          },
          {
            icon: 'close',
            fontWeight: 'bold',
            text: 'Does not include PokerU Training'
          }
        ]
      }
    ]
  },
  [ProductTypes.QUARTERLY]: {
    name: 'Quarterly',
    price: 39.99,
    priceDescription: '$13.33 / month',
    product_type: ProductTypes.QUARTERLY,
    frequency: 'quarter',
    planDetails: [
      {
        title: '',
        details: [
          {
            icon: 'check',
            text:
              'Full access to PokerGO livestreaming: Enjoy 100+ days of premier poker events such as the WSOP, Super High Roller Bowl, Poker Masters, and more.'
          },
          {
            icon: 'check',
            text:
              '24/7 on-demand streaming: Watch classic WSOP episodes dating back to 1973, High Stakes Poker, No Gamble, No Future, and more.'
          },
          {
            icon: 'check',
            text: 'Save more than 33% compared to our monthly plan.'
          },
          {
            icon: 'close',
            fontWeight: 'bold',
            text: 'Does not include PokerU Training'
          }
        ]
      }
    ]
  },
  [ProductTypes.ANNUAL]: {
    name: 'Annual',
    price: 99.99,
    priceDescription: '$8.33 / month',
    product_type: ProductTypes.ANNUAL,
    frequency: 'year',
    planDetails: [
      {
        title: '',
        details: [
          {
            icon: 'check',
            text:
              'Full access to PokerGO livestreaming: Enjoy 100+ days of premier poker events such as the WSOP, Super High Roller Bowl, Poker Masters, and more.'
          },
          {
            icon: 'check',
            text:
              '24/7 on-demand streaming: Watch classic WSOP episodes dating back to 1973, High Stakes Poker, No Gamble, No Future, and more.'
          },
          {
            icon: 'check',
            text: 'Save more than 58% compared to our monthly plan.'
          },
          {
            icon: 'check',
            text:
              'Exclusive promotions and discounts only for Annual and VIP members.'
          },
          {
            icon: 'close',
            fontWeight: 'bold',
            text: 'Does not include PokerU Training'
          }
        ]
      }
    ]
  },
  [ProductTypes.TRAINING_ANNUAL]: {
    name: 'PokerGO VIP',
    price: '299.99',
    priceDescription: '$24.99 / month',
    product_type: ProductTypes.TRAINING_ANNUAL,
    highlight: true,
    frequency: 'year',
    planDetails: [
      {
        title: '',
        details: [
          {
            icon: 'check',
            text:
              'Full PokerGO annual subscriber benefits, including all access to 100+ days of livestreams and all on-demand content.'
          }
        ]
      },
      {
        title: 'Plus additional VIP-only bonuses:',
        details: [
          {
            icon: 'check',
            fontWeight: 'bold',
            endSlotName: 'learn_more',
            text:
              'Exclusive Membership to PokerU: Learn from top poker pros with unlimited access to 100 elite poker strategy videos powered by Run It Once.'
          },
          {
            icon: 'check',
            text: 'Special VIP-only events.'
          },
          {
            icon: 'check',
            text: '$25 merchandise credit at PokerGO Shop (one-time use).'
          }
        ]
      }
    ]
  }
}

export const discountedPlans = {
  [ProductTypes.ANNUAL_DISCOUNT]: {
    name: 'ANNUAL',
    product_type: ProductTypes.ANNUAL_DISCOUNT,
    ribbon: 'SAVE 20%',
    striked: basePlans[ProductTypes.ANNUAL].price,
    price: 79.99,
    planDetails: basePlans[ProductTypes.ANNUAL].planDetails
  },
  [ProductTypes.QUARTERLY_DISCOUNT]: {
    name: 'QUARTERLY',
    product_type: ProductTypes.QUARTERLY_DISCOUNT,
    ribbon: 'SAVE 25%',
    striked: basePlans[ProductTypes.QUARTERLY].price,
    price: 29.99,
    planDetails: basePlans[ProductTypes.QUARTERLY].planDetails
  }
}

export const cancellationFlow = {
  [ProductTypes.MONTHLY]: {
    steps: []
  },
  [ProductTypes.QUARTERLY]: {
    steps: [
      [
        basePlans[ProductTypes.MONTHLY],
        discountedPlans[ProductTypes.ANNUAL_DISCOUNT]
      ]
    ]
  },
  [ProductTypes.ANNUAL]: {
    steps: [
      [discountedPlans[ProductTypes.ANNUAL_DISCOUNT]],
      [
        basePlans[ProductTypes.MONTHLY],
        discountedPlans[ProductTypes.QUARTERLY_DISCOUNT]
      ]
    ]
  },
  [ProductTypes.TRAINING_ANNUAL]: {
    steps: [
      [basePlans[ProductTypes.ANNUAL]],
      [
        basePlans[ProductTypes.MONTHLY],
        discountedPlans[ProductTypes.QUARTERLY_DISCOUNT],
        discountedPlans[ProductTypes.ANNUAL_DISCOUNT]
      ]
    ]
  }
}

export const upgradablePlans = {
  [ProductTypes.MONTHLY]: [
    ProductTypes.QUARTERLY,
    ProductTypes.ANNUAL,
    ProductTypes.TRAINING_ANNUAL
  ],
  [ProductTypes.QUARTERLY]: [ProductTypes.ANNUAL, ProductTypes.TRAINING_ANNUAL],
  [ProductTypes.ANNUAL]: [ProductTypes.TRAINING_ANNUAL],
  [ProductTypes.TRAINING_ANNUAL]: []
}
