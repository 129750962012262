<template>
  <div class="cancel-page">
    <div class="cancel-page__container">
      <div class="cancel-page__container--header">
        <h1>{{ pageTitle }}</h1>
        <p
          class="text-white"
          v-if="this.$route.query.plan && this.$route.query.plan !== 'annual'"
        >
          Keep subscribing to PokerGO with this special offer
        </p>
      </div>
      <div class="cancel-page__container--footer">
        <p class="text-white">
          {{ cancellationNote }}
        </p>
      </div>
      <div class="cancel-page__container--body">
        <div class="cancel-page__container--form mb-10">
          <p
            v-if="this.$route.query.plan && this.$route.query.plan !== 'annual'"
            class="cancel-page__container--form--label"
          >
            LIMITED TIME OFFER!
          </p>
          <div class="special_offer" style="display: flex; gap: 20px">
            <RegisterPlan
              v-for="(p, i) in filteredOfferPlans"
              :showSelectPlanBtn="false"
              :showAcceptNewOfferBtn="true"
              :showCancelSubscriptionBtn="filteredOfferPlans.length < 2"
              :plan="p"
              :key="i"
              :disableActions="isPaymentProcessing"
              @accept-offer="takeDiscount(p)"
              @cancel-subscription="cancelClickHandle"
            />
          </div>
        </div>
      </div>

      <div class="btn-cancel-container" v-if="filteredOfferPlans.length > 1">
        <button
          class="btn-cancel-subscription"
          @click="cancel"
          :disabled="isPaymentProcessing"
        >
          Cancel Subscription
        </button>
      </div>
    </div>
    <ConfirmationDialog :modelOptions="modelOptions" v-if="modelOptions.open" />
  </div>
</template>
<script>
import VueScript2 from 'vue-script2'
import { mapGetters, mapState } from 'vuex'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import RegisterPlan from '@/views/register/components/RegisterPlan.vue'
import {
  basePlans,
  cancellationFlow,
  discountedPlans,
  ProductTypes
} from '@/library/config/plans'
import _ from 'lodash'
export default {
  name: 'CancelSpecialOffer',
  components: { RegisterPlan, ConfirmationDialog },
  data() {
    return {
      currentStep: 1,
      totalStep: 1,
      preventMultiSubmit: false,
      offerPlans: [
        basePlans[ProductTypes.ANNUAL],
        ..._.values(discountedPlans)
      ],
      modelOptions: {
        open: false,
        header: '',
        description: '',
        type: 'success',
        onClose: () => {}
      }
    }
  },
  computed: {
    ...mapState({
      controls: (state) => state.checkout.controls,
      processing: (state) => state.checkout.controls.loading,
      plans: (state) => state.checkout.plans,
      subscription: (state) => state.user.subscription
    }),
    ...mapGetters(['isAuthenticated', 'user']),
    isPaymentProcessing() {
      return this.processing || this.preventMultiSubmit
    },
    filteredOfferPlans() {
      if (this.$route.query.plan) {
        return this.offerPlans.filter(
          (el) => el.product_type === this.$route.query.plan
        )
      } else
        return cancellationFlow[this.subscription.interval].steps[
          this.currentStep - 1
        ]
    },
    pageTitle() {
      if (this.$route.query.plan && this.$route.query.plan === 'annual') {
        return 'Downgrade Subscription'
      } else {
        return ' Special Offer'
      }
    },
    displayedPlanPrice() {
      if (this.filteredOfferPlans.length > 0) {
        return this.filteredOfferPlans[0].price
      }
      return ''
    },
    cancellationNote() {
      return this.subscription.interval !== 'training_annual'
        ? 'If you select a new plan, your current plan’s rate will prorate into the new plan effective immediately. If you have any questions, please feel free to contact Customer Service at 1-866-476-5374'
        : `By accepting this offer, you agree that your new PokerGO annual subscription will begin the day your PokerGO VIP annual subscription ends. You won’t be charged $${this.displayedPlanPrice} until your current PokerGO VIP subscription expires.`
    }
  },
  async mounted() {
    if (!this.isAuthenticated) {
      await this.$router.push('/login')
      return
    }

    this.totalStep = cancellationFlow[this.subscription.interval].steps.length
    await VueScript2.load('https://js.stripe.com/v3/')
    this.stripe = window.Stripe(process.env.STRIPE_KEY)
  },
  methods: {
    confirm() {
      this.$router.push('/account/cancel/confirm')
    },
    async takeDiscount(plan) {
      this.$store.commit('checkoutControls', { loading: true })
      if (this.preventMultiSubmit) return
      this.preventMultiSubmit = true

      try {
        let payload = {
          interval: plan.product_type
        }

        this.$store
          .dispatch('account/TakeDiscount', payload)
          .then(async (rs) => {
            if (rs.status !== 200) {
              this.preventMultiSubmit = false
              this.$toasted.error(
                'Error cannot complete taking discount subscription'
              )
              return
            }

            this.modelOptions = {
              open: true,
              header: 'CHANGED',
              description:
                'Your subscription plan has been successfully changed.',
              type: 'success',
              onClose: () => {
                this.$router.push('/account')
              }
            }

            this.$showMessage({
              title: 'Update Subscription Success',
              text: 'Subscription Successfully Updated'
            })
          })
        this.$store.commit('checkoutControls', { loading: false })
      } catch (err) {
        this.$store.commit('checkoutControls', { loading: false })
        this.preventMultiSubmit = false
        this.$toasted.error('Error')
      }
    },
    async cancelClickHandle() {
      if (this.currentStep < this.totalStep) {
        this.currentStep++
      } else {
        await this.cancel()
      }
    },
    async cancel() {
      this.$store.commit('checkoutControls', { loading: true })
      if (this.preventMultiSubmit) return
      this.preventMultiSubmit = true

      setTimeout(() => (this.preventMultiSubmit = false), 1000)
      try {
        this.$store.commit('checkoutControls', { loading: false })
        this.$store.dispatch('account/Cancel').then((rs) => {
          if (rs.status !== 200) {
            this.$toasted.error(
              'Error cannot complete cancel of subscriptionnnn'
            )
            return
          }
          this.$showMessage({
            title: 'Cancel Subscription Success',
            text: 'Subscription Successfully Canceled'
          })
          this.confirm()
        })
      } catch (err) {
        this.$store.commit('checkoutControls', { loading: false })
        this.$toasted.error('Error')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cancel-page__container--form--label {
  margin-bottom: 0;
}
.cancel-page__container--body {
  display: flex;
  justify-content: center;
}
.cancel-page__container--footer {
  max-width: 540px;
  color: grey;

  margin: 10px auto 0 auto;

  p {
    text-align: center;
  }
}
button:disabled {
  cursor: not-allowed;
}

.btn-cancel-container {
  text-align: center;
  margin: 0 20px 20px;
  .btn-cancel-subscription {
    width: 40%;
    color: #8a0eb1;
    background-color: #f6e9fa;
    font-size: 1rem;
    height: 45px;
    border: none;
    border-radius: 5px;
  }
}
@media (max-width: 786px) {
  .special_offer {
    flex-direction: column;
    padding: 0px 30px;
  }
  .btn-cancel-subscription {
    width: 200px !important;
  }
}
</style>
