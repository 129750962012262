var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register flex-column-center" },
    [
      _c("h1", [_vm._v("CONFIRM CHANGE")]),
      _c("UpgradeCheckout", {
        key: _vm.$route.fullPath,
        on: { subscribe: _vm.subscribe }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }