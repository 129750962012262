<template>
  <div class="modal-background" @click="$emit('no')">
    <div class="modal-content">
      <div class="modal-body" @click.stop>
        <p class="modal-top">
          <i
            class="fa fa-exclamation-circle"
            style="font-size:32px;color:#b31313"
          ></i>
          WARNING!
        </p>
        <div class="modal-header">
          <div class="modal-heading">
            <p>
              If you're paying with your bank account through Paypal it will
              take 2-3 business days for Paypal to clear your funds. You will
              not gain immediate access to your PokerGO subscription until your
              funds have been cleared through Paypal.
            </p>
          </div>
        </div>
        <br />
        <div class="modal-buttons">
          <div class="modal-button">
            <a
              class="button is-primary save-button"
              @click.stop="$emit('continue', $event)"
              >I understand</a
            >
          </div>
          <div class="modal-button">
            <a
              class="button is-primary save-button"
              @click.prevent="$emit('cancel')"
              >Cancel</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import Vue from 'vue'

export default Vue.extend({
  name: 'AreYouSure',
  props: {
    to: {
      type: String,
      required: false,
      default: undefined
    },
    routerReplace: {
      type: Boolean
    },
    pending: {
      type: Boolean
    }
  }
})
</script>
<style lang="scss" scoped>
.modal-background {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #0004;

  .modal-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    min-width: 600px;
    min-height: 200px;
    overflow: hidden;
    text-align: center;
    border-radius: 15px;
    border: solid 2px #9f26b5;
    background-color: white;
    width: 90%;
    margin: 0 auto;
    .modal-body {
      width: 100%;
      .modal-top {
        font-size: 32px;
        font-style: italic;
        color: #b31313;
        padding-top: 15px;
      }
      .modal-header {
        margin-top: 0px;
        margin-right: 10px;
        margin-left: 10px;
        .modal-heading {
          font-size: 26px;
          text-align: left;
          padding: 10px;
          line-height: 1em;
          color: black;
        }
      }
      .modal-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      .modal-button {
        padding-right: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        .save-button {
          width: 200px;
          font-size: 14px;
          border-radius: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .modal-content {
      min-height: 0px;
      min-width: 0px;
      .modal-body {
        .modal-header {
          .modal-heading {
            font-size: 14px;
          }
        }
        .modal-buttons {
          .modal-button {
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    .modal-content {
      min-height: 0px;
      min-width: 0px;
      .modal-body {
        .modal-header {
          .modal-heading {
            padding: 0;
            font-size: 16px;
          }
        }
        .modal-buttons {
          flex-wrap: wrap;
          .modal-button {
            padding-right: 2px !important;
            padding-left: 2px !important;
            padding-bottom: 10px;
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .form-field.is-horizontal {
    display: flex;
    flex-flow: row;
    justify-content: space-around;

    .button {
      width: 100px;
    }
  }
}
</style>
