export const settings = {
  gameMailingList: 'https://',
  baseURL: process.env.API_ADDRESS || 'http://localhost:6868',
  chatURLStatus: process.env.CHAT_API_ADDRESS || 'http://localhost:7777',
  chatURLSocket: process.env.CHAT_SOCKET_ADDRESS || 'ws://localhost:7777',
  videoProgressBaseURL:
    process.env.VIDEO_PROGRESS_API_ADDRESS || 'http://localhost:6001',
  subscriberBaseURL:
    process.env.SUBSCRIPTION_SERVICE_URL || 'http://localhost:2000',
  baseWebsiteURL:
    window.location.protocol + '//' + window.location.hostname + ':3000',
  propertyID: process.env.PROPERTY_ID || 'pokergo-staging',
  deviceToken:
    process.env.DEVICE_TOKEN || 'adf60227-7f55-4b54-9eea-bbf4d5d6443e',
  googleAnalyticsID: process.env.GOOGLE_ANALYTICS_ID || 'UA-78131689-1',
  googleOptimizeID: process.env.GOOGLE_OPTIMIZE_ID || '',
  baseEventURL:
    process.env.EVENTS_API_ADDRESS || 'http://localhost:5000/api/v1/',
  applicationVersion:
    process.env.WEB_VERSION && process.env.WEB_VERSION !== 'undefined'
      ? process.env.WEB_VERSION
      : '2.0'
}
