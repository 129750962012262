var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayOverlay
    ? _c("div", { staticClass: "overlay-container" }, [
        _c(
          "div",
          { ref: "myElement", staticClass: "overlay bg-card-suits" },
          [
            _c("font-awesome-icon", {
              staticClass: "close-icon",
              attrs: { icon: "times" },
              on: { click: _vm.closeDialog }
            }),
            _c("div", { staticClass: "main-container" }, [
              _c("img", {
                staticClass: "pokerplay-logo",
                attrs: {
                  src: require("@/assets/images/pokergoplay/pokergoplay.webp")
                }
              }),
              _vm._m(0),
              _vm._m(1),
              _vm._m(2),
              _c("div", { staticClass: "check-box-container" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.doNotShowAgain,
                      expression: "doNotShowAgain"
                    }
                  ],
                  staticStyle: { "margin-right": "8px" },
                  attrs: { type: "checkbox", id: "do-not-show" },
                  domProps: {
                    checked: Array.isArray(_vm.doNotShowAgain)
                      ? _vm._i(_vm.doNotShowAgain, null) > -1
                      : _vm.doNotShowAgain
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.doNotShowAgain,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.doNotShowAgain = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.doNotShowAgain = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.doNotShowAgain = $$c
                      }
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "do-not-show-text",
                    attrs: { for: "do-not-show" }
                  },
                  [_vm._v(" Do not show this again ")]
                )
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "join-text" }, [
      _vm._v(" Join the community at "),
      _c("br"),
      _vm._v("PokerGO Play and win real "),
      _c("br"),
      _vm._v("prizes playing real poker. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://pokergoplay.com/", target: "_blank" }
        },
        [_vm._v(" Play Now ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "promo-text-container" }, [
      _vm._v(" Use code "),
      _c("span", { staticClass: "promo-code" }, [_vm._v("POKERGOPLAY")]),
      _c("span", { staticStyle: { display: "block" } }, [
        _vm._v("for extra chips.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }