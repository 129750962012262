var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    !_vm.entities.length
      ? _c("div", { staticClass: "my-list-container" }, [
          _vm._v(" There is no content in your list. ")
        ])
      : _c(
          "div",
          { staticClass: "flex-column" },
          _vm._l(_vm.entities, function(entity) {
            return _c(
              "div",
              { key: entity.id },
              [
                _c("font-awesome-icon", {
                  staticClass: "my-list-trash",
                  attrs: { icon: "times", size: "lg" },
                  on: {
                    click: function($event) {
                      return _vm.deleteFavorite(entity)
                    }
                  }
                }),
                _c(
                  "router-link",
                  {
                    staticClass: "row",
                    attrs: { to: "/" + entity.entity_type + "s/" + entity.id }
                  },
                  [
                    _c("div", { staticClass: "thumbnail" }, [
                      _c("img", { attrs: { src: _vm.overlayImage(entity) } }),
                      _vm.getVideoProgressPercent(entity.id)
                        ? _c("i", { staticClass: "progress-bar-bg" })
                        : _vm._e(),
                      _vm.getVideoProgressPercent(entity.id)
                        ? _c("i", {
                            staticClass: "progress-bar",
                            style: {
                              width:
                                String(_vm.getVideoProgressPercent(entity.id)) +
                                "%"
                            }
                          })
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "description" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(entity.title))
                      ]),
                      _vm._v(" " + _vm._s(entity.description) + " ")
                    ])
                  ]
                )
              ],
              1
            )
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }