<template>
  <div class="picker-container">
    <div class="grid-container">
      <div v-for="sticker in stickers">
        <img
          class="sticker"
          :src="`${sticker.value}`"
          @click="sendSticker(sticker)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'StickerPicker',
  data() {
    return {}
  },
  computed: {
    ...mapState({
      stickers: (state) => state.chat.stickers
    })
  },
  methods: {
    sendSticker(sticker) {
      this.$emit('send-emoji', sticker)
    }
  }
}
</script>

<style scoped>
.picker-container {
  position: absolute;
  background-color: white !important;
  padding: 10px;
  border-radius: 5px;
  height: 420px;
  width: 332px;
  overflow-y: scroll;
}
.sticker {
  max-height: 100px;
  max-width: 100px;
  cursor: pointer;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 10px;
  text-align: center;
}
</style>
