<script>
export default {
  name: 'Main',
  computed: {
    showMarketingTopHeaderText() {
      return process.env.MARKETING_TOP_HEADER_TEXT === 'show'
    }
  }
}
</script>

<template>
  <div class="relative">
    <div class="absolute top-0 w-full h-full bg-black">
      <img
        class="w-full h-full object-cover opacity-40"
        src="@/assets/images/marketing/background.jpg"
      />
    </div>
    <div class="z-20 relative mx-auto py-20 text-center">
      <div class="text-3xl mb-4" v-if="showMarketingTopHeaderText">
        <a
          href="https://www.pokergo.com/collections/ed83f4b7-48e5-49a4-880c-85dc4777b422"
          target="_blank"
        >
          <div class="flex justify-center px-3">
            <span class="header-glowing-box flex px-4 py-3">
              Click here: Today Only: Watch 2024 WSOP Main Event Day 1D for FREE
            </span>
          </div>
        </a>
      </div>
      <div class="text-5xl">
        PokerGO: YOUR HOME FOR<br />POKER ENTERTAINMENT
      </div>
      <div
        class="text-5xl font-bold mt-3"
        style="font-family: UniSans-Bold-Italic"
      >
        GET IN THE GAME!
      </div>
      <div class="text-2xl mt-4">
        PLANS AS LOW AS $8.34/MONTH*
      </div>
      <div class="text-sm -mt-2">
        *WHEN BILLED ANNUALLY
      </div>
      <div class="text-2xl mt-1" style="font-family: UniSans-Bold">
        JOIN TODAY AND START WATCHING!
      </div>

      <router-link
        to="/register/select-plan"
        class="glowing-btn text-xl w-80 py-2 mx-auto mt-4"
      >
        SELECT YOUR PLAN
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header-glowing-box {
  color: white;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: $poker-accent;
  box-shadow: inset 0 0 20px 10px #9f26b5, 0 0 20px 10px #9f26b5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  &:hover {
    background-color: transparent;
  }
}
</style>
