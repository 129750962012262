<script>
export default {
  name: 'Main',
  computed: {
    showMarketingTopHeaderText() {
      return process.env.MARKETING_TOP_HEADER_TEXT === 'show'
    }
  },
  methods: {
    scrollToContent() {
      window.scrollTo({
        top: window.innerHeight - 223,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<template>
  <div class="relative">
    <div class="absolute top-0 w-full h-full bg-black">
      <img
        class="w-full h-full object-cover opacity-40"
        src="@/assets/images/marketing/background.jpg"
      />
    </div>
    <div class="z-20 relative mx-auto py-20 md:py-20 text-center">
      <div class="text-3xl mb-4" v-if="showMarketingTopHeaderText">
        <a
          href="https://www.pokergo.com/collections/ed83f4b7-48e5-49a4-880c-85dc4777b422"
          target="_blank"
        >
          <div class="flex justify-center px-3">
            <span class="header-glowing-box flex px-4 py-3">
              Click here: Today Only: Watch 2024 WSOP Main Event Day 1D for FREE
            </span>
          </div>
        </a>
      </div>

      <div ref="contentRef" class="text-center">
        <div class="hidden md:block">
          <h1 class=" font-bold text-4xl mb-4 uppercase">
            Your home for poker entertainment!
          </h1>
          <div
            class="text-3xl font-bold mb-4"
            style="font-family: UniSans-Bold-Italic"
          >
            GET IN THE GAME!
          </div>
        </div>

        <div
          class="md:hidden flex flex-col justify-center"
          style="height: calc(100vh - 143px); margin-top: -80px"
        >
          <h1 class="font-bold text-4xl uppercase">
            Your home for poker entertainment!
          </h1>

          <div class="my-5 flex flex-col justify-center  md:hidden px-3">
            <div class="flex flex-col justify-center gap-3">
              <div class="submit-payment" @click="scrollToContent">
                <i class="fa fa-play mr-2"></i> Watch Poker
              </div>
              <a
                class="submit-payment gap-2"
                href="https://playpokergo.onelink.me/YuJc/nh56lr4w"
                target="_blank"
              >
                <svg
                  class="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  style="fill: white; width: 17px"
                >
                  <path
                    d="M7.5 194.9c-15.4-26.6-6.3-60.7 20.4-76.1L220.7 7.5c26.6-15.4 60.7-6.3 76.1 20.4l167 289.3c15.4 26.6 6.2 60.7-20.4 76.1L250.5 504.5c-26.6 15.4-60.7 6.2-76.1-20.4L7.5 194.9zM197 159.6c-11.1-3-22.6 3.6-25.6 14.8l-7.2 26.9-20.4 76.1c-7 26 8.5 52.7 34.4 59.7s52.7-8.5 59.7-34.4l2.4-8.8c.1-.4 .2-.8 .3-1.1l17.7 30.7-12.1 7c-6.7 3.8-8.9 12.4-5.1 19s12.4 8.9 19 5.1l48.2-27.8c6.7-3.8 8.9-12.4 5.1-19s-12.4-8.9-19-5.1l-12.1 7-17.7-30.7c.4 .1 .8 .2 1.1 .3l8.8 2.4c26 7 52.7-8.5 59.7-34.4s-8.5-52.7-34.4-59.7l-76.1-20.4L197 159.6zM459.4 420.9c41.9-24.2 56.3-77.8 32.1-119.8L354.7 64.2c1.7-.2 3.5-.2 5.3-.2l224 0c30.9 0 56 25.1 56 56l0 336c0 30.9-25.1 56-56 56l-224 0c-13.7 0-26.2-4.9-35.9-13l135.3-78.1zm74.5-183.3L582 187.6c14-14.5 13.2-38.5-2.2-51.9c-14-11.7-34.5-9.5-46.9 3.2l-4.7 5.2-5-5.2c-12.5-12.7-33.2-15-46.7-3.2c-15.7 13.5-16.5 37.5-2.5 51.9l48.4 49.9c3 3.2 8.2 3.2 11.5 0z"
                  />
                </svg>
                Play Now
              </a>
            </div>
          </div>

          <div
            class="text-3xl font-bold mb-20"
            style="font-family: UniSans-Bold-Italic"
          >
            GET IN THE GAME!
          </div>
        </div>

        <div class="font-medium text-2xl mb-1">
          JOIN TODAY AND START WATCHING!
        </div>
        <div class="font-medium text-lg">PLANS AS LOW AS $8.34/MONTH*</div>
        <router-link
          to="/register/select-plan"
          class="glowing-btn text-xl w-80 py-2 mx-auto mt-4"
        >
          SELECT YOUR PLAN
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header-glowing-box {
  color: white;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: $poker-accent;
  box-shadow: inset 0 0 20px 10px #9f26b5, 0 0 20px 10px #9f26b5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  &:hover {
    background-color: transparent;
  }
}

.submit-payment {
  height: 48px;
  width: 100%;
  font-weight: bold;
  color: #fff;
  background-color: #9f26b5;
  border: none;
  border-radius: 5px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit-payment:hover,
.submit-payment:active {
  opacity: 0.8;
}
</style>
