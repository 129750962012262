import api from '@/api'

const state = {
  participants: [],
  participantsCount: 0,
  visitedChats: [],
  stickers: []
}

const actions = {
  getStatusData({ commit }, videoId) {
    return api.chat
      .getStatusData(videoId)
      .then((res) => {
        return res
      })
      .catch((err) => {
        if (err) {
          commit('checkoutError', { message: 'Chat Error ' + err })
        }
      })
  },
  fetchChatParticipants({ commit }, idVideo) {
    return api.chat
      .getParticipants(idVideo)
      .then((res) => {
        const participants = res.data
        commit('setChatParticipants', participants)
      })
      .catch((err) => {
        if (err) {
          commit('checkoutError', { message: 'Chat Error ' + err })
        }
      })
  },
  fetchChatParticipantsCount({ commit }, idVideo) {
    return api.chat
      .getParticipantsCount(idVideo)
      .then((res) => {
        const participants = res.data
        commit('setChatParticipantsCount', participants)
      })
      .catch((err) => {
        if (err) {
          commit('checkoutError', { message: 'Chat Error ' + err })
        }
      })
  },
  fetchChatHistory({ commit }, videoId) {
    return api.chat.fetchChatHistory(videoId).then((res) => {
      return res.data
    })
  },
  getUserInfo({ commit }, videoId) {
    return api.chat
      .getUserInfo(videoId)
      .then((res) => {
        return res
      })
      .catch((err) => {
        if (err) {
          commit('checkoutError', { message: 'Chat Error ' + err })
        }
      })
  },
  reportParticipants({ commit }, payload) {
    return api.chat
      .reportParticipants(payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        if (err) {
          commit('checkoutError', { message: 'Chat Error ' + err })
        }
      })
  },
  fetchStickers({ commit }) {
    return api.chat
      .fetchStickers()
      .then((res) => {
        console.log('STICKERS')
        commit('setStickers', res.data)
        return res
      })
      .catch((err) => {
        if (err) {
          commit('fetchStickers', { message: 'Fetch Stickers Error ' + err })
        }
      })
  }
}

const mutations = {
  mutDataStatus: (state, payload) => {
    state.statusData = payload
  },
  setChatParticipants: (state, payload) => {
    state.participants = payload.data.participants
  },
  setChatParticipantsCount: (state, payload) => {
    state.participantsCount = payload.data
  },
  addVisitedChat: (state, videoId) => {
    if (state.visitedChats.indexOf(videoId) > -1) return

    state.visitedChats = [...state.visitedChats, videoId]
  },
  setStickers: (state, stickers) => {
    state.stickers = stickers
  }
}

const getters = {
  getChatParticipants(state) {
    return state.participants
  }
}

export default {
  actions,
  getters,
  mutations,
  state
}
