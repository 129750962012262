<template>
  <div class="modal-background" @click="$emit('no')">
    <div class="modal-content">
      <div class="modal-body" @click.stop>
        <div class="modal-icon">
          <img src="@/assets/images/green-check-14.svg" class="imgicon" />
          <span>CHANGED</span>
        </div>
        <div name="modal-header" class="modal-head">
          <p v-if="text === undefined" class="modal-heading">
            You have unsaved changes. Leave the page without saving them?
          </p>
          <p v-if="text !== undefined" class="modal-heading" v-html="text">
            {{ text }}
          </p>
        </div>
        <div name>
          <button
            class="button is-primary save-button"
            @click.prevent="$emit('yes')"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: 'GenericModal',
  props: {
    text: {
      type: String,
      required: false,
      default: undefined
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #0004;

  .modal-content {
    color: black;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 50%;
    min-width: 400px;
    min-height: 200px;
    overflow: hidden;
    text-align: center;
    padding: 8px;
    .modal-head {
      padding-top: 10px;
    }
    .modal-body {
      border: 1px solid #9f26b5;
      border-radius: 10px;
      padding: 20px;
      background-color: white;
      .modal-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        .imgicon {
          width: 25px;
        }
        span {
          height: 15px;
        }
      }
      .modal-header {
        margin-top: 50px;
        margin-right: 10px;
        margin-left: 10px;
      }
      .modal-heading {
        padding-top: 10px;
      }
    }
  }

  .form-field.is-horizontal {
    display: flex;
    flex-flow: row;
    justify-content: space-around;

    .button {
      width: 100px;
    }
  }
}
</style>
