<template>
  <div class="page">
    <div class="home-container">
      <main class="main">
        <div class="wrapper">
          <section class="try-pocker-free">
            <h3>TRY POKER FREE</h3>
            <div class="try-pocker-free__content">
              <div class="left">
                <h1>Watch Exclusive Live Poker Tournaments & TV Shows</h1>
                <h4>Thousands of live poker events & original shows</h4>
                <h4>Live and On Demand</h4>
                <div class="subscribe">
                  <div class="subscribe-btn">
                    <router-link to="/register">
                      Subscribe to watch Live Poker Events & Shows
                    </router-link>
                  </div>
                  <div class="subscribe-login">
                    or <router-link to="/login">Login</router-link>
                  </div>
                </div>
                <p>
                  "PokerGo is the driving force of poker's future being very
                  bright."
                </p>
                <div class="popculture">
                  <div class="around">
                    <div class="x">PC</div>
                  </div>
                  <span>popculture.</span>
                </div>
              </div>
              <div class="right">
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      LIVE EVENTS
                    </button>
                    <!-- <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile"
                                    aria-selected="false">UPCOMING TOURNAMENTS</button> -->
                  </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div class="list-item-video" v-if="this.scheduleData.data">
                      <div
                        class="item-video"
                        v-for="item in this.scheduleData.data.collection_list.slice(
                          0,
                          3
                        )"
                        :key="item.id"
                      >
                        <video
                          width="150"
                          height="100"
                          controls
                          :poster="item.images[0].url"
                          style="object-fit:cover"
                        >
                          <source :src="item.source" type="video/mp4" />
                        </video>
                        <div class="desc-video" @click="linkToSchedule">
                          <h3 v-if="item.label.length > 20">
                            {{ item.label.substring(0, 50) + '...' }}
                          </h3>
                          <h3 v-if="item.label.length < 20">
                            {{ item.label }}
                          </h3>
                          <p v-if="item.description.length > 60">
                            {{ item.description.substring(0, 60) + '...' }}
                          </p>
                          <p v-if="item.description.length < 60">
                            {{ item.description }}
                          </p>
                        </div>
                      </div>
                      <!-- <div class="item-video">
                        <video
                          width="150"
                          height="100"
                          controls
                          poster="../../assets/images/pic3.jpeg"
                          style="object-fit:cover"
                        >
                          <source
                            src="https://www.w3schools.com/html/movie.mp4"
                            type="video/mp4"
                          />
                        </video>
                        <div class="desc-video">
                          <h3>All-Start ProAm 2021 | Day 1</h3>
                          <p>
                            All-Star ProAm live from the PokerGo Studio at ARIA
                            Resort & Casino
                          </p>
                        </div>
                      </div>
                      <div class="item-video">
                        <video
                          width="150"
                          height="100"
                          controls
                          poster="../../assets/images/pic4.jpeg"
                          style="object-fit:cover"
                        >
                          <source
                            src="https://www.w3schools.com/html/movie.mp4"
                            type="video/mp4"
                          />
                        </video>
                        <div class="desc-video">
                          <h3>Stairway To Millioms 2022</h3>
                          <p>
                            $2,000 No-Limit Hold'em final table from the 2022
                            Stairway To Millions
                          </p>
                        </div>
                      </div> -->
                      <router-link class="more-video" to="/schedule">
                        More Live Events
                      </router-link>
                    </div>
                    <!-- when collection_list empty-->
                    <div
                      class="list-item-video"
                      v-if="this.scheduleData.data == undefined"
                    >
                      <div class="item-video">
                        <video
                          width="150"
                          height="100"
                          controls
                          poster="../../assets/images/pic1.jpeg"
                          style="object-fit:cover"
                        >
                          <source
                            src="https://www.w3schools.com/html/movie.mp4"
                            type="video/mp4"
                          />
                        </video>
                        <div class="desc-video">
                          <h3>All-Start ProAm 2021 | Day 1</h3>
                          <p>
                            All-Star ProAm live from the PokerGo Studio at ARIA
                            Resort & Casino
                          </p>
                        </div>
                      </div>
                      <div class="item-video">
                        <video
                          width="150"
                          height="100"
                          controls
                          poster="../../assets/images/pic3.jpeg"
                          style="object-fit:cover"
                        >
                          <source
                            src="https://www.w3schools.com/html/movie.mp4"
                            type="video/mp4"
                          />
                        </video>
                        <div class="desc-video">
                          <h3>All-Start ProAm 2021 | Day 1</h3>
                          <p>
                            All-Star ProAm live from the PokerGo Studio at ARIA
                            Resort & Casino
                          </p>
                        </div>
                      </div>
                      <div class="item-video">
                        <video
                          width="150"
                          height="100"
                          controls
                          poster="../../assets/images/pic4.jpeg"
                          style="object-fit:cover"
                        >
                          <source
                            src="https://www.w3schools.com/html/movie.mp4"
                            type="video/mp4"
                          />
                        </video>
                        <div class="desc-video">
                          <h3>Stairway To Millioms 2022</h3>
                          <p>
                            $2,000 No-Limit Hold'em final table from the 2022
                            Stairway To Millions
                          </p>
                        </div>
                      </div>
                      <router-link class="more-video" to="/schedule">
                        More Live Events
                      </router-link>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    UPCOMING TOURNAMENTS
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      entities: []
    }
  },
  // created(){
  //   console.log(',,,,,,,,,,,,,,,,,',this.scheduleData.data.collection_list);
  // },
  methods: {
    linkToSchedule() {
      this.$router.push('/schedule')
    }
  },
  mounted() {
    console.log('===========', this.scheduleData.data)
  },
  computed: {
    scheduleData() {
      return this.$store.state.schedule.scheduleData
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  margin-top: 20px;
  padding-bottom: 40px !important;
}
.main {
  width: 100%;

  .wrapper {
    width: 1200px;
    margin: 0 auto;
    padding: 0 !important;

    .try-pocker-free {
      margin-top: 30px;

      h3 {
        font-size: 16px;
        color: #a528bc;
      }
      &__content {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 50%;
          h1 {
            color: #ffffff;
            font-size: 4rem;
            line-height: 1.2;
            margin-bottom: 40px;
            font-weight: 600;
          }
          h4 {
            opacity: 0.5;
            color: #ffffff;

            font-size: 24px;
            letter-spacing: 0;
            line-height: 36px;
          }
          .subscribe {
            display: flex;
            font-size: 14px;
            margin-top: 60px;
            &-btn {
              background: #60186d;
              height: 60px;
              line-height: 60px;
              text-align: center;
              border-radius: 5px;
              font-weight: 500;
              a {
                padding: 20px 40px;
                text-decoration: none;
                color: #ffffff;

                height: 19px;

                font-size: 16px;
                letter-spacing: 0;
                line-height: 19px;
              }
            }
            &-btn:hover {
              background: #301934;
              transition: 0.3s ease-in;
            }
            &-login {
              height: 60px;
              line-height: 60px;
              text-align: center;
              border-radius: 5px;
              margin-left: 20px;
              font-weight: 500;
              color: #fff;
              margin-bottom: 30px;
              font-size: 16px;

              color: #ffffff;

              font-size: 16px;
              letter-spacing: 0;

              a {
                color: #fff;
                margin-left: 5px;
              }
            }
          }
          p {
            height: 19px;
            width: 491px;
            color: #ffffff;

            font-size: 16px;
            letter-spacing: 0;
          }
          .popculture {
            display: flex;
            align-items: center;
            .around {
              width: 18px;
              height: 18px;
              background-color: #3b85b9;
              border-radius: 50%;
              font-size: 10px;
              position: relative;
              color: black;
              font-weight: 800;
              .x {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
            span {
              color: #ffffff;
              font-weight: 600;
              font-size: 16px;
              margin-left: 4px;
            }
          }
        }
        .right {
          width: 40%;
          nav {
            .nav-tabs {
              border-style: none;
              color: #ffffff !important;
              margin-bottom: 1.5rem;
              border-bottom: 1px solid #222b3e;
            }
            .nav-link {
              background: none;
              border-style: none;

              font-weight: 500;
              padding-left: 0;
              color: #ffffff;

              font-size: 13px;
              letter-spacing: 0.5px;
            }
            .nav-link:nth-child(1) {
              //   padding: 0;
            }
            .active {
              border-bottom: 4px solid #a528bc;
              color: #ffffff;
            }
          }
          .tab-content {
            color: #fff;
            .tab-pane {
              .list-item-video {
                .item-video {
                  display: flex;
                  height: 120px;
                  margin-bottom: 10px;
                  cursor: pointer;
                  .desc-video {
                    width: 50%;
                    margin-left: 1rem;
                    h3 {
                      font-size: 16px;
                      font-weight: 500;
                      color: #fff;
                      text-transform: capitalize;
                      cursor: pointer;
                    }
                    p {
                      color: #99999c;
                      font-weight: 300;
                      line-height: 1.3;
                      cursor: pointer;
                    }
                  }
                }
                .more-video {
                  color: #a528bc;
                  font-size: 16px;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .main {
    width: 90%;
    margin: 0 auto;

    .wrapper {
      width: 100%;
      .try-pocker-free {
        &__content {
          .left {
            width: 50%;
            h1 {
              color: #fff;
              font-size: 2.5rem;
              line-height: 1.2;
              transition: 0.3s ease-in;
            }
            h4 {
              font-size: 1.5rem;
              color: #ffffff;
              font-weight: 400;
            }
            .subscribe {
              display: flex;
              font-size: 14px;
              margin-top: 60px;
              &-btn {
                background: #60186d;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 5px;
                font-weight: 500;
                a {
                  padding: 20px 40px;
                  text-decoration: none;
                  color: #fff;
                  font-weight: 600;
                }
              }
              &-btn:hover {
                background: #301934;
                transition: 0.3s ease-in;
              }
              &-login {
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 5px;
                margin-left: 20px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 30px;
                font-size: 16px;
                a {
                  color: #fff;
                  margin-left: 5px;
                }
              }
            }
            p {
              color: #ffffff;
              font-weight: 500;
            }
            .popculture {
              display: flex;
              align-items: center;
              img {
                width: 14px;
                height: 14px;
              }
              span {
                color: #ffffff;
                font-weight: 600;
                font-size: 16px;
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .main {
    width: 90%;
    margin: 0 auto;
    transition: 0.5s ease-in;

    .wrapper {
      width: 100%;
      .try-pocker-free {
        &__content {
          .left {
            width: 50%;
            h1 {
              color: #fff;
              font-size: 2rem;
              line-height: 1.2;
              transition: 0.3s ease-in;
            }
            h4 {
              font-size: 1rem;
              transition: 0.3s ease-in;
              color: #ffffff;
              font-weight: 400;
            }
            .subscribe {
              display: flex;
              font-size: 14px;
              margin-top: 60px;
              &-btn {
                background: #60186d;
                height: 40px;
                line-height: 40px;
                transition: 0.3s ease-in;
                text-align: center;
                border-radius: 5px;
                font-weight: 500;
                a {
                  padding: 20px 20px;
                }
              }
              &-login {
                height: 40px;
                line-height: 40px;
                transition: 0.3s ease-in;
                a {
                }
              }
            }
            p {
              font-size: 1rem;
            }
            .popculture {
              display: flex;
              align-items: center;
              img {
                width: 14px;
                height: 14px;
              }
              span {
                color: #ffffff;
                font-weight: 600;
                font-size: 16px;
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .main {
    .wrapper {
      width: 100%;
      .try-pocker-free {
        &__content {
          .left {
            width: 50%;

            .subscribe {
              display: block;
              font-size: 14px;
              margin-top: 60px;
              &-btn {
                background: #60186d;
                height: 40px;
                line-height: 40px;
                transition: 0.3s ease-in;
                text-align: center;
                border-radius: 5px;
                font-weight: 500;
                a {
                }
              }
              &-login {
                height: 40px;
                line-height: 40px;
                transition: 0.3s ease-in;
                a {
                }
              }
            }
            p {
              font-size: 1rem;
              line-height: 1.3;
            }
            .popculture {
              display: flex;
              align-items: center;
              img {
                width: 14px;
                height: 14px;
              }
              span {
                color: #ffffff;
                font-weight: 600;
                font-size: 16px;
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .main {
    padding: 0 max(50% - 700px, 1.5rem);
    width: 100%;
    transition: 0.5s ease-in;
    .wrapper {
      width: 100%;
      .try-pocker-free {
        &__content {
          display: flex;
          flex-wrap: wrap;

          .left {
            width: 100%;
            order: 2;
            h1 {
              color: #fff;
              font-size: 4rem;
              line-height: 1.2;
              font-weight: 500;
              margin-bottom: 40px;
            }
            h4 {
              font-size: 1.5rem;
              color: #ffffff;
              font-weight: 400;
            }
            .subscribe {
              display: flex;
              font-size: 14px;
              margin-top: 60px;
              &-btn {
                background: #60186d;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 5px;
                font-weight: 500;
                a {
                  padding: 20px 40px;
                  text-decoration: none;
                  color: #fff;
                  font-weight: 600;
                }
              }
              &-login {
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 5px;
                margin-left: 20px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 30px;
                font-size: 16px;
                a {
                  color: #fff;
                  margin-left: 5px;
                }
              }
            }
            p {
              color: #ffffff;
              font-weight: 500;
            }
            .popculture {
              display: flex;
              align-items: center;
              img {
                width: 14px;
                height: 14px;
              }
              span {
                color: #ffffff;
                font-weight: 600;
                font-size: 16px;
                margin-left: 4px;
              }
            }
          }
          .right {
            width: 100%;
            order: 1;
            nav {
              .nav-tabs {
                border-style: none;
                color: #ffffff !important;
                margin-bottom: 1.5rem;
                border-bottom: 1px solid #222b3e;
              }
              .nav-link {
                background: none;
                border-style: none;
                color: #ffffff;
                font-weight: 500;
                padding-left: 0;
              }
              .nav-link:nth-child(1) {
                //   padding: 0;
              }
              .active {
                border-bottom: 4px solid #a528bc;
                color: #ffffff;
              }
            }
            .tab-content {
              color: #fff;
              .tab-pane {
                .list-item-video {
                  .item-video {
                    display: flex;
                    height: 120px;
                    margin-bottom: 10px;
                    .desc-video {
                      width: 50%;
                      margin-left: 1rem;
                      h3 {
                        font-size: 16px;
                        font-weight: 500;
                        color: #fff;
                      }
                      p {
                        color: #99999c;
                        font-weight: 300;
                        line-height: 1.3;
                      }
                    }
                  }
                  .more-video {
                    color: #a528bc;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .main {
    padding: 0 max(50% - 700px, 1.5rem);
    width: 100%;
    transition: 0.5s ease-in;
    .wrapper {
      width: 100%;
      .try-pocker-free {
        &__content {
          display: flex;
          flex-wrap: wrap;

          .left {
            width: 100%;
            order: 2;
            h1 {
              color: #fff;
              font-size: 3rem;
              line-height: 1;
              transition: 0.3s ease-in;
              font-weight: 500;
              margin-bottom: 40px;
            }
            h4 {
              font-size: 1.5rem;
              color: #ffffff;
              font-weight: 400;
            }
            .subscribe {
              display: flex;
              flex-wrap: wrap;
              font-size: 14px;
              margin-top: 30px;
              transition: 0.3s ease-in;
              &-btn {
                background: #60186d;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 5px;
                font-weight: 500;
                width: 100%;
                a {
                  padding: 20px 40px;
                  text-decoration: none;
                  color: #fff;
                  font-weight: 600;
                }
              }
              &-login {
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 5px;
                margin-left: 20px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 0px;
                font-size: 16px;
                width: 100%;
                margin-left: 0px;
                a {
                  color: #fff;
                  margin-left: 5px;
                }
              }
            }
            p {
              color: #ffffff;
              font-weight: 500;
            }
            .popculture {
              display: flex;
              align-items: center;
              margin-bottom: 2rem;
              img {
                width: 14px;
                height: 14px;
              }
              span {
                color: #ffffff;
                font-weight: 600;
                font-size: 16px;
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 620px) {
  .main {
    padding: 0 max(50% - 700px, 1.5rem);
    width: 100%;
    transition: 0.5s ease-in;
    .wrapper {
      width: 100%;
      .try-pocker-free {
        &__content {
          display: flex;
          flex-wrap: wrap;

          .left {
            width: 100%;
            order: 2;
            h1 {
              color: #fff;
              font-size: 2.5rem;
              line-height: 1.2;
              transition: 0.3s ease-in;
              font-weight: 500;
              margin-bottom: 20px;
              margin-top: 2rem;
            }
            h4 {
              font-size: 1rem;
              color: #ffffff;
              font-weight: 400;
            }
            .subscribe {
              display: flex;
              flex-wrap: wrap;
              font-size: 14px;
              margin-top: 30px;
              transition: 0.3s ease-in;
              &-btn {
                background: #60186d;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 5px;
                font-weight: 500;
                width: 100%;
                a {
                  padding: 20px 00px;
                  text-decoration: none;
                  color: #fff;
                  font-weight: 600;
                }
              }
              &-login {
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 5px;
                margin-left: 20px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 0px;
                font-size: 16px;
                width: 100%;
                margin-left: 0px;
                a {
                  color: #fff;
                  margin-left: 5px;
                }
              }
            }
            p {
              color: #ffffff;
              font-weight: 500;
            }
            .popculture {
              display: flex;
              align-items: center;
              img {
                width: 14px;
                height: 14px;
              }
              span {
                color: #ffffff;
                font-weight: 600;
                font-size: 16px;
                margin-left: 4px;
              }
            }
          }
          .right {
            .tab-content {
              .tab-pane {
                .list-item-video {
                  .item-video {
                    .desc-video {
                      h3 {
                        font-size: 14px;
                      }
                      p {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .main {
    width: 100%;

    .wrapper {
      .try-pocker-free {
        &__content {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          .right {
            .tab-content {
              color: #fff;
              .tab-pane {
                .list-item-video {
                  .item-video {
                    display: flex;
                    flex-wrap: wrap;
                    height: 120px;
                    margin-bottom: 5rem;
                    video {
                      width: 100%;
                      height: 100%;
                    }
                    .desc-video {
                      width: 100%;
                      margin-left: 0rem;
                      margin-top: 10px;

                      h3 {
                        font-size: 16px;
                        font-weight: 500;
                        color: #fff;
                      }
                      p {
                        color: #99999c;
                        font-weight: 300;
                        line-height: 1.3;
                        margin-bottom: 1rem;
                      }
                    }
                  }
                  .more-video {
                    color: #a528bc;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
          .left {
            h1 {
              font-size: 2rem;
              margin-top: 2rem;
              margin-bottom: 20px;
            }
            .subscribe {
              .subscribe-btn {
                line-height: 20px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 10px;
                line-height: initial;
              }
              .subscribe-login {
                height: 40px;
                line-height: 40px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
