var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cancel-page" }, [
    _c("div", { staticClass: "cancel-page__container" }, [
      _vm._m(0),
      _c("div", { staticClass: "cancel-page__container--form" }, [
        _c("div", {
          staticClass: "cancel-page__container--form--content",
          domProps: { innerHTML: _vm._s(_vm.document.content) }
        }),
        _c("div", { staticClass: "buttons" }, [
          _c(
            "button",
            {
              staticClass: "btn-cancel",
              on: {
                click: function($event) {
                  return _vm.redirect()
                }
              }
            },
            [_vm._v(" Keep My Subscription ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn-continue",
              on: {
                click: function($event) {
                  return _vm.specialOffer()
                }
              }
            },
            [_vm._v(" Continue ")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cancel-page__container--header" }, [
      _c("h1", [_vm._v("New Content now available")]),
      _c("p", [_vm._v(" Lots of exciting, new content weekly on PokerGO! ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }