<template>
  <div class="register flex-column-center">
    <h1>CONFIRM CHANGE</h1>
    <UpgradeCheckout :key="$route.fullPath" @subscribe="subscribe" />
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import UpgradeCheckout from './components/UpgradeCheckout'
import api from '@/api'

export default {
  name: 'UpgradeComplete',
  components: { UpgradeCheckout },
  data() {
    return {
      isPaypal: false,
      isCreditCard: false,
      isAmazon: false,
      type: ''
    }
  },
  computed: {
    user() {
      return this.getEmail || ''
    },
    ...mapState({
      plan: (state) => state.checkout.selectedPlan
    }),
    ...mapGetters({
      checkoutPrice: 'checkoutPrice',
      getEmail: 'getEmail'
    })
  },
  methods: {
    goHome() {
      window.location.href = '/'
    },
    subscribe(payload) {
      const subscription = {
        email: this.username,
        promocode: payload.coupon,
        payment_id: payload.id,
        store: payload.store,
        external_entitlement_id: payload.externalID,
        clear_pending_paypal: payload.clear_pending_paypal
      }
      this.$store
        .dispatch('subscribe', subscription)
        .then((payment_session) => {
          setTimeout(() => {
            dataLayer.push({ event: 'UpgradePlanComplete' })
            console.log('pushed event for UpgradePlanComplete')

            console.log(
              'Facebook purchase complete event fired: payload, ',
              payload
            )

            if (payload.plan)
              api.ga.gatag.call(this, {
                price: payload.plan.price,
                type: payload.plan.type,
                transaction_id: payment_session.ID
              })

            this.$store.commit('checkoutControls', { loading: false })
            if (payload.store === 'paypal') {
              window.location.replace(payment_session.SessionID)
            } else {
              this.$router.push({ path: this.$route.path + '/complete' })
            }
          }, 2000)
        })
        .catch(() => {
          this.$store.commit('checkoutControls', { loading: false })
          setTimeout(() => {
            dataLayer.push({ event: 'PurchaseFailed' })
            console.log('pushed event for PurchaseFailed')
          }, 2000)
        })
    }
  },
  mounted() {
    if (this.$route.query.paypal) {
      this.isPaypal = true
      this.type = 'Paypal'

      // save data to localStorage for hiding 'subscribe' btn
      const currentTime = moment().unix()
      localStorage.setItem('paypalPurchaseTime', currentTime)

      return
    }
    if (this.$route.query.merchant === 'amazon') {
      this.isAmazon = true
      this.type = 'Amazon'
      return
    }
    this.isCreditCard = true
    this.type = 'Credit Card'
  }
}
</script>
<style scoped lang="scss">
.register {
  background-color: #10171f;
  color: #ffffff;
  padding-top: 100px;
  min-height: calc(100vh - 320px);

  h1 {
    text-align: center;
  }
  h2 {
    font-family: 'UniSans-Bold';
    font-style: italic;
    font-size: 1.8rem;
    text-align: center;
  }
  h5 {
    font-family: 'UniSans-Bold';
    font-size: 1.3rem;
    margin-top: 0;
    margin-bottom: 25px;
    max-width: 700px;
    text-align: center;
  }
  .details {
    margin: 20px 0;
    max-width: 600px;
    width: 100%;
    .title {
      font-family: 'UniSans-Bold';
      text-transform: uppercase;
      padding: 1rem;
    }
    .content {
      width: 100%;
      background-color: $input-grey;
      color: $dark-grey;
      height: 120px;
      justify-content: space-around;
      margin-bottom: 20px;
      font-size: 20px;
      padding: 10px;
      .col {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
      }
    }
    button {
      width: 50%;
      min-width: 250px;
      font-size: 23px;
    }
  }

  .apps {
    padding-bottom: 50px;
    .logos-row {
      max-width: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      padding-bottom: 50px;
      img {
        margin: 0 20px;
      }
    }
  }
}
</style>
