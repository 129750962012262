import api from '@/api'
const { collections } = api
export default {
  async GetCollectionView(state, payload) {
    let response = await collections.getCollectionView(payload)
    if (response.status === 200) {
      console.log('GetCollectionView', response)
    }
    return response
  },
  async GetOneEntity(state, payload) {
    let response = await collections.getOneEntity(payload)
    if (response.status === 200) {
      console.log('GetOneEntity', response)
    }
    return response
  },
  async PostFavorite(state, payload) {
    console.log('payloadPostFavorite', payload)
    let response = await collections.postFavorite(payload)
    if (response.status === 200) {
      console.log('PostFavorite', response)
    }
    return response
  },
  async DeleteFavorite(state, payload) {
    let response = await collections.deleteFavorite(payload)
    if (response.status === 200) {
      console.log('DeleteFavorite', response)
    }
    return response
  }
}
