import api from '.'
import { instance as axios } from '../plugins/axios'
import { settings } from '@/library/variables'

export const marketing = {
  async getMarketingData() {
    try {
      return await axios.get(
        '/v2/properties/' + settings.propertyID + '/marketing'
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
