<script>
import TrainingCommonView from './TrainingCommonView.vue'
import TrainingUserView from './TrainingUserView.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Training',
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState({
      subscription: (state) => state.user.subscription
    }),
    CurrentView() {
      return !this.isAuthenticated ||
        this.subscription.interval !== 'training_annual'
        ? TrainingCommonView
        : TrainingUserView
    }
  },
  components: { TrainingCommonView, TrainingUserView }
}
</script>

<template>
  <component :is="CurrentView" />
</template>

<style scoped lang="scss"></style>
