var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register-steps" },
    _vm._l(
      _vm.steps.filter(function(s) {
        return s.step == _vm.currentStep
      }),
      function(step, i) {
        return _c("div", { key: i, staticClass: "text-center px-3" }, [
          _c("span", { staticClass: "step-title " }, [
            _vm._v(_vm._s(step.title) + " ")
          ]),
          _c(
            "div",
            { staticClass: "step-index-group" },
            _vm._l(
              _vm.steps.filter(function(s) {
                return s.title
              }),
              function(step, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "step",
                    class: {
                      active: _vm.isActive(step),
                      complete: _vm.isComplete(i)
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "index",
                        class: { active: _vm.isActive(step) }
                      },
                      [
                        _vm.isComplete(i)
                          ? _c(
                              "b",
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "check" }
                                })
                              ],
                              1
                            )
                          : _c("b", [_vm._v(_vm._s(i + 1))])
                      ]
                    )
                  ]
                )
              }
            ),
            0
          )
        ])
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }