var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-background",
      on: {
        click: function($event) {
          return _vm.$emit("no")
        }
      }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c(
          "div",
          {
            staticClass: "modal-body",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _vm._m(0),
            _vm._m(1),
            _c("br"),
            _c("div", { staticClass: "modal-buttons" }, [
              _c(
                "div",
                { staticClass: "modal-button" },
                [
                  _vm.to !== undefined
                    ? _c(
                        "router-link",
                        {
                          staticClass: "button is-primary save-button",
                          attrs: { to: _vm.to }
                        },
                        [_vm._v("OK")]
                      )
                    : _vm._e(),
                  _vm.to === undefined
                    ? _c(
                        "a",
                        {
                          staticClass: "button is-primary save-button",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$emit("ok")
                            }
                          }
                        },
                        [_vm._v("OK")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "modal-top" }, [
      _c("i", {
        staticClass: "fa fa-exclamation-circle",
        staticStyle: { "font-size": "32px", color: "#b31313" }
      }),
      _vm._v(" ACCOUNT ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("p", { staticClass: "modal-heading" }, [
        _vm._v(
          " Please wait 5 minutes for PayPal to send the payment information, and you will receive your premium subscription. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }