var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "plan" }, [
    _c("span", { staticClass: "plan__period" }, [_vm._v(_vm._s(_vm.name))]),
    _c("div", [
      _c("span", { staticClass: "plan__price" }, [
        _vm._v("$" + _vm._s(_vm.price))
      ]),
      _vm._v(" / " + _vm._s(_vm.frequency) + " ")
    ]),
    _vm.isSelected
      ? _c("span", { staticClass: "plan__badge-active" }, [_vm._v("SELECTED")])
      : _c(
          "button",
          {
            staticClass: "plan__btn-select",
            on: {
              click: function($event) {
                return _vm.$emit("click")
              }
            }
          },
          [_vm._v(" Select Plan ")]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }