<template>
  <div class="page-login">
    <div class="login-inner">
      <!-- <copy-box /> -->

      <div class="login-box">
        <br />
        <router-link to="/login" class="btn-home">
          <i class="fas fa-arrow-left"></i> <span>Login</span>
        </router-link>
        <form @submit.prevent="submit" class="form">
          <div>
            <div class="logo-login">
              <img src="../assets/images/Logo-PKG-1.png" alt="" />
            </div>
            <br />
            <br />
            <br />
            <h2>Start streaming today!</h2>
            <p>
              Login in now to enjoy the top poker streaming app in the world
            </p>
            <div class="text-field">
              <input
                id="username-input"
                v-model="user"
                type="email"
                required
                placeholder="Email"
              />
            </div>
            <span v-if="errorMessage !== ''" class="error-message">
              <font-awesome-icon icon="times" />
              {{ errorMessage }}
            </span>
          </div>
          <br />
          <br />
          <div class="line"></div>
          <br />
          <button class="glowing-btn h-12 w-full mt-1" type="submit">
            Reset
          </button>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="form-footer">
            <p>Customer Service is available to help!</p>
            <p>
              CALL US at 1-866-476-5374 | Monday - Friday, 7am to 5pm PT or
              email support@pokergo.com
            </p>
          </div>
        </form>
        <br />
      </div>
      <generic-modal
        v-if="isShowModal"
        @yes="redirect()"
        @no="modalClosed = true"
        :text="
          `An email with instructions on how to reset your password has been sent to ${user}.<br /><br/>Check your spam or junk folder if you don’t see the email in your inbox.
          If you no longer have access to this email account or have any questions, please <a href='/faq'>contact us</a>.`
        "
      />
    </div>
  </div>
</template>

<script>
import GenericModal from '../components/GenericModal.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'LoginView',
  layout: 'login',
  components: {
    GenericModal
  },
  data() {
    return {
      user: '',
      errorMessage: '',
      modalClosed: true
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getPropertyId']),
    destPath() {
      return this.$route.query.to || '/'
    },
    property() {
      return this.getPropertyId
    },
    isShowModal() {
      return this.modalClosed === false && this.errorMessage === ''
    }
  },
  watch: {
    authenticated() {
      if (this.isAuthenticated) {
        this.$router.push(this.destPath)
      }
    }
  },
  mounted() {
    this.$store.commit('setLoginText', '')
    this.$nextTick(() => {
      if (this.isAuthenticated) {
        this.$router.push(this.destPath)
      }
    })
  },
  methods: {
    async submit() {
      this.modalClosed = true
      var usertype = this.$route.query.type
      let payload = {
        user: this.user,
        user_type: usertype
      }
      await this.$store
        .dispatch('account/ForgotPassword', payload)
        .then((rs) => {
          if (rs.statusCode > 300) {
            if (rs.statusCode == 500) {
              this.errorMessage = 'Email Not Found'
            } else {
              this.$toasted.error('Internal Server Error')
            }
            return
          }
          this.errorMessage = ''
        })
      this.modalClosed = false
    },
    redirect() {
      this.$router.push('/login')
    }
  }
}
</script>
<style lang="scss" scoped>
.spacer-small {
  padding-right: 5px;
  padding-left: 5px;
}

.form {
  width: 80%;
  margin: 0 auto;
  padding-top: 10%;
}
.text-field {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 30px;
  right: 10px;
}
.page-login {
  // width: 100%;
  // padding: 0px !important;
  // max-width: 100% !important;
  // display: block;
  background: url('../assets/images/home-bg.png') no-repeat;
  backdrop-filter: blur(5px);
  background-size: cover;
  height: 100%;
  width: 100%;
  .login-inner {
    width: 40%;
    height: 100%;
    .login-box {
      background: #10181f;
      max-width: 100%;
      height: 100%;
      .btn-home {
        width: 90%;
        margin: 0 auto;
        color: #ffffff;
        font-size: 16px;
        i {
          opacity: 0.4;
          font-size: 16px;
          margin-right: 10px;
        }
      }
      form {
        .logo-login {
          margin: 0 auto;
          width: 196.04px;
          img {
            width: 100%;
          }
        }
        h2,
        p {
          text-align: center;
        }
        h2 {
          font-size: 40px;
          margin-bottom: 2rem;
        }
        p {
          width: 65%;
          margin: 0 auto;
          font-size: 18px;
          opacity: 0.42;
          margin-bottom: 3rem;
        }
        .text-field {
          input[type='email'] {
            border-radius: 4px;
          }
          input[type='text'] {
            border-radius: 4px;
          }
          input[type='password'] {
            border-radius: 4px;
            position: relative;
          }

          .eye-icon {
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .forgot-password {
          text-decoration: underline;
          font-size: 12px;
          color: #9f26b5;
        }
        .line {
          width: 100%;
          opacity: 0.15;
          background-color: #ffffff;
          height: 1px;
        }
        .button {
          width: 100%;
          text-transform: capitalize;
          font-size: 14px;
          max-width: 100%;
        }
        .isAccount {
          display: block;
          text-align: center;
          color: #ffffff;
          font-size: 14px;
        }
        .subscribe {
          display: block;
          text-align: center;
          text-decoration: underline;
          color: #9f26b5;
          font-size: 14px;
        }
        .form-footer {
          margin-bottom: 50px;
          p {
            text-align: center;
            width: 80%;
            margin: 0 auto;
            padding: 0;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
