var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "register-header" },
    [
      _c(
        "span",
        {
          staticClass: "back-home",
          on: {
            click: function($event) {
              return _vm.$emit("back")
            }
          }
        },
        [
          _c("font-awesome-icon", {
            staticClass: "icon-back",
            attrs: { icon: ["fas", "arrow-left"] }
          }),
          _vm._v(" " + _vm._s(_vm.label) + " ")
        ],
        1
      ),
      _c("router-link", { staticClass: "title", attrs: { to: "/" } }, [
        _c("img", {
          attrs: { src: require("@/assets/images/Logo-Horizontal-NoNeon.svg") }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }