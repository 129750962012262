var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-background",
      on: {
        click: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c(
          "div",
          {
            staticClass: "modal-body",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _vm._m(0),
            _c("br"),
            _c("div", {}, [
              _c(
                "a",
                {
                  staticClass: "button is-primary save-button",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$emit("ok")
                    }
                  }
                },
                [_vm._v(" Okay ")]
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("p", { staticClass: "modal-heading" }, [
        _vm._v(" Looks like your promotion code is not valid. ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }