<template>
  <div class="modal-background" @click="$emit('no')">
    <div class="modal-content">
      <div class="modal-body" @click.stop>
        <p class="modal-top">
          <i
            v-if="modelOptions.type === 'success'"
            class="fa fa-check-circle"
            style="font-size:32px;color:#37ce7b"
          ></i>
          <i
            v-else-if="modelOptions.type === 'error'"
            class="fa fa-exclamation-circle"
            style="font-size:32px;color:#E01D16FF"
          ></i>
          <span
            :style="{
              color: modelOptions.type === 'success' ? '#37ce7b' : '#E01D16FF'
            }"
          >
            {{ modelOptions.header }}
          </span>
        </p>
        <div class="modal-header">
          <p class="modal-heading">
            {{ modelOptions.description }}
          </p>
        </div>
        <br />
        <div class="modal-buttons">
          <div class="modal-button">
            <a
              class="button is-primary save-button"
              @click.prevent="modelOptions.onClose()"
            >
              CLOSE
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import Vue from 'vue'

export default Vue.extend({
  name: 'AreYouSure',
  props: {
    modelOptions:{
      type: Object,
      required: false,
    }
  }
})
</script>
<style lang="scss" scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #0004;

  .modal-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 500px;
    max-height: 300px;
    overflow: hidden;
    text-align: center;
    border-radius: 15px;
    border: solid 2px #9f26b5;
    background-color: white;

    .modal-body {
      width: 100%;
      .modal-top {
        font-size: 32px;
        font-style: italic;
        color: #37ce7b;
        padding-top: 15px;
      }
      .modal-header {
        justify-content: center !important;
        padding: 0px;
        margin-top: 0px;
        margin-right: 10px;
        margin-left: 10px;
        .modal-heading {
          font-size: 16px;
          text-align: center;
          padding: 10px;
          line-height: 1em;
          color: black;
        }
      }
      .modal-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      .modal-button {
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
        .save-button {
          border-radius: 10px;
          width: 200px;
          font-size: 18px;
        }
      }
    }
  }

  .form-field.is-horizontal {
    display: flex;
    flex-flow: row;
    justify-content: space-around;

    .button {
      width: 100px;
    }
  }
}
</style>
