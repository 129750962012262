<template>
  <div class="cancel-page">
    <div class="cancel-page__container">
      <div class="cancel-page__container--header">
        <h1>New Content now available</h1>
        <p>
          Lots of exciting, new content weekly on PokerGO!
        </p>
      </div>
      <div class="cancel-page__container--form">
        <div
          v-html="document.content"
          class="cancel-page__container--form--content"
        />

        <div class="buttons">
          <button class="btn-cancel" @click="redirect()">
            Keep My Subscription
          </button>
          <button class="btn-continue" @click="specialOffer()">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CancelNewContent',
  props: ['reason'],
  components: {},
  data() {
    return {}
  },
  async beforeMount() {
    await this.$store.dispatch('GET_DOCUMENT', 'cancel-new-content')
  },
  mounted() {
    let cards = Array.from(document.getElementsByClassName('card'))
    cards.map((card) => {
      if (card.innerHTML.includes('img') || card.innerHTML.includes('video')) {
        card.classList.add('card-img')
      }
    })
  },
  computed: {
    ...mapGetters(['getDocuments']),
    document() {
      const document = this.getDocuments.filter(
        (item) => item.label === 'cancel-new-content'
      )
      if (document.length > 0) {
        return document[0]
      } else {
        return {
          id: 'cancel-new-content',
          label: 'cancel-new-content',
          content: ''
        }
      }
    },
    isDiscountAvailable() {
      switch (this.$route.query.store) {
        case 'stripe':
          return this.$route.query.discountable
        case 'paypal':
          return false
        case 'amazonweb':
          return false
        default:
          return false
      }
    }
  },
  methods: {
    ...mapActions(['GET_DOCUMENT']),
    specialOffer() {
      if (this.isDiscountAvailable) {
        // show discount page if discountable
        this.$router.push({
          name: 'CancelSpecialOffer',
          query: {
            reason: this.$route.query.reason,
            other_cancel_reason: this.$route.query.other_cancel_reason
          }
        })
      } else {
        // cancel subscription
        this.cancelSubscription()
      }
    },
    async cancelSubscription() {
      try {
        this.$store.dispatch('account/Cancel').then((rs) => {
          if (rs.status !== 200) {
            this.$toasted.error('Error cannot complete cancel of subscription')
            return
          }
          this.$showMessage({
            title: 'Cancel Subscription Success',
            text: 'Subscription Successfully Canceled'
          })
          this.$router.push('/account/cancel/confirm')
        })
      } catch (err) {
        this.$toasted.error('Error')
      }
    },
    redirect() {
      this.$router.push('/account')
    }
  }
}
</script>
<style lang="scss" scoped>
.cancel-page__container {
  max-width: 100%;

  &--form {
    padding-bottom: 3rem;

    .cancel-page__container--form--content {
      width: 100%;
      max-width: 1000px;
      background: transparent;
      padding: 0 10px;
    }

    .buttons {
      max-width: 400px;
      margin: auto;
    }
  }

  ::v-deep .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
  }
  ::v-deep .card {
    width: 50%;
    border: none;
  }
  ::v-deep .card:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 2rem 0 2rem 2rem;
  }
  ::v-deep .card:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 2rem 2rem 2rem 0;
  }
  ::v-deep .card img {
    margin: 0;
    padding: 0;
  }
  ::v-deep .card h1 {
    margin-top: 30px;
    text-align: left;
    font-size: 16px;
    color: grey;
  }
  ::v-deep .card p {
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  @media (max-width: 768px) {
    ::v-deep .row {
      flex-direction: column;
    }
    ::v-deep .card {
      width: 100%;
      padding: 2rem !important;
    }
    ::v-deep .card.card-img {
      padding: 0 !important;
    }
    ::v-deep .card:first-child {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    ::v-deep .card:last-child {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
}
</style>
