import { General } from '@/library/general'

export const overlayImage = {
  methods: {
    overlayImage(entity) {
      return General.getOverlayImage(
        entity.air_date,
        entity.pre_air_images,
        entity.images
      )
    }
  }
}
