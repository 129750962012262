<template>
  <div class="register-layout">
    <SimpleHeader :label="label" @back="handleBack" />
    <slot />
  </div>
</template>
<script lang="js">
import SimpleHeader from '../components/SimpleHeader.vue'
import { mapGetters } from "vuex";

export default {
  name: 'RegisterLayout',
  components: { SimpleHeader },
  computed: {
    ...mapGetters(['isAuthenticated']),
    label() {
      if (this.$route.path.includes('/account/cancel/confirm')) return 'Back Home'
      if (this.$route.path.includes('/complete')) return 'Start Watching'
      if (this.$route.path.includes('/account/cancel')) return 'Cancel'
      return 'Back Home'
    }
  },
  methods: {
    startWatching() {
      const loginRedirectURL = localStorage.getItem('loginRedirectURL')
      if (loginRedirectURL) {
        this.$router.push({ path: loginRedirectURL })
        localStorage.removeItem('loginRedirectURL')
      } else if (this.$store.state.videoId)
        window.location.href = `/videos/${this.$store.state.videoId}`
      else window.location.href = '/'
    },
    handleBack() {
      if (this.$route.path.includes('/account/cancel/confirm')) {
        this.$router.push('/')
        return
      }

      if (this.$route.path.includes('/account/cancel')) {
        this.$router.push('/account')
        return
      }

      if (this.$route.path.includes('/complete')) {
        this.startWatching()
        return
      }

      this.$router.push('/')

    }
  }
}
</script>
<style scoped lang="scss">
.register-layout {
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .register-layout {
    padding-bottom: 60px;
  }
}
</style>
