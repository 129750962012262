var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        _vm.layout,
        { tag: "component", class: _vm.tabCustom },
        [_c("router-view", { on: { authenticated: _vm.setAuthenticated } })],
        1
      ),
      _c("div", { staticClass: "bottom-bar" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _c("i", { staticClass: "fas fa-home" }),
                _c("span", [_vm._v("Home")])
              ])
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/on-demand" } }, [
                _c("i", { staticClass: "fas fa-play" }),
                _c("span", [_vm._v("On Demand")])
              ])
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/schedule" } }, [
                _c("i", { staticClass: "fas fa-calendar-alt" }),
                _c("span", [_vm._v("Schedule")])
              ])
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/training" } }, [
                _c("i", { staticClass: "fas fa-graduation-cap" }),
                _c("span", [_vm._v("PokerU")])
              ])
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/options" } }, [
                _c("i", { staticClass: "fas fa-bars" }),
                _c("span", [_vm._v("More")])
              ])
            ],
            1
          )
        ])
      ]),
      _c("portal-target", { attrs: { name: "modal-outlet" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }