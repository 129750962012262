var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative overflow-y-hidden" },
    [
      _c("TrainingOvals"),
      _c("div", [
        _c("div", { staticClass: "home-container" }, [
          _c(
            "div",
            {
              staticClass:
                "hidden lg:block mt-10 mb-5 mx-auto max-w-full md:max-w-5xl"
            },
            [_c("DesktopMainVideo")],
            1
          ),
          _c(
            "div",
            { staticClass: "block lg:hidden" },
            [_c("MobileMainVideo")],
            1
          ),
          _c(
            "div",
            {
              staticClass: "flex flex-col items-center mb-10 md:mb-28 px-5 z-20"
            },
            [
              _vm._m(0),
              !this.isSubscriber
                ? _c(
                    "router-link",
                    {
                      staticClass: "glowing-btn w-80 h-14 text-base mb-4 z-20",
                      attrs: {
                        to: {
                          name: "Select a Plan",
                          query: { plan: "training_annual" }
                        }
                      }
                    },
                    [_vm._v(" Join PokerGO VIP ")]
                  )
                : this.subscription.store === "stripe"
                ? _c(
                    "router-link",
                    {
                      staticClass: "glowing-btn w-80 h-14 text-base mb-4 z-20",
                      attrs: {
                        to: {
                          name: "Upgrade",
                          query: { plan: "training_annual" }
                        }
                      }
                    },
                    [_vm._v(" Upgrade to PokerGO VIP ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("div", [_c("Stats")], 1),
          _c("div", { staticClass: "mb-20" }, [_c("ToughestPros")], 1),
          _c("div", [_c("TrainingEndText")], 1)
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "opacity-50 text-center mb-10 text-17" }, [
      _vm._v(
        " Elevate your game and gain an edge over the competition with our VIP Training"
      ),
      _c("br"),
      _vm._v(
        "Package, specially designed for the most dedicated poker enthusiasts. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }