var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "emoji",
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "emoji-btn" },
        [
          !_vm.isStickerPickerOpen
            ? _c("font-awesome-icon", {
                staticClass: "check",
                attrs: {
                  icon: _vm.isEmojiPickerOpen ? "keyboard" : "smile-beam"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.toggleEmojiPicker($event)
                  }
                }
              })
            : _vm._e(),
          !_vm.isEmojiPickerOpen
            ? _c("i", {
                staticClass: "keyboard",
                class: {
                  "fas fa-keyboard": _vm.isStickerPickerOpen,
                  "far fa-sticky-note": !_vm.isStickerPickerOpen
                },
                staticStyle: { "margin-left": "5px" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.toggleStickerPicker($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.isStickerPickerOpen
        ? _c("StickerPicker", { on: { "send-emoji": _vm.sendSticker } })
        : _vm._e(),
      _vm.isEmojiPickerOpen
        ? _c("picker", {
            attrs: {
              title: "Pick your emoji…",
              emoji: "point_up",
              include: _vm.includedCategory,
              custom: _vm.customEmojis
            },
            on: { select: _vm.addEmoji }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }