var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "title" }, [_vm._v("WE HATE TO SEE YOU FOLD")]),
      _c("p", { staticClass: "sub-title" }, [
        _vm._v(" We hope you will check back to see what's new with PokerGO. ")
      ]),
      _c("div", { staticClass: "banner" }, [
        _c("p", [_vm._v("Customer Service is always willing to help!")]),
        _c("p", [
          _c("strong", [_vm._v("CALL US at 1-866-746-5374")]),
          _vm._v(" | Monday-Friday 1a PT to 7p PT ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }