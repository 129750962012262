import api from '@/api'
import { instance as axios } from '@/plugins/axios'
import getSymbolFromCurrency from 'currency-symbol-map'
import { basePlans } from '@/library/config/plans'
import _ from 'lodash'

const state = {
  error: undefined,
  plans: [],
  promo: {},
  selectedPlan: {},
  user: {},
  controls: { loading: false, error: undefined },
  stripePaymentInfor: {},
  promoCodeDetails: undefined
}

const actions = {
  async getPlans({ commit, state }, payload) {
    return api.checkout
      .getProduct()
      .then(async (res) => {
        const plansbase = _.values(basePlans)

        if (res.data && res.data.data) {
          //console.log('debug ', res.data.data)
          const plans_response = res.data.data
          const plans = plansbase.map((base) => {
            let element = plans_response.find(
              (p) => base.product_type === p.type
            )
            element = { ...element }
            const symbol = getSymbolFromCurrency(element.price.currency)
            const price = element.price.value
            const stripe_product_id = element.price.stripe_product_id
            const country_group = element.price.country_group
            let priceDescription
            if (base.product_type !== 'monthly') {
              priceDescription =
                price != base.price ? '' : base.priceDescription
            } else {
              priceDescription = base.priceDescription
            }
            delete element.price
            delete element.name
            delete element.stripe_product_id
            delete element.country_group
            return {
              ...base,
              ...{
                id: element.id,
                symbol,
                price,
                stripe_product_id,
                country_group,
                priceDescription
              },
              ...element
            }
          })

          let promiseResponses = []

          if (payload && payload.code) {
            promiseResponses = await Promise.all(
              plans.map(async (plan) => {
                const { data } = await api.checkout.validateCoupon(
                  { coupon: payload.code, merchant: 'stripe' },
                  {
                    type: plan.product_type,
                    priceNumber: plan.price
                  }
                )
                return { ...data.data, type: plan.product_type }
              })
            )
          }

          const resolvedPlans = plans.map((plan) => {
            const promiseResponse = promiseResponses.find(
              (el) => el.type === plan.type
            )
            if (promiseResponse?.Valid) {
              return {
                ...plan,
                offerValue: promiseResponse.Price.toFixed(2),
                offerDescription: promiseResponse.Description
              }
            }
            return plan
          })

          commit('checkoutPlans', resolvedPlans)
        }
      })
      .catch((err) => {
        console.log(err)
        if (err) {
          commit('checkoutError', { message: 'No plan available', status: 404 })
        }
      })
  },
  checkPromo({ commit, state }, payload) {
    let selectedPlan

    if (payload.period) {
      const promoPlans = state.plans.filter(
        (p) => p.product_type === payload.period.toLowerCase()
      )
      if (promoPlans.length) selectedPlan = promoPlans[0]
    } else if (state.selectedPlan) {
      selectedPlan = state.selectedPlan
    }

    if (selectedPlan !== undefined && payload) {
      return api.checkout
        .validateCoupon(payload, {
          type: selectedPlan.product_type,
          priceNumber: selectedPlan.price
        })
        .then((res) => {
          commit('selectPromo', res.data.data)
          return res.data.data
        })
        .catch((err) => {
          console.log(err)
          return false
        })
    }
  },
  fetchUser({ commit }) {
    return api.authentication
      .fetchMe()
      .then(({ data }) => {
        commit('setUser', {
          user: data.data,
          userEntitlements: data.data.external_product_ids
        })
        return { user: data.data, entitlements: data.included }
      })
      .catch((error) => {
        throw error
      })
  },
  signUp({ commit }, payload) {
    commit('checkoutControls', { loading: true, error: undefined })
    return axios
      .post(
        '/properties/' + payload.property_id + '/register',
        JSON.stringify({
          data: {
            type: 'subscriber',
            attributes: {
              ...payload.form,
              ...{
                property_id: payload.property_id,
                code: payload.code
              }
            }
          }
        })
      )
      .then(({ data }) => {
        commit('checkoutControls', { loading: false })
        commit('setAuthenticated', data.meta.token)
        return data
      })
      .catch((error) => {
        console.log(error)
        commit('checkoutControls', { loading: false, error: error })
      })
  },
  subscribe({ commit, state }, payload) {
    const plan = {
      ...state.selectedPlan,
      ...{
        price: payload.plan.price
      },
      ...{
        plan_type: payload.plan.product_type
      }
    }
    commit('checkoutControls', { loading: true, error: undefined })
    return api.checkout
      .submitPostPurchase({ ...plan, ...payload })
      .then((resp) => {
        if (resp.status !== 200) {
          const detail =
            resp.message || 'There is an error occurred while purchasing'
          commit('checkoutControls', { loading: false, error: detail })
          throw detail
        }
        commit('checkoutControls', { loading: false, error: undefined })
        return resp.data.data
      })
      .catch((error) => {
        commit('checkoutControls', { loading: false, error: error })
      })
  },
  async SubmitUpgradeSubscription(state, payload) {
    let response = await api.checkout.submitUpgradeSubscription(payload)
    if (response.status === 200) {
      console.log('GetOneEntity', response)
    }
    return response
  },
  async setAgreementDetail(state, { amazonBillingAgreementId, accessToken }) {
    const res = await api.checkout.setAgreementDetail(
      amazonBillingAgreementId,
      accessToken
    )
    return res
  },
  async confirmAgreementDetail(state, amazonBillingAgreementId) {
    const res = await api.checkout.confirmAgreementDetail(
      amazonBillingAgreementId
    )
    return res
  },
  async authorizeAgreementDetail(
    state,
    { amazonBillingAgreementId, productId }
  ) {
    const res = await api.checkout.authorizeAgreementDetail(
      amazonBillingAgreementId,
      productId
    )
    return res
  },
  async updatePaymentMethod(state, payload) {
    const res = await api.checkout.updatePaymentMethod(payload)
    return res
  },
  async resumeSubscription(state, payload) {
    const res = await api.checkout.resumeSubscription(payload)
    return res
  }
}

const mutations = {
  checkoutControls: (state, payload) => {
    state.controls = { ...state.controls, ...payload }
  },
  stripePaymentInfor: (state, payload) => {
    state.stripePaymentInfor = payload
  },
  checkoutPlans: (state, payload) => (state.plans = payload),
  checkoutError: (state, payload) => (state.error = payload),
  currentUser: (state, payload) => (state.user = payload),
  selectPlan: (state, payload) => {
    state.promo = {}
    state.selectedPlan = payload
  },
  selectPromo: (state, payload) => (state.promo = payload),
  resetPromo: (state) => (state.promo = {}),
  setPromoCodeDetails: (state, payload) => (state.promoCodeDetails = payload),
  resetPromoCodeDetails: (state) => (state.promoCodeDetails = undefined)
}

const getters = {
  checkoutPrice: (state) => (plan) => {
    plan = plan || state.selectedPlan
    if (!state.promo || !state.promo.Valid) {
      return {
        price: plan.price,
        symbol: plan.symbol
      }
    } else {
      return { price: state.promo.Price, symbol: plan.symbol }
    }
  },
  plansWithPromoDiscount(state) {
    if (state.promoCodeDetails) {
      const { Interval, Price } = state.promoCodeDetails

      return state.plans.map((plan) => {
        if (plan.product_type === Interval) {
          return {
            ...plan,
            striked: plan.price,
            price: (plan.price - Price).toFixed(2),
            ribbon: 'Promo Code'
          }
        }
        return plan
      })
    } else {
      return state.plans
    }
  }
}

export default {
  actions,
  getters,
  mutations,
  state
}
