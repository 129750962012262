<template>
  <portal to="modal-outlet">
    <div
      class="fixed z-50 inset-0 flex items-center justify-center w-full min-h-screen"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        aria-hidden="true"
        @click="$emit('close')"
      />
      <div
        class="relative w-full m-3"
        :class="isFull ? 'h-full lg:w-5/12' : 'lg:w-3/12'"
      >
        <slot />
      </div>
    </div>
  </portal>
</template>

<script>
import { Portal } from 'portal-vue'

export default {
  components: {
    Portal
  },
  props: {
    isFull: {
      default: false
    }
  }
}
</script>
