import api from '.'
import { videoProgressInstance } from '../plugins/axios'

export const progress = {
  async getProgress() {
    return videoProgressInstance.get('/subscribers/me/progress')
  },
  async getVideoProgress(videoID) {
    try {
      const videoProgress = await videoProgressInstance.get(
        '/subscribers/me/progress/' + videoID
      )
      return videoProgress.data
    } catch (err) {
      return api.response.error(err)
    }
  },
  async postEvent(payload) {
    try {
      return await videoProgressInstance.post(
        '/subscribers/me/events',
        payload,
        {
          headers: { 'Content-Type': 'application/vnd.api+json' }
        }
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async postProgress(id, payload) {
    try {
      return await videoProgressInstance.post(
        '/subscribers/me/progress',
        payload,
        {
          headers: { 'Content-Type': 'application/vnd.api+json' }
        }
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
