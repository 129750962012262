const General = {
  /**
   * If the current time is < airdate and a pre air image is defined show that image
   * if no pre air image is defined then show the original video image as an overlay
   *
   * @param {*} air_date
   * @param {*} pre_air_images
   * @param {*} images
   */
  getOverlayImage(air_date, pre_air_images, images) {
    try {
      //Required always
      if (this.checkNulls(air_date)) {
        return this.getImage(images)
      }
      if (this.checkNulls(images)) {
        console.log('getOverlayImage is missing images', images)
        return ''
      }

      const now = new Date()
      const airDate = new Date(air_date)

      //console.log('preair image ', now, ' ', airDate)
      if (now < airDate && pre_air_images?.length) {
        for (let i = 0; i < pre_air_images.length; i++) {
          if (pre_air_images[i].label === 'SD') {
            return pre_air_images[i].url
          }
        }
      } else {
        return this.getImage(images)
      }
    } catch (err) {
      console.log('getOverlayImage', err)
    }
    return ''
  },
  checkNulls(value) {
    if (value === '' || value === undefined || value === null) {
      return true
    } else {
      return false
    }
  },
  getImage(images) {
    for (let i = 0; i < images.length; i++) {
      if (images[i].label === 'SD') {
        return images[i].url
      }
    }
    return ''
  },
  setWithExpiry(key, value, ttl) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl
    }
    localStorage.setItem(key, JSON.stringify(item))
  },
  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }
}

export { General }
