var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "error-container" }, [
      _c("h1", [_vm._v(" An error occurred ")]),
      _c(
        "h2",
        [
          _vm._v(" Try our "),
          _c("router-link", { attrs: { to: "/search" } }, [_vm._v("SEARCH")]),
          _vm._v(" to find your page ")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }