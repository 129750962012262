var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "min-h-screen" }, [
        _c("div", { staticClass: "controls" }, [
          _c("div", { staticClass: "ml-auto" }, [
            _c(
              "div",
              {
                staticClass:
                  " flex justify-center items-center rounded-md gap-2 cursor-pointer",
                staticStyle: {
                  width: "120px",
                  height: "35px",
                  border: "1px solid rgba(255, 255, 255, 0.25)"
                },
                on: { click: _vm.toggleFilterWindow }
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "filter" } }),
                _c("b", [_vm._v("FILTERS")])
              ],
              1
            )
          ])
        ]),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "text-center mt-20" },
              [
                _c("loading-progress", {
                  attrs: {
                    progress: 60,
                    indeterminate: true,
                    "hide-background": true,
                    size: "50",
                    rotate: "",
                    fillDuration: "2",
                    rotationDuration: "1"
                  }
                })
              ],
              1
            )
          : !_vm.videos.length
          ? _c("div", { staticClass: "text-center text-xl mt-20" }, [
              _vm._v(" No videos found! ")
            ])
          : _c("div", [
              _c(
                "div",
                {
                  staticClass: "grid-container",
                  class: _vm.cardContainerClass
                },
                _vm._l(_vm.videos, function(entity, idx) {
                  return _c("small-card", {
                    key: idx + entity.id,
                    staticStyle: { position: "relative" },
                    attrs: {
                      "entity-id": entity.id,
                      "entity-type": entity.type,
                      "entity-data": entity.images ? entity : undefined,
                      duration: entity.duration,
                      "show-sub-text": false,
                      author: "Krzysztof Slaski",
                      "show-title": true,
                      "no-reload": true
                    }
                  })
                }),
                1
              )
            ])
      ]),
      _c("ResultsFilter", {
        attrs: { show: _vm.showFiltersPopup },
        on: { search: _vm.handleSearch, close: _vm.handleClosePopup }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }