var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "collection-container" }, [
      _vm.collectionId
        ? _c(
            "div",
            { staticClass: "back-home" },
            [
              _c(
                "router-link",
                { attrs: { to: "/collections/" + _vm.collectionId } },
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon-back",
                    attrs: { icon: ["fas", "arrow-left"] }
                  }),
                  _vm._v(" Back to collection ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "header-text" }, [
          _c("div", { staticClass: "body-header" }, [
            _c("h1", [_vm._v(_vm._s(_vm.title))])
          ]),
          _c("p", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.description))
          ]),
          _vm.loggedIn
            ? _c(
                "button",
                {
                  staticClass: "my-list-button",
                  on: { click: _vm.setFavorite }
                },
                [
                  _vm.favorite
                    ? _c("font-awesome-icon", {
                        staticClass: "circled",
                        attrs: { icon: "check" }
                      })
                    : _vm._e(),
                  !_vm.favorite
                    ? _c("font-awesome-icon", {
                        staticClass: "circled",
                        attrs: { icon: "plus" }
                      })
                    : _vm._e(),
                  _vm._v(
                    " " + _vm._s(_vm.favorite ? "remove" : "my list") + " "
                  )
                ],
                1
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "header-img" }, [
          _c("img", { attrs: { src: _vm.hdImage } })
        ])
      ]),
      _c(
        "div",
        { staticClass: "container" },
        _vm._l(_vm.collectionEntities, function(entity, idx) {
          return _c("Row", {
            key: idx + entity,
            staticClass: "row-with-margin",
            attrs: {
              type: entity.type,
              title: entity.title,
              description: entity.description,
              "collection-entities": entity.entities
            },
            on: { reloaddata: _vm.loadCollectionData }
          })
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }