var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "group h-10 flex justify-between items-center pl-4 pr-5 hover:bg-tw_primary-100"
    },
    [
      _c(
        "label",
        {
          staticClass:
            "inline-block w-full font-unisans-regular text-sm text-black cursor-pointer leading-10 group-hover:text-tw_primary-300",
          attrs: { for: _vm.id }
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _c("input", {
        staticClass:
          "inline-block h-4 w-4 text-tw_primary-300 transition focus:ring-0 cursor-pointer",
        attrs: { type: "checkbox", name: _vm.groupName, id: _vm.id },
        domProps: { value: _vm.value, checked: _vm.isChecked },
        on: { change: _vm.handleInput }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }