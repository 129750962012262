import api from '.'
import { instance as axios } from '../plugins/axios'
import { settings } from '@/library/variables'

export const collections = {
  async postFavorite(payload) {
    try {
      return await axios.post(
        '/v2/properties/' +
          settings.propertyID +
          `/subscribers/me/favorites/` +
          payload.type +
          `/` +
          payload.id,
        { headers: { 'Content-Type': 'application/vnd.api+json' } }
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async deleteFavorite(payload) {
    try {
      return await axios.delete(
        '/v2/properties/' +
          settings.propertyID +
          `/subscribers/me/favorites/` +
          payload.type +
          `/` +
          payload.id
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async listFavorites() {
    try {
      return await axios.get(
        '/v2/properties/' + settings.propertyID + `/subscribers/me/favorites`
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getCollectionView(entity) {
    try {
      return await axios.get(
        '/v2/properties/' +
          settings.propertyID +
          `/` +
          entity.entityType +
          `/` +
          entity.entityId +
          `?include=entities`
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getOneEntity(entity) {
    try {
      let queryparam = ''

      if (entity.type === 'collections') {
        queryparam = '?include=entities'
      }

      return await axios.get(
        '/v2/properties/' +
          settings.propertyID +
          `/` +
          entity.type +
          `/` +
          entity.id +
          queryparam
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
