<template>
  <div class="register-layout">
    <Header />
    <div>
      <slot />
    </div>
    <notifications group="messages" position="bottom right" />
  </div>
</template>
<script lang="js">
import Header from '../components/Header.vue'

export default {
  name: 'DefaultNoFooter',
  components: { Header },
  data () {
    return {
      hideModal: false
    }
  },
}
</script>
