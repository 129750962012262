var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "hidden md:block" }, [
        _c("div", { staticClass: "lg-oval-1" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/training/ovals/lg-oval-1.png"),
              alt: "oval-1"
            }
          })
        ]),
        _c("div", { staticClass: "lg-oval-2" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/training/ovals/lg-oval-2.png"),
              alt: "oval-2"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "lg:hidden" }, [
        _c("div", { staticClass: "sm-oval-1" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/training/ovals/sm-oval-1.png"),
              alt: "sm-oval-1"
            }
          })
        ]),
        _c("div", { staticClass: "sm-oval-2" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/training/ovals/sm-oval-2.png"),
              alt: "sm-oval-2"
            }
          })
        ]),
        _c("div", { staticClass: "sm-oval-3" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/training/ovals/sm-oval-3.png"),
              alt: "sm-oval-3"
            }
          })
        ]),
        _c("div", { staticClass: "sm-oval-4" }, [
          _c("img", {
            staticClass: "w-full object-cover",
            attrs: {
              src: require("@/assets/images/training/ovals/sm-oval-4.png"),
              alt: "sm-oval-4"
            }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }