var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payment-form-container card-element" },
    [
      _c(
        "form",
        {
          attrs: { id: "payment-form", method: "post" },
          on: {
            keydown: function($event) {
              $event.keyCode === 13 ? $event.preventDefault() : false
            },
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitClicked($event)
            }
          }
        },
        [
          _c("p", { staticClass: "payment-form-title" }, [
            _vm._v("Pay with Amazon")
          ]),
          _vm._m(0),
          _vm.declined
            ? _c("p", { staticClass: "red" }, [_vm._v("Payment Declined")])
            : _vm._e(),
          _c("p", { staticClass: "red" }, [_vm._v(_vm._s(this.error))]),
          _vm.loading
            ? _c("p", { staticClass: "loading-msg" }, [
                _vm._v(" Payment Processing Please Wait ... ")
              ])
            : _c(
                "button",
                {
                  class: [_vm.buttonClass, _vm.activeClass],
                  attrs: { disabled: this.disabled }
                },
                [_vm._v(" Subscribe ")]
              ),
          _c("p", { staticClass: "payment-form-policy" }, [
            _vm._v(
              " By confirming your subscription, you allow PokerGO to charge your card for this payment and future payments in accordance with their terms. "
            )
          ])
        ]
      ),
      _vm.modalPending
        ? _c("paypal-pending-modal", {
            on: { yes: _vm.waitModal, no: _vm.submitAfterCheck }
          })
        : _vm._e(),
      _vm.modalWait
        ? _c("paypal-wait-modal", { on: { ok: _vm.goToAccount } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-center", staticStyle: { "margin-top": "40px" } },
      [
        _c("div", {
          staticStyle: {
            width: "320px",
            height: "250px",
            display: "inline-block"
          },
          attrs: { id: "addressBookWidgetDiv" }
        }),
        _c("div", {
          staticStyle: {
            width: "320px",
            height: "250px",
            display: "inline-block"
          },
          attrs: { id: "walletWidgetDiv" }
        }),
        _c("div", {
          staticStyle: {
            width: "320px",
            height: "250px",
            display: "inline-block"
          },
          attrs: { id: "consentWidgetDiv" }
        }),
        _c("div", { staticStyle: { clear: "both" } })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }