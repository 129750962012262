<template>
  <div>
    <ul class="list-options">
      <li v-if="loggedIn">
        <router-link to="/account">Profile</router-link>
      </li>

      <li>
        <router-link to="/search">Search</router-link>
      </li>
      <li v-if="loggedIn">
        <router-link to="/my-list">My List</router-link>
      </li>
      <li>
        <router-link to="/terms-and-conditions"
          >Terms and Conditions</router-link
        >
      </li>
      <li>
        <router-link to="/privacy-policy">Privacy Policy</router-link>
      </li>
      <li>
        <router-link to="/about">About PokerGO</router-link>
      </li>
      <li>
        <router-link to="/faq">FAQ</router-link>
      </li>
      <li>
        <a href="https://www.pokergonews.com/press-releases/" target="_blank">
          Press Releases
        </a>
      </li>
      <li>
        <router-link to="/faq#contact">Contact Us</router-link>
      </li>
      <li>
        <a href="https://shop.pokergo.com/" target="_blank">
          Shop Now
        </a>
      </li>
      <li>
        <a href="https://playpokergo.com/" target="_blank">
          Play Now
        </a>
      </li>
      <li v-if="loggedIn">
        <router-link to="/logout">Sign out</router-link>
      </li>
      <li v-if="!loggedIn">
        <router-link to="/login">Sign in</router-link>
      </li>
      <div class="account">{{ this.email }}</div>

      <div class="btn-subscribe">
        <router-link
          :to="loggedIn ? '/select-plan' : '/register/select-plan'"
          v-if="!isPaidOrPending()"
        >
          Subscribe
        </router-link>
        <router-link
          to="/account/change-plan/checkout"
          v-else-if="
            this.subscription.store === 'stripe' &&
              this.subscription.interval !== 'training_annual'
          "
        >
          Upgrade
        </router-link>
      </div>
      <div class="version">Version {{ version }}</div>
    </ul>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { version } from '../../../package.json'

export default {
  name: 'Options',
  data() {
    return {
      windowWidth: 900
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isSubscriber']),
    isMobile() {
      return this.windowWidth <= 1024
    },
    ...mapState({
      email: (state) => state.user.email,
      subscription: (state) => state.user.subscription
    }),
    version() {
      return version
    },
    loggedIn() {
      return this.isAuthenticated
    }
  },
  methods: {
    isPaidOrPending() {
      const paypalPurchaseTime = localStorage.getItem('paypalPurchaseTime')
      if (this.isSubscriber) {
        // if paypal purchase success, remove item from localStorage
        if (paypalPurchaseTime) {
          localStorage.removeItem('paypalPurchaseTime')
        }
        return true
      }

      if (paypalPurchaseTime) {
        const currentTime = moment().unix()
        // if paypal purchase time is past more than 5 mins, remove the item and make 'subscribe' button visible
        if (currentTime - Number(paypalPurchaseTime) > 300) {
          localStorage.removeItem('paypalPurchaseTime')
          return false
        } else {
          return true
        }
      }

      if (this.isSubscriber) {
        return true
      }

      return false
    }
  }
}
</script>
<style lang="scss" scoped>
ul {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  background: #000000;
  margin-top: 82px;
  overflow-y: scroll;
  padding-bottom: 200px;
  li {
    list-style-type: none;
    // padding: 10px 20px 10px 20px;
    background: #141414;
    margin: 20px 0px;
    cursor: pointer;
    a {
      color: #ffff;
      font-size: 14px;
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      padding: 10px 20px 10px 20px;
    }
  }
  li:hover {
    background: gray;
    transition: 0.3s ease-in;
  }
  .account {
    text-align: center;
    font-size: 14px;
    color: #ffff;
    opacity: 0.5;
  }
  .version {
    text-align: center;

    font-size: 14px;
    color: #ffff;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 768px) {
  ul {
    padding-bottom: 150px;
    .version {
      position: static;
      left: 0;
      top: 0;
      transform: none;
      padding-top: 2rem;
    }
  }
}
@media screen and (max-width: 420px) {
  ul {
    margin-top: 83px !important;
  }
}
.btn-subscribe {
  text-align: center;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    min-width: 170px;
    display: block;
    padding: 13px 23px;
    text-decoration: none;
    border: 2px solid #ffffff;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 12px 4px #9f26b5, 0 0 8px 4px #9f26b5;
    color: #ffffff;
    font-family: 'UniSans';
    font-size: 14px;
    letter-spacing: 0;
    margin: 0 16px;
  }
}
.btn-subscribe:hover {
  a {
    background-color: $poker-accent !important;
    transition: 0.2s ease-in;
  }
}

.experiences-btn {
  margin: 20px;
}
</style>
