<script>
import PgtPopup from '../PgtPopup.vue'
import GameTypeList from './Filter/GameTypeList.vue'
import api from '../../api'

export default {
  name: 'ResultsFilter',
  components: { GameTypeList, PgtPopup },
  data: () => ({
    formMeta: {
      showGameTypePopover: false,
      showCoachesPopover: false,
      gameTypeList: [],
      coachesList: []
    },
    formData: {
      gameType: [],
      mostRecent: false,
      coaches: []
    }
  }),
  props: {
    show: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => {}
    }
  },
  async mounted() {
    const { data } = await api.training.getTrainingFiltersData()
    const { Coaches: coaches, GameTypes: gameTypes } = data.data
    this.formMeta.gameTypeList = gameTypes.map((el, i) => ({
      id: `type_${i}`,
      name: el
    }))
    this.formMeta.coachesList = coaches.map((el, i) => ({
      id: `coaches_${i}`,
      name: el
    }))
  },
  methods: {
    initSearch() {
      this.$emit('search', this.formData)
    },
    closeFilterPopup() {
      this.$emit('close')
    },
    handleGameTypeSelect(type) {
      console.log('type', type)
      this.formData.gameType = [type]
      this.formMeta.showGameTypePopover = false
    },
    handleCoacheSelect(type) {
      console.log('type', type)
      this.formData.coaches = [type]
      this.formMeta.showCoachesPopover = false
    }
  }
}
</script>

<template>
  <PgtPopup v-if="show" @close="closeFilterPopup" :isFull="true">
    <div class="flex h-full justify-center lg:items-center items-center">
      <div class="bg-white text-black bg-white w-full" style="max-width: 450px">
        <div
          class="relative flex items-center justify-center px-4 py-3 divider rounded"
        >
          <div style="font-family: 'UniSans-SemiBold';">FILTERS</div>
          <i
            class="fas fa-times absolute right-6 text-gray-400 cursor-pointer"
            @click="closeFilterPopup"
          />
        </div>
        <div class="flex justify-between items-center px-4 py-3 divider">
          <div style="font-family: 'UniSans-SemiBold';">
            Game Type
            <div class="mt-1 opacity-50" v-if="formData.gameType.length">
              <span
                v-for="(gameType, i) in formData.gameType"
                :key="`gametype_${i}`"
              >
                {{ gameType.name }}
              </span>
            </div>
          </div>
          <div class="flex gap-2">
            <div
              v-if="formData.gameType.length"
              @click="formData.gameType = []"
              class="opacity-50  underline cursor-pointer"
            >
              Clear
            </div>
            <div
              class="relative"
              v-click-outside="() => (formMeta.showGameTypePopover = false)"
            >
              <span
                class="text-tw_primary-300 underline cursor-pointer"
                @click="formMeta.showGameTypePopover = true"
              >
                Select
              </span>

              <GameTypeList
                v-if="formMeta.showGameTypePopover"
                type="radio"
                :list="formMeta.gameTypeList"
                :selectedGameType="formData.gameType"
                @select="handleGameTypeSelect"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center px-4 py-3 divider">
          <div style="font-family: 'UniSans-SemiBold';">
            Most Recent
            <div class="mt-1 opacity-50" v-if="formData.mostRecent">
              {{ formData.mostRecent }}
            </div>
          </div>
          <div class="flex gap-2">
            <div
              v-if="formData.mostRecent"
              @click="formData.mostRecent = false"
              class="opacity-50  underline cursor-pointer"
            >
              Clear
            </div>
            <div
              class="text-tw_primary-300 underline cursor-pointer"
              @click="formData.mostRecent = true"
            >
              Select
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center px-4 py-3 divider">
          <div style="font-family: 'UniSans-SemiBold';">
            Coaches
            <div class="mt-1 opacity-50" v-if="formData.coaches.length">
              <span
                v-for="(coache, i) in formData.coaches"
                :key="`coache_${i}`"
              >
                {{ coache.name }}
              </span>
            </div>
          </div>

          <div class="flex gap-2">
            <div
              v-if="formData.coaches.length"
              @click="formData.coaches = []"
              class="opacity-50  underline cursor-pointer"
            >
              Clear
            </div>
            <div
              class="relative"
              v-click-outside="() => (formMeta.showCoachesPopover = false)"
            >
              <span
                class="text-tw_primary-300 underline cursor-pointer"
                @click="formMeta.showCoachesPopover = true"
              >
                Select
              </span>

              <GameTypeList
                v-if="formMeta.showCoachesPopover"
                type="radio"
                :list="formMeta.coachesList"
                :selectedGameType="formData.coaches"
                @select="handleCoacheSelect"
              />

              <!--              <GameTypeList-->
              <!--                v-if="formMeta.showCoachesPopover"-->
              <!--                type="checkbox"-->
              <!--                :list="formMeta.coachesList"-->
              <!--                :selectedGameType="formData.coaches"-->
              <!--                @select="handleCoacheSelect"-->
              <!--              />-->
            </div>
          </div>
        </div>
        <div class="px-4 py-4 flex justify-end">
          <div class="outline-btn px-16 py-2 font-black" @click="initSearch">
            Apply
          </div>
        </div>
      </div>
    </div>
  </PgtPopup>
</template>

<style scoped lang="scss">
.divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
