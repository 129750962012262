<template>
  <div class="action_btn_container">
    <div>
      <router-link
        :to="`/videos/${prevVideoId}`"
        class="next_btn"
        v-if="prevVideoId"
      >
        <font-awesome-icon
          icon="play"
          style="margin-right: 8px; transform: rotate(180deg)"
        />
        <div class="prev_text">
          <span v-if="isMobile">
            Previous
          </span>
          <span v-else>Previous Episode</span>
        </div>
      </router-link>
    </div>

    <div>
      <router-link :to="`/videos/${nextVideoId}`" v-if="nextVideoId">
        <progress-button
          ref="nextProgressBtn"
          class="next_btn"
          style="border: 0px; height: 32px; pointer-events: none"
          :duration="5000"
          fill-color="#ccc"
        >
          <div class="next_text">
            <span v-if="isMobile">
              {{ nextVideoOnAir ? 'Next' : 'Upcoming' }}
            </span>
            <span v-else
              >{{ nextVideoOnAir ? 'Next' : 'Upcoming' }} Episode</span
            >
            <font-awesome-icon
              style="margin-left: 8px"
              class="icon"
              icon="play"
            />
          </div>
        </progress-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from 'vue-progress-button'
export default {
  name: 'PlayerActionButtons',
  components: {
    'progress-button': Button
  },
  props: {
    prevVideoId: {
      type: String,
      default: ''
    },
    nextVideoId: {
      type: String,
      default: ''
    },
    nextVideoOnAir: {
      type: Boolean,
      default: true
    },
    isPlayerPlaying: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      windowWidth: 900
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 768
    }
  },
  methods: {
    autoNext() {
      if (this.nextVideoId) {
        this.$refs.nextProgressBtn.click()
        setTimeout(() => {
          if (!this.isPlayerPlaying) {
            this.$router.push(`/videos/${this.nextVideoId}`)
          }
        }, 5000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.action_btn_container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  gap: 10px;
  .next_btn {
    display: flex;
    width: 143px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 4px;
    padding: 8px 10px;
    background: white;
    color: black;
    font-size: 12px;

    @media (max-width: 768px) {
      width: 100px;
    }
  }
}
</style>
