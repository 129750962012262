<template>
  <div class="page">
    <div class="container centered">
      <div class="narrow-text-container">
        <div v-html="document.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AboutPokerGo',
  computed: {
    ...mapGetters(['getDocuments']),
    document() {
      const document = this.getDocuments.filter(
        (item) => item.label === 'about'
      )
      if (document.length > 0) {
        return document[0]
      } else {
        return {
          label: 'About',
          content: ''
        }
      }
    }
  },
  methods: {
    ...mapActions(['GET_DOCUMENT'])
  },
  async beforeMount() {
    // await this.$store.dispatch('property/getDocument', 'about')
    await this.$store.dispatch('GET_DOCUMENT', 'about')
  },
  head() {
    return {
      title: {
        inner: 'About and Overview | PokerGO'
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'PokerGO is the exclusive home of the biggest live poker events and TV shows in the world. Learn more about our video streaming service and original content.'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.narrow-text-container {
  width: 100%;
  max-width: 800px;
}
</style>
