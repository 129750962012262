// eslint-disable-next-line no-empty-pattern
import Vue from 'vue'

Vue.prototype.$showError = function({ text, title }) {
  this.$notify({
    group: 'messages',
    title: title,
    text: text,
    type: 'error',
    duration: 20000
  })
}

Vue.prototype.$showInfo = function({ text, title }) {
  this.$notify({
    group: 'messages',
    title: title,
    text: text,
    type: 'error',
    duration: 10000
  })
}

Vue.prototype.$showMessage = function({ text, title }) {
  this.$notify({
    group: 'messages',
    title: title,
    text: text,
    type: 'success',
    duration: 10000
  })
}
