var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-background",
      on: {
        click: function($event) {
          return _vm.$emit("no")
        }
      }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c(
          "div",
          {
            staticClass: "modal-body",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _vm._m(0),
            _vm._m(1),
            _c("br"),
            _c("div", { staticClass: "modal-buttons" }, [
              _c("div", { staticClass: "modal-button" }, [
                _c(
                  "a",
                  {
                    staticClass: "button is-primary save-button",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("continue", $event)
                      }
                    }
                  },
                  [_vm._v("I understand")]
                )
              ]),
              _c("div", { staticClass: "modal-button" }, [
                _c(
                  "a",
                  {
                    staticClass: "button is-primary save-button",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$emit("cancel")
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "modal-top" }, [
      _c("i", {
        staticClass: "fa fa-exclamation-circle",
        staticStyle: { "font-size": "32px", color: "#b31313" }
      }),
      _vm._v(" WARNING! ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("div", { staticClass: "modal-heading" }, [
        _c("p", [
          _vm._v(
            " If you're paying with your bank account through Paypal it will take 2-3 business days for Paypal to clear your funds. You will not gain immediate access to your PokerGO subscription until your funds have been cleared through Paypal. "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }