var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "action_btn_container" }, [
    _c(
      "div",
      [
        _vm.prevVideoId
          ? _c(
              "router-link",
              {
                staticClass: "next_btn",
                attrs: { to: "/videos/" + _vm.prevVideoId }
              },
              [
                _c("font-awesome-icon", {
                  staticStyle: {
                    "margin-right": "8px",
                    transform: "rotate(180deg)"
                  },
                  attrs: { icon: "play" }
                }),
                _c("div", { staticClass: "prev_text" }, [
                  _vm.isMobile
                    ? _c("span", [_vm._v(" Previous ")])
                    : _c("span", [_vm._v("Previous Episode")])
                ])
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      [
        _vm.nextVideoId
          ? _c(
              "router-link",
              { attrs: { to: "/videos/" + _vm.nextVideoId } },
              [
                _c(
                  "progress-button",
                  {
                    ref: "nextProgressBtn",
                    staticClass: "next_btn",
                    staticStyle: {
                      border: "0px",
                      height: "32px",
                      "pointer-events": "none"
                    },
                    attrs: { duration: 5000, "fill-color": "#ccc" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "next_text" },
                      [
                        _vm.isMobile
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.nextVideoOnAir ? "Next" : "Upcoming"
                                  ) +
                                  " "
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.nextVideoOnAir ? "Next" : "Upcoming"
                                ) + " Episode"
                              )
                            ]),
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          staticStyle: { "margin-left": "8px" },
                          attrs: { icon: "play" }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }