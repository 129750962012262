var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-page-wrapper" }, [
    _c("div", { staticClass: "mobile_margin" }),
    _vm.collectionId
      ? _c(
          "div",
          {
            class:
              "" +
              (_vm.entitled ? "back-home" : "back-home back-home-not-entitled")
          },
          [
            _c(
              "router-link",
              { attrs: { to: "/collections/" + _vm.collectionId } },
              [
                _c("font-awesome-icon", {
                  staticClass: "icon-back",
                  attrs: { icon: ["fas", "arrow-left"] }
                }),
                _vm._v(" Back to collection ")
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.entitled
      ? _c(
          "div",
          {
            staticClass: "page container",
            staticStyle: { "margin-top": "0px !important" }
          },
          [
            _c(
              "div",
              { staticClass: "video" },
              [
                _c(
                  "div",
                  { staticClass: "player-container" },
                  [
                    _c(
                      "div",
                      [
                        _vm.fetched && _vm.onAir
                          ? _c("video-player", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.trailerPlaying,
                                  expression: "!trailerPlaying"
                                }
                              ],
                              key: _vm.$route.fullPath,
                              attrs: {
                                entitled: _vm.entitled,
                                id: _vm.routeId,
                                "overlay-image": _vm.overlayImage(),
                                autoplay: !_vm.hasTrailer,
                                "video-data": _vm.videoData,
                                trailerPlayering: _vm.trailerPlaying
                              },
                              on: {
                                streamEnded: function($event) {
                                  _vm.streamLive = false
                                },
                                streamStarted: function($event) {
                                  _vm.streamLive = true
                                },
                                onPlayerPlay: function($event) {
                                  _vm.isPlayerPlaying = true
                                },
                                onPlayerEnded: function($event) {
                                  return _vm.onPlayerEnded()
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.trailerPlaying && _vm.hasTrailer && _vm.trailerFetched
                      ? _c("trailer-player", {
                          key: "trailer-key-" + _vm.routeId,
                          attrs: {
                            id: "trailer-" + _vm.routeId,
                            "video-source": _vm.trailerVideoSource
                          },
                          on: {
                            close: _vm.closeTrailerVideo,
                            end: _vm.endTrailerVideo
                          }
                        })
                      : _vm._e(),
                    !_vm.onAir && !_vm.trailerPlaying && _vm.thumbnailImage
                      ? _c("img", {
                          staticClass: "overlay",
                          attrs: { src: _vm.thumbnailImage }
                        })
                      : _vm._e(),
                    !_vm.onAir && _vm.fetched && !_vm.trailerPlaying
                      ? _c("CountdownTimer", {
                          attrs: { airDate: _vm.airDate, videoType: _vm.type }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("PlayerActionButtons", {
                  ref: "playerActionButtons",
                  attrs: {
                    "prev-video-id": _vm.prevVideoId,
                    "next-video-id": _vm.nextVideoId,
                    nextVideoOnAir: _vm.nextVideoOnAir,
                    isPlayerPlaying: _vm.isPlayerPlaying
                  }
                }),
                _vm.isShowDetail
                  ? _c("div", { staticClass: "video-info" }, [
                      _c("div", { staticClass: "body-header" }, [
                        _c("h1", { staticClass: "body-title" }, [
                          _vm._v(_vm._s(_vm.title))
                        ])
                      ]),
                      _c("div", { staticClass: "description" }, [
                        _vm._m(0),
                        _c("div", { staticClass: "v-cv" }, [
                          _vm.hasTrailer
                            ? _c(
                                "button",
                                {
                                  staticClass: "trailer-button",
                                  on: { click: _vm.setTrailer }
                                },
                                [
                                  !_vm.trailerPlaying
                                    ? _c("font-awesome-icon", {
                                        staticClass: "circled",
                                        attrs: { icon: "play" }
                                      })
                                    : _vm._e(),
                                  _vm.trailerPlaying
                                    ? _c("font-awesome-icon", {
                                        staticClass: "circled",
                                        attrs: { icon: "pause" }
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.trailerPlaying
                                          ? "Playing..."
                                          : "Watch Trailer"
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.loggedIn
                            ? _c(
                                "button",
                                {
                                  staticClass: "my-list-button",
                                  on: { click: _vm.setFavorite }
                                },
                                [
                                  _vm.favorite
                                    ? _c("font-awesome-icon", {
                                        staticClass: "circled",
                                        attrs: { icon: "check" }
                                      })
                                    : _vm._e(),
                                  !_vm.favorite
                                    ? _c("font-awesome-icon", {
                                        staticClass: "circled",
                                        attrs: { icon: "plus" }
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.favorite ? "remove" : "my list"
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _vm.subheading !== ""
                          ? _c("p", { staticClass: "subheading" }, [
                              _vm._v(" " + _vm._s(_vm.subheading) + " ")
                            ])
                          : _vm._e(),
                        _c("p", [_vm._v(_vm._s(_vm.description))])
                      ])
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm.type === "live" && !_vm.hideChat
              ? _c("Chat", {
                  attrs: { videoId: _vm.videoId },
                  on: {
                    clicked: _vm.onClickChild,
                    "hide-chat": _vm.handleHideChat
                  }
                })
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "touch",
                    rawName: "v-touch:swipe.up",
                    value: _vm.previousColumn,
                    expression: "previousColumn",
                    arg: "swipe",
                    modifiers: { up: true }
                  },
                  {
                    name: "touch",
                    rawName: "v-touch:swipe.down",
                    value: _vm.nextColumn,
                    expression: "nextColumn",
                    arg: "swipe",
                    modifiers: { down: true }
                  }
                ],
                ref: "videoList",
                staticClass: "video-list"
              },
              [
                _vm.currentIdx > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "up-button",
                        on: { click: _vm.previousColumn }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "icon" },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "up-list",
                              attrs: { icon: "chevron-up" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { ref: "videoContainer", staticClass: "list-container" },
                  _vm._l(this.slicedShowTiles, function(entity, i) {
                    return _c(
                      "div",
                      {
                        key: entity.id,
                        ref: "videoTile",
                        refInFor: true,
                        staticClass: "entity",
                        style: _vm.positionStyle(i)
                      },
                      [
                        _c("div", { staticClass: "thumbnail" }, [
                          _c("img", {
                            attrs: { src: _vm.overlayImage(entity) }
                          })
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c("div", { staticClass: "episode-title" }, [
                            _vm._v(_vm._s(entity.title))
                          ]),
                          _vm._m(1, true)
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _vm.sideTiles.length - _vm.currentIdx >=
                _vm.currentlyVisible * 0.5
                  ? _c(
                      "div",
                      {
                        staticClass: "down-button",
                        on: { click: _vm.nextColumn }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "icon" },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "down-list",
                              attrs: { icon: "chevron-down" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          ],
          1
        )
      : _vm._e(),
    !_vm.entitled
      ? _c("div", { staticClass: "subscribe-container" }, [
          _c(
            "div",
            {
              staticClass: "video-detail",
              class:
                !_vm.onAir && _vm.fetched && _vm.isMobile
                  ? "video-detail-countdown"
                  : ""
            },
            [
              !_vm.onAir && _vm.fetched && _vm.isMobile
                ? _c("div", { staticClass: "mobile-countdown-wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "mobile-countdown" },
                      [
                        _c("CountdownTimer", {
                          attrs: {
                            airDate: _vm.airDate,
                            videoType: _vm.type,
                            type: "not-subscribe-mobile"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "video-img" },
                [
                  _c("img", { attrs: { src: _vm.thumbnailImage } }),
                  _vm.trailerPlaying && _vm.hasTrailer && _vm.trailerFetched
                    ? _c("trailer-player", {
                        key: "trailer-key-" + _vm.routeId,
                        staticClass: "subscribe-trailer",
                        attrs: {
                          id: "trailer-" + _vm.routeId,
                          "video-source": _vm.trailerVideoSource
                        },
                        on: {
                          close: _vm.closeTrailerVideo,
                          end: _vm.endTrailerVideo
                        }
                      })
                    : _vm._e(),
                  !_vm.onAir &&
                  _vm.fetched &&
                  !_vm.isMobile &&
                  !_vm.trailerPlaying
                    ? _c("CountdownTimer", {
                        attrs: {
                          airDate: _vm.airDate,
                          videoType: _vm.type,
                          type: "not-subscribe-desktop"
                        }
                      })
                    : _vm._e(),
                  _vm.isMobile
                    ? _c("div", { staticClass: "btn-trailer-wrapper" }, [
                        _vm.hasTrailer
                          ? _c(
                              "button",
                              {
                                staticClass: "trailer-button",
                                on: { click: _vm.setTrailer }
                              },
                              [
                                !_vm.trailerPlaying
                                  ? _c("font-awesome-icon", {
                                      staticClass: "circled",
                                      attrs: { icon: "play" }
                                    })
                                  : _vm._e(),
                                _vm.trailerPlaying
                                  ? _c("font-awesome-icon", {
                                      staticClass: "circled",
                                      attrs: { icon: "pause" }
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.trailerPlaying
                                        ? "Playing..."
                                        : "Watch Trailer"
                                    ) +
                                    " "
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.loggedIn
                          ? _c(
                              "button",
                              {
                                staticClass: "my-list-button",
                                on: { click: _vm.setFavorite }
                              },
                              [
                                _vm.favorite
                                  ? _c("font-awesome-icon", {
                                      staticClass: "circled",
                                      attrs: { icon: "check" }
                                    })
                                  : _vm._e(),
                                !_vm.favorite
                                  ? _c("font-awesome-icon", {
                                      staticClass: "circled",
                                      attrs: { icon: "plus" }
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.favorite ? "remove" : "my list"
                                    ) +
                                    " "
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("br")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "video-desc" }, [
                _c("p", { staticClass: "video-title" }, [
                  _vm._v(_vm._s(_vm.title))
                ]),
                _vm.subheading
                  ? _c("p", { staticClass: "subheading" }, [
                      _vm._v(_vm._s(_vm.subheading))
                    ])
                  : _vm._e(),
                !_vm.isMobile
                  ? _c("div", { staticClass: "btn-trailer-wrapper" }, [
                      _vm.hasTrailer
                        ? _c(
                            "button",
                            {
                              staticClass: "trailer-button",
                              on: { click: _vm.setTrailer }
                            },
                            [
                              !_vm.trailerPlaying
                                ? _c("font-awesome-icon", {
                                    staticClass: "circled",
                                    attrs: { icon: "play" }
                                  })
                                : _vm._e(),
                              _vm.trailerPlaying
                                ? _c("font-awesome-icon", {
                                    staticClass: "circled",
                                    attrs: { icon: "pause" }
                                  })
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.trailerPlaying
                                      ? "Playing..."
                                      : "Watch Trailer"
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.loggedIn
                        ? _c(
                            "button",
                            {
                              staticClass: "my-list-button",
                              on: { click: _vm.setFavorite }
                            },
                            [
                              _vm.favorite
                                ? _c("font-awesome-icon", {
                                    staticClass: "circled",
                                    attrs: { icon: "check" }
                                  })
                                : _vm._e(),
                              !_vm.favorite
                                ? _c("font-awesome-icon", {
                                    staticClass: "circled",
                                    attrs: { icon: "plus" }
                                  })
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.favorite ? "remove" : "my list") +
                                  " "
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("br")
                    ])
                  : _vm._e(),
                !_vm.isMobile
                  ? _c("p", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.description))
                    ])
                  : _vm._e()
              ])
            ]
          ),
          _c("div", { staticClass: "content-area" }, [
            _c("p", { staticClass: "notation-title" }, [
              _vm._v(" " + _vm._s(_vm.headerText) + " ")
            ]),
            _c("p", { staticClass: "notation-desc" }, [
              _vm._v(
                " Watch 100+ days a year of LIVE poker tournaments, including the WSOP and enjoy exclusive, NEW poker shows such as No Gamble, No Future & High Stakes Poker! "
              )
            ]),
            _c("div", { staticClass: "btn-wrapper" }, [
              _c(
                "div",
                {
                  staticClass: "glowing-btn px-5 h-14 my-1",
                  on: { click: _vm.handleNavigation }
                },
                [_vm._v(" " + _vm._s(_vm.actionBtnText) + " ")]
              ),
              !_vm.loggedIn
                ? _c(
                    "div",
                    { staticClass: "btn-signin", on: { click: _vm.gotoLogin } },
                    [_vm._v(" SIGN IN ")]
                  )
                : _vm._e()
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "duration" }, [
      _c("b", [_vm._v(" Episode 1 ")]),
      _vm._v(" "),
      _c("span", [_vm._v("|")]),
      _vm._v(" 2:36:02 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "duration" }, [
      _c("b", [_vm._v(" Episode 1 ")]),
      _vm._v(" "),
      _c("span", [_vm._v("|")]),
      _vm._v(" 2:36:02 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }