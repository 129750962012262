/* eslint-disable */

export default ({ app }) => {
    app.router.afterEach((to, from) => {
        if (to.path === "/checkout") {
        switch (to.query.success) {
            case "true":
            setTimeout(() => {
                ga('send', {
                    hitType: 'event',
                    eventCategory: 'PurchasedSubscription',
                    eventAction: 'view',
                    eventLabel: 'subscription-complete',
                    eventValue: 1
                });
            }, 2000);
            break;
            case "false":
            setTimeout(() => {
                ga('send', {
                hitType: 'event',
                eventCategory: "PurchasedSubscription",
                eventAction: "view",
                eventLabel: "unsuccessful-checkout",
                eventValue: 1,
                });
            }, 2000);
            break;
            default:
            break;
        }
        }
    });
};
