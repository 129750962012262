<template>
  <div class="page">
    <div>
      <Banner class="banner_class" page="schedule" location="header" />
      <div class="flex-column">
        <!-- Top Section -->
        <div class="top-section" style="display: none">
          <div class="red-skewed">
            <div class="red-unskewed-content">Live Now > watch</div>
          </div>
          <div class="after"></div>
        </div>
        <!-- Content -->
        <div class="content">
          <p>Times are Displayed in Your Time Zone</p>
          <div class="month-group" v-for="m in monthNamesVisible" :key="m">
            <div class="month-title">{{ m }} {{ getYear(entities[m]) }}</div>
            <div
              class="event"
              :key="entity.id"
              v-for="entity in entities[m]"
              :class="{ live: entity.video_type === 'live' }"
            >
              <div class="date">
                <div class="date-header">
                  <div class="day">{{ entity.day }}</div>
                  <div class="time">
                    <b> {{ entity.time }} </b> {{ entity.timeOfDay }}
                  </div>
                </div>
                <div class="weekday">
                  {{ entity.dayOfWeek }}
                </div>
              </div>
              <router-link
                class="video-details"
                :to="`/${entity.type}s/${entity.id}`"
              >
                <div class="thumbnail">
                  <!-- Temporary thumbnail -->
                  <img :src="getThumnail(entity)" />
                </div>
                <div class="description">
                  <div
                    v-if="liveNow(entity)"
                    class="status-label"
                    :class="{ live: entity.video_type === 'live' }"
                  >
                    Live now
                  </div>
                  <div
                    v-else
                    class="status-label"
                    :class="{ live: entity.video_type === 'live' }"
                  >
                    {{ entity.subheading }}
                  </div>
                  <div class="title" v-line-clamp:20="2">
                    {{ entity.title }}
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Banner from '../../components/Banner.vue'
export default {
  name: 'Schedule',
  components: { Banner },
  computed: {
    monthNamesVisible() {
      let list = []
      for (let item in this.entities) {
        list.push(item)
        //console.log(item)
      }
      return list
    },
    rand() {
      return Math.round(10 * Math.random()) + 1
    },
    scheduleData() {
      return this.$store.state.schedule.scheduleData
    }
  },
  data() {
    return {
      entities: [],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      weekDays: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ]
    }
  },
  methods: {
    liveNow(entity) {
      const now = new Date()
      return now > new Date(entity.air_date) && now < new Date(entity.end_date)
    },
    getYear(item) {
      return item[0].year
    },
    getThumnail(entity) {
      if (!entity.pre_air_images) {
        return entity.images[0].url
      } else {
        return entity.pre_air_images[0].url
      }
    },
    async getData() {
      const res = await this.$store.dispatch('schedule/GetScheduleData')
      if (res === undefined || res.data === undefined) {
        console.log('Cannot get schedule view')
        setTimeout(() => this.getData(), 3000)
        return
      }
      this.entities = this.scheduleData.data.collection_list.reduce(
        (acc, cur) => {
          const airDate = new Date(cur.air_date)
          const month = this.monthNames[airDate.getMonth()]
          const min = `${airDate.getMinutes()}`
          cur.day = airDate.getDate()
          cur.time =
            (airDate.getHours() % 12 || 12) +
            ':' +
            (min.length < 2 ? `0${min}` : min)
          cur.timeOfDay = airDate.getHours() >= 12 ? 'PM' : 'AM'
          cur.year = airDate.getFullYear()
          cur.dayOfWeek = this.weekDays[airDate.getDay()]
          acc[month] = (acc[month] || []).concat(cur)
          //.sort((a, b) => new Date(a.air_date) - new Date(b.air_date))
          return acc
        },
        {}
      )
    }
  },
  async mounted() {
    // reset videoId on Schedule view
    this.$store.commit('setVideoId', null)

    document.title = this.$route.name + ' | PokerGO'
    this.getData()
    //console.log(this.entities)
  }
}
</script>
<style scoped lang="scss">
.bgAuthenticated {
  background-image: none !important;
}
.flex-column {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.top-section {
  height: 300px;
  width: 100vw;
  background: url('https://storage.googleapis.com/gxm-video-platform-images/pokergo-staging/61cffa8f-45dd-46c2-b17b-7dace77119ef-4K.jpg')
    no-repeat;
  background-size: cover;
  position: relative;
  right: $side-padding;
  display: flex;
  flex-direction: column;
  @media (max-width: 786px) {
    height: 215px;
    right: 0;
  }
  .after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    display: block;
    color: #fff;
    background: linear-gradient(180deg, rgba(2, 0, 36, 0) 30%, #111820 90%);
    @media (max-width: 786px) {
      height: 215px;
    }
  }
  .red-skewed {
    background: #cc0829;
    width: 250px;
    transform: skewX(-25deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 0.5rem;
    .red-unskewed-content {
      transform: skewX(25deg);
      display: flex;
      line-height: 20px;
      color: #fff;
      text-transform: uppercase;
      font-size: 22px;
      font-weight: 900;
    }
  }
}
.month-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 80px;
  @media (max-width: 786px) {
    margin-bottom: 40px;
  }
  .month-title {
    width: 100%;
    text-transform: uppercase;
    background: #2b363d;
    font-family: 'UniSans-Bold-Italic';
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 2rem;
    padding-left: 1.2rem;
    height: 40px;
    padding-top: 2px;
    @media (max-width: 786px) {
      text-align: center;
    }
  }
}

.event {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  background-color: #111820;
  border: 2px solid #2b363d;
  @media (max-width: 786px) {
    flex-direction: column;
    font-size: 12px;
  }
  &.live {
    border: 2px solid #cc0829;
  }
}

.date {
  display: flex;
  flex-direction: column;
  width: 15%;
  min-width: 200px;
  @media (max-width: 786px) {
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    height: 3em;
  }
  .date-header {
    display: flex;
    flex-direction: row;
    height: 60%;
    min-width: 120px;
    @media (max-width: 786px) {
      width: 30%;
      height: auto;
    }
    .day {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #2b363d;
      width: 3em;
      font-weight: bold;
      font-size: 1.75em;
      // @media (max-width: 786px) {
      //   width: 3em;
      // }
    }
    .time {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;
      background: #555d60;
      font-size: 18px;
    }
  }
  .weekday {
    height: 40%;
    padding-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    @media (max-width: 786px) {
      height: auto;
      width: 70%;
      background: #555d60;
      justify-content: flex-end;
    }
  }
}
.video-details {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: stretch;
  text-decoration: none;
  color: inherit;
  .thumbnail {
    height: 90px;
    @media (max-width: 786px) {
      height: 70px;
    }
    img {
      height: 100%;
    }
  }
  .description {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    .status-label {
      color: inherit;
      min-height: 1.5rem;
      font-weight: bold;
      font-size: 0.75em;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #9f26b5;
      /* For live events red */
      &.live {
        color: #cc0829;
      }
    }
    .title {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
      font-weight: bold;
      max-height: 42px;
      line-height: 20px;
    }
  }
}
.banner_class {
  margin-top: 40px;
  @media (max-width: 971px) {
    margin-top: 0px;
  }
}
</style>
