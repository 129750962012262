<script>
export default {
  name: 'Stats',
  data() {
    return {
      stats: [
        { value: '100+', description: 'Coaching Videos' },
        { value: '75+', description: 'Hours of Poker Training' },
        { value: '40+', description: 'Professional Coaches' }
      ]
    }
  }
}
</script>

<template>
  <div
    class="text-center flex flex-col md:flex-row justify-center gap-10 md:gap-14 mb-10 md:mb-28"
  >
    <div
      v-for="(stat, i) in stats"
      :key="`state_${i}`"
      class="flex flex-col items-center"
    >
      <div class="text-7xl">
        {{ stat.value }}
      </div>
      <div class="text-lg opacity-50 w-32">{{ stat.description }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
