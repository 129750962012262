<template>
  <div class="page">
    <CancelBanner />
    <div v-html="document.content" class="content"></div>
  </div>
</template>
<script>
import CancelBanner from '@/components/CancelBanner.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CancelIOS',
  components: {
    CancelBanner
  },
  data() {
    return {}
  },
  async beforeMount() {
    await this.$store.dispatch('GET_DOCUMENT', 'cancel-ios')
  },
  computed: {
    ...mapGetters(['getDocuments']),
    document() {
      const document = this.getDocuments.filter(
        (item) => item.label === 'cancel-ios'
      )
      if (document.length > 0) {
        return document[0]
      } else {
        return {
          id: 'cancel-ios',
          label: 'cancel-ios',
          content: ''
        }
      }
    }
  },
  methods: {
    ...mapActions(['GET_DOCUMENT'])
  }
}
</script>
<style lang="scss" scoped>
::v-deep h1 {
  font-family: 'UniSans-Bold-Italic';
  text-align: center;
}
::v-deep p {
  text-align: left;
  font-size: larger;
}

.page {
  flex-flow: column;
  background-color: white;
  color: black;
}
.content {
  margin-bottom: 0;
}
</style>
