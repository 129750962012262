<template>
  <div
    class="z-20 absolute right-0 top-10 w-52 max-h-72 flex flex-col flex-gap-2 overflow-y-auto bg-white rounded-sm shadow-md"
  >
    <div v-if="type === 'radio'">
      <GameTypeRadio
        v-for="(gameType, index) in list"
        :key="index"
        :label="gameType.name"
        :id="gameType.id"
        :value="gameType.name"
        :isChecked="selectedGameType.map((el) => el.id).includes(gameType.id)"
        name="game-type"
        @select="$emit('select', gameType)"
      />
    </div>

    <div v-else-if="type === 'select'">
      <GameTypeSelect
        v-for="(gameType, index) in list"
        :key="index"
        :label="gameType.name"
        :id="gameType.id"
        :value="gameType.name"
        :isChecked="selectedGameType.map((el) => el.id).includes(gameType.id)"
        name="game-type"
        @select-checkbox="
          (e) => $emit('select-checkbox', { value: e, data: gameType })
        "
      />
    </div>
  </div>
</template>

<script>
import GameTypeRadio from './GameTypeRadio.vue'
import GameTypeSelect from './GameTypeSelect.vue'

export default {
  components: {
    GameTypeSelect,
    GameTypeRadio
  },
  props: {
    type: {
      type: String,
      default: 'radio'
    },
    list: {
      type: Array,
      default: () => []
    },
    selectedGameType: {
      type: Array,
      default: () => []
    }
  }
}
</script>
