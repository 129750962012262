var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "picker-container" }, [
    _c(
      "div",
      { staticClass: "grid-container" },
      _vm._l(_vm.stickers, function(sticker) {
        return _c("div", [
          _c("img", {
            staticClass: "sticker",
            attrs: { src: "" + sticker.value },
            on: {
              click: function($event) {
                return _vm.sendSticker(sticker)
              }
            }
          })
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }