<template>
  <div class="overlay-container" v-if="displayOverlay">
    <div class="overlay bg-card-suits" ref="myElement">
      <font-awesome-icon @click="closeDialog" class="close-icon" icon="times" />
      <div class="main-container">
        <img
          class="pokerplay-logo"
          src="@/assets/images/pokergoplay/pokergoplay.webp"
        />
        <div class="join-text">
          Join the community at <br />PokerGO Play and win real <br />prizes
          playing real poker.
        </div>
        <div>
          <a href="https://pokergoplay.com/" target="_blank" class="btn">
            Play Now
          </a>
        </div>

        <div class="promo-text-container">
          Use code <span class="promo-code">POKERGOPLAY</span>
          <span style="display: block">for extra chips.</span>
        </div>

        <div class="check-box-container">
          <input
            type="checkbox"
            id="do-not-show"
            style="margin-right: 8px"
            v-model="doNotShowAgain"
          />

          <label for="do-not-show" class="do-not-show-text">
            Do not show this again
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      displayOverlay: false,
      doNotShowAgain: false
    }
  },
  mounted() {
    this.clickOutsideHandler = (event) => {
      // Check if the click is outside the element
      if (!this.$refs.myElement.contains(event.target)) {
        this.closeDialog()
      }
    }
    // Add click event listener to the document
    document.addEventListener('click', this.clickOutsideHandler)

    const lastDisplayed = localStorage.getItem('play_overlay_displayed')
    if (lastDisplayed) {
      let duration = moment.duration(moment().diff(moment(lastDisplayed)))
      let hours = duration.asHours()
      if (hours < 6) {
        return
      }
    }
    this.displayOverlay = true
  },
  beforeDestroy() {
    // Remove the click event listener from the document
    document.removeEventListener('click', this.clickOutsideHandler)
  },
  methods: {
    closeDialog() {
      this.displayOverlay = false

      const mainOverlayDisplayedTime = this.doNotShowAgain
        ? moment()
            .add(100, 'y')
            .toString()
        : moment().format()

      localStorage.setItem('play_overlay_displayed', mainOverlayDisplayedTime)
    }
  }
}
</script>

<style lang="scss" scoped>
.overlay-container {
  top: 0px;
  z-index: 1001;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  width: 1048px;
  height: 600px;
  margin: 0px 20px;
  background-size: cover;
  background: url('../assets/images/pokergoplay/bg-web.png') no-repeat center;

  border-radius: 6px;
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 640px) {
    background: linear-gradient(
        180deg,
        rgba(15, 23, 30, 0) 0%,
        rgba(15, 23, 30, 0.24) 85%,
        #0e161d 100%
      ),
      url('../assets/images/pokergoplay/bg-mobile.png') no-repeat center;
    align-items: flex-start;
    height: auto;
  }

  .main-container {
    z-index: 12;
    text-align: center;
    font-size: 35px;
  }

  .close-icon {
    position: absolute;
    z-index: 20;
    right: 20px;
    top: 20px;
    color: rgba(255, 255, 255);
    cursor: pointer;
  }
}

.pokerplay-logo {
  width: 180px;
  margin-bottom: 12px;
  @media (max-width: 640px) {
    width: 124px;
    margin-bottom: 13px;
    margin-top: 16px;
  }
}

.check-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;

  label {
    opacity: 0.4;
  }

  input[type='checkbox'] {
    accent-color: #9f26b5;
    height: 16px;
    width: 16px;
  }

  @media (max-width: 640px) {
    margin-bottom: 12px;
  }
}

.join-text {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 32px;
  @media (max-width: 640px) {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 39px;
  }
}
.promo-text-container {
  font-size: 14px;
  margin-bottom: 52px;
  @media (max-width: 640px) {
    margin-bottom: 116px;
    line-height: 21px;
  }
  .promo-code {
    font-family: UniSans-Bold;
    @media (max-width: 640px) {
      display: block;
    }
  }
}

.btn {
  box-sizing: border-box;
  height: 58px;
  width: 168px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 12px 4px #9f26b5, 0 0 8px 4px #9f26b5;
  color: white;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  margin: 0 auto 23px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: UniSans-SemiBold;

  @media (max-width: 640px) {
    width: 152px;
    margin: 0 auto 23px auto;
  }
}
</style>
