var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "login-box" }, [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.reset($event)
                }
              }
            },
            [
              _c("div", [
                _c("h2", [_vm._v("Reset Password")]),
                _c("div", { staticClass: "text-field" }, [
                  _c("label", [_vm._v("New Password")]),
                  (_vm.showNewPassword ? "text" : "password") === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.pass,
                            expression: "pass"
                          }
                        ],
                        attrs: {
                          id: "username-input",
                          required: "",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.pass)
                            ? _vm._i(_vm.pass, null) > -1
                            : _vm.pass
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.pass,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.pass = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.pass = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.pass = $$c
                            }
                          }
                        }
                      })
                    : (_vm.showNewPassword ? "text" : "password") === "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.pass,
                            expression: "pass"
                          }
                        ],
                        attrs: {
                          id: "username-input",
                          required: "",
                          type: "radio"
                        },
                        domProps: { checked: _vm._q(_vm.pass, null) },
                        on: {
                          change: function($event) {
                            _vm.pass = null
                          }
                        }
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.pass,
                            expression: "pass"
                          }
                        ],
                        attrs: {
                          id: "username-input",
                          required: "",
                          type: _vm.showNewPassword ? "text" : "password"
                        },
                        domProps: { value: _vm.pass },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.pass = $event.target.value
                          }
                        }
                      }),
                  _c(
                    "span",
                    {
                      staticClass: "eye-icon",
                      on: {
                        click: function($event) {
                          _vm.showNewPassword = !_vm.showNewPassword
                        }
                      }
                    },
                    [
                      !_vm.showNewPassword
                        ? _c("img", {
                            staticStyle: { height: "20px" },
                            attrs: {
                              src: require("@/assets/images/eye-solid.svg"),
                              alt: ""
                            }
                          })
                        : _vm._e(),
                      _vm.showNewPassword
                        ? _c("img", {
                            staticStyle: { height: "20px" },
                            attrs: {
                              src: require("@/assets/images/eye-slash-solid.svg"),
                              alt: ""
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]),
                _c("div", { staticClass: "text-field" }, [
                  _c("label", [_vm._v("Confirm Password")]),
                  (_vm.showConfirmPassword ? "text" : "password") === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.confirmPass,
                            expression: "confirmPass"
                          }
                        ],
                        attrs: {
                          id: "password-input",
                          required: "",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.confirmPass)
                            ? _vm._i(_vm.confirmPass, null) > -1
                            : _vm.confirmPass
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.confirmPass,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.confirmPass = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.confirmPass = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.confirmPass = $$c
                            }
                          }
                        }
                      })
                    : (_vm.showConfirmPassword ? "text" : "password") ===
                      "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.confirmPass,
                            expression: "confirmPass"
                          }
                        ],
                        attrs: {
                          id: "password-input",
                          required: "",
                          type: "radio"
                        },
                        domProps: { checked: _vm._q(_vm.confirmPass, null) },
                        on: {
                          change: function($event) {
                            _vm.confirmPass = null
                          }
                        }
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.confirmPass,
                            expression: "confirmPass"
                          }
                        ],
                        attrs: {
                          id: "password-input",
                          required: "",
                          type: _vm.showConfirmPassword ? "text" : "password"
                        },
                        domProps: { value: _vm.confirmPass },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.confirmPass = $event.target.value
                          }
                        }
                      }),
                  _c(
                    "span",
                    {
                      staticClass: "eye-icon",
                      on: {
                        click: function($event) {
                          _vm.showConfirmPassword = !_vm.showConfirmPassword
                        }
                      }
                    },
                    [
                      !_vm.showConfirmPassword
                        ? _c("img", {
                            staticStyle: { height: "20px" },
                            attrs: {
                              src: require("@/assets/images/eye-solid.svg"),
                              alt: ""
                            }
                          })
                        : _vm._e(),
                      _vm.showConfirmPassword
                        ? _c("img", {
                            staticStyle: { height: "20px" },
                            attrs: {
                              src: require("@/assets/images/eye-slash-solid.svg"),
                              alt: ""
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]),
                _c("div", [
                  _vm.errorMessage !== ""
                    ? _c(
                        "p",
                        { staticClass: "p-error" },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "times" } }),
                          _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _c("br"),
              _c(
                "button",
                { staticClass: "button", attrs: { type: "submit" } },
                [_vm._v("Reset")]
              ),
              _c("span", { staticClass: "spacer-small" }, [_vm._v("|")]),
              _c("router-link", { attrs: { to: "/register" } }, [
                _vm._v("Register")
              ])
            ],
            1
          )
        ])
      ]),
      _vm.modalClosed === false
        ? _c("generic-modal", {
            attrs: {
              text:
                "Your password has been reset. Please return to the " +
                _vm.usertype +
                " and login with your new password."
            },
            on: {
              yes: function($event) {
                return _vm.redirect()
              },
              no: function($event) {
                _vm.modalClosed = true
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }