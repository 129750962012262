<template>
  <div
    v-show="visible && !errorLoading"
    @click="navigate"
    class="position-relative long-card"
  >
    <!-- Image Container -->
    <span class="image-container position-relative mb-2 cursor-pointer">
      <img
        :src="thumbnailImage"
        @error="error"
        @load="imageLoaded"
        v-show="!placeholder"
        :ref="'thumbnail'"
        class="image"
      />

      <img
        v-if="!entitled"
        class="locked"
        src="@/assets/images/lock.png"
        title="A subscription is required to view this video."
      />
      <img
        v-if="entityType === 'collection'"
        class="icon-collection"
        src="@/assets/images/icon_collection.png"
        title="Video collection"
      />
      <img
        v-show="placeholder"
        src="@/assets/images/pgoplaceholder-sm.png"
        class="image"
      />
      <!-- progress bar -->
      <i v-if="progressPercent" class="progress-bar-bg" />
      <i
        v-if="progressPercent"
        :style="{ width: progressPercent }"
        class="progress-bar"
      />
      <!---->
    </span>
    <!-- Text Container -->
    <span class="text-container">
      <small v-if="showTitle" class="title">{{ title }}</small>
      <br v-if="showTitle" />
      <small v-if="subheading" class="subheading">{{ subheading }}</small>
      <small v-show="description" class="desc">{{ description }}</small>
      <br />
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { isArray } from 'lodash'

export default {
  name: 'LongCard',
  props: {
    entityType: {
      required: true,
      type: String
    },
    entityId: {
      required: true,
      type: String
    },
    entityLockbypass: {
      required: false,
      type: Boolean,
      default: () => false
    },
    visible: {
      type: Boolean,
      default: () => true
    },
    large: {
      type: Boolean,
      default: () => false
    },
    skeleton: {
      type: Boolean,
      default: () => false
    },
    showTitle: {
      type: Boolean,
      default: () => true
    },
    entityData: {
      type: Object,
      default: () => {}
    } // this should be {images: []Image; title: string; progress: number, entityType: 'video'|'collection'}
  },
  data() {
    return {
      title: '',
      date: '',
      images: [],
      pre_air_images: [],
      description: '',
      subheading: '',
      errorLoading: false,
      showPlaceholder: false,
      userEntitled: false,
      lockBypass: false,
      isFallbackImage: false
    }
  },
  computed: {
    ...mapGetters(['getVideoProgressPercent', 'getPropertyId']),
    property() {
      return this.getPropertyId
    },
    progressPercent() {
      if (this.getVideoProgressPercent(this.entityId))
        return String(this.getVideoProgressPercent(this.entityId)) + '%'
      return 0
    },
    placeholder() {
      return this.showPlaceholder || this.skeleton
    },
    entitled() {
      if (!this.lockBypass) {
        return this.userEntitled
      } else {
        // remove lock icon
        return true
      }
    },
    thumbnailImage() {
      // prepair image for live video (in search)
      if (this.entityData.video_type === 'live' && this.pre_air_images.length) {
        const filtered = this.pre_air_images.filter((img) => img.label === 'SD')
        if (filtered.length) return filtered[0].url
      }

      if (isArray(this.images)) {
        for (let i = 0; i < this.images.length; i++) {
          if (this.images[i].label === 'SD') {
            return this.images[i].url
          }
        }
      }

      return this.images
    },
    hdImage() {
      const now = new Date()
      const air_date = new Date(this.air_date)
      if (now < air_date && this.pre_air_images !== undefined) {
        for (let i = 0; i < this.pre_air_images.length; i++) {
          if (this.pre_air_images[i].label === 'SD') {
            return this.pre_air_images[i].url
          }
        }
      } else {
        for (let i = 0; i < this.images.length; i++) {
          if (this.images[i].label === 'SD') {
            return this.images[i].url
          }
        }
      }
      return ''
    }
  },
  async mounted() {
    //console.log('long card entites', this.entityData)
    if (this.entityData && this.entityData.images) {
      this.title = this.entityData.title
      this.entityType = this.entityData.type
      this.images = this.entityData.images
      this.pre_air_images = this.entityData.pre_air_images
      this.air_date = this.entityData.air_date
      this.userEntitled = this.entityData.entitled
      if (this.entityData.description !== '') {
        this.description = this.entityData.description
      }

      if (this.entityData.subheading !== '') {
        this.subheading = this.entityData.subheading
      }

      if (this.entityLockbypass) {
        this.lockBypass = this.entityLockbypass
      }

      return
    }

    if (this.entityType !== 'collection' && this.entityType !== 'video') {
      return
    }
    //remove api call here
  },
  methods: {
    error(e) {
      //e.target.src = this.thumbnailImage
      console.log('Error loading: ' + this.thumbnailImage)

      // fallback to 'images' if live video doesn't have preairImage
      if (
        this.entityData.video_type === 'live' &&
        this.images.length &&
        !this.isFallbackImage
      ) {
        // set flag to avoid infinite failure and loading
        this.isFallbackImage = true
        const filtered = this.images.filter((img) => img.label === 'SD')
        if (filtered.length) e.target.src = filtered[0].url
      }
    },
    imageLoaded() {
      this.showPlaceholder = false
    },
    navigate() {
      let path
      if (this.entityType === 'video' || this.entityType === 'vod') {
        path = '/videos/' + this.entityId
      }
      if (this.entityType === 'collection' || this.entityType === 'playlist') {
        path = '/collections/' + this.entityId
      }
      this.$emit('click')
      this.$router.push(path)
    }
  }
}
</script>
<style lang="scss" scoped>
.long-card {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: $header-bg-color;
  transition: 0.2s;

  .image-container {
    display: flex;
    flex: 0 1 auto;
    max-width: 370px;
    height: 211px;
    margin-right: 10px;
    border: solid #293038 4px;
  }

  .text-container {
    display: flex;
    flex: 1;
    flex-flow: column;
    min-height: 211px;
    height: 100%;
    padding-top: 10px;
    color: #c5c5c5;
  }
  @media screen and (max-width: 420px) {
    .text-container {
      padding: 10px;
    }
  }

  .image {
    width: auto;
    height: 100%;
  }
  .video-card {
    height: 100%;
  }
  .title {
    max-width: 80%;
    font-size: 20px;
    font-family: 'UniSans-Bold-Italic';
    text-transform: uppercase;
  }
  .desc {
    font-size: 16px;
    font-weight: lighter;
  }
  .subheading {
    color: #9f26b5;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .date {
    font-size: 14px;
  }
  .position-relative {
    position: relative !important;
  }

  .progress-bar-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: #535353;
    width: 100%;
  }
  .progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: $accent-color;
  }
}

.long-card:hover {
  color: #fff;

  .image-container {
    border: solid #303c44 4px;
  }

  .title {
    font-weight: 900;
    text-decoration: underline;
  }
}
@media screen and (max-width: 768px) {
  .long-card {
    display: block;
    .text-container {
    }
  }
}
@media screen and (max-width: 420px) {
  .long-card {
    width: 100%;
    .image-container {
      width: 100%;

      .image {
        width: 100%;
      }
    }
  }
}
.locked {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 12%;
  min-width: 15px;
  max-width: 40px;
  height: 15%;
  min-height: 15px;
  max-height: 40px;
  font-size: 30px;
  color: red;
}

.icon-collection {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 37px;
  height: 28px;
}
</style>
