import api from '@/api'
export default {
  async FetchTransactions(state) {
    let response = await api.account.fetchTransactions()
    if (response.status === 200) {
      state.commit('setTransaction', response.data.data)
    }
    return response
  },
  async Cancel() {
    let response = await api.account.cancel()
    if (response.status === 200) {
      console.log('responseCancel', response.data)
    }
    return response
  },
  async TakeDiscount(state, payload) {
    let response = await api.account.takeDiscount(payload.interval)
    if (response.status === 200) {
      console.log('responseTakeDiscount', response.data)
    }
    return response
  },
  async SaveNameChange(state, payload) {
    let response = await api.account.saveNameChange(payload)
    if (response.status === 200) {
      console.log('responseSaveNameChange', response.data)
    }
    return response
  },
  async SavePassword(state, payload) {
    let response = await api.account.savePassword(payload)
    if (response.status === 200) {
      console.log('responseSavePassword', response.data)
    }
    return response
  },
  async ResetPassword(state, payload) {
    let response = await api.account.resetPassword(payload.data, payload.type)
    if (response.status === 200) {
      console.log('responseResetPassword', response.data)
    }
    return response
  },
  async ForgotPassword(state, payload) {
    let response = await api.authentication.forgotPassword(
      payload.user,
      payload.user_type
    )
    if (response.status === 200) {
      console.log('getSubscriberProgress', response)
    }
    return response
  }
}
