import api from '.'
import { instance as axios } from '../plugins/axios'
import { settings } from '@/library/variables'

export const home = {
  async getSubscriberProgress() {
    try {
      return await axios.get(
        '/properties/' + settings.propertyID + '/subscribers/me/progress'
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getSubscriberFavorites() {
    try {
      return await axios.get(
        '/v2/properties/' + settings.propertyID + '/subscribers/me/favorites'
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getViewData(viewname) {
    try {
      return await axios.get(
        '/v2/properties/' +
          settings.propertyID +
          '/views/' +
          viewname +
          '?include=featured,entities'
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async patchProgress(videoId) {
    try {
      return await axios.patch(
        `/properties/` +
          settings.propertyID +
          `/subscribers/me/progress/` +
          videoId,
        {
          data: {
            id: videoId,
            type: 'video',
            meta: {
              progress: 0
            }
          }
        },
        {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          progress: false
        }
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async fetchVideoDetails(videoId) {
    try {
      return await axios.get(
        `/v2/properties/${settings.propertyID}/videos/${videoId}`
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
