import api from '.'
import { instance as axios } from '../plugins/axios'
import { settings } from '@/library/variables'

export const search = {
  async searchQuery(payload) {
    try {
      return await axios.get(
        `/v4/api/search?query=${encodeURIComponent(payload.query)}&sortField=${
          payload.sortField
        }&sortDirection=${payload.sortDirection}`
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getPopularSearch() {
    try {
      return await axios.get(
        '/v2/properties/' + settings.propertyID + '/popular-search'
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getSearchAutoComplete(payload) {
    try {
      return await axios.get(
        `/v4/api/search/autocomplete?query=${payload.query}&maxResults=10`
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async publishSearchTrack(payload) {
    try {
      return await axios.post(`/v4/api/search/track`, payload)
    } catch (err) {
      return api.response.error(err)
    }
  }
}
