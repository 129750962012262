var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "z-20 relative mx-auto py-20 md:py-20 text-center" },
      [
        _vm.showMarketingTopHeaderText
          ? _c("div", { staticClass: "text-3xl mb-4" }, [_vm._m(1)])
          : _vm._e(),
        _c(
          "div",
          { ref: "contentRef", staticClass: "text-center" },
          [
            _vm._m(2),
            _c(
              "div",
              {
                staticClass: "md:hidden flex flex-col justify-center",
                staticStyle: {
                  height: "calc(100vh - 143px)",
                  "margin-top": "-80px"
                }
              },
              [
                _c("h1", { staticClass: "font-bold text-4xl uppercase" }, [
                  _vm._v(" Your home for poker entertainment! ")
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "my-5 flex flex-col justify-center  md:hidden px-3"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-col justify-center gap-3" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "submit-payment",
                            on: { click: _vm.scrollToContent }
                          },
                          [
                            _c("i", { staticClass: "fa fa-play mr-2" }),
                            _vm._v(" Watch Poker ")
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "submit-payment gap-2",
                            attrs: {
                              href:
                                "https://playpokergo.onelink.me/YuJc/nh56lr4w",
                              target: "_blank"
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "icon",
                                staticStyle: { fill: "white", width: "17px" },
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 640 512"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M7.5 194.9c-15.4-26.6-6.3-60.7 20.4-76.1L220.7 7.5c26.6-15.4 60.7-6.3 76.1 20.4l167 289.3c15.4 26.6 6.2 60.7-20.4 76.1L250.5 504.5c-26.6 15.4-60.7 6.2-76.1-20.4L7.5 194.9zM197 159.6c-11.1-3-22.6 3.6-25.6 14.8l-7.2 26.9-20.4 76.1c-7 26 8.5 52.7 34.4 59.7s52.7-8.5 59.7-34.4l2.4-8.8c.1-.4 .2-.8 .3-1.1l17.7 30.7-12.1 7c-6.7 3.8-8.9 12.4-5.1 19s12.4 8.9 19 5.1l48.2-27.8c6.7-3.8 8.9-12.4 5.1-19s-12.4-8.9-19-5.1l-12.1 7-17.7-30.7c.4 .1 .8 .2 1.1 .3l8.8 2.4c26 7 52.7-8.5 59.7-34.4s-8.5-52.7-34.4-59.7l-76.1-20.4L197 159.6zM459.4 420.9c41.9-24.2 56.3-77.8 32.1-119.8L354.7 64.2c1.7-.2 3.5-.2 5.3-.2l224 0c30.9 0 56 25.1 56 56l0 336c0 30.9-25.1 56-56 56l-224 0c-13.7 0-26.2-4.9-35.9-13l135.3-78.1zm74.5-183.3L582 187.6c14-14.5 13.2-38.5-2.2-51.9c-14-11.7-34.5-9.5-46.9 3.2l-4.7 5.2-5-5.2c-12.5-12.7-33.2-15-46.7-3.2c-15.7 13.5-16.5 37.5-2.5 51.9l48.4 49.9c3 3.2 8.2 3.2 11.5 0z"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" Play Now ")
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "text-3xl font-bold mb-20",
                    staticStyle: { "font-family": "UniSans-Bold-Italic" }
                  },
                  [_vm._v(" GET IN THE GAME! ")]
                )
              ]
            ),
            _c("div", { staticClass: "font-medium text-2xl mb-1" }, [
              _vm._v(" JOIN TODAY AND START WATCHING! ")
            ]),
            _c("div", { staticClass: "font-medium text-lg" }, [
              _vm._v("PLANS AS LOW AS $8.34/MONTH*")
            ]),
            _c(
              "router-link",
              {
                staticClass: "glowing-btn text-xl w-80 py-2 mx-auto mt-4",
                attrs: { to: "/register/select-plan" }
              },
              [_vm._v(" SELECT YOUR PLAN ")]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "absolute top-0 w-full h-full bg-black" }, [
      _c("img", {
        staticClass: "w-full h-full object-cover opacity-40",
        attrs: { src: require("@/assets/images/marketing/background.jpg") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          href:
            "https://www.pokergo.com/collections/ed83f4b7-48e5-49a4-880c-85dc4777b422",
          target: "_blank"
        }
      },
      [
        _c("div", { staticClass: "flex justify-center px-3" }, [
          _c("span", { staticClass: "header-glowing-box flex px-4 py-3" }, [
            _vm._v(
              " Click here: Today Only: Watch 2024 WSOP Main Event Day 1D for FREE "
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden md:block" }, [
      _c("h1", { staticClass: " font-bold text-4xl mb-4 uppercase" }, [
        _vm._v(" Your home for poker entertainment! ")
      ]),
      _c(
        "div",
        {
          staticClass: "text-3xl font-bold mb-4",
          staticStyle: { "font-family": "UniSans-Bold-Italic" }
        },
        [_vm._v(" GET IN THE GAME! ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }