var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register-checkout centered" },
    [
      _c("promo-not-valid", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPromoInvalidModel,
            expression: "showPromoInvalidModel"
          }
        ],
        on: {
          close: function($event) {
            _vm.showPromoInvalidModel = false
          },
          ok: _vm.closePopup
        }
      }),
      _c("div", { staticClass: "side-menu" }, [
        _vm._m(0),
        _c("div", { staticClass: "plan-recall" }, [
          _c("div", { staticClass: "inner" }, [
            _c("span", { staticClass: "bold" }, [
              _vm._v(_vm._s(_vm.plan.name))
            ]),
            _c("div", { staticClass: "money" }, [
              _c("div", { staticClass: "usd-99" }, [
                _vm._v("US $" + _vm._s(_vm.plan.price))
              ]),
              _c("div", { staticClass: "price" }, [
                _vm.plan
                  ? _c("span", [
                      _vm.plan.product_type != "monthly"
                        ? _c("span", [_vm._v(" US ")])
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.plan.priceDescription))])
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "flex justify-center" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "text-tw_primary-300 text-xs underline float-right hover:text-tw_primary-300",
                      staticStyle: { "font-family": "UniSans-SemiBold" },
                      attrs: { to: "/select-plan" }
                    },
                    [_vm._v(" Change ")]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._m(1)
        ]),
        _c("div", { staticClass: "external-payment" }, [
          _vm._m(2),
          _c("div", { staticClass: "payment-box" }, [
            _c("div", { staticClass: "list-item" }, [
              _c(
                "div",
                {
                  staticClass: "item",
                  on: {
                    click: function($event) {
                      return _vm.selectPayment("stripe")
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/credit cards-group.svg"),
                      width: "120"
                    }
                  }),
                  _c("RadioInput", {
                    attrs: {
                      name: "method",
                      value: "stripe",
                      id: "stripe",
                      isChecked: true
                    },
                    on: { change: _vm.selectPayment }
                  })
                ],
                1
              ),
              _vm.plan.country_group === "default" &&
              !_vm.isTrackdeskUser &&
              _vm.plan.product_type !== "training_annual"
                ? _c(
                    "div",
                    {
                      staticClass: "item",
                      on: {
                        click: function($event) {
                          return _vm.selectPayment("paypal")
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/PayPal.svg"),
                          width: "120"
                        }
                      }),
                      _c("RadioInput", {
                        attrs: {
                          name: "method",
                          value: "paypal",
                          id: "paypal"
                        },
                        on: { change: _vm.selectPayment }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "amazon-payment",
                  staticStyle: { display: "none !important" },
                  attrs: { id: "AmazonPayButton" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "amazon-pay-button",
                      on: { click: _vm.triggerAmazonButton }
                    },
                    [_vm._m(3)]
                  )
                ]
              )
            ])
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "external-payments",
            staticStyle: { display: "none" }
          },
          [
            _c("div", { staticClass: "creditcard-payment" }, [
              _c("button", {
                attrs: { disabled: _vm.isPaymentProcessing },
                on: { click: _vm.clickCreditCard }
              })
            ]),
            _vm._m(4),
            _c("div", { staticClass: "paypal-payment" }, [
              _c(
                "button",
                {
                  staticClass: "paypal-button",
                  attrs: { disabled: _vm.isPaymentProcessing },
                  on: { click: _vm.clickPaypal }
                },
                [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_150x38.png",
                      alt: "PayPal"
                    }
                  })
                ]
              )
            ])
          ]
        )
      ]),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.paymentClient === "stripe",
                        expression: "paymentClient === 'stripe'"
                      }
                    ]
                  },
                  [
                    _c(
                      "form",
                      {
                        staticClass: "register-form",
                        attrs: { id: "payment-form", method: "post" },
                        on: {
                          keydown: function($event) {
                            $event.keyCode === 13
                              ? $event.preventDefault()
                              : false
                          },
                          submit: function($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.submit)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "experiment-form" }, [
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("label", [_vm._v("Phone Number")]),
                                _c("ValidationProvider", {
                                  attrs: { rules: "phone" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.phone_number,
                                                  expression: "phone_number"
                                                }
                                              ],
                                              staticClass: "input-field",
                                              attrs: {
                                                id: "card-element-phone",
                                                placeholder: "Phone Number",
                                                disabled:
                                                  _vm.isPaymentProcessing,
                                                type: "tel"
                                              },
                                              domProps: {
                                                value: _vm.phone_number
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.phone_number =
                                                    $event.target.value
                                                }
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "errors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("label", [_vm._v("Address")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.address,
                                    expression: "address"
                                  }
                                ],
                                staticClass: "input-field",
                                attrs: {
                                  id: "card-element-card-address",
                                  placeholder: "Address",
                                  disabled: _vm.isPaymentProcessing
                                },
                                domProps: { value: _vm.address },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.address = $event.target.value
                                  }
                                }
                              })
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "form-row",
                              class: { mobile_row: _vm.isMobile }
                            },
                            [
                              _c("div", { staticClass: "col" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.city,
                                      expression: "city"
                                    }
                                  ],
                                  staticClass: "input-field",
                                  attrs: {
                                    id: "card-element-card-city",
                                    placeholder: "City",
                                    disabled: _vm.isPaymentProcessing
                                  },
                                  domProps: { value: _vm.city },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.city = $event.target.value
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "col" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.state,
                                      expression: "state"
                                    }
                                  ],
                                  staticClass: "input-field",
                                  attrs: {
                                    id: "card-element-card-state",
                                    placeholder: "State",
                                    disabled: _vm.isPaymentProcessing
                                  },
                                  domProps: { value: _vm.state },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.state = $event.target.value
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "col" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.postal_code,
                                      expression: "postal_code"
                                    }
                                  ],
                                  staticClass: "input-field",
                                  attrs: {
                                    id: "card-element-card-postal",
                                    placeholder: "Postal Code",
                                    disabled: _vm.isPaymentProcessing
                                  },
                                  domProps: { value: _vm.postal_code },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.postal_code = $event.target.value
                                    }
                                  }
                                })
                              ])
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c("label", [_vm._v("Credit Card information *")]),
                            _c("div", {
                              staticClass: "input",
                              attrs: { id: "card-element-card-number" }
                            })
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "form-row",
                            class: { mobile_row: _vm.isMobile }
                          },
                          [
                            _c("div", { staticClass: "col" }, [
                              _c("div", {
                                staticClass: "input",
                                attrs: { id: "card-element-card-expiry" }
                              })
                            ]),
                            _c("div", { staticClass: "col" }, [
                              _c("div", {
                                staticClass: "input",
                                attrs: { id: "card-element-card-cvc" }
                              })
                            ]),
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("ValidationProvider", {
                                  attrs: { rules: "required|zip" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.zip,
                                                  expression: "zip"
                                                }
                                              ],
                                              staticClass: "input-field",
                                              attrs: {
                                                id: "Postal Code",
                                                placeholder:
                                                  "Billing Postal Code",
                                                disabled:
                                                  _vm.isPaymentProcessing
                                              },
                                              domProps: { value: _vm.zip },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.zip = $event.target.value
                                                }
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "errors" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "form",
                  {
                    staticClass: "register-form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.submit)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errorMessage,
                            expression: "errorMessage"
                          }
                        ],
                        staticClass: "form-row"
                      },
                      [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            {
                              staticClass: "error",
                              attrs: { id: "card-errors", role: "alert" }
                            },
                            [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.paymentClient !== "amazon",
                            expression: "paymentClient !== 'amazon'"
                          }
                        ],
                        staticClass: "promo-code"
                      },
                      [
                        _c("span", [_vm._v("Promo Code")]),
                        _c("SwitchButton", {
                          attrs: { checked: _vm.showPromo, showLabel: false },
                          on: { toggle: _vm.togglePromo }
                        })
                      ],
                      1
                    ),
                    _vm.showPromo
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              staticStyle: { width: "80%" }
                            },
                            [
                              _c("div", { staticClass: "form-control" }, [
                                _c("label", [_vm._v("Promo Code")]),
                                _c("div", { staticClass: "flex-row-center" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coupon,
                                        expression: "coupon"
                                      }
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      type: "text",
                                      disabled: _vm.isPaymentProcessing
                                    },
                                    domProps: { value: _vm.coupon },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.coupon = $event.target.value
                                        },
                                        _vm.checkEmptyPromo
                                      ]
                                    }
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "register-button validate button",
                                      attrs: {
                                        type: "button",
                                        disabled: _vm.isPaymentProcessing
                                      },
                                      on: { click: _vm.couponChange }
                                    },
                                    [_vm._v(" Validate ")]
                                  )
                                ]),
                                _vm.validated && _vm.coupon.length
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "payment-form-promo",
                                        class: _vm.promo.Valid
                                          ? "valid"
                                          : "invalid"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.discountMessage) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.paymentClient === "stripe",
                            expression: "paymentClient === 'stripe'"
                          }
                        ],
                        staticClass: "form-row"
                      },
                      [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "button",
                            {
                              staticClass: "glowing-btn w-full h-12 mb-4",
                              attrs: { disabled: _vm.isPaymentProcessing },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return handleSubmit(_vm.submit)
                                }
                              }
                            },
                            [
                              !_vm.isPaymentProcessing
                                ? _c("span", [
                                    _vm._v(" Subscribe "),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.checkoutPrice.symbol) +
                                          _vm._s(_vm.checkoutPrice.price) +
                                          " "
                                      )
                                    ])
                                  ])
                                : _c(
                                    "span",
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "fa-pulse",
                                        attrs: { icon: "spinner" }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          ),
                          _c("p", { staticClass: "payment-form-policy" }, [
                            _vm._v(
                              " By confirming your subscription, you allow PokerGO to charge your card for this payment and future payments in accordance with their terms. "
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.paymentClient === "paypal",
                        expression: "paymentClient === 'paypal'"
                      }
                    ],
                    staticClass: "form-row"
                  },
                  [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "button",
                        {
                          staticClass: "glowing-btn w-full h-12 mb-4",
                          attrs: { disabled: _vm.isPaymentProcessing },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.shoeBankWarningModel($event)
                            }
                          }
                        },
                        [
                          !_vm.isPaymentProcessing
                            ? _c("span", [
                                _vm._v(" Continue to PayPal "),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.checkoutPrice.symbol) +
                                      _vm._s(_vm.checkoutPrice.price) +
                                      " "
                                  )
                                ])
                              ])
                            : _c(
                                "span",
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "fa-pulse",
                                    attrs: { icon: "spinner" }
                                  })
                                ],
                                1
                              )
                        ]
                      ),
                      _c("p", { staticClass: "payment-form-policy" }, [
                        _vm._v(
                          " By confirming your subscription, you allow PokerGO to charge your card for this payment and future payments in accordance with their terms. "
                        )
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.paymentClient === "amazon",
                        expression: "paymentClient === 'amazon'"
                      }
                    ],
                    staticClass: "form-row"
                  },
                  [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "button",
                        {
                          staticClass: "register-button button",
                          attrs: { disabled: _vm.isPaymentProcessing },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.triggerAmazonButton($event)
                            }
                          }
                        },
                        [
                          _vm._v(" Continue to Amazon "),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.checkoutPrice.symbol) +
                                _vm._s(_vm.checkoutPrice.price) +
                                " "
                            )
                          ])
                        ]
                      ),
                      _c("p", { staticClass: "payment-form-policy" }, [
                        _vm._v(
                          " By confirming your subscription, you allow PokerGO to charge your card for this payment and future payments in accordance with their terms. "
                        )
                      ])
                    ])
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm.modalPending
        ? _c("paypal-pending-modal", {
            attrs: { pending: _vm.isPending },
            on: { yes: _vm.waitModal, no: _vm.continueSubmit }
          })
        : _vm._e(),
      _vm.modalWait
        ? _c("paypal-wait-modal", { on: { ok: _vm.goToAccount } })
        : _vm._e(),
      _vm.showBankWarningModel
        ? _c("paypal-bank-warning-modal", {
            on: {
              cancel: _vm.closeBankWarningModel,
              continue: _vm.completePaypal
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label" }, [
      _c("span", [_vm._v("SUBSCRIPTION")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "auto-renews" }, [
      _c("span", { staticClass: "opacity-50" }, [
        _vm._v("Plan automatically renews")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label" }, [
      _c("span", [_vm._v("PAYMENT")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "round" }, [
      _c("input", { attrs: { type: "checkbox" } }),
      _c("label", { attrs: { for: "AmazonPayButton" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "amazon-payment" }, [
      _c("div", {
        staticClass: "amazon-pay-button",
        attrs: { id: "AmazonPayButton" }
      }),
      _c("div", { staticClass: "amazon-pay-button", attrs: { id: "Logout" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }