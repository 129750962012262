<script>
export default {
  name: 'FrequentQuestion',
  props: {
    question: {
      type: String,
      default: ''
    },
    answer: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    collapsed: true
  }),
  methods: {
    toggle() {
      this.collapsed = !this.collapsed
    }
  }
}
</script>

<template>
  <div class="py-3 px-5 bg-white text-black cursor-pointer" @click="toggle">
    <div class="text-xl font-bold flex justify-between items-center">
      {{ question }}
      <font-awesome-icon :icon="!collapsed ? 'chevron-up' : 'chevron-down'" />
    </div>
    <transition name="fade">
      <div v-if="!collapsed" class="mt-3 leading-6">
        {{ answer }}
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
/* CSS for the fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
