var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-background",
      on: {
        click: function($event) {
          return _vm.$emit("no")
        }
      }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c(
          "div",
          {
            staticClass: "modal-body",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c("p", { staticClass: "modal-top" }, [
              _vm.modelOptions.type === "success"
                ? _c("i", {
                    staticClass: "fa fa-check-circle",
                    staticStyle: { "font-size": "32px", color: "#37ce7b" }
                  })
                : _vm.modelOptions.type === "error"
                ? _c("i", {
                    staticClass: "fa fa-exclamation-circle",
                    staticStyle: { "font-size": "32px", color: "#E01D16FF" }
                  })
                : _vm._e(),
              _c(
                "span",
                {
                  style: {
                    color:
                      _vm.modelOptions.type === "success"
                        ? "#37ce7b"
                        : "#E01D16FF"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.modelOptions.header) + " ")]
              )
            ]),
            _c("div", { staticClass: "modal-header" }, [
              _c("p", { staticClass: "modal-heading" }, [
                _vm._v(" " + _vm._s(_vm.modelOptions.description) + " ")
              ])
            ]),
            _c("br"),
            _c("div", { staticClass: "modal-buttons" }, [
              _c("div", { staticClass: "modal-button" }, [
                _c(
                  "a",
                  {
                    staticClass: "button is-primary save-button",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.modelOptions.onClose()
                      }
                    }
                  },
                  [_vm._v(" CLOSE ")]
                )
              ])
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }