<template>
  <label class="switch">
    <input type="checkbox" :checked="checked" @click="$emit('toggle')" />
    <span class="slider round">
      <p class="label__icon__on label__icon">{{ showLabel ? 'ON' : '' }}</p>
      <p class="label__icon__off label__icon">{{ showLabel ? 'OFF' : '' }}</p>
    </span>
  </label>
</template>
<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: true,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true,
      required: false
    }
  }
}
</script>
<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 25px;
  border: 1px solid rgba(0, 230, 64, 1);
  border-radius: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  left: 0px;
  bottom: 0px;
  background-color: #e1dddd;
  transition: 0.4s;
  margin: 1px;
}

input:checked + .slider {
  background-color: rgba(0, 230, 64, 1);
}
input:checked + .chat__content__head {
  display: none;
}
input:checked + .slider:before {
  background: white;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
  .label__icon {
    font-size: 14px;
    width: 40%;
    font-weight: 900 !important;
    font-size: 8px;
  }
  .label__icon__on {
    left: 4px;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .label__icon__off {
    margin: 0;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.slider.round:before {
  border-radius: 50%;
}
</style>
