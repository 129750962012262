var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "touch",
          rawName: "v-touch:swipe.left",
          value: _vm.pageRight,
          expression: "pageRight",
          arg: "swipe",
          modifiers: { left: true }
        },
        {
          name: "touch",
          rawName: "v-touch:swipe.right",
          value: _vm.pageLeft,
          expression: "pageLeft",
          arg: "swipe",
          modifiers: { right: true }
        }
      ],
      staticClass: "row-wrapper"
    },
    [
      _vm.title
        ? _c("h2", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.title) + " ")
          ])
        : _vm._e(),
      _vm.type === "video"
        ? _c(
            "div",
            { staticStyle: { width: "100%" } },
            _vm._l(_vm.shownEntities, function(entity, idx) {
              return _c("long-card", {
                key: idx + entity.id,
                staticStyle: { position: "relative" },
                style: {
                  left: String((100 / _vm.numVisible) * -_vm.currentIdx) + "%"
                },
                attrs: {
                  "entity-id": entity.id,
                  "entity-type": entity.type,
                  "entity-data": entity.images ? entity : undefined,
                  large: _vm.large,
                  video: idx === _vm.currentIdx,
                  "show-title": false,
                  "no-reload": true
                }
              })
            }),
            1
          )
        : _vm._e(),
      _vm.type === "collection" || _vm.type === "event"
        ? _c(
            "div",
            {
              staticClass: "row",
              style: _vm.large ? "justify-content: center;" : "",
              on: {
                mouseover: function($event) {
                  _vm.rowHovering = true
                },
                mouseleave: function($event) {
                  _vm.rowHovering = false
                }
              }
            },
            [
              _vm._l(_vm.shownEntities, function(entity, idx) {
                return _c("small-card", {
                  key: idx + entity.id,
                  staticStyle: { position: "relative" },
                  style: {
                    left: String((100 / _vm.numVisible) * -_vm.currentIdx) + "%"
                  },
                  attrs: {
                    "entity-id": entity.id,
                    "entity-type": entity.type,
                    "entity-data": entity.images ? entity : undefined,
                    type: _vm.large
                      ? "large"
                      : _vm.type === "event"
                      ? "event"
                      : "small",
                    video: idx === _vm.currentIdx,
                    "show-title": _vm.showCardTitles,
                    duration: entity.duration,
                    author: entity.author,
                    "no-reload": true
                  },
                  on: { updatedata: _vm.loadParentData }
                })
              }),
              _vm.rowHovering &&
              _vm.shownEntities.length > _vm.numVisible &&
              _vm.currentIdx !== 0
                ? _c(
                    "div",
                    {
                      staticClass: "left-button",
                      class: { large: _vm.large },
                      on: {
                        click: function($event) {
                          return _vm.pageLeft()
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icon" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "carosel-button",
                            attrs: { icon: ["fa", "chevron-circle-left"] }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.shownEntities.length > _vm.numVisible &&
              _vm.currentIdx !== _vm.shownEntities.length - _vm.numVisible
                ? _c(
                    "div",
                    {
                      staticClass: "right-button",
                      class: { large: _vm.large },
                      on: {
                        click: function($event) {
                          return _vm.pageRight()
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "icon",
                          staticStyle: { width: "2em", height: "2em" }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "carosel-button",
                            attrs: { icon: ["fa", "chevron-circle-right"] }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }