var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      [
        _c("Banner", {
          staticClass: "banner_class",
          attrs: { page: "schedule", location: "header" }
        }),
        _c("div", { staticClass: "flex-column" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("p", [_vm._v("Times are Displayed in Your Time Zone")]),
              _vm._l(_vm.monthNamesVisible, function(m) {
                return _c(
                  "div",
                  { key: m, staticClass: "month-group" },
                  [
                    _c("div", { staticClass: "month-title" }, [
                      _vm._v(
                        _vm._s(m) + " " + _vm._s(_vm.getYear(_vm.entities[m]))
                      )
                    ]),
                    _vm._l(_vm.entities[m], function(entity) {
                      return _c(
                        "div",
                        {
                          key: entity.id,
                          staticClass: "event",
                          class: { live: entity.video_type === "live" }
                        },
                        [
                          _c("div", { staticClass: "date" }, [
                            _c("div", { staticClass: "date-header" }, [
                              _c("div", { staticClass: "day" }, [
                                _vm._v(_vm._s(entity.day))
                              ]),
                              _c("div", { staticClass: "time" }, [
                                _c("b", [
                                  _vm._v(" " + _vm._s(entity.time) + " ")
                                ]),
                                _vm._v(" " + _vm._s(entity.timeOfDay) + " ")
                              ])
                            ]),
                            _c("div", { staticClass: "weekday" }, [
                              _vm._v(" " + _vm._s(entity.dayOfWeek) + " ")
                            ])
                          ]),
                          _c(
                            "router-link",
                            {
                              staticClass: "video-details",
                              attrs: {
                                to: "/" + entity.type + "s/" + entity.id
                              }
                            },
                            [
                              _c("div", { staticClass: "thumbnail" }, [
                                _c("img", {
                                  attrs: { src: _vm.getThumnail(entity) }
                                })
                              ]),
                              _c("div", { staticClass: "description" }, [
                                _vm.liveNow(entity)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "status-label",
                                        class: {
                                          live: entity.video_type === "live"
                                        }
                                      },
                                      [_vm._v(" Live now ")]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "status-label",
                                        class: {
                                          live: entity.video_type === "live"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(entity.subheading) + " "
                                        )
                                      ]
                                    ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "line-clamp",
                                        rawName: "v-line-clamp:20",
                                        value: 2,
                                        expression: "2",
                                        arg: "20"
                                      }
                                    ],
                                    staticClass: "title"
                                  },
                                  [_vm._v(" " + _vm._s(entity.title) + " ")]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              })
            ],
            2
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "top-section", staticStyle: { display: "none" } },
      [
        _c("div", { staticClass: "red-skewed" }, [
          _c("div", { staticClass: "red-unskewed-content" }, [
            _vm._v("Live Now > watch")
          ])
        ]),
        _c("div", { staticClass: "after" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }