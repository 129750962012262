<template>
  <div class="page">
    <div v-html="document.content" class="content"></div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { settings } from '@/library/variables'
export default {
  name: 'CancelPaypal',
  data() {
    return {}
  },
  async beforeMount() {
    await this.$store.dispatch('GET_DOCUMENT', 'cancel-paypal')
  },
  computed: {
    ...mapGetters(['getDocuments']),
    document() {
      const document = this.getDocuments.filter(
        (item) => item.label === 'cancel-paypal'
      )
      if (document.length > 0) {
        return document[0]
      } else {
        return {
          id: 'cancel-paypal',
          label: 'cancel-paypal',
          content: ''
        }
      }
    }
  },
  methods: {
    ...mapActions(['GET_DOCUMENT'])
  }
}
</script>
<style lang="scss" scoped>
::v-deep h1 {
  font-family: 'UniSans-Bold-Italic';
  text-align: center;
}
::v-deep p {
  text-align: left;
  font-size: larger;
  width: 80%;
  padding-left: 20%;
}
.page {
  flex-flow: row;
  background-color: white;
  color: black;
}
.content {
  margin-bottom: 0;
}
</style>
