<template>
  <div class="modal-background" @click="$emit('close')">
    <div class="modal-content">
      <div class="modal-body" @click.stop>
        <div class="modal-header">
          <p class="modal-heading">
            Looks like your promotion code is not valid.
          </p>
        </div>
        <br />
        <div class>
          <a class="button is-primary save-button" @click.prevent="$emit('ok')">
            Okay
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import Vue from 'vue'

export default Vue.extend({
  name: 'PromoNotValid',
  props: {
    to: {
      type: String,
      required: false,
      default: "undefined"
    },
    text: {
      type: String,
      required: false,
      default: "undefined"
    },
    routerReplace: {
      type: Boolean
    }
  }
})
</script>
<style lang="scss" scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #0004;
  z-index: 10;

  .modal-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    min-width: 400px;
    min-height: 200px;
    overflow: hidden;
    text-align: center;
    background-color: $secondary-color;
    border: 1px solid $primary-color;
    border-radius: 5px;
    width: 30%;

    .modal-body {
      width: 100%;

      .modal-header {
        margin-top: 50px;
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .form-field.is-horizontal {
    display: flex;
    flex-flow: row;
    justify-content: space-around;

    .button {
      width: 100px;
    }
  }
}
</style>
