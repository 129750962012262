<template>
  <div class="register flex-column-center">
    <h1>CHANGE PASSWORD</h1>
    <div class="password-container">
      <div class="section">
        <form @submit.prevent="savePassword">
          <div class="field-group">
            <div class="field-item text-field">
              <input
                id="newpassword-edit"
                v-model="newPassword"
                :type="showNewPassword ? 'text' : 'password'"
                placeholder="New Password"
              />
              <span
                class="eye-icon"
                @click="showNewPassword = !showNewPassword"
              >
                <img
                  v-if="!showNewPassword"
                  src="@/assets/images/eye-solid.svg"
                  alt=""
                  style="height: 20px"
                />
                <img
                  v-if="showNewPassword"
                  src="@/assets/images/eye-slash-solid.svg"
                  alt=""
                  style="height: 20px"
                />
              </span>
            </div>
            <div class="field-item">
              <input
                id="confirmpassword-edit"
                v-model="confirmPassword"
                :type="showNewPassword ? 'text' : 'password'"
                placeholder="Confirm New Password"
              />
            </div>
            <p v-if="emptyPassword && passwordIncorrect" class="p-error">
              <font-awesome-icon icon="times" /> Please enter password
            </p>
            <p v-if="passwordMismatch" class="p-error">
              <font-awesome-icon icon="times" />Passwords must match
            </p>
            <p v-if="passwordLength" class="p-error">
              <font-awesome-icon icon="times" />Password must be 8 or more
              characters
            </p>
            <div class="action">
              <a class="btn-cancel" @click="redirect()">
                Cancel
              </a>
              <button class="button" type="submit">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <generic-light-modal
      v-if="modalClosed === false"
      :to="'/account'"
      :text="'Your password has been changed successfully.'"
      @no="modalClosed = true"
      @yes="redirect()"
    />
  </div>
</template>
<script>
import GenericLightModal from '@/components/GenericLightModal.vue'
export default {
  name: 'ChangePassword',
  components: {
    GenericLightModal
  },
  data() {
    return {
      editName: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      modalClosed: true,
      passwordIncorrect: false,
      emtyPassword: false,
      showOldPassword: false,
      showNewPassword: false
    }
  },
  computed: {
    passwordMismatch() {
      return (
        (this.newPassword !== '' || this.confirmPassword !== '') &&
        this.newPassword !== this.confirmPassword
      )
    },
    passwordLength() {
      return (
        (this.newPassword !== '' || this.confirmPassword !== '') &&
        this.newPassword.length < 8
      )
    },
    emptyPassword() {
      return !this.newPassword || !this.confirmPassword
    }
  },
  methods: {
    async savePassword() {
      this.passwordIncorrect = false
      if (this.emptyPassword) {
        this.passwordIncorrect = true
        return
      }
      if (this.passwordMismatch === true || this.passwordLength === true) {
        return
      } else {
        try {
          let payload = {
            new_password: this.newPassword,
            new_password_confirm: this.confirmPassword
          }
          await this.$store
            .dispatch('account/SavePassword', payload)
            .then((res) => {
              // if response is success
              if (res.status === 200) {
                this.modalClosed = false
                return
              }

              // if there is error occured
              this.passwordIncorrect = true

              this.$showError({
                title: res.title,
                text: res.message
              })
            })
        } catch (e) {
          console.log('exception: ', e)
        }
      }
    },
    redirect() {
      this.$router.push('/account')
    }
  }
}
</script>
<style lang="scss" scoped>
.register {
  padding-top: 40px;
}
.password-container {
  width: 100%;
  max-width: 700px;
  height: 100%;
  padding: 0px;
  margin-top: 50px;
}
.action {
  margin: 20px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn-cancel {
    color: black;
    margin-right: 1rem;
    cursor: pointer;
  }
}
.p-error {
  margin-left: 10px;
  color: $red;
  svg {
    margin-right: 1em;
  }
}

.label {
  font-weight: bold;
  width: 50%;
  text-transform: uppercase;
}

.field-group {
  background: white;
  border-radius: 8px;
  padding: 20px;

  .field-item {
    margin-bottom: 10px;
    height: 50px;
    display: flex;
    flex-flow: row;
    align-items: center;
    position: relative;

    input {
      border: none;
      background: #f4f4f4;
    }

    .button {
      margin-top: 20px;
    }
    &.horizontal {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      &.apart {
        justify-content: space-between;
      }
    }
  }
}

.section {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  padding: 0px;
}
.text-field {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 15px;
  right: 10px;
}
</style>
