<script>
export default {
  name: 'TrainingOvals'
}
</script>

<template>
  <div>
    <div class="hidden md:block">
      <div class="lg-oval-1">
        <img src="@/assets/images/training/ovals/lg-oval-1.png" alt="oval-1" />
      </div>
      <div class="lg-oval-2">
        <img src="@/assets/images/training/ovals/lg-oval-2.png" alt="oval-2" />
      </div>
    </div>
    <div class="lg:hidden">
      <div class="sm-oval-1">
        <img
          src="@/assets/images/training/ovals/sm-oval-1.png"
          alt="sm-oval-1"
        />
      </div>
      <div class="sm-oval-2">
        <img
          src="@/assets/images/training/ovals/sm-oval-2.png"
          alt="sm-oval-2"
        />
      </div>
      <div class="sm-oval-3">
        <img
          src="@/assets/images/training/ovals/sm-oval-3.png"
          alt="sm-oval-3"
        />
      </div>
      <div class="sm-oval-4">
        <img
          class="w-full object-cover"
          src="@/assets/images/training/ovals/sm-oval-4.png"
          alt="sm-oval-4"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.sm-oval-1 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
}

.sm-oval-2 {
  position: absolute;
  top: 350px;
  right: 0px;
  z-index: -1;
}
.sm-oval-3 {
  position: absolute;
  top: 1050px;
  left: 0px;
  z-index: -1;
}

.sm-oval-4 {
  position: absolute;
  top: 1390px;
  right: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.lg-oval-1 {
  position: absolute;
  z-index: -1;
  top: -600px;
  right: 0px;
}

.lg-oval-2 {
  position: absolute;
  z-index: -1;
  top: 620px;
  right: 0px;
}
</style>
