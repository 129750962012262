var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      staticClass: "position-relative",
      class: {
        "large-card": _vm.large,
        "small-card": _vm.type === "small",
        "event-card": _vm.type === "event"
      },
      on: {
        click: function($event) {
          return _vm.navigate(false)
        }
      }
    },
    [
      _vm.type !== "event"
        ? _c("div", { staticClass: "video-card" }, [
            _vm.showPlaceholder || !_vm.showVideoPlayer
              ? _c("div", { staticClass: "position-relative cursor-pointer" }, [
                  _c("img", {
                    staticClass: "image",
                    attrs: { src: _vm.overlayImage },
                    on: { error: _vm.error, load: _vm.imageLoaded }
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showPlaceholder,
                        expression: "showPlaceholder"
                      }
                    ],
                    staticClass: "image",
                    attrs: {
                      src: require("@/assets/images/pgoplaceholder-sm.png")
                    },
                    on: { load: _vm.imageLoaded }
                  }),
                  _vm.progress
                    ? _c("i", { staticClass: "progress-bar-bg" })
                    : _vm._e(),
                  _vm.progress
                    ? _c("i", {
                        staticClass: "progress-bar",
                        style: { width: _vm.progressPercent }
                      })
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.showVideoPlayer
              ? _c(
                  "div",
                  { staticClass: "position-relative cursor-pointer" },
                  [
                    _c("video-player", {
                      key: _vm.$route.fullPath,
                      attrs: {
                        id: _vm.entityId,
                        "overlay-image": _vm.overlayImage,
                        autoplay: false
                      }
                    }),
                    _vm.progress
                      ? _c("i", { staticClass: "progress-bar-bg" })
                      : _vm._e(),
                    _vm.progress
                      ? _c("i", {
                          staticClass: "progress-bar",
                          style: { width: _vm.progressPercent }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.type !== "large"
              ? _c("div", { staticClass: "infor" }, [
                  _c("div", { class: ["infor-left"] }, [
                    _vm.showTitle
                      ? _c(
                          "div",
                          {
                            staticClass: "title",
                            class: { overflow: true },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.navigate(true)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.title) + " ")]
                        )
                      : _vm._e(),
                    _vm.showSubText
                      ? _c(
                          "div",
                          { staticClass: "flex gap-2 text-sm opacity-60" },
                          [
                            _vm.author
                              ? _c("div", [_vm._v("By " + _vm._s(_vm.author))])
                              : _vm._e(),
                            _vm.duration
                              ? _c("div", [
                                  _vm._v(
                                    " (" +
                                      _vm._s(
                                        Math.floor(
                                          _vm.moment
                                            .duration(_vm.duration, "seconds")
                                            .asMinutes()
                                        )
                                      ) +
                                      " min) "
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ]),
                  !_vm.entitled
                    ? _c("div", { staticClass: "infor-right" }, [
                        _c("img", {
                          staticClass: "locked-img",
                          attrs: {
                            src: require("@/assets/images/lock-solid.svg"),
                            title:
                              "A subscription is required to view this video."
                          }
                        })
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.type === "large"
              ? _c("small", { staticClass: "title" }, [
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ])
              : _vm._e(),
            _c("br")
          ])
        : _c(
            "div",
            {
              staticClass: "video-card",
              style: { backgroundImage: "url(" + _vm.overlayImage + ")" }
            },
            [
              _c("div", { staticClass: "after" }),
              _vm.showTitle
                ? _c(
                    "small",
                    {
                      staticClass: "title",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.navigate(true)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  )
                : _vm._e(),
              _c("br")
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }