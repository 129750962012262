<template>
  <div class="radio">
    <input
      type="radio"
      :value="value"
      :name="name"
      :id="id"
      :checked="isChecked"
      @change="$emit('change', value)"
    />
    <label :for="id"></label>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: false,
      default: ''
    },
    isChecked: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.radio {
  position: relative;
}

.radio label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.radio label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.radio input[type='radio'] {
  visibility: hidden;
}

.radio input[type='radio']:checked + label {
  background-color: #9f26b5;
  border-color: #9f26b5;
}

.radio input[type='radio']:checked + label:after {
  opacity: 1;
}
</style>
