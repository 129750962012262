<template>
  <div class="modal-background" @click="$emit('no')">
    <div class="modal-content">
      <div class="modal-body" @click.stop>
        <p class="modal-top">
          <i
            class="fa fa-exclamation-circle"
            style="font-size:32px;color:#b31313"
          ></i>
          ACCOUNT
        </p>
        <div class="modal-header">
          <p class="modal-heading">
            Please wait 5 minutes for PayPal to send the payment information,
            and you will receive your premium subscription.
          </p>
        </div>
        <br />
        <div class="modal-buttons">
          <div class="modal-button">
            <router-link
              v-if="to !== undefined"
              :to="to"
              class="button is-primary save-button"
              >OK</router-link
            >
            <a
              v-if="to === undefined"
              class="button is-primary save-button"
              @click.prevent="$emit('ok')"
              >OK</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import Vue from 'vue'

export default Vue.extend({
  name: 'AreYouSure',
  props: {
    to: {
      type: String,
      required: false,
      default: undefined
    },
    routerReplace: {
      type: Boolean
    }
  }
})
</script>
<style lang="scss" scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #0004;

  .modal-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    min-width: 600px;
    min-height: 200px;
    overflow: hidden;
    text-align: center;
    border-radius: 15px;
    border: solid 2px #9f26b5;
    background-color: white;

    .modal-body {
      width: 100%;
      .modal-top {
        font-size: 32px;
        font-style: italic;
        color: #b31313;
        padding-top: 15px;
      }
      .modal-header {
        margin-top: 0px;
        margin-right: 10px;
        margin-left: 10px;
        .modal-heading {
          font-size: 26px;
          text-align: left;
          padding: 10px;
          line-height: 1em;
          color: black;
        }
      }
      .modal-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      .modal-button {
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
        .save-button {
          border-radius: 10px;
          width: 200px;
          font-size: 18px;
        }
      }
    }
  }

  .form-field.is-horizontal {
    display: flex;
    flex-flow: row;
    justify-content: space-around;

    .button {
      width: 100px;
    }
  }
}
</style>
