<template>
  <div>
    <div class="container__main" v-if="chatEnabled">
      <ChatHeader
        @hide-chat="handleHideChat"
        :isChecked="isChecked"
        @toggle="handleClick"
      />
      <div v-if="showChatRules">
        <ChatRules @agree="handleClickAgree" />
      </div>
      <div v-else style="background: #111820">
        <div class="chat__background__chat" v-if="!isChecked"></div>
        <div class="chat__turnoff" v-if="!isChecked">Chat is off.</div>
        <div class="chat__content" v-if="isChecked">
          <label class="chat__content__head">
            <p>Welcome to the poker chat room !</p>
          </label>
          <div class="chat__content__highlighted" v-if="shouldShowHighlighted">
            <p>{{ highlightedMessage }}</p>
          </div>
          <div
            class="chat__content__main flexcroll"
            v-if="isChecked"
            ref="chatPanel"
          >
            <div class="chat__box">
              <div
                v-for="(item, index) in historyMessagesAfterBlocked"
                :key="item.key"
                class="chat__content__main--item"
              >
                <div :class="{ active: index === selectedIndex }">
                  <div
                    class="chat__content__main--item__dot"
                    v-if="isActionable(item)"
                  >
                    <font-awesome-icon
                      icon="ellipsis-v"
                      @click="handleBanUser(index)"
                      v-if="selectedIndex === index"
                    />
                  </div>
                  <div @click="handleClickHighlight(index)" class="bm__item">
                    <span :class="getSenderClass(item)">
                      {{ displayUsername(item) }}
                      <label v-if="isSticker(item.content.message)">
                        <img
                          class="sticker"
                          :src="getStickerURL(item.content.message)"
                        />
                      </label>
                      <label v-else>
                        {{ item.content.message }}
                      </label>
                    </span>
                  </div>
                  <div
                    v-if="isShowPopupBan === index"
                    class="chat__box__popup-report"
                  >
                    <div class="icon-close" @click="handleClosePopup(index)">
                      <font-awesome-icon class="check" icon="times" />
                    </div>
                    <div
                      class="chat__box__popup-report__item"
                      @click="handleReportUser(item)"
                    >
                      <span class="icon"><font-awesome-icon icon="flag"/></span>
                      <span class="report__user">Report {{ item.sender }}</span>
                    </div>
                    <div
                      class="chat__box__popup-report__item"
                      @click="handleBlocktUser(item.sender)"
                    >
                      <span class="icon"><font-awesome-icon icon="ban"/></span>
                      <span class="label">Block {{ item.sender }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat__footer">
          <div class="chat__footer__send_btn">
            <div class="count__string">{{ message.length }}/200</div>
            <div class="chat__footer__send_btn__inner">
              <span class="chat__footer__emoji">
                <emoji-popup @select="addEmoji" @send-emoji="sendSticker" />
              </span>
              <input
                :disabled="!isWebsocketReady"
                v-model="message"
                class="chat__footer__input_box"
                type="text"
                @keyup.enter="handleSendMessage"
                :placeholder="
                  isWebsocketReady ? `Say somethings ...` : 'Connecting ...'
                "
              />
              <button
                :disabled="!isWebsocketReady"
                class="chat__footer__send_btn__inner__btn"
                :class="{ sendable: message.trim().length && connection }"
                title="Send"
                @click="handleSendMessage"
              >
                <font-awesome-icon
                  v-if="!isWebsocketReady"
                  icon="spinner"
                  class="fa-pulse fa-1x fa-fw"
                />
                <font-awesome-icon v-else icon="paper-plane" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!chatEnabled && !dismiss && !loggedIn" class="container__main">
      <div class="btn_container">
        <button class="button" type="submit" @click="gotoLogin">
          Sign in to chat
        </button>
        <div style="margin-top: 10px; cursor: pointer">
          <span @click="dismiss = true">dismiss</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { localstorage } from '@/services/storage/localStorageService'
import { settings } from '@/library/variables'
import { mapGetters, mapState } from 'vuex'
import UUID from 'uuid'
import EmojiPopup from './EmojiPopup'
import ChatRules from './ChatRules'
import ChatHeader from './ChatHeader'

export default {
  name: 'Chat',
  components: {
    EmojiPopup,
    ChatRules,
    ChatHeader
  },
  props: {
    videoId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showChatRules: true,
      selectedIndex: -1,
      isChecked: true,
      userInfo: {},
      stringMessage: '',
      rateLimit: 0,
      socketClosed: false,
      bodyDataSend: {},
      id: UUID.v4(),
      connection: null,
      chatEnabled: false,
      chatStatus: 'no_record',
      message: '',
      shouldShowHighlighted: false,
      highlightedMessage: '',
      intervalFunc: null,
      intervalParticipantFunc: null,
      historyMessages: [],
      isShowPopupBan: -1,
      blockedUsers: [],
      participantsShown: [],
      participantsCountShown: 0,
      handleSendMessageFunc: () => {},
      dismiss: false,
      isWebsocketReady: false
    }
  },
  watch: {
    participants() {
      this.participantsShown = this.participants
    },
    participantsCountShown() {
      this.participantsCountShown = this.participantsCount
    },
    historyMessages() {
      this.$nextTick(() => {
        this.scrollToBottomOfChatWindow()
      })
    },
    message() {
      this.message = this.message.substring(0, 200)
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState({
      participants: (state) => state.chat.participants,
      participantsCount: (state) => state.chat.participantsCount,
      visitedChats: (state) => state.chat.visitedChats,
      stickers: (state) => state.chat.stickers
    }),
    historyMessagesAfterBlocked() {
      return this.historyMessages.filter(
        (message) => !this.blockedUsers.includes(message.sender)
      )
    },
    loggedIn() {
      return this.isAuthenticated
    }
  },
  async mounted() {
    this.videoId = this.$route.params.id
    this.$store.dispatch('getStatusData', this.videoId).then((res) => {
      if (!res.data) return

      this.rateLimit = res.data.rate_limit
      this.chatEnabled = res.data.setting.chat_enabled
      this.chatStatus = res.data.setting.chat_status

      const rateLimit = require('function-rate-limit')
      this.handleSendMessageFunc = rateLimit(this.rateLimit, 60000, () => {
        this.sendMessage()
      })
    })
  },
  beforeDestroy() {
    clearInterval(this.intervalFunc)
    clearInterval(this.intervalParticipantFunc)
  },
  methods: {
    async initChat() {
      if (this.chatEnabled) {
        if (this.chatStatus === 'live' && this.isChecked) {
          this.establishSocketConnection()
        } else {
          this.constantCheckStatus()
        }
      }
      this.$store.dispatch('getUserInfo', this.videoId).then((res) => {
        if (res.status === 200 && res.data) {
          this.userInfo = res.data.data
        }
      })
      this.updateParticipants()
      this.intervalParticipantFunc = setInterval(
        this.updateParticipants,
        120000
      )

      const chatHistory = await this.$store.dispatch(
        'fetchChatHistory',
        this.videoId
      )
      if (chatHistory?.data)
        this.historyMessages = chatHistory.data
          .filter((el) => !this.isSystemOnlyMessage(el))
          .map((message) => this.transformMessage(message))
      await this.$store.dispatch('fetchStickers')
    },
    handleHideChat() {
      this.$emit('hide-chat')
      this.isChecked = false
      this.connection.close()
    },
    handleClickAgree() {
      this.showChatRules = false
      this.initChat()
      this.$nextTick(() => {
        this.scrollToBottomOfChatWindow()
      })
    },
    displayUsername(item) {
      if (
        ['moderator', ''].includes(item.content.code) ||
        item.content.msg_type === 'system'
      ) {
        if (item.sender.length > 20) return `${item.sender.slice(0, 20)}..`
        return item.sender
      }

      return ''
    },
    isSticker(text) {
      return text.startsWith('sticker:')
    },
    getStickerURL(text) {
      const url = this.stickers.find(
        ({ sticker_id }) => sticker_id === text.slice(8)
      )
      if (url) {
        return url.value
      } else return text
    },
    transformMessage(message) {
      switch (message.content.code) {
        case 'ban_user': {
          message.content.message += new Date(
            message.content.expires
          ).toLocaleString()
        }
      }
      return message
    },
    getSenderClass(item) {
      if (item.content.code === 'admin') return 'user-admin'
      if (item.content.code === 'moderator' || item.sender.indexOf('*') > -1)
        return 'user-asterisk'
      return ''
    },
    updateParticipants() {
      this.$store.dispatch('fetchChatParticipants', this.videoId)
      this.$store.dispatch('fetchChatParticipantsCount', this.videoId)
    },
    constantCheckStatus() {
      this.$store.dispatch('getStatusData', this.videoId).then((res) => {
        if (!res.data) return

        this.rateLimit = res.data.rate_limit
        this.chatStatus = res.data.setting.chat_status
        if (this.chatStatus === 'live' && this.isChecked) {
          this.establishSocketConnection()
        } else {
          setTimeout(() => this.constantCheckStatus(), 3000)
        }
      })
    },
    isActionable(message) {
      return (
        this.userInfo.username !== message.sender &&
        message.content.code !== 'moderator' &&
        message.content.msg_type !== 'system'
      )
    },
    scrollToBottomOfChatWindow() {
      const chatWindow = this.$refs.chatPanel
      if (chatWindow) chatWindow.scrollTop = chatWindow.scrollHeight
    },
    establishSocketConnection() {
      this.connection = new WebSocket(
        `${settings.chatURLSocket}/v1/api/video/${
          this.videoId
        }/token/${localstorage.getToken()}/chat`
      )
      this.connection.onopen = (event) => {
        console.log('Websocket: successfully connected')
        this.isWebsocketReady = true
        this.$store.dispatch('fetchChatParticipants', this.videoId)
      }
      this.connection.onclose = (event) => {
        console.log('successfully closed')
        this.isWebsocketReady = false
        clearInterval(this.intervalFunc)
        if (!this.socketClosed && this.isChecked) {
          this.establishSocketConnection()
        }
      }
      this.connection.onmessage = (event) => {
        const msg = JSON.parse(event.data)
        if (this.isSystemOnlyMessage(msg)) {
          return
        }
        if (this.isSystemMessage(msg)) {
          this.handleSystemMessage(msg)
        } else {
          this.handleUserMessage(msg)
        }
      }

      /**
       * Send a message e.g. `ping` periodically to prevent closing socket.
       */
      this.intervalFunc = setInterval(() => {
        if (!this.socketClosed) {
          this.connection.send(JSON.stringify({ ping: '' }))
        }
      }, 30000)
    },
    isSystemMessage(socketMessage) {
      return socketMessage.content.msg_type === 'system'
    },
    isSystemOnlyMessage(socketMessage) {
      return socketMessage.content.msg_type === 'system-only'
    },
    handleUserMessage(socketMessage) {
      if (!socketMessage.content.hidden) {
        this.historyMessages.push(socketMessage)
      }
    },
    highlight(message) {
      this.shouldShowHighlighted = true
      this.highlightedMessage = message
      setTimeout(() => (this.shouldShowHighlighted = false), 5000)
    },
    handleSystemMessage(socketMessage) {
      let appendToMessages = true
      switch (socketMessage.content.code) {
        case 'hide': {
          console.log('received hide msg')
          this.chatEnabled = false
          break
        }
        case 'show': {
          console.log('received show msg')
          this.chatEnabled = true
          break
        }
        case 'retry':
        case 'login_from_other': {
          this.socketClosed = true
          this.highlight(socketMessage.content.message)
          break
        }
        case 'ended': {
          this.socketClosed = true
          this.highlight(socketMessage.content.message)
          break
        }
        case 'join': {
          this.participantsShown.push(socketMessage.content.meta_info?.username)
          this.participantsCountShown += 1
          break
        }
        case 'left': {
          this.participantsShown = this.participantsShown.filter(
            (p) => p !== socketMessage.content.meta_info?.username
          )
          this.participantsCountShown -= 1
          break
        }
        case 'paused': {
          this.highlight(socketMessage.content.message)
          this.chatStatus = 'paused'
          break
        }
        case 'live': {
          this.highlight(socketMessage.content.message)
          this.chatStatus = 'live'
          break
        }
        case 'ban_user': {
          appendToMessages = false
          const bannedUser = socketMessage.content.meta_info.username.trim()
          if (bannedUser === this.userInfo.username) {
            socketMessage.content.message += new Date(
              socketMessage.content.expires
            ).toLocaleString()
            this.historyMessages.push(socketMessage)
          }
          break
        }
        case 'unban_user': {
          appendToMessages = false
          const bannedUser = socketMessage.content.meta_info.username.trim()
          if (bannedUser === this.userInfo.username) {
            this.historyMessages.push(socketMessage)
          }
          break
        }
        case 'report': {
          appendToMessages = false
          const reportingUser = socketMessage.content.meta_info.reporting_username.trim()
          if (reportingUser === this.userInfo.username) {
            this.historyMessages.push(socketMessage)
          }
          break
        }
        case 'block_message': {
          appendToMessages = false
          const messageId = socketMessage.content.meta_info.message_id.trim()
          if (messageId !== '') {
            this.historyMessages = this.historyMessages.filter(
              (message) => message.id !== messageId
            )
          }
          break
        }
      }
      if (appendToMessages && !socketMessage.content.hidden) {
        this.historyMessages.push(socketMessage)
        console.log(socketMessage)
      }
    },
    handleClick() {
      this.isChecked = !this.isChecked
      if (!this.isChecked) {
        this.connection.close()
      } else {
        this.constantCheckStatus()
      }
      this.$emit('clicked', this.isChecked)
    },
    sendMessage() {
      if (
        this.message.trim() &&
        this.connection &&
        this.connection.readyState === WebSocket.OPEN
      ) {
        this.connection.send(JSON.stringify({ message: this.message.trim() }))
        this.message = ''
      } else {
        console.log('Error')
      }
    },
    handleBanUser(index) {
      this.isShowPopupBan = index
      this.selectedIndex = index

      if (index > this.historyMessagesAfterBlocked.length - 4) {
        // if selected chat msg is one of the last 3 messages
        this.$nextTick(() => {
          this.scrollToBottomOfChatWindow()
        })
      }
    },
    handleSendMessage() {
      this.handleSendMessageFunc()
    },
    handleReportUser(message) {
      const payload = {
        videoId: this.videoId,
        messageId: message.id,
        username: message.sender
      }
      this.$store.dispatch('reportParticipants', payload).then((res) => {
        console.log(res)
      })
      this.highlight(`User ${message.sender} has been reported`)
      this.handleClosePopup()
    },
    handleBlocktUser(blockedUser) {
      this.blockedUsers.push(blockedUser)
      this.highlight(`User ${blockedUser} has been blocked`)
      this.handleClosePopup()
    },
    handleClosePopup() {
      this.isShowPopupBan = -1
      this.selectedIndex = -1
    },
    handleClickHighlight(index) {
      this.selectedIndex = index
    },
    mouseOver(index) {
      this.selectedIndex === index
    },
    addEmoji(emoji) {
      this.message += emoji
    },
    sendSticker(sticker) {
      this.message += 'sticker:' + sticker.sticker_id
      this.handleSendMessage()
    },
    gotoLogin() {
      if (this.$route.name === 'VideosId') {
        localStorage.setItem('loginRedirectURL', window.location.pathname)
      }
      this.$router.push({ path: '/login' })
    }
  }
}
</script>
<style lang="scss" scoped>
.container__main {
  margin: 20px 1rem 0px 1rem;
  position: relative;
  height: 562px;
  width: 430px;
}

@media (max-width: 786px) {
  .container__main {
    width: 100vw;
    margin: 0 0 20px 0;
    height: calc(100vh - 57vw - 150px - 80px);
    max-height: 430px;
    overflow-y: auto;
  }
}

.video {
  width: 70%;
}

.chat {
  &__turnoff {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }

  &__background__chat {
    background-image: url('../../assets/images/logo.png');
    background-repeat: no-repeat;
    background-size: 224px 75px;
    background-position: center 20%;
    position: absolute;
    width: 100%;
    height: 100px;
    left: 50%;
    top: 43%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
  }

  @media (max-width: 786px) {
    &__background__chat {
      background-size: 285px 100px;
    }
  }

  &__content {
    width: 100%;

    &__highlighted {
      padding: 7px;
      background: #333;
    }

    p {
      line-height: 38px;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
      padding-left: 14px;
    }

    @media (max-width: 950px) {
      p {
        font-size: 14px;
      }
    }
    @media (max-width: 850px) {
      p {
        font-size: 11px;
      }
    }
    @media (max-width: 786px) {
      p {
        font-size: 14px;
        text-align: unset;
        margin-left: 20px;
        margin-top: 10px;
      }
    }

    &__main {
      overflow-y: auto;
      height: 368px;
      /* width */

      .chat__box {
        position: relative;
        min-height: 10rem;

        &__popup-report {
          position: absolute;
          top: 40px;
          right: 5px;
          width: 200px;
          height: 100px;
          background: #081119;
          color: white;
          z-index: 2;
          transition: all 0.4s ease;

          &__item {
            display: flex;
            height: 50px;
            align-items: center;

            .report__user {
              color: white !important;
            }

            span:hover {
              cursor: pointer;
            }

            .icon {
              margin-right: 15px;
              margin-left: 10px;
              font-size: 20px;
              color: #747a7f;
            }

            .label {
              color: white;
            }
          }
        }
      }

      .background-popup {
        background: #60116e;
      }

      &--item {
        position: relative;
        display: flex;
        padding: 0px 5px;

        & > div {
          width: 100%;
        }

        &__dot {
          position: absolute;
          top: 0.5rem;
          right: 10px;
          // font: 14px;
          svg {
            font-size: 20px;
          }
        }

        &__dot:hover {
          cursor: pointer;
        }

        span {
          display: inline;
          color: greenyellow;
          font-weight: bold;
          z-index: 1;

          label {
            display: inherit;
            padding-left: 5px;
            font-weight: 400;
            color: white;
          }
        }

        span.user-admin {
          color: fuchsia;
        }

        span.user-asterisk {
          color: #ff760d;
        }
      }

      &__main {
        overflow-y: scroll;
        height: 450px;
        /* width */

        &--item {
          span {
            font-size: 14px;
          }
        }
      }

      @media (max-width: 786px) {
        &--item {
          span {
            font-size: 18px;
          }
        }
      }
      @media (max-width: 420px) {
        &--item {
          span {
            font-size: 14px;
          }
        }
      }
    }

    @media (max-width: 786px) {
      &__main {
        padding: 10px;
        height: 220px;
      }
    }
  }

  @media (max-width: 786px) {
    &__content {
      background: none;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    background: #162029;
    width: 100%;
    height: 100px;
    text-align: center;

    box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px,
      rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px,
      rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;

    &__btn {
      width: 90%;
      height: 40px;
      background: #344250;
      border-radius: 4px;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      a {
        line-height: 40px;
        font-weight: 600;
      }
    }

    &__send_btn {
      width: 90%;

      .count__string {
        width: 0px;
        padding-bottom: 10px;
        color: #62696f;
        font-size: 14px;
      }

      border-radius: 4px;
      margin: 0 auto;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);

      &__inner {
        height: 40px;
        display: flex;
        background: #344250;
        border-radius: 5px;

        input {
          height: 40px;
          background: none;
          border: none;
        }

        ::placeholder {
          color: #62696f;
          opacity: 1; /* Firefox */
        }

        input[type='text'] {
          color: white;
          line-height: 40px;
          font-size: 14px;
        }

        &__btn {
          width: 51px;
          border-style: none;
          background: none;
          border: none;
          height: 40px;
          color: #62696f;
          font-size: 16px;
        }

        &__btn.sendable {
          color: white;
        }

        &__btn:hover {
          cursor: pointer;
          background: #62696f;
          color: #344250;
          transition: 0.4s;
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    &__footer {
      height: 80px;
      bottom: -8px;
    }
  }

  &__footer &__footer__btn:hover {
    cursor: pointer;
    background: #ccc;
    transition: 0.4s ease-in;
  }

  &__footer &__footer__btn:hover a {
    transition: 0.4s ease-in;
    color: #111820;
  }
}

@media (max-width: 786px) {
  .container__main {
    display: block;

    .video {
      width: 100%;

      .player-container {
        width: 100%;
      }
    }

    .chat {
      width: 100%;
      border: none;
    }
  }
}

// --------scroll------------------

.flexcroll::-webkit-scrollbar {
  width: 10px;
  background: white;
}

.flexcroll::-webkit-scrollbar-thumb {
  // border-radius: 10px;
  background: grey;
}

.icon-close {
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 0;
  padding: 2px 5px 0px 5px;
  color: #081119;
  height: 20px;
  width: 20px;
  background: #ccc;
}

@media (max-width: 786px) {
  .icon-close {
    padding: 4px 5px 0px 6px;
  }
}

.icon-close:hover {
  cursor: pointer;
}

.active {
  background: #60116e88;
  width: 100%;
  min-height: 30px;
  transition: all 0.3s ease;
}

.bm__item {
  width: 95%;
  margin-left: 10px;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
  font-size: 14px;
}

.chat__footer__emoji {
  width: 40px;
  height: 40px;
}

// chat__content__main
@media screen and (max-width: 768px) {
  .chat__content__main {
    height: calc(100vh - 57vw - 150px - 30px - 160px);
    max-height: 260px;
  }
  .chat__content__head {
    display: none;
  }
}

.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
.sticker {
  max-height: 50px;
  max-width: 50px;
}
</style>
