var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative mx-auto" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPlayer,
            expression: "showPlayer"
          }
        ],
        class: { "player-swiper-class md:-ml-64": _vm.videoClass }
      },
      [_c("div", { ref: "videoPlayerRef", staticClass: "w-full" })]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showPlayer,
            expression: "!showPlayer"
          }
        ],
        staticClass: "flex justify-center"
      },
      [
        _c(
          "div",
          { staticClass: "relative img-shadow rounded-lg" },
          [
            _vm._m(0),
            _vm.bgGradient
              ? _c("div", {
                  staticClass:
                    "linear-bg-gradient absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center cursor-pointer"
                })
              : _vm._e(),
            _vm._t("default"),
            _c(
              "div",
              {
                staticClass:
                  "absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center cursor-pointer",
                on: { click: _vm.initPlayer }
              },
              [_c("PlayBtn")],
              1
            )
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center md:w-max" }, [
      _c("img", {
        staticClass: "w-full",
        attrs: {
          src: require("@/assets/images/training/video-thumbnails/main-video-thumbnail.png")
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }