import api from '.'
import { instance as axios } from '../plugins/axios'
import { settings } from '@/library/variables'

export const demand = {
  async getDemandView(viewName) {
    try {
      let resp = await axios.get(
        '/v2/properties/' +
          settings.propertyID +
          '/views/' +
          viewName +
          '?include=entities&include=featured'
      )
      if (resp.status === 200) {
        //now get all the collections
        return resp.data
      } else {
        console.log('Error  ' + resp.message)
        //TODO throw an error here
      }
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getViewData(viewname) {
    try {
      return await axios.get(
        '/v2/properties/' +
          settings.propertyID +
          '/views/' +
          viewname +
          '?include=featured,entities'
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
