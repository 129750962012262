var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register flex-column-center" }, [
    _c("h1", [
      _vm._v(" Thank you "),
      _c("span", [_vm._v(_vm._s(_vm.formatNameUser))]),
      _vm._v(" for subscribing! ")
    ]),
    _c("h5", [
      _vm._v(
        " You have unlocked PokerGO, including 100+ days of live events, exclusive poker shows & more! "
      )
    ]),
    _vm.isPaypal
      ? _c("h5", { staticClass: "paypal-notation" }, [
          _vm._v(
            " It may take up to 5 minutes if you paid by credit card on Paypal to access premium content but if you paid by bank account through Paypal it can take up to 48 hours to clear your bank. "
          )
        ])
      : _vm._e(),
    _c("div", { staticClass: "details flex-column-center" }, [
      _c("div", { staticClass: "title" }, [_vm._v("SUBSCRIPTION DETAILS:")]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "head" }, [
          _c("div", { staticClass: "list-item" }, [
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "edit-info", on: { click: _vm.goToAccount } },
                [
                  _c("span", [
                    !_vm.nameInitial
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/images/account-hover-icon.png"),
                            alt: "",
                            height: "50"
                          }
                        })
                      : _c("span", [_vm._v(_vm._s(_vm.nameInitial))])
                  ]),
                  _c("div", { staticClass: "CSSBadge" }, [
                    _c(
                      "div",
                      { staticClass: "CSSBadge-number" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon-edit",
                          attrs: { icon: ["fas", "pen"] }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.formatNameUser))
                ]),
                _c("div", { staticClass: "email" }, [_vm._v(_vm._s(_vm.email))])
              ])
            ]),
            _vm.plan.name
              ? _c("div", { staticClass: "item" }, [
                  _c("span", { staticClass: "plan-label" }, [
                    _vm._v(" " + _vm._s(_vm.plan.name) + " ")
                  ])
                ])
              : _vm._e()
          ])
        ]),
        _c("div", { staticClass: "content-subscriber" }, [
          _c("div", { staticClass: "list-item" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.plan.symbol) + _vm._s(_vm.planPrice))
              ]),
              _c("span", [_vm._v("Purchase Price")])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v(_vm._s(_vm.plan.symbol + _vm.plan.price))]),
              _c("span", [_vm._v("Renewal Price")])
            ]),
            _vm._m(0)
          ])
        ])
      ]),
      !_vm.isPaypal
        ? _c(
            "div",
            {
              staticClass: "glowing-btn h-12 px-12 mt-1",
              on: { click: _vm.startWatching }
            },
            [_c("div", {}, [_vm._v("Start watching")])]
          )
        : _vm._e()
    ]),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("span", [_vm._v("Credit Card")]),
      _c("span", [_vm._v("Payment Options")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "apps flex-column-center" }, [
      _c("div", { staticClass: "logos-row" }, [
        _c(
          "a",
          {
            attrs: {
              href:
                "https://apps.apple.com/us/app/pokergo-stream-poker-tv/id1235783484"
            }
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/download-applestore.png") }
            })
          ]
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://play.google.com/store/apps/details?id=com.pokercentral.poker&hl=en_US&gl=US"
            }
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/download-googleplay.png") }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "logos-row" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/logos/logo-iphone.svg") }
        }),
        _c("img", {
          attrs: { src: require("@/assets/images/logos/logo-ipad.svg") }
        }),
        _c("img", {
          attrs: { src: require("@/assets/images/logos/logo-appleTV.svg") }
        }),
        _c("img", {
          attrs: { src: require("@/assets/images/logos/logo-android.svg") }
        }),
        _c("img", {
          attrs: { src: require("@/assets/images/logos/logo-roku.svg") }
        }),
        _c("img", {
          attrs: { src: require("@/assets/images/logos/logo-amazonfiretv.svg") }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }