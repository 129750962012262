<template>
  <div class="register-steps">
    <div
      v-for="(step, i) in steps.filter((s) => s.step == currentStep)"
      :key="i"
      class="text-center px-3"
    >
      <span class="step-title ">{{ step.title }} </span>
      <div class="step-index-group">
        <div
          class="step"
          v-for="(step, i) in steps.filter((s) => s.title)"
          :key="i"
          :class="{ active: isActive(step), complete: isComplete(i) }"
        >
          <div class="index" :class="{ active: isActive(step) }">
            <b v-if="isComplete(i)"><font-awesome-icon icon="check"/></b>
            <b v-else>{{ i + 1 }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RegisterSteps',
  data() {
    return {}
  },
  props: {
    currentStep: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['user']),
    firstName() {
      return this.user.basic ? this.user.basic.first_name : ''
    },
    steps() {
      let title = 'Maximize your poker experience with PokerGO!'
      if (this.firstName != '') {
        title =
          this.firstName + ', maximize your poker experience with PokerGO!'
      }
      var s = [
        { title: title, step: 'select-plan' },
        { title: "Let's start creating your account", step: 'register' },
        { title: 'Please verify your email address', step: 'verify-email' },
        { title: 'Confirm your payment method', step: 'checkout' },
        { step: 'complete' }
      ]
      return s
    }
  },
  methods: {
    isActive(step) {
      return this.currentStep === step.step
    },
    isComplete(index) {
      return this.steps.findIndex((s) => s.step === this.currentStep) > index
    }
  }
}
</script>
<style scoped lang="scss">
.register-steps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .step-title {
    font-size: 30px;
  }

  .step-index-group {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
  }
  .step-index-group .step:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #9f26b5;
    top: 20px; /*half of height Parent (li) */
    left: -50%;
    z-index: -1;
  }
  .step-index-group .step:nth-child(1):after {
    left: 15%;
  }
  .step-index-group .step:nth-child(3):after {
    left: -58%;
  }
  @media screen and (max-width: 768px) {
    .step-index-group .step:nth-child(3):after {
      left: -66%;
    }
  }
  @media screen and (max-width: 520px) {
    .step-index-group .step:nth-child(3):after {
      left: -42%;
      /* background: red; */
      width: 50%;
    }
  }
  .step {
    z-index: 2;
    display: flex;
    align-items: center;
    color: $disabled-grey;
    height: 40px;
    transform: skewX(-15deg);
    margin-right: 1rem;
    padding-left: 0.5rem;
    padding-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
    .index {
      font-family: 'UniSans-Bold';
      font-size: 21px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      border: 2px solid $disabled-grey;
      border-color: inherit;
      text-align: center;
      z-index: 2;
      background: #10171f;
      &.active {
        color: #10171f;
        background-color: $poker-accent;
      }
      &.complete {
        color: $poker-accent;
        border-color: $poker-accent;
        z-index: 2;
      }
    }
    .title {
      width: auto;
    }
    &.active {
      color: $poker-accent;
      border-color: $poker-accent;
    }
    &.complete {
      color: white;
      border-color: $poker-accent;
      z-index: 2;
      .index {
        color: $poker-accent;
        border-color: $poker-accent;
      }
    }
    div {
      font-family: 'UniSans-Bold-Italic';
      transform: skewX(15deg);
    }
  }
}

@media screen and (max-width: 768px) {
  .register-steps {
    width: 100%;
    justify-content: space-evenly;
    .step {
      width: 30%;
      padding-right: 0;
      margin-right: 0;
      .title {
        width: min-content;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .register-steps {
    width: 100%;
    justify-content: space-evenly;
    font-size: 0.8rem;
    .step {
      width: 28%;
      margin-right: 0;
      &:first-child {
        border-left: none;
        margin-left: -25px;
        padding-left: 1rem;
        padding-right: 5px;
        width: 36%;
      }
      &:last-child {
        border-right: none;
        margin-right: -100px;
        padding-right: 1rem;
        padding-left: 5px;
        width: 36%;
        transition: 0.3s ease;
      }
      .title {
        width: min-content;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .register-steps {
    text-align: center;
    font-size: 14px;
    .step {
      width: 28%;
      margin-right: 0;
      left: 5%;
      &:first-child {
        border-left: none;
        margin-left: -25px;
        padding-left: 1rem;
        padding-right: 5px;
        width: 36%;
      }
      &:last-child {
        border-right: none;
        margin-right: -100px;
        padding-right: 1rem;
        padding-left: 5px;
        width: 36%;
      }
      .title {
        width: min-content;
      }
    }
  }
}
</style>
