import api from '@/api'
const { home, progress } = api
export default {
  async GetViewData(state, payload) {
    let response = await home.getViewData(payload)
    return response
  },
  async GetSubscriberFavorites(state, payload) {
    let response = await home.getSubscriberFavorites(payload)
    return response
  },
  async GetProgress(state, payload) {
    let response = await progress.getProgress(payload)
    return response
  },
  async GetSubscriberProgress(state, payload) {
    let response = await progress.getSubscriberProgress(payload)
    return response
  },
  async PatchProgress(state, videoId) {
    let response = await progress.patchProgress(videoId)
    return response
  }
}
