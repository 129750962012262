var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "home-container" }, [
      _c("main", { staticClass: "main" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c("section", { staticClass: "try-pocker-free" }, [
            _c("h3", [_vm._v("TRY POKER FREE")]),
            _c("div", { staticClass: "try-pocker-free__content" }, [
              _c("div", { staticClass: "left" }, [
                _c("h1", [
                  _vm._v("Watch Exclusive Live Poker Tournaments & TV Shows")
                ]),
                _c("h4", [
                  _vm._v("Thousands of live poker events & original shows")
                ]),
                _c("h4", [_vm._v("Live and On Demand")]),
                _c("div", { staticClass: "subscribe" }, [
                  _c(
                    "div",
                    { staticClass: "subscribe-btn" },
                    [
                      _c("router-link", { attrs: { to: "/register" } }, [
                        _vm._v(" Subscribe to watch Live Poker Events & Shows ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "subscribe-login" },
                    [
                      _vm._v(" or "),
                      _c("router-link", { attrs: { to: "/login" } }, [
                        _vm._v("Login")
                      ])
                    ],
                    1
                  )
                ]),
                _c("p", [
                  _vm._v(
                    ' "PokerGo is the driving force of poker\'s future being very bright." '
                  )
                ]),
                _vm._m(0)
              ]),
              _c("div", { staticClass: "right" }, [
                _vm._m(1),
                _c(
                  "div",
                  {
                    staticClass: "tab-content",
                    attrs: { id: "nav-tabContent" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade show active",
                        attrs: {
                          id: "nav-home",
                          role: "tabpanel",
                          "aria-labelledby": "nav-home-tab"
                        }
                      },
                      [
                        this.scheduleData.data
                          ? _c(
                              "div",
                              { staticClass: "list-item-video" },
                              [
                                _vm._l(
                                  this.scheduleData.data.collection_list.slice(
                                    0,
                                    3
                                  ),
                                  function(item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        staticClass: "item-video"
                                      },
                                      [
                                        _c(
                                          "video",
                                          {
                                            staticStyle: {
                                              "object-fit": "cover"
                                            },
                                            attrs: {
                                              width: "150",
                                              height: "100",
                                              controls: "",
                                              poster: item.images[0].url
                                            }
                                          },
                                          [
                                            _c("source", {
                                              attrs: {
                                                src: item.source,
                                                type: "video/mp4"
                                              }
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "desc-video",
                                            on: { click: _vm.linkToSchedule }
                                          },
                                          [
                                            item.label.length > 20
                                              ? _c("h3", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.label.substring(
                                                          0,
                                                          50
                                                        ) + "..."
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _vm._e(),
                                            item.label.length < 20
                                              ? _c("h3", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.label) +
                                                      " "
                                                  )
                                                ])
                                              : _vm._e(),
                                            item.description.length > 60
                                              ? _c("p", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.description.substring(
                                                          0,
                                                          60
                                                        ) + "..."
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _vm._e(),
                                            item.description.length < 60
                                              ? _c("p", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.description) +
                                                      " "
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    )
                                  }
                                ),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "more-video",
                                    attrs: { to: "/schedule" }
                                  },
                                  [_vm._v(" More Live Events ")]
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        this.scheduleData.data == undefined
                          ? _c(
                              "div",
                              { staticClass: "list-item-video" },
                              [
                                _vm._m(2),
                                _vm._m(3),
                                _vm._m(4),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "more-video",
                                    attrs: { to: "/schedule" }
                                  },
                                  [_vm._v(" More Live Events ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade",
                        attrs: {
                          id: "nav-profile",
                          role: "tabpanel",
                          "aria-labelledby": "nav-profile-tab"
                        }
                      },
                      [_vm._v(" UPCOMING TOURNAMENTS ")]
                    )
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "popculture" }, [
      _c("div", { staticClass: "around" }, [
        _c("div", { staticClass: "x" }, [_vm._v("PC")])
      ]),
      _c("span", [_vm._v("popculture.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", [
      _c(
        "div",
        {
          staticClass: "nav nav-tabs",
          attrs: { id: "nav-tab", role: "tablist" }
        },
        [
          _c(
            "button",
            {
              staticClass: "nav-link active",
              attrs: {
                id: "nav-home-tab",
                "data-bs-toggle": "tab",
                "data-bs-target": "#nav-home",
                type: "button",
                role: "tab",
                "aria-controls": "nav-home",
                "aria-selected": "true"
              }
            },
            [_vm._v(" LIVE EVENTS ")]
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-video" }, [
      _c(
        "video",
        {
          staticStyle: { "object-fit": "cover" },
          attrs: {
            width: "150",
            height: "100",
            controls: "",
            poster: require("../../assets/images/pic1.jpeg")
          }
        },
        [
          _c("source", {
            attrs: {
              src: "https://www.w3schools.com/html/movie.mp4",
              type: "video/mp4"
            }
          })
        ]
      ),
      _c("div", { staticClass: "desc-video" }, [
        _c("h3", [_vm._v("All-Start ProAm 2021 | Day 1")]),
        _c("p", [
          _vm._v(
            " All-Star ProAm live from the PokerGo Studio at ARIA Resort & Casino "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-video" }, [
      _c(
        "video",
        {
          staticStyle: { "object-fit": "cover" },
          attrs: {
            width: "150",
            height: "100",
            controls: "",
            poster: require("../../assets/images/pic3.jpeg")
          }
        },
        [
          _c("source", {
            attrs: {
              src: "https://www.w3schools.com/html/movie.mp4",
              type: "video/mp4"
            }
          })
        ]
      ),
      _c("div", { staticClass: "desc-video" }, [
        _c("h3", [_vm._v("All-Start ProAm 2021 | Day 1")]),
        _c("p", [
          _vm._v(
            " All-Star ProAm live from the PokerGo Studio at ARIA Resort & Casino "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-video" }, [
      _c(
        "video",
        {
          staticStyle: { "object-fit": "cover" },
          attrs: {
            width: "150",
            height: "100",
            controls: "",
            poster: require("../../assets/images/pic4.jpeg")
          }
        },
        [
          _c("source", {
            attrs: {
              src: "https://www.w3schools.com/html/movie.mp4",
              type: "video/mp4"
            }
          })
        ]
      ),
      _c("div", { staticClass: "desc-video" }, [
        _c("h3", [_vm._v("Stairway To Millioms 2022")]),
        _c("p", [
          _vm._v(
            " $2,000 No-Limit Hold'em final table from the 2022 Stairway To Millions "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }