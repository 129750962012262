var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "register-plan",
      class: [{ highlighted: _vm.plan.highlight }]
    },
    [
      _vm.plan.ribbon
        ? _c("div", { staticClass: "ribbon" }, [
            _c("span", [_vm._v(_vm._s(_vm.plan.ribbon))])
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "title flex-column-center",
          class: [{ "text-white": _vm.plan.highlight }],
          style: { background: _vm.plan.highlight && "#9f26b5" }
        },
        [_vm._v(" " + _vm._s(_vm.plan.name) + " ")]
      ),
      _vm.plan.striked
        ? _c("div", { staticClass: "offer__price" }, [
            _c("div", { staticClass: "offer__price--original" }, [
              _vm._v(" US$ "),
              _c("span", [_vm._v(_vm._s(_vm.plan.striked))])
            ]),
            _c("div", { staticClass: "offer__price--new text-black" }, [
              _c("div", { staticClass: "offer__price--new_symbol" }, [
                _vm._v(" US$ ")
              ]),
              _c("div", { staticClass: "offer__price--new_value" }, [
                _vm._v(" " + _vm._s(_vm.plan.price) + " ")
              ])
            ])
          ])
        : _c("div", [
            _c("div", { staticClass: "offer flex-row-center" }, [
              _c("div", { staticClass: "currency" }, [_vm._v("US$")]),
              _c("div", { staticClass: "price" }, [
                _vm._v(" " + _vm._s(_vm.plan.price) + " "),
                _vm.plan.priceDescription
                  ? _c(
                      "div",
                      { staticClass: "description flex-column-center" },
                      [_vm._v(" " + _vm._s(_vm.plan.priceDescription) + " ")]
                    )
                  : _vm._e()
              ])
            ])
          ]),
      _c("div"),
      _c("div", { staticClass: "offer__buttons" }, [
        _vm.showSelectPlanBtn
          ? _c(
              "button",
              {
                staticClass: "select-plan-button button",
                attrs: { disabled: _vm.disableActions },
                on: { click: _vm.selectPlan }
              },
              [
                !_vm.disableActions
                  ? _c("span", [_vm._v("Select Plan")])
                  : _c(
                      "span",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "fa-pulse",
                          attrs: { icon: "spinner" }
                        })
                      ],
                      1
                    )
              ]
            )
          : _vm._e(),
        _vm.showAcceptNewOfferBtn
          ? _c(
              "button",
              {
                staticClass: "btn-accept",
                attrs: { disabled: _vm.disableActions },
                on: {
                  click: function($event) {
                    return _vm.$emit("accept-offer")
                  }
                }
              },
              [
                !_vm.disableActions
                  ? _c("span", [_vm._v("Accept New Offer")])
                  : _c(
                      "span",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "fa-pulse",
                          attrs: { icon: "spinner" }
                        })
                      ],
                      1
                    )
              ]
            )
          : _vm._e(),
        _vm.showCancelSubscriptionBtn
          ? _c(
              "button",
              {
                staticClass: "btn-cancel-subscription",
                attrs: { disabled: _vm.disableActions },
                on: {
                  click: function($event) {
                    return _vm.$emit("cancel-subscription")
                  }
                }
              },
              [
                !_vm.disableActions
                  ? _c("span", [_vm._v("Cancel Subscription")])
                  : _c(
                      "span",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "fa-pulse",
                          attrs: { icon: "spinner" }
                        })
                      ],
                      1
                    )
              ]
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "details" },
        _vm._l(_vm.plan.planDetails, function(detailGroup, index) {
          return _c(
            "div",
            { key: "detailGroup_" + index },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-weight": "bold",
                    "margin-top": "15px",
                    "margin-left": "8px"
                  }
                },
                [_vm._v(" " + _vm._s(detailGroup.title) + " ")]
              ),
              _vm._l(detailGroup.details, function(d, i) {
                return _c(
                  "div",
                  {
                    key: i + "_" + _vm.plan.product_type + "_detail",
                    staticClass: "detail"
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "check",
                      attrs: { icon: d.icon }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "text",
                        style: { "font-weight": d.fontWeight }
                      },
                      [
                        _vm._v(" " + _vm._s(d.text) + " "),
                        d.endSlotName ? _vm._t(d.endSlotName) : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }