var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentBannerURL
    ? _c("div", { staticClass: "banner_container" }, [
        _c(
          "a",
          {
            attrs: { href: _vm.currentBannerLink, target: "_blank" },
            on: { click: _vm.handleBannerClick }
          },
          [
            _c("img", {
              attrs: { src: _vm.currentBannerURL, alt: "top_banner" }
            })
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }