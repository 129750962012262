import UUID from 'uuid'
import api from '@/api'
import { settings } from '../library/variables'

export default {
  state: {
    loginPageText: '',
    showLoginLogo: false,
    sessionId: '',
    sessionStart: '',
    default_views: [
      { name: 'home', display: 'Home', route: '/' },
      { name: 'live', display: 'Schedule', route: '/schedule' },
      { name: 'shows', display: 'On Demand', route: '/on-demand' },
      {
        name: 'highlights',
        display: 'Highlights',
        route: '/highlights',
        requestViewName: 'contributors'
      }
    ],
    settings: settings,
    documents: [],
    promocode: '',
    plans: [],
    discountDescription: '',
    error: {
      statusCode: null,
      message: ''
    },
    progressVideos: null,
    videoId: null,
    property_id: process.env.PROPERTY_ID,
    searchKeyword: '',
    bannersList: []
  },
  mutations: {
    setLoginText(state, value) {
      state.loginPageText = value
    },
    showLoginLogo(state, value) {
      state.showLoginLogo = value
    },
    setDocument(state, data) {
      state.sessionId = UUID.v4()
      state.sessionStart = new Date()
      const label = data.data.label
      const title = data.data?.title
      const content = data.data.content
      if (state.documents.findIndex((item) => item.label === label) === -1) {
        state.documents.push({
          label,
          title,
          content
        })
      }
    },
    setDocumentWithPrefix(state, data) {
      state.sessionId = UUID.v4()
      state.sessionStart = new Date()
      const documents = data.data
      documents.forEach((document) => {
        if (
          state.documents.findIndex((item) => item.label === document.label) ===
          -1
        ) {
          state.documents.push(document)
        }
      })
    },
    setPromoCode(state, value) {
      state.promocode = value
    },
    //Sets the price, plan and other data used for promocode call
    setCheckoutData(state, value) {
      state.checkoutData = value
    },
    setCheckoutPlans(state, value) {
      state.plans = value
    },
    setDiscountDescription(state, value) {
      state.discountDescription = value
    },
    setError(state, value) {
      state.error = value
    },
    setProgressVideos(state, value) {
      state.progressVideos = value
    },
    setVideoId(state, value) {
      state.videoId = value
    },
    setSearchKeyword(state, value) {
      state.searchKeyword = value
    },
    setBanners(state, value) {
      state.bannersList = value || []
    }
  },
  getters: {
    getPropertyId(state) {
      return state.property_id
    },
    settings(state) {
      return state.settings
    },
    getViews(state) {
      return state.default_views
    },
    getDocuments(state) {
      return state.documents
    },
    getVideoProgress: (state) => (videoId) => {
      let selectedProgress = 0
      if (state.progressVideos?.length) {
        state.progressVideos.forEach((video) => {
          if (video.id === videoId) selectedProgress = video.progress
        })
      }
      return selectedProgress
    },
    getVideoProgressPercent: (state) => (videoId) => {
      let selectedProgressPercent = 0
      if (state.progressVideos?.length) {
        state.progressVideos.forEach((video) => {
          if (video.id === videoId)
            selectedProgressPercent = video.progress_percent
        })
      }
      return selectedProgressPercent
    }
  },
  actions: {
    GET_DOCUMENT({ commit }, payload) {
      api.document
        .getDocument(payload, settings.propertyID)
        .then((response) => {
          if (response.status === 200) {
            commit('setDocument', response.data)
          } else {
            this.$toasted.error(response.message)
          }
        })
    },
    GET_DOCUMENT_WITH_PREFIX({ commit }, payload) {
      api.document
        .getDocumentWithPrefix(payload, settings.propertyID)
        .then((response) => {
          if (response.status === 200) {
            commit('setDocumentWithPrefix', response.data)
          } else {
            this.$toasted.error(response.message)
          }
        })
    },
    SET_ERROR({ commit }, payload) {
      commit('setError', payload)
    },
    async fetchAndSetBanners({ commit }) {
      const { data } = await api.account.getAllBanners()
      commit('setBanners', data)
    }
  }
}
