<template>
  <div class="account-page">
    <h1 :class="customClass" v-if="updatedFirstName != ''">
      {{ updatedFirstName }}'s Account
    </h1>
    <h1 :class="customClass" v-if="updatedFirstName == ''">Account</h1>
    <div class="container height-stretch page-body">
      <UpdateCard
        v-if="updateCard"
        :subscription="subscriptionToUpdate"
        @close="toggleUpdateCardModal(false, {})"
        @reload="fetchTransactions"
      ></UpdateCard>
      <div class="profile-section">
        <div class="section-title">
          <span>Profile</span>
        </div>
        <div class="section-body">
          <div class="info-group">
            <div class="avatar">
              <img
                src="@/assets/images/account-hover-icon.png"
                alt=""
                width="70"
              />
              <font-awesome-icon
                v-if="editName"
                :icon="['fas', 'pen']"
                class="icon-edit"
                @click="test"
              />
            </div>
            <div class="info">
              <div class="name-field bold">
                {{
                  nameChangeSuccess === true ? updatedNameString : nameString
                }}
              </div>
              <div class="light">{{ email }}</div>
            </div>
          </div>
          <a
            class="action-btn"
            v-if="editName == false"
            @click="
              editName = true
              nameChangeSuccess = false
            "
          >
            Edit
          </a>
        </div>

        <div class="edit-form">
          <div class="form">
            <div class="field-input name">
              <input
                id="firstname-edit"
                v-model="updatedFirstName"
                tabindex="1"
                type="text"
                placeholder="First Name"
                class="name-edit"
                :readonly="!editName"
              />
              <input
                id="lastname-edit"
                v-model="updatedLastName"
                tabindex="2"
                type="text"
                placeholder="Last Name"
                class="name-edit"
                :readonly="!editName"
              />
            </div>
            <div class="field-input">
              <input v-model="email" tabindex="2" type="text" readonly="true" />
            </div>
            <div class="field-input">
              <input
                id="username-edit"
                v-model="updatedUsername"
                tabindex="2"
                type="text"
                maxlength="20"
                minlength="5"
                placeholder="Nickname"
                :readonly="!editName"
              />
              <p v-if="!isValidUsername" class="err-msg">
                Alphabet and numbers are acceptable and at least 5 characters.
                No spaces allowed.
              </p>
              <p v-if="duplicateUsername" class="err-msg">
                Username is already taken
              </p>
            </div>
            <div class="field-input">
              <input
                id="phone-edit"
                v-model="updatedPhone"
                tabindex="2"
                type="text"
                maxlength="20"
                minlength="5"
                placeholder="Phone Number"
                :readonly="!editName"
              />
              <p v-if="!isValidPhoneNumber && editName" class="err-msg">
                Numbers are acceptable and at least 5 characters
              </p>
            </div>
          </div>
          <div v-if="editName == true" class="action-button-group">
            <a class="btn-cancel" @click="cancelNameChange">
              Cancel
            </a>
            <button class="button" @click="saveNameChange">
              Save
            </button>
          </div>
        </div>
      </div>

      <div class="blur" v-if="editName"></div>

      <div class="login-section">
        <div class="section-title">
          <span>Login</span>
        </div>
        <div class="section-body">
          <div class="info">
            <div class="bold">Last updated {{ pwdLastUpdateDuration }} ago</div>
            <div class="light">Password</div>
          </div>
          <router-link
            to="/account/changepassword"
            class="action-btn"
            @click="test"
          >
            Change Password
          </router-link>
        </div>
      </div>

      <div class="subscription-section">
        <div class="section-title">
          <span>Subscription</span>
        </div>
        <div
          v-if="canResubscribe"
          class="section-body"
          style="padding-top: 25px; padding-bottom: 25px"
        >
          <div class="info">
            <div class="light">
              You no longer have a subscription. To resubscribe, please click
              Subscribe
            </div>
          </div>
          <div class="btn-subscribe">
            <router-link to="/select-plan">
              Subscribe
            </router-link>
          </div>
        </div>
        <div v-else class="section-body horizontal">
          <div class="item">
            <div class="info">
              <div class="bold">{{ getPlanName(transaction.interval) }}</div>
              <div class="light">Plan</div>
            </div>
            <div class="inner__btn" v-if="!this.transaction.cancel_date">
              <a v-if="canCancel" class="cancel-btn" @click="cancelClicked">
                Cancel
              </a>
              <a
                v-if="canResume"
                class="action-btn"
                @click="toggleUpdateCardModal(true, transaction)"
              >
                Update Card
              </a>
              <a
                class="action-btn"
                @click="upgrade"
                v-if="
                  this.transaction.store === 'stripe' &&
                    this.transaction.interval !== 'training_annual'
                "
              >
                Upgrade Plan
              </a>
              <router-link
                v-if="!isSubscriber"
                to="/register"
                class="action-btn"
              >
                Subscribe
              </router-link>
            </div>
          </div>
          <div class="item">
            <div class="info">
              <div class="bold">{{ transaction.store }}</div>
              <div class="light">Payment Method</div>
            </div>
            <a
              class="action-btn"
              v-if="canUpdatePaymentMethod"
              @click="toggleUpdateCardModal(true, transaction)"
            >
              Edit
            </a>
          </div>
          <div class="item price">
            <div class="item-group">
              <div class="info">
                <div class="bold">$ {{ transaction.amount }}</div>
                <div class="light">Purchase</div>
              </div>
              <div class="info">
                <div class="bold">$ {{ transaction.renewal_price }}</div>
                <div class="light">Renewal</div>
              </div>
            </div>
          </div>
          <div class="item">
            <span class="light">Purchase Date </span>
            <span class="date">{{ getStartDate() }}</span>
            <span class="circle"><i class="fas fa-circle"></i></span>
            <span class="light">{{
              canCancel ? 'Next Billing Date' : 'Canceled Date'
            }}</span>
            <span class="date">{{ getEndDate() }}</span>
          </div>
        </div>
      </div>

      <div class="upgrade-section" v-if="!this.transaction.cancel_date">
        <button
          @click="upgrade"
          v-if="
            this.transaction.store === 'stripe' &&
              (this.transaction.interval === 'monthly' ||
                this.transaction.interval === 'quarterly')
          "
        >
          <div class="bold">Upgrade to a PokerGO Annual Plan</div>
          <div class="light">Get 58% off an Annual Subscription</div>
        </button>

        <button
          @click="upgrade"
          v-else-if="
            this.transaction.store === 'stripe' &&
              this.transaction.interval === 'annual'
          "
        >
          <div class="bold">Upgrade to a PokerGO VIP Plan</div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import UpdateCard from '@/components/UpdateCard.vue'
import VueScript2 from 'vue-script2'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'AccountPage',
  components: {
    UpdateCard
  },
  data() {
    return {
      editName: false,
      transaction: {},
      updateCard: false,
      cardDetails: {},
      stripe: undefined,
      transactionsFetched: false,
      updatedFirstName: '',
      updatedLastName: '',
      updatedUsername: '',
      updatedPhone: '',
      nameChangeSuccess: false,
      subscriptionToUpdate: {},
      errorText: '',
      showErrorText: false,
      isShowPlan: false,
      isPendingPurchase: false,
      purchased: false,
      duplicateUsername: false,
      selectedPlan: {},
      appver: '',
      interactiveMode: false
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user', 'isSubscriber']),
    ...mapState({
      plans: (state) => state.checkout.plans
    }),
    ...mapActions(['FETCH_ME']),
    subscriptionsLoading() {
      return !this.transactionsFetched
    },
    firstName() {
      return this.user.basic ? this.user.basic.first_name : ''
    },
    itgOffMode() {
      return this.user.basic.itg_off ? true : false
    },
    lastName() {
      return this.user.basic ? this.user.basic.last_name : ''
    },
    nameString() {
      return this.firstName + ' ' + this.lastName
    },
    updatedNameString() {
      return this.updatedFirstName + ' ' + this.updatedLastName
    },
    email() {
      return this.user.basic ? this.user.basic.email : ''
    },
    property() {
      return this.user.basic ? this.user.basic.property_id : ''
    },
    username() {
      return this.user.basic ? this.user.basic.user_name : ''
    },
    zip() {
      return this.user.basic ? this.user.basic.postal_code : ''
    },
    isValidUsername() {
      if (
        this.updatedUsername?.trim() !== '' &&
        (this.updatedUsername?.trim().length < 5 ||
          this.updatedUsername?.trim().length > 20)
      )
        return false

      const regex = new RegExp(/^[a-zA-Z0-9]*$/)
      return regex.test(this.updatedUsername)
    },
    isValidPhoneNumber() {
      if (
        this.updatedPhone?.trim() !== '' &&
        (this.updatedPhone?.trim().length < 5 ||
          this.updatedPhone?.trim().length > 20)
      )
        return false

      const regex = new RegExp(/^[0-9-]*$/)
      return regex.test(this.updatedPhone)
    },
    isPurchased() {
      return (
        this.transaction.id !== '' &&
        this.transaction.subscription_status !== 'Termination'
      )
    },
    phone() {
      return this.user.basic ? this.user.basic.phone_number : ''
    },
    endDateTitle() {
      if (
        this.transaction.cancel_date !== undefined &&
        this.transaction.cancel_date !== null &&
        this.transaction.cancel_date !== ''
      ) {
        return 'Canceled Date'
      } else {
        return 'Next Billing Date'
      }
    },
    canCancel() {
      // if pending status, can't cancel
      if (this.isPendingPurchase) return false

      const cancelablePayments = [
        'stripe',
        'amazonweb',
        'paypal',
        'apple',
        'google',
        'amazon',
        'roku'
      ]
      return (
        cancelablePayments.includes(this.transaction.store) &&
        !this.transaction.cancel_date
      )
    },
    isCancelled() {
      return this.endDateTitle === 'Canceled Date'
    },
    canUpdatePaymentMethod() {
      return this.transaction.store === 'stripe' && this.canCancel
    },
    canResume() {
      const now = new Date()
      return (
        this.transaction.store === 'stripe' &&
        this.transaction.cancel_date &&
        now < new Date(this.transaction.cancel_date)
      )
    },
    canResubscribe() {
      const now = new Date()
      return (
        this.transaction.cancel_date &&
        new Date(this.transaction.cancel_date) < now
      )
    },
    customClass() {
      //page-title
      if (!this.isAuthenticated) {
        return 'page-title bgNotAuthenticated'
      } else {
        return 'page-title bgAuthenticated'
      }
    },
    pwdLastUpdateDuration() {
      return moment
        .duration(moment().diff(this.user?.basic?.updated_at))
        .humanize()
    }
  },
  async mounted() {
    this.$store.dispatch('FETCH_ME')
    this.appversion()
    if (!this.isAuthenticated) {
      await this.$router.push('/login')
      return
    }
    await VueScript2.load('https://js.stripe.com/v3/')
    this.stripe = window.Stripe(process.env.STRIPE_KEY)

    await Promise.all([this.fetchTransactions()])
    this.transactionsFetched = true

    if (this.transaction.interval) {
      const filteredPlans = this.plans.filter(
        (p) => p.product_type === this.transaction.interval
      )
      if (filteredPlans.length) this.selectedPlan = filteredPlans[0]
    }

    // add delay for getting user data from BE
    setTimeout(() => {
      this.setUpdatedValue()
    }, 500)
  },
  watch: {
    user() {
      // update initialValue when currentUser is updated
      this.setUpdatedValue()
    }
  },
  methods: {
    getPlanName(interval) {
      if (interval === 'training_annual') return 'PokerGO VIP Annual'
      else return interval
    },
    test() {
      console.log('tt')
    },
    appversion() {
      this.appver = process.env.WEB_VERSION
    },
    checkNum(e) {
      let invalidCha = ['e', 'E', '-', '+']
      if (invalidCha.includes(e.key)) {
        e.preventDefault()
      }
    },
    onChangePhone(e) {
      switch (e.target.className) {
        case 'phone-1':
          if (e.target.value.length === 3) {
            this.$nextTick(() => {
              this.$refs['phone-2'].focus()
            })
          }
          break
        case 'phone-2':
          if (e.target.value.length === 3) {
            this.$nextTick(() => {
              this.$refs['phone-3'].focus()
            })
          }
          break
      }
    },
    cancelClicked() {
      let store = this.transaction.store

      // show cancel instruction page if user subscribe from other devices
      if (store === 'roku') {
        this.$router.push({ name: 'CancelRoku' })
        return
      }
      if (store === 'google') {
        this.$router.push({ name: 'CancelAndroid' })
        return
      }
      if (store === 'apple') {
        this.$router.push({ name: 'CancelIOS' })
        return
      }
      if (store === 'amazon') {
        this.$router.push({ name: 'CancelFireTV' })
        return
      }

      this.$router.push({
        name: 'AccountCancelReason'
      })
    },
    toggleUpdateCardModal(value, subscription) {
      this.subscriptionToUpdate = subscription
      this.updateCard = value
    },
    getEndDate() {
      if (this.transaction.cancel_date) {
        return moment(this.transaction.cancel_date).format('MM-DD-YYYY')
      }
      if (this.isPendingPurchase) {
        return 'PENDING PURCHASE'
      }
      if (!this.transaction.next_billing_date) {
        return ''
      }
      return moment(this.transaction.next_billing_date).format('MM-DD-YYYY')
    },
    getStartDate() {
      if (this.isPendingPurchase) {
        return 'PENDING PURCHASE'
      }
      if (!this.transaction.created_at) {
        return ''
      }
      return moment(this.transaction.created_at).format('MM-DD-YYYY')
    },
    async saveNameChange() {
      this.nameChangeSuccess = false

      if (!this.isValidUsername) return
      if (!this.isValidPhoneNumber) return

      this.showErrorText = false

      const value = await this.$store.dispatch('account/SaveNameChange', {
        first_name: this.updatedFirstName?.trim(),
        last_name: this.updatedLastName?.trim(),
        user_name: this.updatedUsername?.trim(),
        phone_number: this.updatedPhone,
        itg_off: !this.interactiveMode
      })

      if (value.statusCode === 409) {
        this.duplicateUsername = true
        return
      }
      if (value.status !== 200) {
        // no content returned
        this.$toasted.error('Error')
        return
      }
      this.duplicateUsername = false
      this.editName = false
      this.nameChangeSuccess = true

      // refetch userdata which is changed in BE
      this.$store.dispatch('fetchUser')
    },
    upgrade() {
      this.$router.push('/account/change-plan/checkout')
    },
    cancelNameChange() {
      // reset updatedValue to original one
      this.setUpdatedValue()
      this.duplicateUsername = false
      this.editName = false
      this.nameChangeSuccess = false
    },
    async fetchTransactions() {
      return await this.$store
        .dispatch('account/FetchTransactions')
        .then((rs) => {
          if (rs.status !== 200) {
            this.$toasted.error('Cannot get your subscription')
            return
          }
          this.transaction = rs.data.data
          if (this.transaction.subscription_status === 'Pending') {
            this.isPendingPurchase = true
          }
          if (this.isPurchased) {
            this.purchased = true
          }
          return rs
        })
    },
    setUpdatedValue() {
      this.updatedFirstName = this.firstName
      this.updatedLastName = this.lastName
      this.updatedUsername = this.username
      this.updatedPhone = this.phone
      this.interactiveMode = !this.itgOffMode
    }
  }
}
</script>
<style scoped>
.slider::before {
  background: #e1dddd !important;
}
</style>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.slider.round .label__icon__off {
  right: -4px;
}
.err-msg {
  color: red;
  font-size: 13px;
}
.err-icon {
  margin-right: 5px;
}
.account-page {
  background: #111820;

  h1 {
    text-align: center;
    @media (max-width: 768px) {
      padding-top: 150px;
    }
  }
}
.page-title {
  font-family: 'UniSans';
  background-image: url('../../assets/images/background-account.png');
  padding: 60px 0px;
  font-weight: 100;
}
.section-title {
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
  justify-content: space-between;
  span {
    text-transform: uppercase;
  }
}
.section-body {
  color: black;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  &.horizontal {
    flex-direction: column;
    padding: 0px;
    .item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      border-bottom: 1px solid $light-gray;
      i {
        font-size: 5px;
        margin: -5px 10px 0px 10px;
        display: flex;
        color: $light-gray;
      }
      .date {
        color: #10181f;
      }
      .item-group {
        display: flex;
        width: 100%;
        .info {
          width: 50%;
          &:last-child {
            border-left: 1px solid $light-gray;
          }
        }
      }
      &:last-child {
        background-color: rgb(231 231 232);
        font-size: 15px;
        justify-content: center;
        border-bottom: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      &.price {
        padding: 0px;
        .info {
          padding: 15px 20px;
        }
      }
      .circle i {
        color: $light-gray-30;
      }
    }
  }
  .info {
    word-break: break-word;
    .bold {
      font-weight: 600;
    }
    .light {
      color: gray;
    }
  }
  .light {
    color: gray;
    padding-right: 5px;
  }
}
.upgrade-section {
  margin: 40px 0px;
  button {
    border: 2px solid #ffffff;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 12px 4px #9f26b5, 0 0 8px 4px #9f26b5;
    color: white;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    &:hover {
      background-color: $poker-accent !important;
    }
  }
  .bold {
    font-weight: 600;
  }
  .light {
    padding-top: 10px;
    color: $light-gray;
  }
}
.avatar {
  position: relative;
  margin-right: 0.5rem;

  .icon-edit {
    position: absolute;
    top: 5px;
    right: -5px;
    cursor: pointer;
    color: #8a8a8a;
    background: rgb(255, 255, 255, 0.89);
    padding: 5px;
    border-radius: 30px;
  }
}
.info-group {
  display: flex;
  align-items: center;
}
.info {
  text-align: start;
  .bold {
    text-transform: capitalize;
  }
}
.action-btn {
  margin-left: 1rem;
  color: $poker-accent;
  text-decoration: underline;
  cursor: pointer;
}
.cancel-btn {
  color: gray;
  text-decoration: underline;
  cursor: pointer;
}
.edit-form {
  color: black;
  background-color: white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 1px 20px 15px 20px;
}
.field-input {
  width: 100%;
  margin: 15px 0px;

  &.name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .name-edit {
    width: 48%;
  }

  input {
    background-color: #f4f4f4;
    border: none;
    border-radius: 5px;
  }
}
.action-button-group {
  text-align: end;
}
.blur {
  background-color: #111820;
  opacity: 30%;
  height: 500px;
  position: absolute;
  z-index: 999999;
  width: 100%;
  top: 850px;
}
.page-body {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto;
  min-width: 350px;
  padding: 10px;

  .profile {
    width: 50%;
    margin-right: 10%;
  }

  .subscription {
    width: 50%;
  }

  @media (max-width: 550px) {
    flex-flow: column;
    .profile,
    .subscription {
      width: 100%;
    }
  }
}
.height-stretch {
  min-height: calc(100vh - 320px);
}
.action {
  margin-top: 20px;
}
.subscription-card {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  justify-content: space-between;
  padding: 20px;
  padding-top: 30px;
  background-color: #f4f4f4;
  border-bottom: 1px solid $primary-color;
  &:last-of-type {
    border-bottom: none;
  }

  .subscription-item {
    width: 50%;
    padding: 8px;
  }
}

.name-field {
  word-break: break-all;
}

.name-change-notif {
  color: rgb(153, 238, 26);
  text-align: right;
  margin-right: 20px;
}

.title {
  font-size: 20px;
}

.section {
  padding: 0px;
}

.label {
  font-weight: bold;
  width: 25%;
  text-transform: uppercase;
  @media (max-width: 550px) {
    width: 30%;
  }
}

.field {
  margin-bottom: 10px;
  &.horizontal {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: 100%;
    &.apart {
      flex-flow: row;
      justify-content: space-between;
      align-items: flex-end;
    }
    .field-group {
      display: flex;
      flex-flow: column;
      background-color: #f4f4f4;
      padding: 12px;

      @media (max-width: 550px) {
        flex-flow: column;
      }
    }
    .field-item {
      min-width: 250px;
      min-height: 50px;
      display: flex;
      flex-flow: row;
      align-items: center;

      @media (max-width: 550px) {
        // margin-bottom: 10px;
        height: 40px;
      }

      .field-input {
        width: 80%;
        margin: 8px 0px;

        &.name {
          display: flex;
          align-items: center;
        }

        .name-edit {
          width: 49%;
        }
        .name-edit:first-child {
          margin-right: 20px;
        }

        .phone-1 {
          margin-right: 10px;
        }
        .phone-2 {
          margin: 0px 10px;
        }
        .phone-3 {
          margin-left: 10px;
        }

        input {
          border: none;
          @media (max-width: 550px) {
            height: 30px !important;
          }
        }
      }
    }
    .button {
      width: fit-content;
    }
  }
}

th {
  text-align: left;
}
.td-title {
  display: none;
}
.account-btn-container {
  .button {
    margin-left: 10px;
  }
}
@media (max-width: 1024px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  th {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .td-title {
    display: inline;
    margin-right: 1em;
  }
}

@media (max-width: 550px) {
  .account-btn-container {
    margin: auto;
    .button {
      width: 100%;
      margin-bottom: 5px;
      margin-left: 0 !important;
      padding: 0 0.5em !important;
    }
  }
}

.btn-cancel {
  color: black;
  margin-right: 10px;
  cursor: pointer;
}
.link {
  color: #9f26b5;
  cursor: pointer;
}
.upgrade-button {
  width: 130px;
  border-radius: 10px;
  margin-right: 30px;
}
.resume-button {
  width: 200px;
  border-radius: 10px;
  margin-right: 30px;
}
.version {
  text-align: right;
  font-size: 12px;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.bgNotAuthenticated {
  background: url('../../assets/images/Background@2x.png') no-repeat;
  backdrop-filter: blur(5px);
  background-size: cover;
  height: 100%;
}
.bgAuthenticated {
  background: url('../../assets/images/Background@2x.png') no-repeat;
  background-size: cover;
  height: 100%;
}
.profile-section {
  .section-body {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media screen and (max-width: 920px) {
  .inner__btn {
    display: flex;
    font-size: 14px;
    transition: 0.3s ease;
  }
}

.btn-subscribe {
  a {
    padding: 13px 23px;
    background: #9f26b5;
    text-decoration: none;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'UniSans';
    font-size: 14px;
    letter-spacing: 0;
    margin: 0 16px;
  }
}
.btn-subscribe:hover {
  a {
    background: #821f94;
    transition: 0.3s ease-in;
  }
}
</style>
