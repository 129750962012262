var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.chatEnabled
      ? _c(
          "div",
          { staticClass: "container__main" },
          [
            _c("ChatHeader", {
              attrs: { isChecked: _vm.isChecked },
              on: { "hide-chat": _vm.handleHideChat, toggle: _vm.handleClick }
            }),
            _vm.showChatRules
              ? _c(
                  "div",
                  [_c("ChatRules", { on: { agree: _vm.handleClickAgree } })],
                  1
                )
              : _c("div", { staticStyle: { background: "#111820" } }, [
                  !_vm.isChecked
                    ? _c("div", { staticClass: "chat__background__chat" })
                    : _vm._e(),
                  !_vm.isChecked
                    ? _c("div", { staticClass: "chat__turnoff" }, [
                        _vm._v("Chat is off.")
                      ])
                    : _vm._e(),
                  _vm.isChecked
                    ? _c("div", { staticClass: "chat__content" }, [
                        _vm._m(0),
                        _vm.shouldShowHighlighted
                          ? _c(
                              "div",
                              { staticClass: "chat__content__highlighted" },
                              [
                                _c("p", [
                                  _vm._v(_vm._s(_vm.highlightedMessage))
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.isChecked
                          ? _c(
                              "div",
                              {
                                ref: "chatPanel",
                                staticClass: "chat__content__main flexcroll"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "chat__box" },
                                  _vm._l(
                                    _vm.historyMessagesAfterBlocked,
                                    function(item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.key,
                                          staticClass:
                                            "chat__content__main--item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                active:
                                                  index === _vm.selectedIndex
                                              }
                                            },
                                            [
                                              _vm.isActionable(item)
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "chat__content__main--item__dot"
                                                    },
                                                    [
                                                      _vm.selectedIndex ===
                                                      index
                                                        ? _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon:
                                                                  "ellipsis-v"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.handleBanUser(
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "bm__item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleClickHighlight(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      class: _vm.getSenderClass(
                                                        item
                                                      )
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.displayUsername(
                                                              item
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _vm.isSticker(
                                                        item.content.message
                                                      )
                                                        ? _c("label", [
                                                            _c("img", {
                                                              staticClass:
                                                                "sticker",
                                                              attrs: {
                                                                src: _vm.getStickerURL(
                                                                  item.content
                                                                    .message
                                                                )
                                                              }
                                                            })
                                                          ])
                                                        : _c("label", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.content
                                                                    .message
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm.isShowPopupBan === index
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "chat__box__popup-report"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "icon-close",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.handleClosePopup(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              staticClass:
                                                                "check",
                                                              attrs: {
                                                                icon: "times"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "chat__box__popup-report__item",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.handleReportUser(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "icon"
                                                            },
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "flag"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "report__user"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Report " +
                                                                  _vm._s(
                                                                    item.sender
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "chat__box__popup-report__item",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.handleBlocktUser(
                                                                item.sender
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "icon"
                                                            },
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "ban"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Block " +
                                                                  _vm._s(
                                                                    item.sender
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "chat__footer" }, [
                    _c("div", { staticClass: "chat__footer__send_btn" }, [
                      _c("div", { staticClass: "count__string" }, [
                        _vm._v(_vm._s(_vm.message.length) + "/200")
                      ]),
                      _c(
                        "div",
                        { staticClass: "chat__footer__send_btn__inner" },
                        [
                          _c(
                            "span",
                            { staticClass: "chat__footer__emoji" },
                            [
                              _c("emoji-popup", {
                                on: {
                                  select: _vm.addEmoji,
                                  "send-emoji": _vm.sendSticker
                                }
                              })
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.message,
                                expression: "message"
                              }
                            ],
                            staticClass: "chat__footer__input_box",
                            attrs: {
                              disabled: !_vm.isWebsocketReady,
                              type: "text",
                              placeholder: _vm.isWebsocketReady
                                ? "Say somethings ..."
                                : "Connecting ..."
                            },
                            domProps: { value: _vm.message },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleSendMessage($event)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.message = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "chat__footer__send_btn__inner__btn",
                              class: {
                                sendable:
                                  _vm.message.trim().length && _vm.connection
                              },
                              attrs: {
                                disabled: !_vm.isWebsocketReady,
                                title: "Send"
                              },
                              on: { click: _vm.handleSendMessage }
                            },
                            [
                              !_vm.isWebsocketReady
                                ? _c("font-awesome-icon", {
                                    staticClass: "fa-pulse fa-1x fa-fw",
                                    attrs: { icon: "spinner" }
                                  })
                                : _c("font-awesome-icon", {
                                    attrs: { icon: "paper-plane" }
                                  })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ])
                ])
          ],
          1
        )
      : _vm._e(),
    !_vm.chatEnabled && !_vm.dismiss && !_vm.loggedIn
      ? _c("div", { staticClass: "container__main" }, [
          _c("div", { staticClass: "btn_container" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "submit" },
                on: { click: _vm.gotoLogin }
              },
              [_vm._v(" Sign in to chat ")]
            ),
            _c(
              "div",
              { staticStyle: { "margin-top": "10px", cursor: "pointer" } },
              [
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        _vm.dismiss = true
                      }
                    }
                  },
                  [_vm._v("dismiss")]
                )
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "chat__content__head" }, [
      _c("p", [_vm._v("Welcome to the poker chat room !")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }