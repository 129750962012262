<template>
  <div class="banner_container">
    <div v-html="document.content" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RegisterPromoBanner',
  data() {
    return {}
  },
  async mounted() {
    await this.$store.dispatch('GET_DOCUMENT', 'registration')
  },
  computed: {
    ...mapGetters(['getDocuments']),
    document() {
      const document = this.getDocuments.filter(
        (item) => item.label === 'registration'
      )
      if (document.length > 0) {
        return document[0]
      } else {
        return {
          id: '',
          label: '',
          content: ''
        }
      }
    }
  },
  methods: {
    ...mapActions(['GET_DOCUMENT'])
  }
}
</script>

<style scoped lang="scss">
.banner_container {
  max-width: 600px;
  margin: 20px 0px;
  background-color: $poker-accent !important;
  border-radius: 20px;
}
</style>
