var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TrainingVideoPlayer",
    { attrs: { bgGradient: true, showVideoInitially: true } },
    [
      _c(
        "div",
        {
          staticClass:
            "absolute right-0 bottom-1 md:bottom-7 left-0 flex justify-center items-center cursor-pointer"
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("div", { staticClass: "text-sm md:text-6xl mb-1 md:mb-3" }, [
              _vm._v(" Elevate Your Game ")
            ]),
            _c("div", { staticClass: "opacity-50 text-xs md:text-xl" }, [
              _vm._v(" Unlock your poker potential with our"),
              _c("br"),
              _vm._v(" new poker training package. ")
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }