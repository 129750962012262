var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portal", { attrs: { to: "modal-outlet" } }, [
    _c(
      "div",
      {
        staticClass:
          "fixed z-50 inset-0 flex items-center justify-center w-full min-h-screen",
        attrs: { role: "dialog", "aria-modal": "true" }
      },
      [
        _c("div", {
          staticClass:
            "fixed inset-0 bg-black bg-opacity-50 transition-opacity",
          attrs: { "aria-hidden": "true" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        }),
        _c(
          "div",
          {
            staticClass: "relative w-full m-3",
            class: _vm.isFull ? "h-full lg:w-5/12" : "lg:w-3/12"
          },
          [_vm._t("default")],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }