var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "RulesModal",
        { UserNameModal: _vm.currentStep === "username-step" }
      ]
    },
    [
      _vm.currentStep === "rules-step"
        ? _c(
            "div",
            [
              _c("p", { staticClass: "RulesModal--Header" }, [
                _vm._v("CHAT RULES")
              ]),
              _vm._l(_vm.rules, function(rule, index) {
                return _c(
                  "p",
                  { key: "rule-" + index, staticClass: "RulesModal--Item" },
                  [_vm._v(" " + _vm._s(rule) + " ")]
                )
              }),
              _c("div", { staticClass: "RulesModal--Gap" }),
              _c(
                "button",
                {
                  staticClass: "RulesModal--Btn",
                  on: { click: _vm.acceptRules }
                },
                [_vm._v(" OK, GOT IT ")]
              )
            ],
            2
          )
        : _vm._e(),
      _vm.currentStep === "username-step"
        ? _c("div", { staticClass: " px-3" }, [
            _c("div", { staticClass: "mb-4 text-lg" }, [
              _vm._v(" Please add your username to start chat ! ")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userName,
                  expression: "userName"
                }
              ],
              attrs: {
                id: "userName",
                type: "text",
                required: "",
                placeholder: "Username",
                autocomplete: "off"
              },
              domProps: { value: _vm.userName },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.userName = $event.target.value
                  },
                  function($event) {
                    _vm.error = ""
                  }
                ]
              }
            }),
            _vm.error
              ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
              : _vm._e(),
            _c(
              "button",
              {
                staticClass:
                  "glowing-btn w-full h-12 mt-4 disabled:cursor-not-allowed ",
                attrs: { type: "submit" },
                on: { click: _vm.updateUserName }
              },
              [_vm._v(" Add ")]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }