var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "text-center flex flex-col md:flex-row justify-center gap-10 md:gap-14 mb-10 md:mb-28"
    },
    _vm._l(_vm.stats, function(stat, i) {
      return _c(
        "div",
        { key: "state_" + i, staticClass: "flex flex-col items-center" },
        [
          _c("div", { staticClass: "text-7xl" }, [
            _vm._v(" " + _vm._s(stat.value) + " ")
          ]),
          _c("div", { staticClass: "text-lg opacity-50 w-32" }, [
            _vm._v(_vm._s(stat.description))
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }