<template>
  <div class="relative overflow-y-hidden">
    <TrainingOvals />

    <div>
      <div class="home-container">
        <div class="hidden lg:block mt-10 mb-5 mx-auto max-w-full md:max-w-5xl">
          <DesktopMainVideo />
        </div>
        <div class="block lg:hidden">
          <MobileMainVideo />
        </div>

        <!--      Join PokerU btn section-->
        <div class="flex flex-col items-center mb-10 md:mb-28 px-5 z-20">
          <div class="opacity-50 text-center mb-10 text-17">
            Elevate your game and gain an edge over the competition with our VIP
            Training<br />Package, specially designed for the most dedicated
            poker enthusiasts.
          </div>
          <router-link
            v-if="!this.isSubscriber"
            :to="{
              name: 'Select a Plan',
              query: { plan: 'training_annual' }
            }"
            class="glowing-btn w-80 h-14 text-base mb-4 z-20"
          >
            Join PokerGO VIP
          </router-link>
          <router-link
            v-else-if="this.subscription.store === 'stripe'"
            :to="{
              name: 'Upgrade',
              query: { plan: 'training_annual' }
            }"
            class="glowing-btn w-80 h-14 text-base mb-4 z-20"
          >
            Upgrade to PokerGO VIP
          </router-link>
        </div>

        <!--      Stat section-->
        <div>
          <Stats />
        </div>

        <div class="mb-20">
          <ToughestPros />
        </div>

        <!--        <div class="mb-12 md:mb-32">-->
        <!--          <ExpansiveLibrary />-->
        <!--        </div>-->

        <div>
          <TrainingEndText />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToughestPros from '../../../components/Training/ToughestPros.vue'
import DesktopMainVideo from '../../../components/Training/DesktopMainVideo.vue'
import MobileMainVideo from '../../../components/Training/MobileMainVideo.vue'
import { mapGetters, mapState } from 'vuex'
import TrainingOvals from '../../../components/Training/TrainingOvals.vue'
import Stats from '@/components/Training/Stats.vue'
import TrainingEndText from '@/components/Training/TrainingEndText.vue'

export default {
  name: 'Training',
  components: {
    TrainingEndText,
    Stats,
    TrainingOvals,
    MobileMainVideo,
    ToughestPros,
    DesktopMainVideo
  },
  head() {
    return {
      title: { inner: 'Watch Exclusive Live Poker Tournaments and TV Shows' },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Stream the world’s largest collection of live poker tournaments and TV shows like the World Series of Poker (WSOP), Poker After Dark, High Stakes Poker, and more on PokerGO.'
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content:
            'Stream the world’s largest collection of live poker tournaments and TV shows like the World Series of Poker (WSOP), Poker After Dark, High Stakes Poker, and more on PokerGO.'
        },
        { hid: 'title', name: 'title', content: 'PokerGO' },
        { hid: 'og:title', name: 'og:title', content: 'PokerGO' },
        {
          hid: 'image',
          name: 'image',
          content:
            (process.env.SITE_URL || 'https://app.pokergo.com') +
            '/logo_white.png'
        },
        {
          hid: 'url',
          name: 'url',
          content: process.env.SITE_URL || 'https://app.pokergo.com'
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: process.env.TITLE || 'PokerGO'
        },
        {
          hid: 'site_name',
          name: 'site_name',
          content: process.env.TITLE || 'PokerGO'
        },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'google-site-verification',
          content: '83seNIc0yB1_pTXXDaexhtoqHz3jD-IxjcnGV7KTc_U'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['isSubscriber']),
    ...mapState({
      subscription: (state) => state.user.subscription
    })
  },
  async mounted() {},
  methods: {}
}
</script>
<style lang="scss">
.home-container {
  margin: 0px 0 60px 0;
  max-width: 100%;
}

.img-shadow {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.19), 0 2px 29px 0 rgba(0, 0, 0, 0.5);
}

.linear-bg-gradient {
  border-radius: 8px;
  background: linear-gradient(178.39deg, rgba(0, 0, 0, 0) 0%, #10181f 100%);
}

.gradient-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80%;
  background-image: linear-gradient(to bottom, transparent, #111820);
}
</style>
