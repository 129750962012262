var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "font-family": "'UniSans-SemiBold'" } }, [
    _c("img", {
      staticClass: "mb-6 rounded-full lg:w-max w-full",
      attrs: { src: _vm.src, alt: "pro's profile pic" }
    }),
    _c("div", { staticClass: "text-tw_yellow mb-1" }, [
      _vm._v(" " + _vm._s(_vm.series) + " ")
    ]),
    _c("div", { staticClass: "mb-1" }, [_vm._v(" " + _vm._s(_vm.name) + " ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }