<script>
import { mapMutations } from 'vuex'
import { localstorage } from '../services/storage/localStorageService'
export default {
  name: 'Logout',
  methods: {
    ...mapMutations(['resetUserStore'])
  },
  mounted() {
    this.resetUserStore()
    localstorage.clearAuth()
    window.location.href = '/'
  }
}
</script>
