<template>
  <div class="video-player-container">
    <div id="trailerPlayer" ref="trailerPlayerRef"></div>
    <font-awesome-icon
      class="fa-2x icon-close"
      icon="times"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
export default {
  name: 'TrailerPlayer',
  props: {
    id: {
      required: true,
      type: String,
      default: ''
    },
    videoSource: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      player: undefined
    }
  },
  mounted() {
    this.initPlayer('https://cdn.jwplayer.com/v2/media/' + this.videoSource)
  },
  destroyed() {
    this.destroyPlayer()
  },
  methods: {
    initPlayer(videoSource) {
      if (this.player !== undefined) {
        console.log('player undefined')
        return
      }
      this.player = window.jwplayer('trailerPlayer')
      this.player.setup({
        playlist: videoSource,
        playbackRateControls: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
        autostart: true
      })
      this.player.on('play', this.onPlayerPlay)
      this.player.on('pause', this.onPlayerPause)
      this.player.on('seeked', this.onSeeked)
      this.player.on('setupError', this.onPlayerSetupError)
      this.player.on('complete', this.onPlayerEnded)
    },
    destroyPlayer() {
      if (this.player) {
        this.onPlayerPause()
        this.player.stop()
        this.player.remove()
        this.player = undefined
      }
    },
    onSeeked() {
      console.log('trailer: onSeeked...')
    },
    onPlayerPlay() {
      console.log('trailer: onPlayerPlay...')
    },
    onPlayerPause() {
      console.log('trailer: onPlayerPause...')
    },
    onPlayerEnded() {
      this.$emit('end')
    },
    onPlayerSetupError(event) {
      console.log('trailer: onPlayerSetupError: ', event)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/app';
.video-player-container {
  position: relative;
}
.icon-close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}
</style>
