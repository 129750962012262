<template>
  <div class="emoji" @click.stop>
    <div class="emoji-btn">
      <font-awesome-icon
        v-if="!isStickerPickerOpen"
        class="check"
        :icon="isEmojiPickerOpen ? 'keyboard' : 'smile-beam'"
        @click.stop="toggleEmojiPicker"
      />
      <i
        style="margin-left: 5px"
        v-if="!isEmojiPickerOpen"
        class="keyboard"
        :class="{
          'fas fa-keyboard': isStickerPickerOpen,
          'far fa-sticky-note': !isStickerPickerOpen
        }"
        @click.stop="toggleStickerPicker"
      ></i>
    </div>
    <StickerPicker @send-emoji="sendSticker" v-if="isStickerPickerOpen" />
    <picker
      v-if="isEmojiPickerOpen"
      title="Pick your emoji…"
      emoji="point_up"
      :include="includedCategory"
      :custom="customEmojis"
      @select="addEmoji"
    />
  </div>
</template>
<script>
import { Picker } from 'emoji-mart-vue'
import StickerPicker from './StickerPicker'

export default {
  components: {
    StickerPicker,
    Picker
  },
  data() {
    return {
      isStickerPickerOpen: true,
      isEmojiPickerOpen: false,
      includedCategory: ['custom'],
      customEmojis: null
    }
  },
  methods: {
    toggleStickerPicker() {
      this.isStickerPickerOpen = !this.isStickerPickerOpen
    },
    toggleEmojiPicker() {
      this.isEmojiPickerOpen = !this.isEmojiPickerOpen
    },
    addEmoji(emoji) {
      this.$emit('select', emoji.native)
    },
    sendSticker(sticker) {
      this.$emit('send-emoji', sticker)
    },
    hidePopup() {
      this.isEmojiPickerOpen = false
      this.isStickerPickerOpen = false
    }
  },
  mounted() {
    document.body.addEventListener('click', this.hidePopup)

    this.customEmojis = [
      {
        id: 'grinning_face',
        name: 'Grinning face',
        colons: ':grinning',
        emoticons: [],
        short_names: ['grinning']
      },
      {
        id: 'rolling_on_the_floor_laughing',
        name: 'rolling on the floor laughing',
        colons: ':rolling_on_the_floor_laughing',
        emoticons: [],
        short_names: ['rolling_on_the_floor_laughing']
      },
      {
        id: 'wink',
        name: 'winking face',
        colons: ':wink:',
        emoticons: [],
        short_names: ['wink']
      },
      {
        id: 'hugging_face',
        name: 'hugging face',
        colons: ':hugging_face:',
        emoticons: [],
        short_names: ['hugging_face']
      },
      {
        id: 'face_with_hand_over_mouth',
        name: 'face with hand over mouse',
        colons: ':face_with_hand_over_mouth:',
        emoticons: [],
        short_names: ['face_with_hand_over_mouth']
      },
      {
        id: 'shushing_face',
        name: 'shushing face',
        colons: ':shushing_face:',
        emoticons: [],
        short_names: ['shushing_face']
      },
      {
        id: 'thinking_face',
        name: 'thinking face',
        colons: ':thinking_face:',
        emoticons: [],
        short_names: ['thinking_face']
      },
      {
        id: 'no_mouth',
        name: 'no mouth',
        colons: ':no_mouth:',
        emoticons: [],
        short_names: ['no_mouth']
      },
      {
        id: 'face with monocle',
        name: 'face_with_monocle',
        colons: ':face_with_monocle:',
        emoticons: [],
        short_names: ['face_with_monocle']
      },
      {
        id: 'confounded_face',
        name: 'confounded face',
        colons: ':confounded:',
        emoticons: [],
        short_names: ['confounded']
      },
      {
        id: 'victory_hand',
        name: 'victory hand',
        colons: ':v:',
        emoticons: [],
        short_names: ['v']
      },
      {
        id: 'crossed_fingers',
        name: 'crossed fingers',
        colons: ':crossed_fingers:',
        emoticons: [],
        short_names: ['crossed_fingers']
      }
    ]
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.hidePopup)
  }
}
</script>
<style>
.emoji {
  width: 100%;
  height: 100%;
  position: relative;
}
.emoji-btn {
  width: 55px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.emoji-mart-category-label {
  display: none;
}
.emoji-mart-category .emoji-mart-emoji span {
  width: 32px !important;
  height: 32px !important;
}
</style>
