<script>
import { mapState } from 'vuex'

export default {
  name: 'SearchFilters',
  data() {
    return {
      formModel: {
        sortField: '',
        sortDirection: ''
      }
    }
  },
  computed: {
    ...mapState({
      searchKeyword: (state) => state.other.searchKeyword
    })
  },
  watch: {
    formModel: {
      handler(value) {
        this.handleFiltersChange(value)
      },
      deep: true
    },
    searchKeyword() {
      this.formModel = {
        sortField: '',
        sortDirection: ''
      }
    }
  },
  methods: {
    handleFiltersChange(filters) {
      this.$emit('handleFiltersChange', filters)
    }
  }
}
</script>

<template>
  <div class="flex gap-3 justify-end my-3">
    <!--    <div class="">-->
    <!--      <select-->
    <!--        v-model="formModel.sortField"-->
    <!--        class="bg-transparent text-white border-0 focus:outline-none"-->
    <!--      >-->
    <!--        <option value="Date">Date</option>-->
    <!--      </select>-->
    <!--    </div>-->
    <div>
      Sort by:
      <select
        v-model="formModel.sortDirection"
        class="bg-transparent text-white border-0 focus:outline-none ml-3"
      >
        <option value="">-</option>
        <option value="DESC">Date sort desc</option>
        <option value="ASC">Date sort asc</option>
      </select>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
