<template>
  <div class="login-page">
    <div class="columns">
      <div class="column size-4 centered">
        <slot />
        <notifications group="messages" position="bottom right" />
      </div>
    </div>
  </div>
</template>
<script lang="js">

export default {
  name: 'LoginLayout',
}
</script>
<style lang="scss" scoped>
.columns {
  display: flex;
  flex-flow: column;
}
.column {
  display: flex;
  flex: 5;
  justify-content: center;
  $sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

  @each $size in $sizes {
    &.size-#{$size} {
      flex: $size;
    }
  }
}
.logo-container {
  width: 200px;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
}
</style>
