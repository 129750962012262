<template>
  <div :class="classes.countdown">
    <p :class="classes.notation">{{ notation }}</p>
    <div class="countdown-timer">
      <div :class="classes.timerCell">
        <p :class="classes.timerNumber">{{ days }}</p>
        <p :class="classes.timerUnit">DAYS</p>
      </div>
      <div :class="classes.timerCell">
        <p :class="classes.timerNumber">{{ hours }}</p>
        <p :class="classes.timerUnit">HRS</p>
      </div>
      <div :class="classes.timerCell">
        <p :class="classes.timerNumber">{{ mins }}</p>
        <p :class="classes.timerUnit">MINS</p>
      </div>
      <div :class="classes.timerCell">
        <p :class="classes.timerNumber">{{ secs }}</p>
        <p :class="classes.timerUnit">SECS</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'CountdownTimer',
  props: {
    airDate: {
      default: null,
      type: Date
    },
    videoType: {
      default: '',
      type: String
    },
    type: {
      default: 'normal', // values: 'not-subscribe-desktop', 'not-subscribe-mobile', 'normal'
      type: String
    }
  },
  data() {
    return {
      days: 0,
      hours: 0,
      mins: 0,
      secs: 0,
      countdown: null
    }
  },
  computed: {
    classes() {
      return {
        countdown: {
          countdown: true,
          'countdown--vertical':
            this.type === 'normal' || this.type === 'not-subscribe-desktop',
          'countdown--horizontal': this.type === 'not-subscribe-mobile'
        },
        timerCell: {
          'countdown-timer--cell': true,
          'countdown-timer--cell-mobile': this.type === 'not-subscribe-mobile'
        },
        notation: {
          'countdown-notation': true,
          'countdown-notation--mobile': this.type === 'not-subscribe-mobile'
        },
        timerNumber: {
          'timer-cell-number': true,
          'timer-cell-number--normal': this.type === 'normal',
          'timer-cell-number--not-subs-desktop':
            this.type === 'not-subscribe-desktop',
          'timer-cell-number--not-subs-mobile':
            this.type === 'not-subscribe-mobile'
        },
        timerUnit: {
          'timer-cell--unit': true,
          'timer-cell--unit-mobile': this.type === 'not-subscribe-mobile'
        }
      }
    },
    notation() {
      if (this.videoType === 'vod') return 'AVAILABLE IN'
      if (this.videoType === 'live') return 'EVENT STARTS IN'
      return ''
    }
  },
  async mounted() {
    this.countdown = setInterval(() => {
      this.calcTimeDifference()
    }, 1000)
  },
  methods: {
    calcTimeDifference() {
      const duration = moment.duration(moment(this.airDate).diff(moment()))
      let day = Math.floor(
        moment.duration(moment(this.airDate).diff(moment())).asDays()
      )
      this.days = day
      this.hours = duration.hours()
      this.mins = duration.minutes()
      this.secs = duration.seconds()

      if (duration._milliseconds < 500) {
        clearInterval(this.countdown)
        window.location.reload()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.countdown {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.countdown--vertical {
  flex-direction: column;
}
.countdown--horizontal {
  flex-direction: row;
}
.countdown-notation {
  white-space: nowrap;
  font-style: italic;
  margin-bottom: 10px;
}
.countdown-notation--mobile {
  font-size: 12px;
  margin-right: 20px;
  margin-bottom: 0;
}
.countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.countdown-timer--cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
}
.countdown-timer--cell-mobile {
  margin-right: 20px;
}
.countdown-timer--cell:last-child {
  margin-right: 0;
}
.timer-cell-number {
  font-weight: bold;
  margin-bottom: 0;
}
.timer-cell-number--normal {
  font-size: 2.8em;
}
.timer-cell-number--not-subs-desktop {
  font-size: 2em;
}
.timer-cell-number--not-subs-mobile {
  font-size: 1.5em;
}
.timer-cell--unit {
  color: #828282;
  margin-top: -10px;
}
.timer-cell--unit-mobile {
  margin-bottom: 0px;
  margin-top: -5px;
  font-size: 14px;
}
</style>
