<template>
  <div class="page">
    <div class="container">
      <div class="login-box">
        <form @submit.prevent="reset">
          <div>
            <h2>Reset Password</h2>
            <div class="text-field">
              <label>New Password</label>
              <input
                id="username-input"
                v-model="pass"
                :type="showNewPassword ? 'text' : 'password'"
                required
              />
              <span
                class="eye-icon"
                @click="showNewPassword = !showNewPassword"
              >
                <img
                  v-if="!showNewPassword"
                  src="@/assets/images/eye-solid.svg"
                  alt=""
                  style="height: 20px"
                />
                <img
                  v-if="showNewPassword"
                  src="@/assets/images/eye-slash-solid.svg"
                  alt=""
                  style="height: 20px"
                />
              </span>
            </div>
            <div class="text-field">
              <label>Confirm Password</label>
              <input
                id="password-input"
                v-model="confirmPass"
                :type="showConfirmPassword ? 'text' : 'password'"
                required
              />
              <span
                class="eye-icon"
                @click="showConfirmPassword = !showConfirmPassword"
              >
                <img
                  v-if="!showConfirmPassword"
                  src="@/assets/images/eye-solid.svg"
                  alt=""
                  style="height: 20px"
                />
                <img
                  v-if="showConfirmPassword"
                  src="@/assets/images/eye-slash-solid.svg"
                  alt=""
                  style="height: 20px"
                />
              </span>
            </div>
            <div>
              <p v-if="errorMessage !== ''" class="p-error">
                <font-awesome-icon icon="times" />
                {{ errorMessage }}
              </p>
            </div>
          </div>
          <br />
          <button class="button" type="submit">Reset</button>
          <span class="spacer-small">|</span>
          <router-link :to="'/register'">Register</router-link>
        </form>
      </div>
    </div>
    <generic-modal
      v-if="modalClosed === false"
      @yes="redirect()"
      @no="modalClosed = true"
      :text="
        `Your password has been reset. Please return to the ${usertype} and login with your new password.`
      "
    />
  </div>
</template>

<script>
import { settings } from '@/library/variables'
import GenericModal from '../components/GenericModal.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'LoginView',
  layout: 'login',
  components: {
    GenericModal
  },
  data() {
    return {
      user: '',
      pass: '',
      errorMessage: '',
      confirmPass: '',
      showNewPassword: false,
      showConfirmPassword: false,
      modalClosed: true,
      usertype: ''
    }
  },
  computed: {
    ...mapGetters(['getPropertyId']),
    property() {
      return this.getPropertyId
    },
    passwordKey() {
      return this.$route.hash.substr(1)
    }
  },
  watch: {},
  mounted() {
    this.usertype = this.$route.query.type ? 'game' : 'login page'
  },
  methods: {
    async reset() {
      this.modalClosed = true
      if (this.pass.length < 8) {
        this.errorMessage = 'Password must be 8 or more characters'
      } else if (this.pass !== this.confirmPass) {
        this.errorMessage = 'Password and confirm password do not match'
      } else {
        let payload = {
          data: {
            old_password: this.passwordKey,
            new_password: this.pass,
            new_password_confirm: this.confirmPass
          },
          type: this.usertype
        }
        await this.$store
          .dispatch('account/ResetPassword', payload)
          .then((value) => {
            if (value.status !== 200) {
              this.$toasted.error(
                'Error cannot reset password please try again'
              )
              return
            }
            if (this.usertype != undefined) {
              this.modalClosed = false
            } else {
              this.$router.push('/login')
            }
          })
      }
    },
    redirect() {
      this.$router.push('/login')
    }
  }
}
</script>
<style scoped lang="scss">
.spacer-small {
  padding-right: 5px;
  padding-left: 5px;
}

.container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;

  .p-error {
    padding-top: 10px;
    margin-bottom: 0;
    color: $red;
  }

  .login-box {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    width: 30%;
    min-width: 300px;
    max-width: 500px;
    height: 400px;
    padding: 20px;

    .text-field {
      margin-top: 10px;
      position: relative;
    }

    .eye-icon {
      position: absolute;
      top: -42px;
      right: 10px;
    }

    .error-message {
      margin-top: 5px;
      font-size: 12px;
      color: $red;
    }
  }
}
</style>
