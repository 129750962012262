<template>
  <div v-show="isShow && playerLoaded" class="modal-bg">
    <div
      v-click-outside="closeModal"
      class="content"
      :class="{ cta: !isPlaying }"
    >
      <div v-if="playerLoaded && isPlaying" class="controls">
        <div class="controls__select">
          <label class="checkbox-container">
            <input type="checkbox" id="do-not-show" v-model="neverShow" />
            <div class="checkmark"></div>
          </label>
          <label for="do-not-show" class="do-not-show-text"
            >Do not show this again!</label
          >
          <i class="fas fa-times" @click="neverShowUntilNextDay = true" />
        </div>
      </div>
      <div v-if="isPlaying" id="videoPlayerModal" class="video-player-modal" />
      <div v-if="!isPlaying" class="c-call-to-action call-to-action">
        <div>
          <img src="@/assets/images/Logo-pokerGOHoldem.svg" />
        </div>
        <a class="btn-cta" href="https://www.pokergoholdem.com" target="_blank">
          <div class="btn-cta__content">
            <h6>Play Now</h6>
            <span>Continue to PokerGO Holdem</span>
          </div>
        </a>
        <a class="btn-cta btn-cta--trans" href="#" @click="closeModal">
          <div class="btn-cta__content">
            <h6>Watch Now!</h6>
            <span>Continue to PokerGO</span>
          </div>
        </a>
        <div class="cta-pop-controls">
          <div class="cta-pop-controls__select">
            <label class="checkbox-container">
              <input type="checkbox" id="do-not-show" v-model="neverShow" />
              <div class="checkmark"></div>
            </label>
            <label for="do-not-show" class="do-not-show-text">
              <span>Do not show this again!</span>
            </label>
          </div>
          <!-- <a class="cta-pop-controls__link" href="#" @click="neverShow = true"
            >Continue to PokerGO</a
          > -->
          <!-- <i class="fas fa-times" @click="closeModal" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  props: {
    videoSource: {
      type: String,
      default: 'UKG0cEU4'
    }
  },
  data: () => ({
    player: null,
    playerLoaded: false,
    isShow: true,
    isPlaying: true,
    neverShow: false,
    neverShowUntilNextDay: false
  }),
  computed: {
    ...mapGetters(['isShowPghModal']),
    cookieIsShowPghModal() {
      if (this.$cookies.isKey('pghPreference')) {
        const pghPreference = this.$cookies.get('pghPreference')
        const now = moment()
        const closedAt = moment(pghPreference.closedAt)
        const timeDiff = moment.duration(now.diff(closedAt)).asDays()
        return pghPreference.neverShow ? false : timeDiff >= 1 ?? true
      } else {
        return true
      }
    }
  },
  mounted() {
    this.isShow = false
    //this.isShow = this.isShowPghModal && this.cookieIsShowPghModal

    if (this.isMobile) {
      this.playerLoaded = true
    }

    if (this.isShow) {
      this.player = window.jwplayer('videoPlayerModal')
      const videoUrl = `https://cdn.jwplayer.com/v2/media/${this.videoSource}`

      this.player.setup({
        playlist: videoUrl,
        autostart: true
      })
      this.player.on('play', this.onPlayerPlay)
      this.player.on('complete', this.onPlayerEnded)
    }
  },
  methods: {
    isMobile() {
      return this.windowWidth <= 768
    },
    onPlayerPlay() {
      this.playerLoaded = true
      this.$store.commit('setPghPlayStatus', true)
    },
    onPlayerEnded() {
      this.player.remove()
      this.isPlaying = false
    },
    closeModal() {
      // hide modal
      this.isShow = false
      this.$store.commit('setPghPlayStatus', false)

      if (this.player && this.isPlaying) {
        this.player.pause(true)
      }

      if (this.neverShow) {
        // this.$gtag.event({
        //   eventCategory: 'PGHDialogNeverShow',
        //   eventAction: 'view',
        //   eventLabel: 'pgh_nevershow',
        //   eventValue: 1
        // })
      }

      // save preference data to store
      this.$store.commit('setPghPreference', {
        closedAt: moment().toISOString(),
        neverShow: this.neverShow
      })
    }
  },
  watch: {
    neverShowUntilNextDay() {
      const pghPreference = {
        closedAt: moment().toISOString(),
        neverShow: this.neverShow
      }
      //check if it changes and hide modal
      this.$cookies.set('pghPreference', pghPreference, '365d')
      this.$store.commit('setPghPreference', pghPreference)

      this.isShow = false
      this.$store.commit('setPghPlayStatus', false)
    },
    neverShow() {
      const pghPreference = {
        closedAt: moment().toISOString(),
        neverShow: this.neverShow
      }
      this.$cookies.set('pghPreference', pghPreference, '365d')
      this.$store.commit('setPghPreference', pghPreference)

      this.isShow = false
      this.$store.commit('setPghPlayStatus', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  .do-not-show-text {
    opacity: 0.6;
    font-weight: 600;
  }

  .content {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    background: transparent;
    width: 1000px;
    height: 600px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 15px;

    .cta-pop-controls {
      display: flex;
      flex-direction: row;
      gap: 30px;
      width: 100%;
      justify-content: space-around;

      &__select {
        display: flex;
        gap: 10px;
      }

      &__link {
        color: white;
        // border-bottom:1px solid white;
        text-decoration: underline !important;
      }
    }

    .controls {
      // position: absolute;
      display: flex;
      align-items: flex-end;
      top: 40px;
      right: 16px;
      justify-content: flex-end;
      width: 100%;

      &__select {
        display: flex;
        align-items: center;

        i.fa-times {
          height: 40px;
          background: #1c232a;
          width: 40px;
          border-radius: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .checkmark {
        position: relative;
      }

      input {
        display: block;
      }

      label {
        margin-left: 12px;
        font-size: 14px;
      }

      i {
        font-size: 20px;
        margin-left: 24px;
        cursor: pointer;
      }
    }

    .call-to-action {
      width: inherit;
      height: 250px;
      display: flex;
      gap: 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center !important;
      // background: $poker-secondary;

      .checkmark {
        position: relative;
      }
    }

    .c-call-to-action {
      justify-content: flex-start;

      .btn-cta {
        width: 80%;
        display: flex;
        padding: 0;
        height: 120px;
        align-items: center;

        &--trans {
          background: rgba(0, 0, 0, 0.8) !important;
          border: 1.5px solid rgba(255, 255, 255, 0.2) !important;
        }

        img {
          height: 100%;
          width: 35%;
          object-fit: cover;
          border-top-left-radius: inherit;
          border-bottom-left-radius: inherit;
        }

        &__content {
          padding: 15px 20px;
          font-size: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          &--transparent {
            background: transparent !important;
            border-color: #f7f7f7;
          }

          h6 {
            font-size: 18px;
            font-weight: 600;
          }

          span {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .content.cta {
    width: 500px;
    height: 100%;

    @media (max-width: 420px) {
      width: 100%;
      margin: 10px;
    }
  }
}

.btn-cta {
  // margin-top: 24px;
  padding: 13px 23px;
  background: $poker-accent;
  text-decoration: none;
  border-radius: 4px;
  color: #ffffff;
  font-family: 'UniSans';
  font-size: 14px;
  letter-spacing: 0;
}
.btn-cta:hover {
  background: #821f94;
  transition: 0.3s ease-in;
}

/** Checkbox styling */
.checkbox-container {
  display: block;
  padding-left: 4px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background: transparent;
  border: 2px solid gray;
  border-radius: 2px;
}
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $poker-accent;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
