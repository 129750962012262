var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes.countdown }, [
    _c("p", { class: _vm.classes.notation }, [_vm._v(_vm._s(_vm.notation))]),
    _c("div", { staticClass: "countdown-timer" }, [
      _c("div", { class: _vm.classes.timerCell }, [
        _c("p", { class: _vm.classes.timerNumber }, [_vm._v(_vm._s(_vm.days))]),
        _c("p", { class: _vm.classes.timerUnit }, [_vm._v("DAYS")])
      ]),
      _c("div", { class: _vm.classes.timerCell }, [
        _c("p", { class: _vm.classes.timerNumber }, [
          _vm._v(_vm._s(_vm.hours))
        ]),
        _c("p", { class: _vm.classes.timerUnit }, [_vm._v("HRS")])
      ]),
      _c("div", { class: _vm.classes.timerCell }, [
        _c("p", { class: _vm.classes.timerNumber }, [_vm._v(_vm._s(_vm.mins))]),
        _c("p", { class: _vm.classes.timerUnit }, [_vm._v("MINS")])
      ]),
      _c("div", { class: _vm.classes.timerCell }, [
        _c("p", { class: _vm.classes.timerNumber }, [_vm._v(_vm._s(_vm.secs))]),
        _c("p", { class: _vm.classes.timerUnit }, [_vm._v("SECS")])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }