<script>
import TrainingVideoPlayer from './TrainingVideoPlayer.vue'

export default {
  name: 'MobileMainVideo',
  components: { TrainingVideoPlayer },
  data() {
    return {
      showPlayer: false
    }
  },
  methods: {
    handleClickPlayVideo() {
      this.showPlayer = true
    }
  }
}
</script>

<template>
  <div>
    <div class="relative overflow-hidden" v-if="!showPlayer">
      <img
        style="height: 455px"
        class="w-full object-cover"
        src="@/assets/images/training/video-thumbnails/main-video-thumbnail.png"
      />

      <div class="gradient-banner"></div>

      <div class="absolute top-40 w-full z-10">
        <div class="flex flex-col items-center gap-3">
          <div @click="handleClickPlayVideo">
            <img
              src="@/assets/images/training/play-btn.png"
              class="w-20 mb-1 mx-auto"
            />
            <div class="text-center text-sm opacity-60">Play Video</div>
          </div>

          <div class="text-center text-4xl leading-[52px]">
            Elevate Your<br />Game
          </div>
          <div class="text-xl text-center">
            Unlock your poker potential with our<br />
            new poker training package.
          </div>
        </div>
      </div>
    </div>
    <div class="mt-28 mb-10 z-10" style="min-height: 310px" v-else>
      <TrainingVideoPlayer :showVideoInitially="true" v-if="showPlayer" />
    </div>
  </div>
</template>
