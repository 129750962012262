<template>
  <div>
    <div v-if="entitled">
      <video-player
        :key="$route.fullPath"
        v-if="fetched && onAir"
        v-show="!trailerPlaying"
        :entitled="entitled"
        :id="videoId"
        :overlay-image="overlayImage()"
        :autoplay="!hasTrailer"
        @streamEnded="streamLive = false"
        @streamStarted="streamLive = true"
        :video-data="videoData"
        :trailerPlayering="trailerPlaying"
      />
      <img
        v-if="!onAir && !trailerPlaying"
        :src="thumbnailImage"
        class="overlay"
      />
      <CountdownTimer
        v-if="!onAir && fetched && !trailerPlaying"
        :airDate="airDate"
        :videoType="type"
      />
    </div>
    <div v-if="!entitled" class="login-wrapper">
      <img :src="thumbnailImage" class="background-image" />
      <form v-if="!isAuthenticated" @submit.prevent="login" class="form">
        <div class="login-box-container">
          <h2>Sign In</h2>
          <div class="text-field">
            <label>Email Address</label>
            <input id="username-input" v-model="email" type="email" required />
          </div>
          <div class="text-field">
            <label>Password</label>
            <input
              id="password-input"
              v-model="pass"
              :type="showPassword ? 'text' : 'password'"
              required
            />
            <span class="eye-icon" @click="showPassword = !showPassword">
              <img
                v-if="!showPassword"
                src="@/assets/images/eye-solid.svg"
                alt=""
                style="height: 20px"
              />
              <img
                v-if="showPassword"
                src="@/assets/images/eye-slash-solid.svg"
                alt=""
                style="height: 20px"
              />
            </span>
          </div>
          <br />
          <span v-if="errorMessage !== ''" class="error-message">
            <font-awesome-icon icon="times" />
            {{ errorMessage }}
          </span>
        </div>
        <button class="button" type="submit">Submit</button>
        <a
          :href="siteUrl + '/register'"
          target="__blank"
          class="button btn-signup"
        >
          Signup
        </a>
      </form>
      <div v-if="isAuthenticated" class="content-area">
        <p class="notation-title">
          SUBSCRIBE TO UNLOCK VIDEO
        </p>
        <p class="notation-desc">
          Watch 100+ days a year of LIVE poker tournaments, including the WSOP
          and enjoy exclusive, NEW poker shows such as No Gamble, No Future &
          High Stakes Poker!
        </p>
        <div class="btn-wrapper">
          <a
            :href="siteUrl + '/select-plan'"
            target="__blank"
            class="btn-subscribe"
          >
            SUBSCRIBE NOW
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import moment from 'moment'
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import VideoPlayer from '@/components/VideoPlayer.vue'
import CountdownTimer from '@/components/CountdownTimer'
import { General } from '@/library/general'

export default Vue.extend({
  name: 'VideoPage',
  components: {
    VideoPlayer,
    CountdownTimer
  },
  data() {
    return {
      air_date: '',
      currentIdx: 0,
      currentlyVisible: 0,
      entityEntitled: true,
      endDate: '',
      favorite: false,
      hasTrailer: false,
      trailerPlaying: false,
      fetched: false,
      trailerFetched: false,
      images: [],
      onAir: null,
      pre_air_images: [],
      publish_date: '',
      sideTiles: [],
      streamUpcoming: false,
      streamLive: false,
      streamEnded: false,
      streamEndTime: moment(),
      subheading: '',
      type: '',
      videoData: {},
      trailerVideoSource: {},
      airDate: null,

      email: '',
      pass: '',
      errorMessage: '',
      reload: true,
      showPassword: false,
      timer: null
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    entitled() {
      return this.entityEntitled
    },
    videoId() {
      return this.$route.query.id
    },
    siteUrl() {
      return process.env.SITE_URL || 'https://pokergo.com'
    },
    thumbnailImage() {
      let imageThumbnail = ''
      if(this.pre_air_images){
        const imagePreAir = this.pre_air_images.filter(image => image.label === 'SD')
        if (imagePreAir && imagePreAir.length) {
          imageThumbnail = imagePreAir[0].url
        }
        if (imageThumbnail.length) {
          return imageThumbnail
        }
      }

      const image = this.images.filter(image => image.label === 'SD')
      if (image && image.length)
        return image[0].url

      return ''
    }
  },
  async mounted() {
    /**
     * Save videoID into store when user is not authenticated.
     * This value will be used to redirect url after user subscribe (embed UI)
    */
    if (!this.isAuthenticated) {
      this.$store.commit('setVideoId', this.videoId)
    }
    let payload = {
      type: 'videos',
      id: this.videoId,
    }
    this.$store.dispatch('collections/GetOneEntity', payload)
    .then((res) => {
      if (res.status < 300) {
        const date = new Date(res.data.data.published_at)
        this.airDate = new Date(res.data.data.aired_at)
        const endDate = new Date(res.data.data.ended_at)
        this.streamEndTime = moment(endDate)
        const now = new Date()
        this.onAir = this.airDate <= now

        this.subheading = res.data.data.subheading
        this.images = res.data.data.images
        this.pre_air_images = res.data.data.pre_air_images
        this.type = res.data.data.type
        this.favorite = res.data.data.favorite
        this.hasTrailer = !!res.data.data.trailer_source

        this.entityEntitled = res.data.data.entitled
          ? res.data.data.entitled
          : false

        this.publish_date =
          date.getMonth() + 1 + '-' + date.getDate() + '-' + date.getFullYear()
        this.air_date =
          date.getMonth() + 1 + '-' + date.getDate() + '-' + date.getFullYear()
        if (now < this.airDate) {
          this.streamUpcoming = true
        } else if (now > this.airDate && now < endDate) {
          this.streamLive = true
        } else {
          this.streamEnded = true
        }
        this.videoData = res
        this.fetched = true

        // Set trailerSource to trailerVideo
        if (this.hasTrailer) {
          this.trailerVideoSource = res.data.data.trailer_source
          this.trailerFetched = true
        }
      } else {
        if (res.statusCode === 404) {
          this.$bugsnag.notify(new Error('Video not found'))
        } else {
          this.$bugsnag.notify(new Error(`${res.statusCode}: ${res.title}`))
        }
      }
    })

    this.timer = setInterval(() => {
      const supportEl = document.querySelector('#freshworks-container')
      if (supportEl) {
        supportEl.style.display = 'none'
        clearInterval(this.timer)
      }
    }, 100);
  },
  beforeDestroy() {
    const supportEl = document.querySelector('#freshworks-container')
    if (supportEl) supportEl.style.display = 'block'
  },
  methods: {
    ...mapActions(["REQUEST_LOGIN"]),
    overlayImage(entity) {
      entity = entity || this
      let tmp = General.getOverlayImage(
        entity.air_date,
        entity.pre_air_images,
        entity.images
      )
      return tmp
    },
    login() {
      this.errorMessage = ''
      if (this.email !== "" && this.pass !== "") {
        try {
          this.REQUEST_LOGIN({ email: this.email, password: this.pass, code: this.$route.params.code }).then((response) => {
            if (response.status === 200) {
              if (this.$store.state.videoId && this.$store.state.videoId !== undefined)
                this.$router.push({ name: 'VideoEmbed', query: { id: this.$store.state.videoId } }).catch(e => {})
              else
                this.$router.push({ path: '/schedule' })
              if (this.reload) {
                window.location.reload()
              }
            } else if (response.statusCode === 401) {
              this.errorMessage = 'Username and/or password is incorrect.'
            } else {
              this.$bugsnag.notify(new Error(`${response.statusCode}: ${response.message}`))
              this.$toasted.error(
                response.message
              )
            }
          })
        } catch (err) {
          this.$bugsnag.notify(new Error('Login error'))

          if (!err.response) {
            return
          }
          if (err.response.data.errors[0].code === 401) {
            this.errorMessage = 'Username and/or password is incorrect.'
          } else {
            console.log(err.response.data.errors[0].detail)
          }
        }
      } else {
        this.message = "A username and password must be present";
      }
    }
  }
})
</script>
<style lang="scss" scoped>
.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .login-box-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    min-width: 300px;
    max-width: 400px;
    text-align: left;

    h2 {
      padding-top: 0;
      margin-top: 0;
    }
    .text-field {
      margin-top: 10px;
      position: relative;

      .eye-icon {
        position: absolute;
        top: 30px;
        right: 10px;
      }
    }
    .error-message {
      font-size: 14px;
      height: 3em;
      display: flex;
      align-items: center;
      color: $red;

      svg {
        margin-right: 8px;
        margin-bottom: 4px;
      }
    }
  }
  .btn-signup {
    margin-left: 20px;
  }

  .background-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
  }
  .content-area {
    text-align: center;
    margin-top: 30px;
    z-index: 300;

    .notation-title {
      font-family: 'UniSans-Bold-Italic';
      font-size: 2em;
    }

    .notation-desc {
      width: 80%;
      margin: auto;
      font-size: 1.5em;
    }

    .btn-wrapper {
      margin-top: 20px;

      .btn-subscribe {
        font-family: 'UniSans-SemiBold';
        background: $poker-accent;
        padding: 13px 40px 10px 40px;
        width: 250px;
        border-radius: 50px;
        outline: none;
        border: none;
        color: white;
      }
    }
  }
}
.overlay {
  width: 100%;
  height: 100%;
}
</style>
