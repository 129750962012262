<script>
import PokerGoProPersonal from './PokerGoProPersonal.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ArrowButton from './ArrowButton.vue'
export default {
  name: 'ToughestPros',
  components: { ArrowButton, PokerGoProPersonal, Swiper, SwiperSlide },
  data() {
    return {
      pros: [
        {
          series: 'PLO',
          name: 'Phil Galfond'
        },
        {
          series: 'MTT',
          name: 'Jason Koon'
        },
        {
          series: 'NLH Cash',
          name: 'Dan Cates'
        },
        {
          series: 'MTT',
          name: 'Justin Bonomo'
        },
        {
          series: 'MTT',
          name: 'Jeremy Ausmus'
        },
        {
          series: 'Mixed Games',
          name: 'Brian Hastings'
        },
        {
          series: 'NLH Cash',
          name: 'Kevin Rabichow'
        }
      ],
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          300: {
            spaceBetween: 10,
            slidesPerView: 2.5
          },
          1024: {
            slidesPerView: 4
          }
        }
      }
    }
  }
}
</script>

<template>
  <div class="text-center">
    <div class="text-4xl mb-3">
      Elite Pros
    </div>
    <div class="opacity-50 text-17 mb-10 px-2">
      From No Limit Hold’em to Pot Limit Omaha, tournaments to mixed games,
      we’ve<br />
      got you covered with the best Pros in the industry.
    </div>

    <div style="max-width: 1000px" class="mx-auto">
      <Swiper :options="swiperOptions">
        <SwiperSlide v-for="(pro, i) in pros" :key="i">
          <PokerGoProPersonal
            :src="require(`@/assets/images/training/pros/${pro.name}.png`)"
            :series="pro.series"
            :name="pro.name"
          />
        </SwiperSlide>
        <div
          style="top: 95px; left:20px"
          class="swiper-button-prev"
          slot="button-prev"
        >
          <ArrowButton direction="left" class="hidden lg:block" />
        </div>
        <div
          style="top: 95px; right:20px"
          class="swiper-button-next"
          slot="button-next"
        >
          <ArrowButton direction="right" class="hidden lg:block" />
        </div>
      </Swiper>
    </div>
  </div>
</template>

<style>
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: '';
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: '';
}
</style>
