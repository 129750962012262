<template>
  <div class="register-container centered">
    <form class="register-form" @submit.prevent="login">
      <div class="form-row">
        <div class="col">
          <div class="form-control">
            <input
              id="email"
              v-model="value.email"
              type="email"
              required
              autocomplete="new-password"
              placeholder="Email Address"
            />
          </div>
          <p class="email-error" v-if="error">{{ error }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-control">
            <input
              id="firstName"
              v-model="value.first_name"
              type="text"
              autocomplete="new-password"
              placeholder="First Name"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-control">
            <input
              id="lastName"
              v-model="value.last_name"
              type="text"
              autocomplete="new-password"
              placeholder="Last Name"
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div>
            <input
              id="password"
              v-model="value.password"
              :type="showPassword ? 'text' : 'password'"
              required
              autocomplete="new-password"
              placeholder="Create Password"
            />
            <span class="eye-icon" @click="showPassword = !showPassword">
              <img
                v-if="!showPassword"
                src="@/assets/images/eye-solid.svg"
                alt=""
                style="height: 20px"
              />
              <img
                v-if="showPassword"
                src="@/assets/images/eye-slash-solid.svg"
                alt=""
                style="height: 20px"
              />
            </span>
            <span class="description" :class="{ error: !isValidPassword }">
              Minimum 8 characters
            </span>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 25px">
            <vue-recaptcha
              ref="recaptcha"
              :sitekey="$recaptureKey"
              @verify="verifyMethod"
              @expired="onCaptchaExpired"
            ></vue-recaptcha>
          </div>
        </div>
      </div>

      <div
        class="message"
        style="display:flex; gap:10px; align-items:center; margin-top: 15px;justify-content: center"
      >
        <input
          class="input_checkbox"
          required
          v-model="agree"
          type="checkbox"
          id="agree"
        />
        <label
          for="agree"
          style="text-transform:capitalize;text-transform: none;font-size: 0.8rem;font-family: 'UniSans';"
        >
          By creating an account, you agree to the
          <a
            href="/terms-and-conditions"
            target="_blank"
            class=""
            style="color:#9f26b5"
          >
            Terms of Use
          </a>
          and
          <a href="/privacy-policy" target="_blank" style="color:#9f26b5">
            Privacy Policy.
          </a>
        </label>
      </div>

      <div class="mt-3">
        <small v-if="!isValidForm" class="text-red-700 ">
          Terms of Use and Privacy Policy needs to be selected to proceed
        </small>
      </div>

      <div class="form-row">
        <div class="col">
          <hr class="line" />
          <button
            :disabled="!isValidForm"
            class="glowing-btn w-full h-12 mb-1"
            type="submit"
          >
            Create account
          </button>
        </div>
      </div>
      <div class="text-center mt-3 text-sm">
        <span class="isAccount">Already have an account?</span><br />
        <router-link
          to="/login"
          class="subscribe underline text-tw_primary-300"
        >
          Login
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'
export default {
  name: 'RegisterForm',
  components: { VueRecaptcha },
  data() {
    return {
      showPassword: false,
      agree: ''
    }
  },
  props: {
    error: {
      type: String,
      default: undefined
    },
    value: {
      type: Object,
      default: () => ({
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        recapture: ''
      })
    }
  },
  computed: {
    isValidPassword() {
      return this.value.password.length >= 8
    },
    isValidForm() {
      return this.isValidPassword && this.agree && this.value.recapture
    }
  },
  watch: {
    value() {
      this.$emit('change', this.value)
    },
    'value.email': {
      handler() {
        this.$store.commit('checkoutControls', { error: undefined })
      }
    }
  },
  methods: {
    login() {
      this.$emit('login')
    },
    verifyMethod(res) {
      this.value.recapture = res
    },
    onCaptchaExpired() {
      this.value.recapture = ''
      this.$refs.recaptcha.reset()
    }
  }
}
</script>
<style scoped lang="scss">
.register-container {
  width: 70%;
  max-width: 700px;
  min-width: 500px;
  margin: 3rem;
  @media (max-width: 768px) {
    width: 80%;
    max-width: 80%;
    min-width: 80%;
  }
  @media (max-width: 400px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

.eye-icon {
  position: relative;
  left: calc(100% - 40px);
  top: -32px;
  cursor: pointer;
}

.description {
  display: inline-block;
  margin-left: -20px;
  font-size: 14px;
}

.line {
  margin-top: 10px;
  margin-bottom: 10px;
  color: $light-gray-30;
  border: 1px solid;
}

@media screen and (max-width: 768px) {
  .form-row {
    .message {
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 400px) {
  .register-container {
    min-width: 100%;
  }
  .form-row {
    .message {
      font-size: 10px;
    }
  }
}
.form-control {
  padding: 0 !important;
  border: none !important;
  border-radius: 2.25rem;
}
.email-error {
  color: red;
  padding: 5px;
  margin: 0;
}
.input_checkbox {
  @media (max-width: 768px) {
    transform: scale(1.6);
    margin-left: 10px;
    margin-right: 8px;
  }
}
</style>
