<template>
  <div class="cancel-page">
    <div class="cancel-page__container">
      <div class="cancel-page__container--header">
        <h1>Technology can be frustrating</h1>
        <p>
          Let us know how we can help you.
        </p>
      </div>

      <div class="cancel-page__container--form">
        <p class="cancel-page__container--form--label">
          Try these solutions for common technology issues
        </p>
        <div class="cancel-page__container--form--content">
          <div
            v-for="(item, index) in documents"
            :key="item.label"
            class="accordian-item"
          >
            <div
              class="accordian-item__header"
              :class="{ active: activeIndex === index }"
              @click="
                activeIndex === index
                  ? (activeIndex = -1)
                  : (activeIndex = index)
              "
            >
              <div v-html="item.title" class="content" />
              <font-awesome-icon
                :icon="activeIndex === index ? 'chevron-up' : 'chevron-down'"
              />
            </div>
            <div v-if="activeIndex === index" class="accordian-item__content">
              <div v-html="item.content" class="content"></div>
            </div>
          </div>
        </div>
        <button class="btn-cancel" @click="redirect()">
          Keep My Subscription
        </button>
        <button class="btn-continue" @click="specialOffer()">
          Continue
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'CancelStore',
  data: () => ({
    activeIndex: 0
  }),
  async beforeMount() {
    for (let i = 1; i < 5; i++)
      await this.$store.dispatch('GET_DOCUMENT', `cancel-options-${i}`)
  },
  computed: {
    ...mapGetters(['getDocuments']),
    documents() {
      return this.getDocuments.filter((item) =>
        item.label.startsWith('cancel-options-')
      )
    },
    isDiscountAvailable() {
      switch (this.$route.query.store) {
        case 'stripe':
          return this.$route.query.discountable
        case 'paypal':
          return false
        case 'amazonweb':
          return false
        default:
          return false
      }
    }
  },
  methods: {
    ...mapActions(['GET_DOCUMENT']),
    specialOffer() {
      if (this.isDiscountAvailable) {
        // show discount page if discountable
        this.$router.push({
          name: 'CancelSpecialOffer',
          query: {
            reason: this.$route.query.reason,
            other_cancel_reason: this.$route.query.other_cancel_reason
          }
        })
      } else {
        // cancel subscription
        this.cancelSubscription()
      }
    },
    async cancelSubscription() {
      try {
        this.$store.dispatch('account/Cancel').then((rs) => {
          if (rs.status !== 200) {
            this.$toasted.error('Error cannot complete cancel of subscription')
            return
          }
          this.$showMessage({
            title: 'Cancel Subscription Success',
            text: 'Subscription Successfully Canceled'
          })
          this.$router.push('/account/cancel/confirm')
        })
      } catch (err) {
        this.$toasted.error('Error')
      }
    },
    redirect() {
      this.$router.push('/account')
    }
  }
}
</script>
<style lang="scss" scoped>
.cancel-page__container--form {
  .accordian-item {
    border-bottom: 1px solid rgb(233, 233, 233);

    &:last-child {
      border-bottom: none;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      cursor: pointer;
      border-bottom: 1px solid rgb(233, 233, 233);
      font-weight: 600;

      &.active {
        color: $poker-accent;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &__content {
      padding: 20px;
    }
  }
}
</style>
