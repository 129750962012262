var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cancel-page" }, [
    _c("div", { staticClass: "cancel-page__container" }, [
      _vm._m(0),
      _c("div", { staticClass: "cancel-page__container--form" }, [
        _c("p", { staticClass: "cancel-page__container--form--label" }, [
          _vm._v(" Try these solutions for common technology issues ")
        ]),
        _c(
          "div",
          { staticClass: "cancel-page__container--form--content" },
          _vm._l(_vm.documents, function(item, index) {
            return _c(
              "div",
              { key: item.label, staticClass: "accordian-item" },
              [
                _c(
                  "div",
                  {
                    staticClass: "accordian-item__header",
                    class: { active: _vm.activeIndex === index },
                    on: {
                      click: function($event) {
                        _vm.activeIndex === index
                          ? (_vm.activeIndex = -1)
                          : (_vm.activeIndex = index)
                      }
                    }
                  },
                  [
                    _c("div", {
                      staticClass: "content",
                      domProps: { innerHTML: _vm._s(item.title) }
                    }),
                    _c("font-awesome-icon", {
                      attrs: {
                        icon:
                          _vm.activeIndex === index
                            ? "chevron-up"
                            : "chevron-down"
                      }
                    })
                  ],
                  1
                ),
                _vm.activeIndex === index
                  ? _c("div", { staticClass: "accordian-item__content" }, [
                      _c("div", {
                        staticClass: "content",
                        domProps: { innerHTML: _vm._s(item.content) }
                      })
                    ])
                  : _vm._e()
              ]
            )
          }),
          0
        ),
        _c(
          "button",
          {
            staticClass: "btn-cancel",
            on: {
              click: function($event) {
                return _vm.redirect()
              }
            }
          },
          [_vm._v(" Keep My Subscription ")]
        ),
        _c(
          "button",
          {
            staticClass: "btn-continue",
            on: {
              click: function($event) {
                return _vm.specialOffer()
              }
            }
          },
          [_vm._v(" Continue ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cancel-page__container--header" }, [
      _c("h1", [_vm._v("Technology can be frustrating")]),
      _c("p", [_vm._v(" Let us know how we can help you. ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }