var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-player-container" },
    [
      _c("div", { ref: "trailerPlayerRef", attrs: { id: "trailerPlayer" } }),
      _c("font-awesome-icon", {
        staticClass: "fa-2x icon-close",
        attrs: { icon: "times" },
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }