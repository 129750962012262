<template>
  <div>
    <div
      style="background: #1C232A"
      class="rounded-full w-10 h-10 flex items-center justify-center"
      @click="$emit('click')"
    >
      <i
        class="fas text-white"
        :class="direction === 'right' ? 'fa-chevron-right' : 'fa-chevron-left'"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    direction: {
      type: String,
      default: ''
    }
  },
  computed: {
    classes() {
      return {
        root: {
          'flex items-center rounded-full w-14 h-10 from-transparent to-primary-300': true
        }
      }
    }
  }
}
</script>
