<script>
export default {
  name: 'PlayBtn'
}
</script>

<template>
  <div class="text-center ">
    <div class="w-10 md:w-auto mx-auto">
      <img src="@/assets/images/training/play-btn.png" class="w-full" />
    </div>
    <div class="opacity-50 text-xs">
      Play Video
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
