import Vue from 'vue'
const $fb = {
  options: {
    id: process.env.FBP_ID
  }
}

!(function(f, b, e, v, n, t, s) {
  if (f.fbq) return
  n = f.fbq = function() {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  }
  if (!f._fbq) f._fbq = n
  n.push = n
  n.loaded = !0
  n.version = '2.0'
  n.queue = []
  t = b.createElement(e)
  t.async = !0
  t.src = v
  s = b.getElementsByTagName(e)[0]
  s.parentNode.insertBefore(t, s)
})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
$fb.fbq = window.fbq
$fb.fbq('set', 'autoConfig', false, $fb.options.id)
$fb.fbq('init', $fb.options.id)
$fb.fbq('track', 'PageView')

Vue.prototype.$fb = $fb
