var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-background",
      on: {
        click: function($event) {
          return _vm.$emit("no")
        }
      }
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c(
          "div",
          {
            staticClass: "modal-body",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "modal-head", attrs: { name: "modal-header" } },
              [
                _vm.text === undefined
                  ? _c("p", { staticClass: "modal-heading" }, [
                      _vm._v(
                        " You have unsaved changes. Leave the page without saving them? "
                      )
                    ])
                  : _vm._e(),
                _vm.text !== undefined
                  ? _c(
                      "p",
                      {
                        staticClass: "modal-heading",
                        domProps: { innerHTML: _vm._s(_vm.text) }
                      },
                      [_vm._v(" " + _vm._s(_vm.text) + " ")]
                    )
                  : _vm._e()
              ]
            ),
            _c("div", { attrs: { name: "" } }, [
              _c(
                "button",
                {
                  staticClass: "button is-primary save-button",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$emit("yes")
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }