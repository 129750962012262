<template>
  <div class="plan">
    <span class="plan__period">{{ name }}</span>
    <div>
      <span class="plan__price">${{ price }}</span> / {{ frequency }}
    </div>
    <span class="plan__badge-active" v-if="isSelected">SELECTED</span>
    <button v-else class="plan__btn-select" @click="$emit('click')">
      Select Plan
    </button>
  </div>
</template>
<script lang="js">
import Vue from 'vue'

export default Vue.extend({
  name: 'AreYouSure',
  props: {
    isSelected: {
      type: Boolean,
      required: false,
      default: false
    },
    price: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    frequency: {
      type: String,
      required: false,
      default: ''
    }
  },
})
</script>
<style lang="scss" scoped>
.plan {
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 2em;
  width: 100%;
  max-width: 400px;

  &__badge-active {
    color: $poker-accent;
    font-size: 14px;
    margin-top: 8px;
  }

  &__price {
    font-size: 18px;
    font-weight: bold;
  }

  &__period {
    color: $poker-accent;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    background-color: rgba(159, 38, 181, 0.1);
    padding: 8px 20px;
    border-radius: 50px;
    margin-bottom: 16px;
  }

  &__btn-select {
    padding: 8px 16px;
    border: 2px solid $poker-accent;
    border-radius: 6px;
    background: white;
    color: $poker-accent;
    margin-top: 8px;
  }
}
</style>
