<template>
  <div class="register-plan" :class="[{ highlighted: plan.highlight }]">
    <div class="ribbon" v-if="plan.ribbon">
      <span>{{ plan.ribbon }}</span>
    </div>
    <div
      class="title flex-column-center"
      :class="[{ 'text-white': plan.highlight }]"
      :style="{ background: plan.highlight && '#9f26b5' }"
    >
      {{ plan.name }}
    </div>

    <div v-if="plan.striked" class="offer__price">
      <div class="offer__price--original">
        US$ <span>{{ plan.striked }}</span>
      </div>
      <div class="offer__price--new text-black">
        <div class="offer__price--new_symbol">
          US$
        </div>
        <div class="offer__price--new_value">
          {{ plan.price }}
        </div>
      </div>
    </div>

    <div v-else>
      <div class="offer flex-row-center">
        <div class="currency">US$</div>
        <div class="price">
          {{ plan.price }}
          <div
            class="description flex-column-center"
            v-if="plan.priceDescription"
          >
            {{ plan.priceDescription }}
          </div>
        </div>
      </div>
    </div>

    <div></div>

    <div class="offer__buttons">
      <button
        v-if="showSelectPlanBtn"
        class="select-plan-button button"
        @click="selectPlan"
        :disabled="disableActions"
      >
        <span v-if="!disableActions">Select Plan</span>
        <span v-else>
          <font-awesome-icon icon="spinner" class="fa-pulse" />
        </span>
      </button>

      <button
        v-if="showAcceptNewOfferBtn"
        class="btn-accept"
        @click="$emit('accept-offer')"
        :disabled="disableActions"
      >
        <span v-if="!disableActions">Accept New Offer</span>
        <span v-else>
          <font-awesome-icon icon="spinner" class="fa-pulse" />
        </span>
      </button>
      <button
        v-if="showCancelSubscriptionBtn"
        class="btn-cancel-subscription"
        @click="$emit('cancel-subscription')"
        :disabled="disableActions"
      >
        <span v-if="!disableActions">Cancel Subscription</span>
        <span v-else>
          <font-awesome-icon icon="spinner" class="fa-pulse" />
        </span>
      </button>
    </div>

    <div class="details">
      <div
        v-for="(detailGroup, index) in plan.planDetails"
        :key="`detailGroup_${index}`"
      >
        <div style="font-weight: bold; margin-top:15px;margin-left: 8px">
          {{ detailGroup.title }}
        </div>
        <div
          v-for="(d, i) in detailGroup.details"
          class="detail"
          :key="`${i}_${plan.product_type}_detail`"
        >
          <font-awesome-icon class="check" :icon="d.icon" />
          <span class="text" :style="{ 'font-weight': d.fontWeight }">
            {{ d.text }}
            <slot v-if="d.endSlotName" :name="d.endSlotName" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RegisterPlan',
  data() {
    return {}
  },
  props: {
    plan: {
      type: Object,
      default: () => ({
        details: [],
        name: 'plan name',
        price: '11.11'
      })
    },
    showSelectPlanBtn: {
      type: Boolean,
      default: true
    },
    showAcceptNewOfferBtn: {
      type: Boolean,
      default: false
    },
    showCancelSubscriptionBtn: {
      type: Boolean,
      default: false
    },
    disableActions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayPrice() {
      return String(this.plan.price).split('.')
    }
  },
  methods: {
    selectPlan() {
      this.$emit('selectPlan')
    }
  }
}
</script>
<style scoped lang="scss">
.register-plan {
  display: flex;
  flex-direction: column;
  width: 305px;
  margin: 2rem 1rem 0 1rem;
  position: relative;
  overflow: hidden;
  background-color: white;
  padding: 50px 25px;
  border-radius: 7px;
  &:hover {
    margin-top: 15px;
    margin-bottom: -15px;
    padding: 42px 25px;
    .select-plan-button {
      background-color: $poker-accent !important;
      color: white !important;
    }
  }
  .ribbon {
    margin: 0;
    padding: 0;
    background-color: $red;
    color: white;
    padding: 4px 0;
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'UniSans-Bold-Italic';
    font-size: 11px;
    text-transform: uppercase;
    transform: translateX(0%) translateY(30%) rotate(-45deg);
    transform-origin: top right;
  }
  .ribbon:before,
  .ribbon:after {
    content: '';
    position: absolute;
    top: 0;
    margin: 0 -1px;
    width: 100%;
    height: 100%;
    background-color: $red;
  }
  .ribbon:before {
    right: 100%;
  }
  .ribbon:after {
    left: 100%;
  }
  .title {
    text-transform: uppercase;
    font-family: 'UniSans-Bold';
    color: $poker-accent;
    border-radius: 20px;
    background-color: #f5e9f7;
    width: fit-content;
    height: 40px;
    padding: 10px 20px;
    justify-content: center;
    margin: auto;
  }
  .offer {
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 1rem;
    color: black;
    .currency {
      font-size: 16px;
      position: relative;
      top: -12px;
      right: 5px;
    }
    .price {
      font-size: 60px;
      font-family: 'UniSans-Bold';
      .cents {
        font-size: 25px;
        position: relative;
        top: -25px;
      }
    }
    .promo_price {
      font-size: 40px;
      font-family: 'UniSans-Bold';
      .cents {
        font-size: 25px;
        position: relative;
        top: -25px;
      }
    }
    .striked {
      font-size: 45px;
      text-decoration: line-through;
      margin-right: 15px;
    }
    .description {
      font-family: 'UniSans';
      width: 100%;
      font-size: 11px;
      min-height: 14.84px;
    }
  }
  .details {
    list-style: none;
    color: $black;
    margin-left: 0.5rem;
    font-size: 13px;
    flex-grow: 1;
    .detail {
      text-indent: -1rem;
      padding: 0.5rem 0.5rem;
      // border-bottom: 1px dotted #bababa;
      // &:last-child {
      //   border: none;
      // }
      &.bold {
        font-family: 'UniSans-Bold';
      }
      .check {
        color: $poker-accent;
      }
      .text {
      }
    }
  }
  button {
    font-size: 18px;
    height: 40px;
    line-height: 1.1;
  }
}

.price-wrapper {
  position: relative;
  display: inline-block;
}

.price-slash {
  position: relative;
  width: 100%;
  height: 0;
  border-top: 3px solid red;
  transform: rotate(-16deg);
  top: 29px;
}

.promo_offer {
  margin: 64px 0px 63px 0px !important;
}

@media screen and (max-width: 768px) {
  .register-plan {
  }
}

.highlighted {
  border: 2px solid #ffffff;
  border-radius: 4px;
  box-shadow: inset 0 0 12px 4px #9f26b5, 0 0 8px 4px #9f26b5;
}

.offer__price {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  &--original {
    color: grey;
    span {
      text-decoration: line-through;
    }
  }

  &--new {
    display: flex;
    align-items: center;

    &_symbol {
      color: black;
      font-weight: 600;
      margin-right: 0.5rem;
      margin-left: -2rem;
    }

    &_value {
      font-size: 40px;
      font-weight: bold;
    }
  }

  &--monthly {
    color: grey;
  }
}

.offer__buttons {
  width: 200px;
  margin: auto;
  margin-bottom: 20px;

  .btn-accept {
    width: 100%;
    color: white;
    background-color: #8a0eb1;
    font-size: 1rem;
    height: 45px;
    border: none;
    border-radius: 5px;
    font-weight: 600;
  }

  .btn-cancel-subscription {
    width: 100%;
    color: #8a0eb1;
    background-color: #f6e9fa;
    font-size: 1rem;
    height: 45px;
    border: none;
    border-radius: 5px;
    margin-top: 1rem;
  }
}
</style>
