<script>
import RegisterPlan from '../components/RegisterPlan.vue'
import { mapGetters, mapState } from 'vuex'
import ToughestPros from '@/components/Training/ToughestPros.vue'
import Stats from '@/components/Training/Stats.vue'
import TrainingEndText from '@/components/Training/TrainingEndText.vue'
import { ProductTypes } from '@/library/config/plans'

export default {
  name: 'SelectPlanStep',
  components: { TrainingEndText, Stats, ToughestPros, RegisterPlan },
  data() {
    return {
      showTrainingDetails: false
    }
  },
  computed: {
    ProductTypes() {
      return ProductTypes
    },
    ...mapState({
      controls: (state) => state.checkout.controls,
      plan: (state) => state.checkout.selectedPlan,
      plans: (state) => state.checkout.plans
    }),
    ...mapGetters({ plansWithPromoDiscount: 'plansWithPromoDiscount' }),
    filteredPlans() {
      if (this.$route.query.plan) {
        return this.plansWithPromoDiscount.filter(
          (el) => el.product_type === this.$route.query.plan
        )
      }
      return this.plansWithPromoDiscount
    }
  },
  methods: {
    selectPlan(plan) {
      this.$emit('selectPlan', plan)
    },
    showAllPlans() {
      this.$router.push('/register/select-plan')
    },
    handleClickLearnMore() {
      this.showTrainingDetails = true
      this.$nextTick(() => {
        this.$refs.trainingDetailsSection.scrollIntoView({ behavior: 'smooth' })
      })
    }
  }
}
</script>

<template>
  <div style="scroll-behavior: smooth">
    <div>
      <div class="stretch flex-row-center wrap plan-wrapper">
        <register-plan
          v-for="(p, i) in filteredPlans"
          :plan="p"
          :key="i"
          @selectPlan="selectPlan(p)"
        >
          <template v-slot:learn_more>
            <span
              class="underline cursor-pointer text-tw_primary-300"
              @click="handleClickLearnMore"
            >
              Learn More
            </span>
          </template>
        </register-plan>
      </div>
      <div
        class="text-center"
        v-if="
          this.filteredPlans.length === 1 &&
            this.filteredPlans[0].product_type === ProductTypes.TRAINING_ANNUAL
        "
      >
        <span class="text-lg">Not interested in PokerGO VIP training?</span>
        <br />
        <span
          @click="showAllPlans"
          class="cursor-pointer text-tw_primary-300 underline"
        >
          click for other plans
        </span>
      </div>
    </div>
    <div class="mt-20" v-if="showTrainingDetails" ref="trainingDetailsSection">
      <Stats />
      <ToughestPros class="mb-20" />
      <TrainingEndText :show-btn="false" />
    </div>
  </div>
</template>

<style scoped lang="scss">
html {
  scroll-behavior: smooth;
}
</style>
