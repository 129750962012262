var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "z-20 absolute right-0 top-10 w-52 max-h-72 flex flex-col flex-gap-2 overflow-y-auto bg-white rounded-sm shadow-md"
    },
    [
      _vm.type === "radio"
        ? _c(
            "div",
            _vm._l(_vm.list, function(gameType, index) {
              return _c("GameTypeRadio", {
                key: index,
                attrs: {
                  label: gameType.name,
                  id: gameType.id,
                  value: gameType.name,
                  isChecked: _vm.selectedGameType
                    .map(function(el) {
                      return el.id
                    })
                    .includes(gameType.id),
                  name: "game-type"
                },
                on: {
                  select: function($event) {
                    return _vm.$emit("select", gameType)
                  }
                }
              })
            }),
            1
          )
        : _vm.type === "select"
        ? _c(
            "div",
            _vm._l(_vm.list, function(gameType, index) {
              return _c("GameTypeSelect", {
                key: index,
                attrs: {
                  label: gameType.name,
                  id: gameType.id,
                  value: gameType.name,
                  isChecked: _vm.selectedGameType
                    .map(function(el) {
                      return el.id
                    })
                    .includes(gameType.id),
                  name: "game-type"
                },
                on: {
                  "select-checkbox": function(e) {
                    return _vm.$emit("select-checkbox", {
                      value: e,
                      data: gameType
                    })
                  }
                }
              })
            }),
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }