<template>
  <div class="page">
    <div class="my-list-container" v-if="!entities.length">
      There is no content in your list.
    </div>
    <div v-else class="flex-column">
      <div v-for="entity in entities" :key="entity.id">
        <font-awesome-icon
          class="my-list-trash"
          icon="times"
          size="lg"
          @click="deleteFavorite(entity)"
        />
        <router-link :to="`/${entity.entity_type}s/${entity.id}`" class="row">
          <div class="thumbnail">
            <img :src="overlayImage(entity)" />
            <!-- progress bar -->
            <i
              v-if="getVideoProgressPercent(entity.id)"
              class="progress-bar-bg"
            />
            <i
              v-if="getVideoProgressPercent(entity.id)"
              :style="{
                width: String(getVideoProgressPercent(entity.id)) + '%'
              }"
              class="progress-bar"
            />
            <!---->
          </div>
          <div class="description">
            <div class="title">{{ entity.title }}</div>
            {{ entity.description }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { overlayImage } from '@/mixins'
export default {
  name: 'MyList',
  mixins: [overlayImage],
  data() {
    return {
      entities: []
    }
  },
  computed: {
    ...mapGetters(['getVideoProgressPercent']),
    progressPercent() {
      if (this.getVideoProgressPercent(this.entityId))
        return String(this.getVideoProgressPercent(this.entityId)) + '%'
      return 0
    }
  },
  async mounted() {
    // reset videoId on MyList view
    this.$store.commit('setVideoId', null)

    const resp = await this.$store.dispatch('myList/ListFavorites')
    const favoriteVideos = resp.data.data.videos?.videos || []
    favoriteVideos.forEach((favorite) => (favorite.entity_type = 'video'))
    const favoriteCollections = resp.data.data.collections || []
    favoriteCollections.forEach(
      (favorite) => (favorite.entity_type = 'collection')
    )
    this.entities = [...favoriteVideos, ...favoriteCollections]
    console.log(this.entities)
  },
  methods: {
    deleteFavorite(entity) {
      let payload = {
        id: entity.id,
        type: entity.entity_type + 's',
        propertyID: entity.property_id
      }
      this.$store.dispatch('myList/DeleteFavorite', payload).then(() => {
        this.entities = this.entities.filter((en) => en.id !== entity.id)
      })
    }
  }
}
</script>
<style scoped lang="scss">
.my-list-container {
  display: flex;
  height: calc(100vh - 320px);
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  @media (max-width: 768px) {
    height: calc(100vh - 525px);
  }
}
.my-list-trash {
  position: relative;
  font-size: 30px;
  top: 120px;
  left: 0px;
  cursor: pointer;
}
.row {
  display: flex;
  justify-content: center;
  max-width: 1024px;
  margin-top: 1em;
  margin-bottom: 1em;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.thumbnail {
  position: relative;
  display: flex;
  align-items: center;
  width: 33%;
  padding: 3px;
  background-color: #24292f;
  @media (max-width: 768px) {
    width: 50%;
  }
  img {
    width: 100%;
  }
  .progress-bar-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: #535353;
    width: 100%;
  }
  .progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: $accent-color;
  }
}
.description {
  .title {
    color: #bfbfbf;
    padding-bottom: 1em;
    font-weight: bolder;
  }
  flex: 0 0 50%;
  margin-left: 1em;
  color: #808182;
}
</style>
