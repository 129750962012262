<template>
  <div class="page">
    <div class="container centered">
      <div class="narrow-text-container">
        <div v-html="document.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'PrivacyPolicy',
  computed: {
    ...mapGetters(['getDocuments']),
    document() {
      const document = this.getDocuments.filter(
        (item) => item.label === 'privacy-policy'
      )
      if (document.length > 0) {
        return document[0]
      } else {
        return {
          id: 'privacy-policy',
          label: 'Privacy Policy',
          content: ''
        }
      }
    }
  },
  methods: {
    ...mapActions(['GET_DOCUMENT'])
  },
  async beforeMount() {
    // await this.$store.dispatch('property/getDocument', 'privacy-policy')
    await this.$store.dispatch('GET_DOCUMENT', 'privacy-policy')
  },
  head() {
    return {
      title: {
        inner: 'Privacy Policy | PokerGO'
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'PokerGO cares about your privacy. View our privacy policy to understand how, why, and what data we collect.'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.narrow-text-container {
  width: 100%;
  max-width: 800px;
}
</style>
