import api from '.'
import { instance as axios } from '../plugins/axios'
import { settings } from '@/library/variables'

export const schedule = {
  async getScheduleView(viewName) {
    try {
      return await axios.get(
        '/v2/properties/' + settings.propertyID + '/views/' + viewName
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
