<script>
export default {
  name: 'PokerGoProPersonal',
  props: {
    src: {
      type: String,
      default: ''
    },
    series: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div style="font-family: 'UniSans-SemiBold'">
    <img
      :src="src"
      alt="pro's profile pic"
      class="mb-6 rounded-full lg:w-max w-full"
    />
    <div class="text-tw_yellow mb-1">
      {{ series }}
    </div>
    <div class="mb-1">
      {{ name }}
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
