<template>
  <div class="cancel-page">
    <div class="cancel-page__container" style="width: 568px">
      <div class="cancel-page__container--header">
        <h1>Select Reasons</h1>
        <p>
          Select all that apply
        </p>
      </div>
      <div class="cancel-page__container--form">
        <p class="cancel-page__container--form--label">
          SELECT REASONS
        </p>
        <form class="cancel-page__container--form--content">
          <div
            v-for="(item, index) in cancel_reasons"
            :key="`reason_${index}`"
            class="form-item"
            @click="toggleCheckbox(item.value)"
          >
            <span>{{ item.label }}</span>
            <input
              style="fill: red"
              class="inline-block h-4 w-4 text-tw_primary-300 transition focus:ring-0 cursor-pointer"
              :value="item.value"
              type="checkbox"
              v-model="reason"
              :id="`reason_${item.value}`"
            />
          </div>

          <div
            class="other-reason-input pt-3"
            v-if="reason.includes('Cancel Other')"
          >
            <textarea
              class="other-reason"
              type="text"
              name="other-reason"
              rows="5"
              placeholder="Tell us the reason you're leaving PokerGO..."
              v-model="other_reason"
            />
          </div>
        </form>
        <p class="error">{{ error }}</p>
        <button
          class="glowing-btn w-full h-12 mb-1 mt-5"
          @click="handleClickContinue()"
          type="button"
        >
          Continue
        </button>
        <button class="btn-cancel h-12 mb-5" @click="redirect()">
          Keep my subscription
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../../api'
import { mapState } from 'vuex'
import { cancellationFlow } from '@/library/config/plans'

export default {
  name: 'CancelNewContent',
  data() {
    return {
      cancel_reasons: [
        { value: 'Too expensive', label: 'Too expensive' },
        {
          value: 'Did not like content offering',
          label: 'Did not like content offering'
        },
        {
          value: 'Did not like website/app',
          label: 'Did not like website/app'
        },
        { value: 'Cancel Other', label: 'Other' }
      ],
      error: '',
      reason: [],
      other_reason: null
    }
  },
  computed: {
    ...mapState({
      subscription: (state) => state.user.subscription
    })
  },
  watch: {
    'reason.length'() {
      this.error = ''
    }
  },
  methods: {
    toggleCheckbox(value) {
      const index = this.reason.indexOf(value)
      if (index > -1) {
        this.reason.splice(index, 1)
      } else {
        this.reason.push(value)
      }
    },
    async cancelSubscription() {
      try {
        let payload = {
          reason: this.reason,
          other_cancel_reason: this.other_reason
        }
        this.$store.dispatch('account/Cancel', payload).then((rs) => {
          if (rs.status !== 200) {
            this.$toasted.error(
              'Error cannot complete cancel of subscriptionnnn'
            )
            return
          }
          this.$showMessage({
            title: 'Cancel Subscription Success',
            text: 'Subscription Successfully Canceled'
          })
          this.confirm()
        })
      } catch (err) {
        this.$toasted.error('Error')
      }
    },
    redirect() {
      this.$router.push('/account')
    },
    confirm() {
      this.$router.push('/account/cancel/confirm')
    },
    async handleClickContinue() {
      await api.account.submitCancelReason({
        reason: this.reason,
        other_cancel_reason: this.other_reason
      })
      let store = this.subscription.store

      if (this.reason.length) {
        if (store === 'stripe') {
          if (!cancellationFlow[this.subscription.interval].steps.length) {
            return this.cancelSubscription()
          } else {
            await this.$router.push({
              name: 'CancelSpecialOffer'
            })
          }
          return
        }

        if (store === 'paypal') {
          this.cancelSubscription()
          return
        }
        if (store === 'amazonweb') {
          this.cancelSubscription()
        }
      } else {
        this.error = 'Please select an option'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.form-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 30px 20px 20px;
  border-bottom: 1px solid rgb(226, 212, 212);
  min-width: 100%;

  &--last {
    border-bottom: none;
  }

  &.active {
    background: #9f26b50a;
  }
}

.other-reason-input {
  padding: 0 20px 20px 20px;

  &.active {
    background: #9f26b50a;
  }
}

.error {
  color: red;
  text-align: left;
  font-size: small;
  margin-bottom: -30px;
  margin-top: 30px;
}
</style>
