var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register-container centered" }, [
    _c(
      "form",
      {
        staticClass: "register-form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.login($event)
          }
        }
      },
      [
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value.email,
                    expression: "value.email"
                  }
                ],
                attrs: {
                  id: "email",
                  type: "email",
                  required: "",
                  autocomplete: "new-password",
                  placeholder: "Email Address"
                },
                domProps: { value: _vm.value.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.value, "email", $event.target.value)
                  }
                }
              })
            ]),
            _vm.error
              ? _c("p", { staticClass: "email-error" }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              : _vm._e()
          ])
        ]),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value.first_name,
                    expression: "value.first_name"
                  }
                ],
                attrs: {
                  id: "firstName",
                  type: "text",
                  autocomplete: "new-password",
                  placeholder: "First Name"
                },
                domProps: { value: _vm.value.first_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.value, "first_name", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value.last_name,
                    expression: "value.last_name"
                  }
                ],
                attrs: {
                  id: "lastName",
                  type: "text",
                  autocomplete: "new-password",
                  placeholder: "Last Name"
                },
                domProps: { value: _vm.value.last_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.value, "last_name", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", [
              (_vm.showPassword ? "text" : "password") === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.password,
                        expression: "value.password"
                      }
                    ],
                    attrs: {
                      id: "password",
                      required: "",
                      autocomplete: "new-password",
                      placeholder: "Create Password",
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.value.password)
                        ? _vm._i(_vm.value.password, null) > -1
                        : _vm.value.password
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.value.password,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.value, "password", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.value,
                                "password",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.value, "password", $$c)
                        }
                      }
                    }
                  })
                : (_vm.showPassword ? "text" : "password") === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.password,
                        expression: "value.password"
                      }
                    ],
                    attrs: {
                      id: "password",
                      required: "",
                      autocomplete: "new-password",
                      placeholder: "Create Password",
                      type: "radio"
                    },
                    domProps: { checked: _vm._q(_vm.value.password, null) },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.value, "password", null)
                      }
                    }
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.password,
                        expression: "value.password"
                      }
                    ],
                    attrs: {
                      id: "password",
                      required: "",
                      autocomplete: "new-password",
                      placeholder: "Create Password",
                      type: _vm.showPassword ? "text" : "password"
                    },
                    domProps: { value: _vm.value.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.value, "password", $event.target.value)
                      }
                    }
                  }),
              _c(
                "span",
                {
                  staticClass: "eye-icon",
                  on: {
                    click: function($event) {
                      _vm.showPassword = !_vm.showPassword
                    }
                  }
                },
                [
                  !_vm.showPassword
                    ? _c("img", {
                        staticStyle: { height: "20px" },
                        attrs: {
                          src: require("@/assets/images/eye-solid.svg"),
                          alt: ""
                        }
                      })
                    : _vm._e(),
                  _vm.showPassword
                    ? _c("img", {
                        staticStyle: { height: "20px" },
                        attrs: {
                          src: require("@/assets/images/eye-slash-solid.svg"),
                          alt: ""
                        }
                      })
                    : _vm._e()
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "description",
                  class: { error: !_vm.isValidPassword }
                },
                [_vm._v(" Minimum 8 characters ")]
              )
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "25px"
                }
              },
              [
                _c("vue-recaptcha", {
                  ref: "recaptcha",
                  attrs: { sitekey: _vm.$recaptureKey },
                  on: {
                    verify: _vm.verifyMethod,
                    expired: _vm.onCaptchaExpired
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "message",
            staticStyle: {
              display: "flex",
              gap: "10px",
              "align-items": "center",
              "margin-top": "15px",
              "justify-content": "center"
            }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.agree,
                  expression: "agree"
                }
              ],
              staticClass: "input_checkbox",
              attrs: { required: "", type: "checkbox", id: "agree" },
              domProps: {
                checked: Array.isArray(_vm.agree)
                  ? _vm._i(_vm.agree, null) > -1
                  : _vm.agree
              },
              on: {
                change: function($event) {
                  var $$a = _vm.agree,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.agree = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.agree = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.agree = $$c
                  }
                }
              }
            }),
            _vm._m(0)
          ]
        ),
        _c("div", { staticClass: "mt-3" }, [
          !_vm.isValidForm
            ? _c("small", { staticClass: "text-red-700 " }, [
                _vm._v(
                  " Terms of Use and Privacy Policy needs to be selected to proceed "
                )
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col" }, [
            _c("hr", { staticClass: "line" }),
            _c(
              "button",
              {
                staticClass: "glowing-btn w-full h-12 mb-1",
                attrs: { disabled: !_vm.isValidForm, type: "submit" }
              },
              [_vm._v(" Create account ")]
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "text-center mt-3 text-sm" },
          [
            _c("span", { staticClass: "isAccount" }, [
              _vm._v("Already have an account?")
            ]),
            _c("br"),
            _c(
              "router-link",
              {
                staticClass: "subscribe underline text-tw_primary-300",
                attrs: { to: "/login" }
              },
              [_vm._v(" Login ")]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticStyle: {
          "text-transform": "none",
          "font-size": "0.8rem",
          "font-family": "'UniSans'"
        },
        attrs: { for: "agree" }
      },
      [
        _vm._v(" By creating an account, you agree to the "),
        _c(
          "a",
          {
            staticStyle: { color: "#9f26b5" },
            attrs: { href: "/terms-and-conditions", target: "_blank" }
          },
          [_vm._v(" Terms of Use ")]
        ),
        _vm._v(" and "),
        _c(
          "a",
          {
            staticStyle: { color: "#9f26b5" },
            attrs: { href: "/privacy-policy", target: "_blank" }
          },
          [_vm._v(" Privacy Policy. ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }