import api from '.'
import { instance as axios } from '../plugins/axios'
import { settings } from '@/library/variables'

export const training = {
  async getTrainingFiltersData() {
    try {
      return await axios.get(
        'v2/properties/' + settings.propertyID + '/training/filters'
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getTrainingVideos(payload) {
    try {
      return await axios.post(
        'v2/properties/' + settings.propertyID + '/videos/trainingfilter',
        payload
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
