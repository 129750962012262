var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register flex-column-center" },
    [
      _c("h1", [_vm._v("CHANGE PASSWORD")]),
      _c("div", { staticClass: "password-container" }, [
        _c("div", { staticClass: "section" }, [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.savePassword($event)
                }
              }
            },
            [
              _c("div", { staticClass: "field-group" }, [
                _c("div", { staticClass: "field-item text-field" }, [
                  (_vm.showNewPassword ? "text" : "password") === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newPassword,
                            expression: "newPassword"
                          }
                        ],
                        attrs: {
                          id: "newpassword-edit",
                          placeholder: "New Password",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.newPassword)
                            ? _vm._i(_vm.newPassword, null) > -1
                            : _vm.newPassword
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.newPassword,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.newPassword = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.newPassword = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.newPassword = $$c
                            }
                          }
                        }
                      })
                    : (_vm.showNewPassword ? "text" : "password") === "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newPassword,
                            expression: "newPassword"
                          }
                        ],
                        attrs: {
                          id: "newpassword-edit",
                          placeholder: "New Password",
                          type: "radio"
                        },
                        domProps: { checked: _vm._q(_vm.newPassword, null) },
                        on: {
                          change: function($event) {
                            _vm.newPassword = null
                          }
                        }
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newPassword,
                            expression: "newPassword"
                          }
                        ],
                        attrs: {
                          id: "newpassword-edit",
                          placeholder: "New Password",
                          type: _vm.showNewPassword ? "text" : "password"
                        },
                        domProps: { value: _vm.newPassword },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newPassword = $event.target.value
                          }
                        }
                      }),
                  _c(
                    "span",
                    {
                      staticClass: "eye-icon",
                      on: {
                        click: function($event) {
                          _vm.showNewPassword = !_vm.showNewPassword
                        }
                      }
                    },
                    [
                      !_vm.showNewPassword
                        ? _c("img", {
                            staticStyle: { height: "20px" },
                            attrs: {
                              src: require("@/assets/images/eye-solid.svg"),
                              alt: ""
                            }
                          })
                        : _vm._e(),
                      _vm.showNewPassword
                        ? _c("img", {
                            staticStyle: { height: "20px" },
                            attrs: {
                              src: require("@/assets/images/eye-slash-solid.svg"),
                              alt: ""
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]),
                _c("div", { staticClass: "field-item" }, [
                  (_vm.showNewPassword ? "text" : "password") === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.confirmPassword,
                            expression: "confirmPassword"
                          }
                        ],
                        attrs: {
                          id: "confirmpassword-edit",
                          placeholder: "Confirm New Password",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.confirmPassword)
                            ? _vm._i(_vm.confirmPassword, null) > -1
                            : _vm.confirmPassword
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.confirmPassword,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.confirmPassword = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.confirmPassword = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.confirmPassword = $$c
                            }
                          }
                        }
                      })
                    : (_vm.showNewPassword ? "text" : "password") === "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.confirmPassword,
                            expression: "confirmPassword"
                          }
                        ],
                        attrs: {
                          id: "confirmpassword-edit",
                          placeholder: "Confirm New Password",
                          type: "radio"
                        },
                        domProps: {
                          checked: _vm._q(_vm.confirmPassword, null)
                        },
                        on: {
                          change: function($event) {
                            _vm.confirmPassword = null
                          }
                        }
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.confirmPassword,
                            expression: "confirmPassword"
                          }
                        ],
                        attrs: {
                          id: "confirmpassword-edit",
                          placeholder: "Confirm New Password",
                          type: _vm.showNewPassword ? "text" : "password"
                        },
                        domProps: { value: _vm.confirmPassword },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.confirmPassword = $event.target.value
                          }
                        }
                      })
                ]),
                _vm.emptyPassword && _vm.passwordIncorrect
                  ? _c(
                      "p",
                      { staticClass: "p-error" },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "times" } }),
                        _vm._v(" Please enter password ")
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.passwordMismatch
                  ? _c(
                      "p",
                      { staticClass: "p-error" },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "times" } }),
                        _vm._v("Passwords must match ")
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.passwordLength
                  ? _c(
                      "p",
                      { staticClass: "p-error" },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "times" } }),
                        _vm._v("Password must be 8 or more characters ")
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "action" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn-cancel",
                      on: {
                        click: function($event) {
                          return _vm.redirect()
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    { staticClass: "button", attrs: { type: "submit" } },
                    [_vm._v("Save")]
                  )
                ])
              ])
            ]
          )
        ])
      ]),
      _vm.modalClosed === false
        ? _c("generic-light-modal", {
            attrs: {
              to: "/account",
              text: "Your password has been changed successfully."
            },
            on: {
              no: function($event) {
                _vm.modalClosed = true
              },
              yes: function($event) {
                return _vm.redirect()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }