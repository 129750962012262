import api from '@/api'
const { collections } = api
export default {
  async ListFavorites(state, payload) {
    let response = await collections.listFavorites(payload)
    if (response.status === 200) {
      console.log('ListFavorites', response)
    }
    return response
  },
  async DeleteFavorite(state, payload) {
    let response = await collections.deleteFavorite(payload)
    if (response.status === 200) {
      console.log('deleteFavorite', response)
    }
    return response
  }
}
