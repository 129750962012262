import api from '.'
import { instance as axios } from '../plugins/axios'
import { settings } from '@/library/variables'

export const document = {
  async getDocument(label) {
    try {
      return await axios.get(
        `/v2/properties/` + settings.propertyID + '/documents/' + label
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getDocumentWithPrefix(labelPrefix) {
    try {
      return await axios.get(
        `/v2/properties/` +
          settings.propertyID +
          '/documents/prefix/' +
          labelPrefix
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
