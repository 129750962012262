var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "z-20 relative mx-auto py-20 text-center" },
      [
        _vm.showMarketingTopHeaderText
          ? _c("div", { staticClass: "text-3xl mb-4" }, [_vm._m(1)])
          : _vm._e(),
        _vm._m(2),
        _c(
          "div",
          {
            staticClass: "text-5xl font-bold mt-3",
            staticStyle: { "font-family": "UniSans-Bold-Italic" }
          },
          [_vm._v(" GET IN THE GAME! ")]
        ),
        _c("div", { staticClass: "text-2xl mt-4" }, [
          _vm._v(" PLANS AS LOW AS $8.34/MONTH* ")
        ]),
        _c("div", { staticClass: "text-sm -mt-2" }, [
          _vm._v(" *WHEN BILLED ANNUALLY ")
        ]),
        _c(
          "div",
          {
            staticClass: "text-2xl mt-1",
            staticStyle: { "font-family": "UniSans-Bold" }
          },
          [_vm._v(" JOIN TODAY AND START WATCHING! ")]
        ),
        _c(
          "router-link",
          {
            staticClass: "glowing-btn text-xl w-80 py-2 mx-auto mt-4",
            attrs: { to: "/register/select-plan" }
          },
          [_vm._v(" SELECT YOUR PLAN ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "absolute top-0 w-full h-full bg-black" }, [
      _c("img", {
        staticClass: "w-full h-full object-cover opacity-40",
        attrs: { src: require("@/assets/images/marketing/background.jpg") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          href:
            "https://www.pokergo.com/collections/ed83f4b7-48e5-49a4-880c-85dc4777b422",
          target: "_blank"
        }
      },
      [
        _c("div", { staticClass: "flex justify-center px-3" }, [
          _c("span", { staticClass: "header-glowing-box flex px-4 py-3" }, [
            _vm._v(
              " Click here: Today Only: Watch 2024 WSOP Main Event Day 1D for FREE "
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-5xl" }, [
      _vm._v(" PokerGO: YOUR HOME FOR"),
      _c("br"),
      _vm._v("POKER ENTERTAINMENT ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }