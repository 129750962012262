import moment from 'moment'

export default {
  state: {
    pghPreference: {
      closedAt: '',
      neverShow: false
    },
    pghPlayStatus: false
  },
  mutations: {
    setPghPreference(state, value) {
      state.pghPreference = value
    },
    setPghPlayStatus(state, value) {
      state.pghPlayStatus = value
    }
  },
  getters: {
    isShowPghModal(state) {
      if (state.pghPreference.closedAt === '') return true

      const now = moment()
      const closedAt = moment(state.pghPreference.closedAt)
      const timeDiff = moment.duration(now.diff(closedAt)).asDays()
      console.log(timeDiff)

      // return !state.pghPreference.neverShow && timeDiff >= 5
      return state.pghPreference.neverShow ? false : timeDiff >= 1 ?? true
    },
    isPlayingPghModal(state) {
      return state.pghPlayStatus
    }
  }
}
