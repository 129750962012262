export const ga = {
  async gatag(payload) {
    dataLayer.push({
      event: 'Purchase',
      transaction_id: payload.transaction_id,
      affiliation: 'PokerGo Website',
      value: payload.price,
      tax: 0,
      currency: 'USD',
      shipping: 0,
      items: [
        {
          id: Math.floor(Math.random() * 5),
          //'sku': payload.type + ' Subscription',
          name: payload.type + ' plan',
          category: 'Subscriptions',
          quantity: 1,
          price: String(payload.price)
        }
      ]
    })
  }
}
