// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/images/Background@2x.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/images/training/bg-hero.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Lato|Open+Sans&display=swap);"]);
exports.push([module.id, "@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".tab {\n  position: absolute;\n  width: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: contain;\n}\n.bg-training {\n  position: absolute;\n  width: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size: contain;\n}\n.bgNotAuthenticated {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  -webkit-backdrop-filter: blur(5px);\n          backdrop-filter: blur(5px);\n  background-size: 100%;\n}\n.router-link-exact-active {\n  color: #ffffff !important;\n}\n@media screen and (max-width: 768px) {\n.page {\n    margin-top: 83px !important;\n}\n}\n", ""]);
// Exports
module.exports = exports;
