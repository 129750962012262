var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "scroll-behavior": "smooth" } }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "stretch flex-row-center wrap plan-wrapper" },
        _vm._l(_vm.filteredPlans, function(p, i) {
          return _c("register-plan", {
            key: i,
            attrs: { plan: p },
            on: {
              selectPlan: function($event) {
                return _vm.selectPlan(p)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "learn_more",
                  fn: function() {
                    return [
                      _c(
                        "span",
                        {
                          staticClass:
                            "underline cursor-pointer text-tw_primary-300",
                          on: { click: _vm.handleClickLearnMore }
                        },
                        [_vm._v(" Learn More ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        }),
        1
      ),
      this.filteredPlans.length === 1 &&
      this.filteredPlans[0].product_type === _vm.ProductTypes.TRAINING_ANNUAL
        ? _c("div", { staticClass: "text-center" }, [
            _c("span", { staticClass: "text-lg" }, [
              _vm._v("Not interested in PokerGO VIP training?")
            ]),
            _c("br"),
            _c(
              "span",
              {
                staticClass: "cursor-pointer text-tw_primary-300 underline",
                on: { click: _vm.showAllPlans }
              },
              [_vm._v(" click for other plans ")]
            )
          ])
        : _vm._e()
    ]),
    _vm.showTrainingDetails
      ? _c(
          "div",
          { ref: "trainingDetailsSection", staticClass: "mt-20" },
          [
            _c("Stats"),
            _c("ToughestPros", { staticClass: "mb-20" }),
            _c("TrainingEndText", { attrs: { "show-btn": false } })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }