var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible && !_vm.errorLoading,
          expression: "visible && !errorLoading"
        }
      ],
      staticClass: "position-relative long-card",
      on: { click: _vm.navigate }
    },
    [
      _c(
        "span",
        {
          staticClass: "image-container position-relative mb-2 cursor-pointer"
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.placeholder,
                expression: "!placeholder"
              }
            ],
            ref: "thumbnail",
            staticClass: "image",
            attrs: { src: _vm.thumbnailImage },
            on: { error: _vm.error, load: _vm.imageLoaded }
          }),
          !_vm.entitled
            ? _c("img", {
                staticClass: "locked",
                attrs: {
                  src: require("@/assets/images/lock.png"),
                  title: "A subscription is required to view this video."
                }
              })
            : _vm._e(),
          _vm.entityType === "collection"
            ? _c("img", {
                staticClass: "icon-collection",
                attrs: {
                  src: require("@/assets/images/icon_collection.png"),
                  title: "Video collection"
                }
              })
            : _vm._e(),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.placeholder,
                expression: "placeholder"
              }
            ],
            staticClass: "image",
            attrs: { src: require("@/assets/images/pgoplaceholder-sm.png") }
          }),
          _vm.progressPercent
            ? _c("i", { staticClass: "progress-bar-bg" })
            : _vm._e(),
          _vm.progressPercent
            ? _c("i", {
                staticClass: "progress-bar",
                style: { width: _vm.progressPercent }
              })
            : _vm._e()
        ]
      ),
      _c("span", { staticClass: "text-container" }, [
        _vm.showTitle
          ? _c("small", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
          : _vm._e(),
        _vm.showTitle ? _c("br") : _vm._e(),
        _vm.subheading
          ? _c("small", { staticClass: "subheading" }, [
              _vm._v(_vm._s(_vm.subheading))
            ])
          : _vm._e(),
        _c(
          "small",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.description,
                expression: "description"
              }
            ],
            staticClass: "desc"
          },
          [_vm._v(_vm._s(_vm.description))]
        ),
        _c("br")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }