<template>
  <div class="payment-form-container card-element">
    <form
      id="payment-form"
      @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
      @submit.prevent="submitClicked"
      method="post"
    >
      <p class="payment-form-title">Pay with Amazon</p>

      <div class="text-center" style="margin-top: 40px">
        <div
          id="addressBookWidgetDiv"
          style="width: 320px; height: 250px; display: inline-block"
        ></div>
        <div
          id="walletWidgetDiv"
          style="width: 320px; height: 250px; display: inline-block"
        ></div>
        <div
          id="consentWidgetDiv"
          style="width: 320px; height: 250px; display: inline-block"
        ></div>
        <div style="clear: both"></div>
      </div>

      <p v-if="declined" class="red">Payment Declined</p>
      <p class="red">{{ this.error }}</p>
      <p v-if="loading" class="loading-msg">
        Payment Processing Please Wait ...
      </p>

      <button
        v-else
        :class="[buttonClass, activeClass]"
        :disabled="this.disabled"
      >
        Subscribe
      </button>
      <p class="payment-form-policy">
        By confirming your subscription, you allow PokerGO to charge your card
        for this payment and future payments in accordance with their terms.
      </p>
    </form>
    <paypal-pending-modal
      v-if="modalPending"
      @yes="waitModal"
      @no="submitAfterCheck"
    />
    <paypal-wait-modal v-if="modalWait" @ok="goToAccount" />
  </div>
</template>

<script>
import Vue from 'vue'
import VueScript2 from 'vue-script2'
import { mapGetters, mapState } from 'vuex'
import { config, getWidgetsJsURL } from './config'
import PaypalPendingModal from '@/components/PaypalPendingModal.vue'
import PaypalWaitModal from '@/components/PaypalWaitModal.vue'
Vue.use(VueScript2)

export default {
  name: 'AmazonPayElement',
  data() {
    return {
      error: null,
      validated: false,
      loading: false,
      declined: false,
      accessToken: '',
      amazonBillingAgreementId: '',
      buttonClass: 'submit-payment',
      activeClass: 'disabled',
      preventMultiSubmit: false,
      modalPending: false,
      modalWait: false,
      transaction: {}
    }
  },
  components: {
    PaypalPendingModal,
    PaypalWaitModal
  },
  computed: {
    ...mapState({
      plans: (state) => state.checkout.plans
    }),
    ...mapGetters(['getPropertyId']),
    disabled() {
      return this.activeClass === 'disabled' || this.preventMultiSubmit
    },
    product() {
      return this.plans[this.productIdx]
    },
    productIdx() {
      return this.$route.query.planIDX
    },
    property() {
      return this.getPropertyId
    }
  },
  async mounted() {
    this.fetchTransactions()

    var self = this
    window.onAmazonLoginReady = function() {
      window.amazon.Login.setClientId(config.client_id)
      window.amazon.Login.setUseCookie(true)
    }
    const get_details = (billingAgreementId, accessToken) => {
      this.setAgreementDetail(billingAgreementId, accessToken)
    }
    window.onAmazonPaymentsReady = function() {
      new window.OffAmazonPayments.Widgets.AddressBook({
        sellerId: config.merchant_id,
        agreementType: 'BillingAgreement',
        onReady: function(billingAgreement) {
          self.amazonBillingAgreementId = billingAgreement.getAmazonBillingAgreementId()
          self.accessToken = self.$route.query.access_token
          console.log('access' + self.accessToken)
          get_details(self.amazonBillingAgreementId, self.accessToken)

          new window.OffAmazonPayments.Widgets.Consent({
            sellerId: config.merchant_id,

            // amazonBillingAgreementId obtained from the Amazon Address Book widget.
            amazonBillingAgreementId: self.amazonBillingAgreementId,

            design: {
              designMode: 'responsive'
            },
            onReady: function() {},
            onConsent: function() {
              get_details(self.amazonBillingAgreementId, self.accessToken)
            },
            onError: function(error) {
              // your error handling code
              alert(
                'Consent Widget error: ' +
                  error.getErrorCode() +
                  ' - ' +
                  error.getErrorMessage()
              )
            }
          }).bind('consentWidgetDiv')

          new window.OffAmazonPayments.Widgets.Wallet({
            sellerId: config.merchant_id,
            amazonBillingAgreementId: self.amazonBillingAgreementId,
            onPaymentSelect: function() {
              get_details(self.amazonBillingAgreementId, self.accessToken)
            },
            design: {
              designMode: 'responsive'
            },
            onError: function(error) {
              // your error handling code
              alert(
                'Wallet Widget error: ' +
                  error.getErrorCode() +
                  ' - ' +
                  error.getErrorMessage()
              )
            }
          }).bind('walletWidgetDiv')
        },
        onAddressSelect: function() {},
        design: {
          designMode: 'responsive'
        },
        onError: function(error) {
          // your error handling code
          alert(
            'Address Widget error: ' +
              error.getErrorCode() +
              ' - ' +
              error.getErrorMessage()
          )
        }
      }).bind('addressBookWidgetDiv')
    }
    await VueScript2.load(getWidgetsJsURL(config))
  },
  methods: {
    async fetchTransactions() {
      try {
        await this.$store.dispatch('account/FetchTransactions').then((rs) => {
          this.transaction = rs.data.data
        })
      } catch (e) {
        this.$toasted.error('Error please contact customer service')
      }
    },
    waitModal() {
      this.modalPending = false
      this.modalWait = true
    },
    goToAccount() {
      this.$router.push('/account')
    },
    async setAgreementDetail() {
      var self = this
      try {
        const res = await this.$store.dispatch('setAgreementDetail', {
          amazonBillingAgreementId: this.amazonBillingAgreementId,
          accessToken: this.accessToken
        })
        const constraint =
          res.data.data.GetBillingAgreementDetailsResult.BillingAgreementDetails
            .Constraints

        if (!constraint) {
          self.error = ''
          self.activeClass = 'enabled'
        } else {
          self.error =
            'Please ensure that you have confirmed the agreement and select the address and wallet.'
          self.activeClass = 'disabled'
        }
      } catch (e) {
        console.log(e)
      }
    },
    async billAgreementDetail() {
      const res = await this.confirmAgreementDetail()
      if (res.status === 200) {
        const resp = await this.authorizeAgreementDetail()
        console.log(resp)
      }
    },
    async confirmAgreementDetail() {
      this.declined = false
      const res = await this.$store.dispatch(
        'confirmAgreementDetail',
        this.amazonBillingAgreementId
      )
      return res
    },
    async authorizeAgreementDetail() {
      this.declined = false
      const res = await this.$store.dispatch('authorizeAgreementDetail', {
        amazonBillingAgreementId: this.amazonBillingAgreementId,
        productId: this.product.id
      })

      if (res.status == 200) {
        console.log('plan name:', this.$route.query.planName)

        // send data to GA when amazon pay success
        dataLayer.push({ event: 'PurchaseComplete' })
        console.log('pushed event for PurchaseComplete')

        this.$store.dispatch('fetchUser')
        this.$router.push({
          path:
            '/register/select-plan/' +
            this.$route.query.planName +
            '/complete?merchant=amazon'
        })
      }
      return res
    },
    async submitClicked() {
      if (this.transaction.subscription_status === 'Pending') {
        this.modalPending = true
      } else {
        await this.submitAfterCheck()
      }
    },
    async submitAfterCheck() {
      this.preventMultiSubmit = true
      setTimeout(() => (this.preventMultiSubmit = false), 1000)
      await this.billAgreementDetail()
    }
  }
}
</script>
<style scoped>
.validate-box {
  font-weight: normal;
  color: #333 !important;
}
.payment-form-container {
  width: 90%;
  max-width: 1200px;
  margin: 40px auto;
  text-align: center;
  background-color: #fff;
  border-radius: 9px;
}
.loading-msg {
  color: black;
  text-align: center;
}
#payment-form {
  padding: 50px !important;
  background: #fff;
  border-radius: 9px;
}

.payment-form-title {
  font-size: 20px !important;
  font-weight: bold;
  color: #333;
  text-align: left;
}
.payment-form-policy {
  font-size: 0.8em !important;
  color: #ccc;
  text-align: center;
}
input {
  color: #ccc !important;
}
.submit-payment {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: 40px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #fff;
  background-color: #9f26b5;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 0 14px 0 #ca9090;
}
.submit-payment:hover,
.submit-payment:active {
  opacity: 0.8;
}

.submit-payment.disabled {
  opacity: 0.6;
}

#walletWidgetDiv {
  width: 300px;
  height: 300px;
}
#addressBookWidgetDiv {
  width: 300px;
  height: 300px;
}
#consentWidgetDiv {
  width: 300px;
  height: 300px;
}

.card-element * {
  font-family: Quicksand, Open Sans, Segoe UI, sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.red {
  color: red;
}
.card-element {
  display: flex;
  margin: auto;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0;
  -ms-flex-pack: justify;
  border-style: none;
}
.submit-payment {
  margin-top: 30px;
  width: 98%;
}

.card-element input,
.card-element button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-style: none;
  outline: none;
}

.card-element input {
  color: #fff;
}

.card-element input::-webkit-input-placeholder {
  color: #9bacc8;
}

.card-element input::-moz-placeholder {
  color: #9bacc8;
}

.card-element input:-ms-input-placeholder {
  color: #9bacc8;
}

.card-element button {
  cursor: pointer;
}

.card-element button:active {
  background-color: #f5be58;
}
</style>
