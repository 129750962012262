var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-login" }, [
    _c(
      "div",
      { staticClass: "login-inner" },
      [
        _c(
          "div",
          { staticClass: "login-box" },
          [
            _c("br"),
            _c(
              "router-link",
              { staticClass: "btn-home", attrs: { to: "/login" } },
              [
                _c("i", { staticClass: "fas fa-arrow-left" }),
                _vm._v(" "),
                _c("span", [_vm._v("Login")])
              ]
            ),
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [
                _c("div", [
                  _vm._m(0),
                  _c("br"),
                  _c("br"),
                  _c("br"),
                  _c("h2", [_vm._v("Start streaming today!")]),
                  _c("p", [
                    _vm._v(
                      " Login in now to enjoy the top poker streaming app in the world "
                    )
                  ]),
                  _c("div", { staticClass: "text-field" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user,
                          expression: "user"
                        }
                      ],
                      attrs: {
                        id: "username-input",
                        type: "email",
                        required: "",
                        placeholder: "Email"
                      },
                      domProps: { value: _vm.user },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.user = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm.errorMessage !== ""
                    ? _c(
                        "span",
                        { staticClass: "error-message" },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "times" } }),
                          _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c("br"),
                _c("br"),
                _c("div", { staticClass: "line" }),
                _c("br"),
                _c(
                  "button",
                  {
                    staticClass: "glowing-btn h-12 w-full mt-1",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(" Reset ")]
                ),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _vm._m(1)
              ]
            ),
            _c("br")
          ],
          1
        ),
        _vm.isShowModal
          ? _c("generic-modal", {
              attrs: {
                text:
                  "An email with instructions on how to reset your password has been sent to " +
                  _vm.user +
                  ".<br /><br/>Check your spam or junk folder if you don’t see the email in your inbox.\n        If you no longer have access to this email account or have any questions, please <a href='/faq'>contact us</a>."
              },
              on: {
                yes: function($event) {
                  return _vm.redirect()
                },
                no: function($event) {
                  _vm.modalClosed = true
                }
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-login" }, [
      _c("img", {
        attrs: { src: require("../assets/images/Logo-PKG-1.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-footer" }, [
      _c("p", [_vm._v("Customer Service is available to help!")]),
      _c("p", [
        _vm._v(
          " CALL US at 1-866-476-5374 | Monday - Friday, 7am to 5pm PT or email support@pokergo.com "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }