require('dotenv').config()
export const config = {
  merchant_id: process.env.AMAZONWEB_MERCHANT_ID || '', // Merchant/SellerID
  client_id: process.env.AMAZONWEB_CLIENT_ID || '', // Login With Amazon Client ID
  region: 'na', // na,us, de, uk, jp
  currency_code: 'USD', // USD, EUR, GBP, JPY
  sandbox: process.env.AMAZONWEB_SANDBOX || false // Use sandbox test mode
}

export const getWidgetsJsURL = (config) => {
  var sandbox
  if (config.sandbox === 'true') sandbox = 'sandbox/'
  else sandbox = ''

  switch (config.region) {
    case 'na':
      return (
        'https://static-na.payments-amazon.com/OffAmazonPayments/us/' +
        sandbox +
        'js/Widgets.js'
      )
    case 'uk':
      return (
        'https://static-eu.payments-amazon.com/OffAmazonPayments/gbp/' +
        sandbox +
        'lpa/js/Widgets.js'
      )
    case 'jp':
      return (
        'https://static-fe.payments-amazon.com/OffAmazonPayments/jp/' +
        sandbox +
        'lpa/js/Widgets.js'
      )
    default:
      return (
        'https://static-eu.payments-amazon.com/OffAmazonPayments/eur/' +
        sandbox +
        'lpa/js/Widgets.js'
      )
  }
}
