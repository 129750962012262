var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isVideoDataLoaded
      ? _c(
          "div",
          {
            staticClass: "video-player-container",
            class: { hide: _vm.reloadLive },
            on: { click: _vm.navigate }
          },
          [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hideOverlayImage,
                  expression: "hideOverlayImage"
                }
              ],
              ref: "videoPlayerRef",
              staticClass: "video-player-box",
              attrs: { id: "videoPlayer" }
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hideOverlayImage,
                  expression: "!hideOverlayImage"
                }
              ],
              staticClass: "overlay",
              class: { "overlay-top": _vm.isOverlayTop },
              attrs: { src: _vm.imagePreload }
            }),
            !_vm.onAir && !_vm.hideOverlayImage
              ? _c("CountdownTimer", {
                  attrs: {
                    airDate: _vm.airDateObject,
                    videoType: _vm.videoType
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }