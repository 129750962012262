var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "section-games" }, [
      _c("div", { staticClass: "inner" }, [
        _vm._m(0),
        _c("div", [
          _c("div", { staticClass: "philvidwrap" }, [
            _c(
              "video",
              {
                staticClass: "philvid",
                attrs: { autoplay: "", muted: "", loop: "" },
                domProps: { muted: true }
              },
              [
                _c("source", {
                  attrs: {
                    src:
                      "https://content.jwplatform.com/videos/NXtUJUky-TZwR2iuw.mp4",
                    type: "video/mp4"
                  }
                })
              ]
            )
          ]),
          _vm._m(1)
        ])
      ]),
      _vm._m(2),
      _c("div", { staticClass: "sign" }, [
        _c("div", { staticClass: "inner-sign" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "sign-title" }, [
              _vm._v(" JOIN THE A LIST ")
            ]),
            _c("p", [
              _vm._v(
                " Give us your email to receive special offers, sponsor discounts, feature updates and more! "
              )
            ]),
            _c("div", { staticClass: "form" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                attrs: { type: "text", placeholder: "Email" },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.age,
                    expression: "age"
                  }
                ],
                attrs: { type: "text", placeholder: "Age" },
                domProps: { value: _vm.age },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.age = $event.target.value
                  }
                }
              }),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.SendMail($event)
                    }
                  }
                },
                [_vm._v("Submit")]
              )
            ])
          ]),
          _c("div", { staticClass: "right" }, [
            _c(
              "video",
              {
                staticClass: "ringvidwrap",
                attrs: { autoplay: "", muted: "", loop: "" },
                domProps: { muted: true }
              },
              [
                _c("source", {
                  attrs: {
                    src:
                      "https://content.jwplatform.com/videos/iZqTocfk-9cqarGOI.mp4",
                    type: "video/mp4"
                  }
                })
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "title" }, [
        _c("p", [_vm._v("GET IN THE GAME.")]),
        _c("p", [_vm._v("GET FAMOUS.")])
      ]),
      _c("div", { staticClass: "system" }, [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href:
                "https://apps.apple.com/us/app/pokergo-stream-poker-tv/id1235783484"
            }
          },
          [
            _c("div", { staticClass: "img" }, [
              _c("img", {
                attrs: {
                  src: require("../assets/images/holdem/download-apple-store.png"),
                  alt: ""
                }
              })
            ])
          ]
        ),
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href:
                "https://play.google.com/store/apps/details?id=com.pokercentral.poker&hl=en_US&gl=US"
            }
          },
          [
            _c("div", { staticClass: "img" }, [
              _c("img", {
                attrs: {
                  src: require("../assets/images/holdem/download-google-play.png"),
                  alt: ""
                }
              })
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "download-on" }, [
        _c("div", { staticClass: "content" }, [
          _c("p", [_vm._v("Download on your computer")]),
          _c("a", { staticClass: "pc", attrs: { href: "" } }, [_vm._v("PC")]),
          _vm._v("|"),
          _c("a", { staticClass: "mac", attrs: { href: "" } }, [_vm._v("MAC")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "social" }, [
      _c("p", [
        _vm._v(
          ' "The only social mobile Poker game you should be playing." ~ Phil Hellmuth, most poker bracelet wins ever! '
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "list-item" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "item__picture" }, [
            _c("img", {
              attrs: {
                src: require("../assets/images/holdem/App_GetFamous.png"),
                alt: ""
              }
            })
          ]),
          _c("p", [
            _vm._v(
              " Regular TV coverage of players on the top of the leaderboard! Get in the game and get noticed! "
            )
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "item__picture" }, [
            _c("img", {
              attrs: {
                src: require("../assets/images/holdem/App_Realpros.png"),
                alt: ""
              }
            })
          ]),
          _c("p", [
            _vm._v(
              " We have the biggest names in Poker. Phil Hellmuth, Daniel Negreanu, Maria Ho and Antonio Esfandiari and many more...come play vs them! "
            )
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "item__picture" }, [
            _c("img", {
              attrs: {
                src: require("../assets/images/holdem/App_AuthPoker.png"),
                alt: ""
              }
            })
          ]),
          _c("p", [
            _vm._v(
              " Always giving out free complimentary Poker chips to test out your skills. "
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }