export const localstorage = {
  setToken(token) {
    localStorage.setItem('token', token)
  },
  getToken() {
    let token = localStorage.getItem('token')
    if (token && token === 'undefined') {
      return null
    }
    return token
  },
  clearAuth() {
    localStorage.removeItem('token')
    localStorage.removeItem('vuex')
    localStorage.removeItem('subscription')
  },
  setWatchTimer(value) {
    localStorage.setItem('watch_timer', value)
  },
  getWatchTimer() {
    return localStorage.getItem('watch_timer')
  }
}
