<template>
  <div class="register flex-column-center">
    <div v-show="currentStep === 'register' && code">
      <register-promo-banner />
    </div>
    <h6>UNLOCK POKERGO</h6>
    <!-- <h5>Only 3 steps to start watching</h5> -->
    <register-steps :currentStep="currentStep"></register-steps>
    <div
      class="spinner"
      v-show="controls.loading && currentStep !== 'checkout'"
    >
      <font-awesome-icon icon="spinner" class="fa-pulse fa-3x fa-fw" />
    </div>
    <div v-show="currentStep === 'register'" class="flex-column-center">
      <register-form
        v-model="form"
        :error="errorMsg"
        @login="login"
      ></register-form>
    </div>
    <div v-show="currentStep === 'select-plan'">
      <SelectPlanStep @selectPlan="selectPlan" />
    </div>
    <div v-if="currentStep === 'checkout'" class="flex-column-center">
      <RegisterCheckout
        :key="$route.fullPath"
        @subscribe="subscribe"
      ></RegisterCheckout>
    </div>
    <div v-if="currentStep === 'amazon'" class="flex-column-center">
      <AmazonPayElement />
    </div>
    <div v-if="currentStep === 'verify-email'" class="flex-column-center">
      <VerifyEmailStep />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import AmazonPayElement from '@/views/checkout/amazon/_id.vue'
import RegisterCheckout from './components/RegisterCheckout.vue'
import RegisterForm from './components/RegisterForm.vue'
import RegisterSteps from './components/RegisterSteps.vue'
import api from '@/api'
import RegisterPromoBanner from './components/RegisterPromoBanner'
import VerifyEmailStep from './components/VerifyEmailStep.vue'
import SelectPlanStep from './steps/SelectPlanStep.vue'

export default {
  name: 'Register',
  components: {
    SelectPlanStep,
    VerifyEmailStep,
    RegisterPromoBanner,
    AmazonPayElement,
    RegisterCheckout,
    RegisterForm,
    RegisterSteps
  },
  data() {
    return {
      form: {
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        recapture: ''
      },
      errorMsg: ''
    }
  },
  computed: {
    ...mapState({
      controls: (state) => state.checkout.controls,
      plan: (state) => state.checkout.selectedPlan,
      plans: (state) => state.checkout.plans,
      promoCodeDetails: (state) => state.checkout.promoCodeDetails
    }),
    ...mapGetters(['isAuthenticated', 'getPropertyId', 'getEmail']),
    code() {
      return this.promoCodeDetails ? this.promoCodeDetails.Code : ''
    },
    currentStep() {
      const route = this.$route.path.split('/')
      if (
        this.$route.params &&
        this.$route.params.productType &&
        route[route.length - 2] === 'select-plan'
      ) {
        return 'checkout'
      } else {
        return route[route.length - 1]
      }
    },
    destPath() {
      return this.$route.query.to || undefined
    },
    property() {
      return this.getPropertyId
    },
    username() {
      return this.getEmail || ''
    }
  },
  mounted() {
    this.$store
      .dispatch('getPlans', { code: this.$route.query.code })
      .then(() => {
        setTimeout(() => {
          dataLayer.push({ event: 'SignupLand' })
          console.log('pushed event for signup')
        }, 2000)
      })
  },
  methods: {
    ...mapActions(['REQUEST_SIGNUP']),
    ...mapMutations(['setAuthenticated']),
    async login() {
      this.REQUEST_SIGNUP(this.form).then(async (response) => {
        if (!response) return
        if (response.status === 200 || response.status === 428) {
          this.$store.commit('setAuthenticated', response.data.meta.token)

          // set user on register
          this.$store.commit('setUser', {
            user: {
              basic: {
                first_name: this.form.first_name,
                last_name: this.form.last_name,
                email: this.form.email
              }
            }
          })
          dataLayer.push({ event: 'SignUpComplete' })
          console.log('pushed event for SignUpComplete')

          const loginRedirectURL = localStorage.getItem('loginRedirectURL')
          const redirectTriggerPath = localStorage.getItem(
            'redirectTriggerPath'
          )

          if (response.status === 428) {
            this.$router.push({
              path: `/register/verify-email`
            })
          } else if (loginRedirectURL && redirectTriggerPath === '/register') {
            await this.$router.push({ path: loginRedirectURL })
            localStorage.removeItem('loginRedirectURL')
            localStorage.removeItem('redirectTriggerPath')
          } else if (this.plan.product_type) {
            this.$router.push({
              path: `/register/select-plan/${this.plan.product_type}`
            })
          } else {
            this.$router.push({
              path: `/select-plan`
            })
          }
        } else {
          this.errorMsg = response.message
        }
      })
    },
    selectPlan(plan) {
      this.$store.commit('selectPlan', plan)
      if (this.isAuthenticated) {
        this.$router.push({
          path: this.$route.path + '/' + plan.product_type,
          query: { code: this.$route.query.code }
        })
      } else {
        this.$router.push('/register')
      }
    },
    subscribe(payload) {
      let subscription = {
        email: this.username,
        promocode: payload.coupon,
        payment_id: payload.id,
        store: payload.store,
        external_entitlement_id: payload.externalID,
        clear_pending_paypal: payload.clear_pending_paypal,
        plan: payload.plan,
        stripe_postal_code: payload.stripe_postal_code,
        phone_number: payload.phone_number,
        address: payload.address,
        city: payload.city,
        state: payload.state,
        postal_code: payload.postal_code,
        link_id: this.$cookies.get('link_id'),
        tenant_id: this.$cookies.get('tenant_id'),
        source_id: this.$cookies.get('source_id')
      }

      //Check Trackdesk query
      const trackdeskQuery = localStorage.getItem('trackdesk')
      if (trackdeskQuery) {
        const jsonTrackdeskQuery = JSON.parse(trackdeskQuery)
        subscription = { ...subscription, ...jsonTrackdeskQuery }
      }

      this.$store
        .dispatch('subscribe', subscription)
        .then((payment_session) => {
          if (payment_session) {
            setTimeout(() => {
              localStorage.removeItem('promo')
              dataLayer.push({ event: 'PurchaseComplete' })
              console.log('pushed event for PurchaseComplete')

              console.log(
                'Facebook purchase complete event fired: payload, ',
                payload
              )

              if (payload.plan)
                api.ga.gatag.call(this, {
                  price: payload.plan.price,
                  type: payload.plan.type,
                  transaction_id: payment_session.ID
                })

              this.$store.commit('checkoutControls', { loading: false })
              this.$store.commit('stripePaymentInfor', {
                stripeChargeId: payment_session.StripeChargeID,
                stripeCustomerId: payment_session.StripeCustomerID,
                amount: payload?.couponPrice
                  ? payload.couponPrice
                  : payload.plan.price
              })
              if (payload.store === 'paypal') {
                window.location.replace(payment_session.SessionID)
              } else {
                if (payload?.couponPrice != null && payload?.coupon)
                  this.$router.push({
                    path:
                      this.$route.path +
                      `/complete?couponPrice=${payload.couponPrice}`
                  })
                else this.$router.push({ path: this.$route.path + '/complete' })
              }
            }, 2000)
          }
        })
        .catch(() => {
          this.$store.commit('checkoutControls', {
            loading: false,
            error: 'subscribe error'
          })
          setTimeout(() => {
            dataLayer.push({ event: 'PurchaseFail' })
            console.log('pushed event for PurchaseFail')
          }, 2000)
        })
    }
  }
}
</script>
<style lang="scss">
.register {
  background-color: white;
  color: $black;
  padding-top: 20px;
  h1 {
    text-align: center;
  }
  h6 {
    font-family: 'UniSans-Bold';
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 10px;
    color: $poker-accent;
  }
  .stretch {
    align-items: stretch;
  }
  .wrap {
    flex-wrap: wrap;
    justify-content: center;
  }
  .spinner {
    color: #9f26b5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff5e;
  }
}

.register-form {
  label {
    font-family: 'UniSans-Bold';
    font-size: 0.9rem;
    text-transform: uppercase;
    .description {
      font-size: 0.7rem;
      padding-left: 0.5rem;
      text-transform: none;
      font-family: 'UniSans';
      &.error {
        color: $red;
        font-family: 'UniSans-Bold';
      }
    }
  }
  input {
    background-color: #ffffff !important;
    border-color: #272e34 !important;
    box-shadow: none !important;
    outline: none !important;
    border-radius: 5px;
  }

  .input {
    padding: 0.9rem;
  }
  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
  }
  .form-title {
    font-family: 'UniSans-Bold';
    font-size: 1.2rem;
    text-transform: uppercase;
  }
}

.form-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding: 5px;
    button {
      font-size: 23px;
    }
  }
  .message {
    font-size: 11.5px;
    margin: 0;
    text-align: center;
    color: $light-gray-30;
    &.error {
      color: $red;
    }
  }
}

.register-button {
  width: 100%;
  max-width: 100% !important;
  border-radius: 5px;
  font-family: 'UniSans-Bold' !important;
  background-color: $poker-accent !important;
  color: white !important;
  text-transform: unset !important;
  outline: none;
  margin-bottom: 12px !important;
  cursor: pointer;
  &.validate {
    background-color: #5b5b5b !important;
    border-color: #5b5b5b !important;
    margin-bottom: 0 !important;
    margin-left: 5px;
    font-size: 18px !important;
    width: 30%;
    line-height: 45px;
    height: 45px;
  }
  &:hover {
    transform: translateY(-1px);
  }
  &:active {
    box-shadow: 0px 0px 1px 1px $poker-accent;
  }
}

.select-plan-button {
  width: 100%;
  max-width: 100% !important;
  border-radius: 5px;
  font-family: 'UniSans-Bold' !important;
  text-transform: unset !important;
  outline: none;
  background-color: white !important;
  border: 2px solid #9f26b5 !important;
  color: $poker-accent !important;
  border-radius: 5px !important;
  margin-bottom: 12px !important;
  cursor: pointer;
  &.validate {
    background-color: #5b5b5b !important;
    border-color: #5b5b5b !important;
    margin-bottom: 0 !important;
    margin-left: 5px;
    font-size: 18px !important;
    width: 30%;
    line-height: 45px;
    height: 45px;
  }
  &:hover {
    background-color: $poker-accent !important;
    color: white !important;
  }
  &:active {
    box-shadow: 0px 0px 1px 1px $poker-accent;
  }
}

@media screen and (max-width: 500px) {
  .register {
    h1 {
      font-size: 1.8rem;
    }
    .wrap {
      align-items: center;
    }
  }
  .register-button {
    &.validate {
      width: 40%;
      line-height: 45px;
      height: 45px;
    }
  }
}

.plan-wrapper {
  padding-bottom: 30px;
}
</style>
