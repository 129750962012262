var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    _c("div", { staticClass: "text-4xl mb-3" }, [_vm._v(" Elite Pros ")]),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "mx-auto", staticStyle: { "max-width": "1000px" } },
      [
        _c(
          "Swiper",
          { attrs: { options: _vm.swiperOptions } },
          [
            _vm._l(_vm.pros, function(pro, i) {
              return _c(
                "SwiperSlide",
                { key: i },
                [
                  _c("PokerGoProPersonal", {
                    attrs: {
                      src: require("@/assets/images/training/pros/" +
                        pro.name +
                        ".png"),
                      series: pro.series,
                      name: pro.name
                    }
                  })
                ],
                1
              )
            }),
            _c(
              "div",
              {
                staticClass: "swiper-button-prev",
                staticStyle: { top: "95px", left: "20px" },
                attrs: { slot: "button-prev" },
                slot: "button-prev"
              },
              [
                _c("ArrowButton", {
                  staticClass: "hidden lg:block",
                  attrs: { direction: "left" }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "swiper-button-next",
                staticStyle: { top: "95px", right: "20px" },
                attrs: { slot: "button-next" },
                slot: "button-next"
              },
              [
                _c("ArrowButton", {
                  staticClass: "hidden lg:block",
                  attrs: { direction: "right" }
                })
              ],
              1
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "opacity-50 text-17 mb-10 px-2" }, [
      _vm._v(
        " From No Limit Hold’em to Pot Limit Omaha, tournaments to mixed games, we’ve"
      ),
      _c("br"),
      _vm._v(" got you covered with the best Pros in the industry. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }