<template>
  <div class="page">
    <div class="min-h-screen">
      <div class="controls">
        <div class="ml-auto">
          <div
            style="width: 120px; height: 35px;border: 1px solid rgba(255, 255, 255, 0.25);"
            class=" flex justify-center items-center rounded-md gap-2 cursor-pointer"
            @click="toggleFilterWindow"
          >
            <font-awesome-icon icon="filter" />
            <b>FILTERS</b>
          </div>
        </div>
      </div>
      <div class="text-center mt-20" v-if="loading">
        <loading-progress
          :progress="60"
          :indeterminate="true"
          :hide-background="true"
          size="50"
          rotate
          fillDuration="2"
          rotationDuration="1"
        />
      </div>
      <div v-else-if="!videos.length" class="text-center text-xl mt-20">
        No videos found!
      </div>
      <div v-else>
        <div class="grid-container" :class="cardContainerClass">
          <small-card
            v-for="(entity, idx) in videos"
            :key="idx + entity.id"
            :entity-id="entity.id"
            :entity-type="entity.type"
            :entity-data="entity.images ? entity : undefined"
            :duration="entity.duration"
            :show-sub-text="false"
            author="Krzysztof Slaski"
            :show-title="true"
            :no-reload="true"
            style="position: relative;"
          />
        </div>
      </div>
    </div>
    <ResultsFilter
      @search="handleSearch"
      :show="showFiltersPopup"
      @close="handleClosePopup"
    />
  </div>
</template>

<script>
import SmallCard from '@/components/SmallCard.vue'
import ResultsFilter from '../../../components/Training/ResultsFilter.vue'
import api from '../../../api'

export default {
  name: 'TrainingUserView',
  components: { ResultsFilter, SmallCard },
  data() {
    return {
      videos: [],
      loading: true,
      showFiltersPopup: false
    }
  },
  computed: {
    cardContainerClass() {
      return this.videos.length % 3 === 2 ? 'cardContainerClass' : ''
    }
  },
  methods: {
    handleClosePopup() {
      this.showFiltersPopup = false
    },
    async getData() {
      this.loading = true
      const resGrid = await this.$store.dispatch('home/GetViewData', 'training')
      this.videos = resGrid.data.data.collection_list
      this.showFiltersPopup = false
      this.loading = false
    },
    async handleSearch(filters) {
      return this.isExistFilters(filters)
        ? this.filterData(filters)
        : this.getData()
    },
    async filterData(filters) {
      this.loading = true
      this.showFiltersPopup = false
      const payload = {
        game_types: filters ? filters.gameType.map((el) => el.name) : [],
        coaches: filters ? filters.coaches.map((el) => el.name) : [],
        most_recent: filters ? filters.mostRecent : false
      }
      const { data } = await api.training.getTrainingVideos(payload)
      const { videos } = data.data
      this.videos = videos

      this.loading = false
    },
    toggleFilterWindow() {
      this.showFiltersPopup = !this.showFiltersPopup
    },
    isExistFilters(filters) {
      return (
        filters.gameType.length > 0 ||
        filters.coaches.length > 0 ||
        filters.mostRecent
      )
    }
  },
  async mounted() {
    // reset videoId on OnDemand view
    this.$store.commit('setVideoId', null)

    document.title = this.$route.name + ' | PokerGO'
    await this.getData()
  }
}
</script>
<style scoped lang="scss">
.page {
  flex-flow: column;
  margin-top: 50px;
  justify-content: start;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
  .controls {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    line-height: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
    }
    .two-buttons {
      margin: 1rem;
      width: 184px;
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 5px;
      height: 35px;
      line-height: 35px;
      display: flex;
      flex-direction: row;
      button {
        justify-content: center;
        display: flex;
        font-weight: bold;
        font-size: 13px;
        line-height: 13px;
        padding-top: 1px;
        align-items: center;
        cursor: pointer;
        width: 50%;
        height: 100%;
        border: none !important;
        background-color: transparent;
        color: #ffffff;
        .icon {
          height: 22px;
          width: 22px;
          margin-right: 8px;
          &.grid {
            background: url('../../../assets/images/grid-icon-light.svg')
              no-repeat;
          }
          &.list {
            background: url('../../../assets/images/list-icon-dark.svg')
              no-repeat;
          }
        }
        &.active {
          background-color: $poker-accent;
        }
        &:focus {
          outline: none !important;
        }
      }
    }
  }
  .list-container {
    margin: 0 0 60px 0;
    @media (max-width: 768px) {
      margin: 20px 0;
    }
  }
  .grid-container {
    margin: 60px 0;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      margin: 10px 0;
    }
    .small-card {
      margin: 0 55px;
      padding: 15px;
      @media (max-width: 1440px) {
        margin: 0 140px;
      }
      @media (max-width: 768px) {
        padding: 5px;
        margin: 0 0;
      }
    }
  }

  .cardContainerClass {
    justify-content: center;
    @media (max-width: 1440px) {
      justify-content: flex-start;
    }
  }
}
</style>
