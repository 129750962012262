<template>
  <div
    :class="['RulesModal', { UserNameModal: currentStep === 'username-step' }]"
  >
    <div v-if="currentStep === 'rules-step'">
      <p class="RulesModal--Header">CHAT RULES</p>
      <p
        v-for="(rule, index) in rules"
        :key="`rule-${index}`"
        class="RulesModal--Item"
      >
        {{ rule }}
      </p>
      <div class="RulesModal--Gap" />
      <button class="RulesModal--Btn" @click="acceptRules">
        OK, GOT IT
      </button>
    </div>
    <div class=" px-3" v-if="currentStep === 'username-step'">
      <div class="mb-4 text-lg">
        Please add your username to start chat !
      </div>

      <input
        id="userName"
        type="text"
        required
        placeholder="Username"
        autocomplete="off"
        v-model="userName"
        @input="error = ''"
      />
      <p class="error" v-if="error">{{ error }}</p>

      <button
        class="glowing-btn w-full h-12 mt-4 disabled:cursor-not-allowed "
        type="submit"
        @click="updateUserName"
      >
        Add
      </button>
    </div>
  </div>
</template>
<script>
import { account } from '@/api/account'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      currentStep: 'rules-step',
      userName: '',
      error: '',
      rules: [
        'KEEP Nickname clean',
        'NO profanity',
        'NO talk of violence and harm',
        'NO sexually explicit content',
        'NO promoting other sites',
        'NO Politics',
        'DO enjoy conversing with other poker fans'
      ]
    }
  },
  computed: {
    ...mapState({
      username: (state) => state.user.username
    })
  },
  methods: {
    async updateUserName() {
      if (!this.userName) {
        this.error = 'Username is required'
        return
      }
      const response = await account.saveNameChange({
        user_name: this.userName
      })
      if (response.status === 200) {
        this.$emit('agree')
      } else {
        this.error = response.message
      }
    },
    acceptRules() {
      if (this.username) {
        this.$emit('agree')
      } else {
        this.currentStep = 'username-step'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.UserNameModal {
  background: #162029 !important;
  color: white !important;
}
.RulesModal {
  top: 40px;
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 10px 10px 10px;
  background: white;
  color: black;

  &--Header {
    font-size: 2rem;
  }

  &--Item {
    font-size: 1.5rem;
  }

  &--Gap {
    flex-grow: 1;
  }

  &--Btn {
    display: block;
    width: 350px;
    margin: auto;
    background: #9f26b5;
    padding: 13px 40px 10px 40px;
    border-radius: 50px;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }
}

@media (max-width: 786px) {
  .RulesModal {
    height: 390px;

    &--Header {
      font-size: 1.5rem;
    }

    &--Item {
      font-size: 1rem;
    }
  }
}
@media screen and (max-width: 420px) {
  .RulesModal {
    overflow: auto;
    max-height: 390px;
    height: calc(100vh - 57vw - 150px - 40px - 80px);
  }
}

.error {
  color: red;
  padding: 5px;
  margin: 0;
}
</style>
