var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "container centered" }, [
      _c("div", { staticClass: "narrow-text-container" }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.document.content) } })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }