<template>
  <div class="page">
    <div class="error-container">
      <h1>
        An error occurred
      </h1>
      <h2>
        Try our
        <router-link to="/search">SEARCH</router-link>
        to find your page
      </h2>
    </div>
  </div>
</template>
<script lang="js">
export default {
  name: 'Error',
  head() {
    return {
      title: 'Page Not Found | PokerGO',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Page not found.'
        }
      ]
    }
  },
  components: { },
  beforeDestroy() {
    this.$bugsnag.removeOnError()
  }
}
</script>
<style scoped lang="scss">
.error-container {
  display: flex;
  min-height: calc(100vh - 320px);
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  @media (max-width: 768px) {
    height: calc(100vh - 525px);
  }
}
h2 {
  line-height: 1em;
  a {
    color: #9f26b599;
    &:hover {
      color: #9f26b5ff;
    }
  }
}
</style>
