import Vue from 'vue'
import Vuex from 'vuex'
import checkout from './checkout'
import user from './user'
import other from './other'
import chat from './chat'
import progress from './progress'
import account from './account'
import pgh from './pgh'

// setup views
import home from './views/home'
import demand from './views/onDemand'
import schedule from './views/schedule'
import myList from './views/myList'
import search from './views/search'
import createPersistedState from 'vuex-persistedstate'
import videos from './views/videos'
import collections from './views/collections'

Vue.use(Vuex)

const createStore = () => {
  return (
    store ||
    new Vuex.Store({
      plugins: [
        createPersistedState({
          path: ['user'],
          reducer(val) {
            if (val === null) {
              return {}
            }
            const newState = { ...val }
            if (newState.checkout) {
              const { controls, ...restCheckout } = newState.checkout
              newState.checkout = restCheckout
            }
            return newState
          }
        })
      ],
      modules: {
        user: user,
        checkout: checkout,
        other: other,
        chat: chat,
        home,
        demand,
        schedule,
        myList,
        search,
        progress: progress,
        account: account,
        videos,
        collections,
        pgh
      }
    })
  )
}

const store = createStore()

export default store
