var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cancel-page" },
    [
      _c("div", { staticClass: "cancel-page__container" }, [
        _c("div", { staticClass: "cancel-page__container--header" }, [
          _c("h1", [_vm._v(_vm._s(_vm.pageTitle))]),
          this.$route.query.plan && this.$route.query.plan !== "annual"
            ? _c("p", { staticClass: "text-white" }, [
                _vm._v(" Keep subscribing to PokerGO with this special offer ")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "cancel-page__container--footer" }, [
          _c("p", { staticClass: "text-white" }, [
            _vm._v(" " + _vm._s(_vm.cancellationNote) + " ")
          ])
        ]),
        _c("div", { staticClass: "cancel-page__container--body" }, [
          _c("div", { staticClass: "cancel-page__container--form mb-10" }, [
            this.$route.query.plan && this.$route.query.plan !== "annual"
              ? _c(
                  "p",
                  { staticClass: "cancel-page__container--form--label" },
                  [_vm._v(" LIMITED TIME OFFER! ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "special_offer",
                staticStyle: { display: "flex", gap: "20px" }
              },
              _vm._l(_vm.filteredOfferPlans, function(p, i) {
                return _c("RegisterPlan", {
                  key: i,
                  attrs: {
                    showSelectPlanBtn: false,
                    showAcceptNewOfferBtn: true,
                    showCancelSubscriptionBtn:
                      _vm.filteredOfferPlans.length < 2,
                    plan: p,
                    disableActions: _vm.isPaymentProcessing
                  },
                  on: {
                    "accept-offer": function($event) {
                      return _vm.takeDiscount(p)
                    },
                    "cancel-subscription": _vm.cancelClickHandle
                  }
                })
              }),
              1
            )
          ])
        ]),
        _vm.filteredOfferPlans.length > 1
          ? _c("div", { staticClass: "btn-cancel-container" }, [
              _c(
                "button",
                {
                  staticClass: "btn-cancel-subscription",
                  attrs: { disabled: _vm.isPaymentProcessing },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" Cancel Subscription ")]
              )
            ])
          : _vm._e()
      ]),
      _vm.modelOptions.open
        ? _c("ConfirmationDialog", {
            attrs: { modelOptions: _vm.modelOptions }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }