import api from '@/api'
export default {
  async GetScheduleData(state) {
    let response = await api.schedule.getScheduleView('live')
    if (response.status === 200) {
      state.commit('setScheduleData', response.data)
    }
    return response
  }
}
