var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register-container centered" }, [
    _c(
      "form",
      {
        staticClass: "register-form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.verifyCode($event)
          }
        }
      },
      [
        _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            {
              staticClass: "col relative",
              staticStyle: {
                display: "flex",
                "justify-content": "space-between"
              }
            },
            [
              _c("div", [
                _vm._v(" Please check your email "),
                _c("br"),
                _vm._v(_vm._s(_vm.email) + " for a verification code. ")
              ]),
              !_vm.successMessage
                ? _c(
                    "div",
                    {
                      staticClass: "subscribe w-28 absolute right-0",
                      attrs: { to: "/register/select-plan" },
                      on: { click: _vm.resendCode }
                    },
                    [_vm._v(" Resend Code ")]
                  )
                : _vm._e()
            ]
          )
        ]),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.verificationCode,
                    expression: "verificationCode"
                  }
                ],
                attrs: {
                  id: "verificationCode",
                  type: "number",
                  required: "",
                  placeholder: "Verification Code",
                  autocomplete: "off"
                },
                domProps: { value: _vm.verificationCode },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.verificationCode = $event.target.value
                    },
                    function($event) {
                      _vm.error = ""
                    }
                  ]
                }
              })
            ]),
            _vm.error
              ? _c("p", { staticClass: "email-error" }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              : _vm._e()
          ])
        ]),
        _c("div", { staticClass: "form-row" }, [
          _c("div", { staticClass: "col" }, [
            _c("hr", { staticClass: "line" }),
            _c(
              "button",
              {
                staticClass:
                  "glowing-btn w-full h-12 mt-1 disabled:cursor-not-allowed",
                attrs: { disabled: !_vm.isValidForm, type: "submit" }
              },
              [_vm._v(" Verify Email ")]
            )
          ])
        ]),
        _c("div", { staticStyle: { color: "green" } }, [
          _vm._v(" " + _vm._s(_vm.successMessage) + " ")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }