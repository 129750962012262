var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cancel-page" }, [
    _c("div", { staticClass: "cancel-page__container" }, [
      _c("div", { staticClass: "cancel-page__container--header" }, [
        _c("h1", [_vm._v("Subscription is canceled")]),
        _c("p", [
          _vm._v(
            " You will no longer be able to watch premium content after " +
              _vm._s(_vm.getEndDate()) +
              " "
          )
        ])
      ]),
      _c("p", { staticClass: "notification" }, [
        _vm._v(" We’ve sent confirmation of the cancellation to: "),
        _c("span", [_vm._v(_vm._s(_vm.email))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }