<template>
  <div class="ChatHeader">
    <h4>LIVE CHAT</h4>
    <SwitchButton :checked="isChecked" @toggle="$emit('toggle')" />
    <div class="icon__views">
      <RouterLink to="/account" class="flex items-center mr-1">
        <font-awesome-icon
          @click="hideChat"
          class="close-button"
          icon="gear"
          style="font-size: 20px"
        />
      </RouterLink>

      <div v-if="isChecked" class="icon__views-user">
        <span class="dot"></span>
        <font-awesome-icon icon="user" />
      </div>
      <font-awesome-icon @click="hideChat" class="close-button" icon="close" />
    </div>
  </div>
</template>
<script>
import SwitchButton from '../SwitchButton.vue'

export default {
  components: {
    SwitchButton
  },
  props: {
    isChecked: {
      type: Boolean,
      default: true,
      required: true
    }
  },
  methods: {
    hideChat() {
      this.$emit('hide-chat')
    }
  }
}
</script>
<style lang="scss" scoped>
.ChatHeader {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  background: #162029;

  h4 {
    font-weight: 600;
    font-size: 1.25rem;
    margin-right: 10px;
  }

  @media (max-width: 1350px) {
    h4 {
      font-size: 1rem;
    }
  }

  .icon__views {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    gap: 10px;

    &-user {
      font-size: 18px;
      color: #747a7f;
      position: relative;
      width: 25px;
      height: 40px;

      .dot {
        height: 8px;
        width: 8px;
        background-color: rgba(0, 230, 64, 1);
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 5px;
        right: 0;
      }
      svg {
        position: absolute;
        bottom: 13px;
      }
    }
  }
}
.close-button {
  cursor: pointer;
  font-size: 23px;
  color: #747a7f;
}
</style>
