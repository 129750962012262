// check status/api
import api from '.'
// import { instance as axios } from '../plugins/axios'
// import { instanceStatus } from '../plugins/axios'
import { chatInstance } from '../plugins/axios'
// import { settings } from '@/library/variables'

export const chat = {
  async getStatusData(idVideo) {
    try {
      return await chatInstance.get('/v1/api/video/' + idVideo + '/setting')
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getUserInfo(idVideo) {
    try {
      return await chatInstance.get(
        '/v1/api/video/' + idVideo + '/participant/me'
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getParticipants(idVideo) {
    try {
      return await chatInstance.get(
        '/v1/api/video/' + idVideo + '/participants'
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getParticipantsCount(idVideo) {
    try {
      return await chatInstance.get(
        '/v1/api/video/' + idVideo + '/participants/count'
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async reportParticipants(payload) {
    try {
      const { username, videoId, messageId } = payload
      return await chatInstance.post(
        `/v1/api/video/${videoId}/participant/${username}/message/${messageId}/report`
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async fetchChatHistory(videoId) {
    try {
      return await chatInstance.get(`/v1/api/video/${videoId}/messages/latest`)
    } catch (err) {
      return api.response.error(err)
    }
  },
  async fetchStickers() {
    try {
      return await chatInstance.get(`/v1/api/stickers`)
    } catch (err) {
      return api.response.error(err)
    }
  }
}
