<template>
  <div class="modal-background">
    <div class="payment-form-container card-element">
      <form
        id="payment-form"
        @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
        @submit.prevent="submit"
        method="post"
      >
        <button
          v-on:click="$emit('close')"
          type="button"
          class="close-modal-btn"
        >
          &times;
        </button>
        <p class="payment-form-title">Update your card</p>
        <div class="form-row card-row">
          <p class="payment-form-sub-title">Email</p>
          <input
            :value="username"
            :disabled="true"
            class="field single-input-field"
          />
        </div>
        <div class="form-row card-row">
          <p class="payment-form-sub-title">Card information</p>
          <div id="card-element-card-number" class="field empty"></div>
          <div class="flex-element">
            <div
              id="card-element-card-expiry"
              class="field empty half-width"
            ></div>
            <div
              id="card-element-card-cvc"
              class="field empty half-width"
            ></div>
          </div>
          <div class="form-row card-row">
            <p class="payment-form-sub-title">Zip</p>
            <input v-model="zip" class="field single-input-field field-zip" />
          </div>
          <div v-show="errorMessage" id="card-errors" role="alert">
            {{ errorMessage }}
          </div>
        </div>

        <p v-if="loading" class="loading-msg">
          Update Payment Processing Please Wait ...
        </p>

        <button v-else class="submit-payment">Update Payment</button>
        <p class="payment-form-policy">
          By confirming your subscription, you allow Poker Central LLC to charge
          your card for this payment and future payments in accordance with
          their terms.
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import VueScript2 from 'vue-script2'
import { mapGetters } from 'vuex'

const stripeElementStyle = {
  base: {
    fontFamily: 'Uni Sans, Helvetica, Arial, sans-serif',
    cssSrc: '/pokergo/UniSans/fonts.css',
    fontSize: '16px',
    fontWeight: 'normal',
    backgroundColor: 'none', // $black-lighter
    color: '#333',
    '::placeholder': {
      color: '#ccc' // $gray-600
    },
    iconColor: '#ffffff'
  },
  invalid: {
    color: '#ff0000b5' // $primary
  }
}

let stripe
let elements
let cardNumber
let cardExpiry
let cardCvc
export default {
  name: 'StripeCardElement',
  props: ['subscription'],
  data() {
    return {
      valid: true,
      loading: false,
      zip: '',
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters(['user', 'getPropertyId']),
    username() {
      return this.user.basic ? this.user.basic.email : ''
    },
    property() {
      return this.getPropertyId
    }
  },
  async mounted() {
    // initialize zipCode
    this.zip = this.user.basic ? this.user.basic.postal_code : ''

    await VueScript2.load('https://js.stripe.com/v3/')
    stripe = window.Stripe(process.env.STRIPE_KEY)
    elements = stripe.elements({
      fonts: [{ cssSrc: 'https://use.typekit.net/wzs6wwk.css' }]
    })

    const elementClasses = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid'
    }

    cardNumber = elements.create('cardNumber', {
      style: stripeElementStyle,
      classes: elementClasses
    })
    cardNumber.mount('#card-element-card-number')

    cardExpiry = elements.create('cardExpiry', {
      style: stripeElementStyle,
      classes: elementClasses
    })
    cardExpiry.mount('#card-element-card-expiry')

    cardCvc = elements.create('cardCvc', {
      style: stripeElementStyle,
      classes: elementClasses
    })
    cardCvc.mount('#card-element-card-cvc')

    const __this = this
    cardNumber.on('change', function(event) {
      if (event.error) {
        __this.errorMessage = event.error.message
      } else {
        __this.errorMessage = ''
      }
    })
    cardExpiry.on('change', function(event) {
      if (event.error) {
        __this.errorMessage = event.error.message
      } else {
        __this.errorMessage = ''
      }
    })
    cardCvc.on('change', function(event) {
      if (event.error) {
        __this.errorMessage = event.error.message
      } else {
        __this.errorMessage = ''
      }
    })
  },
  watch: {
    zip() {
      this.errorMessage = ''
    }
  },
  methods: {
    async submit(ignoreErrors) {
      this.loading = true

      if (this.errorMessage) {
        this.loading = false
        return
      }

      const details = {}
      const { error, paymentMethod } = await stripe.createPaymentMethod(
        'card',
        cardNumber,
        details
      )
      if (error) {
        if (ignoreErrors && error.code === 'incomplete_number') {
          this.loading = false
          return error
        } else {
          this.loading = false
          throw new Error(error.message)
        }
      }

      // check zipcode

      if (!this.zip) {
        this.errorMessage = 'Postal code is required field'
        this.loading = false
        return
      }
      if (this.zip.length < 5 || this.zip.length > 10) {
        this.errorMessage = 'Postal code should be 5 - 10 length'
        this.loading = false
        return
      }
      const regex = new RegExp(/^[a-zA-Z0-9 ]*$/)
      if (!regex.test(this.zip)) {
        this.errorMessage = 'Postal code is invalid'
        this.loading = false
        return
      }

      this.errorMessage = ''

      try {
        await this.$store.dispatch('updatePaymentMethod', {
          transaction_id: '',
          payment_method_id: paymentMethod.id,
          postal_code: this.zip
        })

        setTimeout(() => {
          dataLayer.push({ event: 'UpdateCard' })
          console.log('pushed event for UpdateCard')

          console.log('Facebook updatecard complete event fired')
        }, 2000)

        this.$showMessage({
          title: 'Update Success',
          text: 'Payment Method Successfully Updated'
        })

        this.$emit('close')

        // refetch userdata which is changed in BE
        this.$store.dispatch('fetchUser')
      } catch (err) {
        const detail = err.response.data.errors[0].detail
        const content = JSON.parse(detail)
        this.errorMessage = content.message
      }

      this.loading = false
    }
  }
}
</script>
<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #0004;
  z-index: 900;
  backdrop-filter: blur(5px);
}
.close-modal-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  background: unset;
}
.validate-box {
  display: inline-flex;
}
.validate-box .single-input-field {
  font-weight: normal;
  color: #333 !important;
}
.field.single-input-field {
  border-radius: 4px;
}
.payment-form-sub-title {
  margin-bottom: 5px !important;
  font-size: 16px !important;
  font-weight: bold;
  color: #333333ad;
  text-align: left;
}
#card-element-card-number {
  border-bottom: none;
  border-radius: 5px 5px 0 0 !important;
}
#card-element-card-expiry {
  border-radius: 0 0 0 5px !important;
}
#card-element-card-cvc {
  border-radius: 0 0 5px 0 !important;
}
.flex-element {
  display: inline-flex;
  width: 100%;
}
.payment-form-container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  width: 90%;
  max-width: 500px;
  text-align: center;
  background-color: #fff;
  border-radius: 9px;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 170px);
  overflow-y: auto;
}
.loading-msg {
  color: black;
  text-align: center;
}
#payment-form {
  padding: 50px !important;
  background: #fff;
  border-radius: 9px;
}
.payment-form-title {
  font-size: 20px !important;
  font-weight: bold;
  color: #333;
  text-align: left;
}
.payment-form-policy {
  font-size: 0.8em !important;
  color: #ccc;
  text-align: center;
}
.payment-form-discount-invalid {
  color: #ff0000b5 !important;
}
.payment-form-discount-valid {
  color: rgb(15, 185, 13) !important;
}
input {
  color: #ccc !important;
}
#card-element {
  background-color: #44125d61;
}
.form-row {
  width: 100%;
  margin: 10px 0;
}
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.submit-validate {
  margin-left: 10px;
  font-weight: bold;
  color: #fff;
  background-color: #9f26b5;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 0 14px 0 #ca9090;
}
.submit-payment {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: 40px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #fff;
  background-color: #9f26b5;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 0 14px 0 #ca9090;
}
.submit-payment:hover,
.submit-payment:active {
  opacity: 0.8;
}
#card-errors {
  height: 20px;
  margin-top: 20px;
  font-size: 0.8em;
  color: #ff0000b5;
  background-color: transparent;
}

.card-element * {
  font-family: Quicksand, Open Sans, Segoe UI, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.card-element .fieldset {
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0 15px 30px;
  -ms-flex-pack: justify;
  border-style: none;
}

.card-element .field {
  width: 100%;
  padding: 10px 20px 11px;
  border: 1px solid #ccc;
}

.card-element .flex-element .field.half-width {
  width: 50%;
}

.card-element .field.third-width {
  width: calc(33% - (5px / 3));
}

.card-element .field.focus,
.card-element .field:focus {
  color: #424770;
  background-color: #f6f9fc;
}

.card-element .field.invalid.focus {
  background-color: #f6f9fc;
}

.card-element .field.focus::-webkit-input-placeholder,
.card-element .field:focus::-webkit-input-placeholder {
  color: #cfd7df;
}

.card-element .field.focus::-moz-placeholder,
.card-element .field:focus::-moz-placeholder {
  color: #cfd7df;
}

.card-element .field.focus:-ms-input-placeholder,
.card-element .field:focus:-ms-input-placeholder {
  color: #cfd7df;
}

.card-element input,
.card-element button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-style: none;
  outline: none;
}

.card-element input {
  color: #fff;
}

.card-element input::-webkit-input-placeholder {
  color: #9bacc8;
}

.card-element input::-moz-placeholder {
  color: #9bacc8;
}

.card-element input:-ms-input-placeholder {
  color: #9bacc8;
}

.card-element button {
  cursor: pointer;
}

.card-element button:active {
  background-color: #f5be58;
}

.card-element .error svg .base {
  fill: #fa755a;
}

.card-element .error svg .glyph {
  fill: #fff;
}

.card-element .error .message {
  color: #fff;
}

.card-element .success .icon .border {
  stroke: #fcd669;
}

.card-element .success .icon .checkmark {
  stroke: #fff;
}

.card-element .success .title {
  color: #fff;
}

.card-element .success .message {
  color: #9cabc8;
}

.card-element .success .reset path {
  fill: #fff;
}

input.field.single-input-field.field-zip {
  width: 100%;
  padding: 10px 20px 11px;
  border: 1px solid #ccc;
  color: #333 !important;
  font-weight: 500;
}
</style>
