var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "py-3 px-5 bg-white text-black cursor-pointer",
      on: { click: _vm.toggle }
    },
    [
      _c(
        "div",
        { staticClass: "text-xl font-bold flex justify-between items-center" },
        [
          _vm._v(" " + _vm._s(_vm.question) + " "),
          _c("font-awesome-icon", {
            attrs: { icon: !_vm.collapsed ? "chevron-up" : "chevron-down" }
          })
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.collapsed
          ? _c("div", { staticClass: "mt-3 leading-6" }, [
              _vm._v(" " + _vm._s(_vm.answer) + " ")
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }