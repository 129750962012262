import api from '@/api'
const { chat, home } = api
export default {
  async GetStatusData(state, payload) {
    let response = await chat.getStatusData(payload)
    if (response.status === 200) {
      console.log('GetStatusData', response)
    }
    return response
  },
  async fetchVideoDetails(state, videoId) {
    let response = await home.fetchVideoDetails(videoId)
    return response
  }
}
