var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "container min-height-700" },
      [
        _c("Banner", {
          staticClass: "banner_class",
          attrs: { page: "search", location: "header" }
        }),
        _c(
          "div",
          { staticClass: "flex items-center justify-between" },
          [
            _c("div", [
              _vm.showDidYouMean
                ? _c("span", [
                    _vm._v(" Did you mean: "),
                    _c(
                      "span",
                      {
                        staticClass: "font-bold hover:underline cursor-pointer",
                        on: { click: _vm.handleDidYouMeanClick }
                      },
                      [_vm._v(" " + _vm._s(_vm.didYouMean) + " ")]
                    )
                  ])
                : _vm._e()
            ]),
            _c("SearchFilters", {
              on: { handleFiltersChange: _vm.handleFiltersChange }
            })
          ],
          1
        ),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "search-wrapper" },
              [
                _c("loading-progress", {
                  attrs: {
                    progress: 60,
                    indeterminate: true,
                    "hide-background": true,
                    size: "50",
                    rotate: "",
                    fillDuration: "2",
                    rotationDuration: "1"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._l(_vm.entities, function(entity, index) {
          return _c(
            "div",
            { key: entity.id, staticClass: "mb-4" },
            [
              _c("LongCard", {
                attrs: {
                  "entity-type": entity.type,
                  "entity-id": entity.id,
                  "entity-data": entity,
                  "entity-lockbypass": true
                },
                on: {
                  click: function($event) {
                    return _vm.handleSearchResultsClick(entity, index)
                  }
                }
              }),
              _vm.showMeta
                ? _c("div", { staticClass: "-mt-5" }, [
                    _vm._v(
                      " SearchScore - " +
                        _vm._s(entity.score) +
                        ", PopularityScore -" +
                        _vm._s(entity.popular_number) +
                        ", Date - " +
                        _vm._s(entity.date) +
                        ", Tags - " +
                        _vm._s(entity.tags) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        }),
        _vm.entities.length === 0 && _vm.searched
          ? _c("div", { staticClass: "no-results" }, [
              _c("p", [
                _vm._v(
                  "Bad Flop. Please check your spelling or try a new search."
                )
              ])
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }