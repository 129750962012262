import api from '.'
import { instance as axios } from '../plugins/axios'
import { settings } from '@/library/variables'

export const checkout = {
  async getProduct() {
    try {
      return await axios.get(
        '/v2/properties/' + settings.propertyID + `/products`
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async setAgreementDetail(billing_agreement_id, access_token) {
    try {
      const body = {
        billing_agreement_id,
        access_token
      }

      return await axios.post(
        '/v2/properties/' +
          settings.propertyID +
          '/subscribers/me/purchase/amazon',
        JSON.stringify(body)
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async confirmAgreementDetail(billing_agreement_id) {
    try {
      const body = {
        billing_agreement_id
      }
      return await axios.post(
        '/v2/properties/' +
          settings.propertyID +
          '/subscribers/me/purchase/amazon/confirm',
        JSON.stringify(body)
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async authorizeAgreementDetail(billing_agreement_id, product_id) {
    try {
      const body = {
        billing_agreement_id,
        product_id
      }

      return await axios.post(
        '/v2/properties/' +
          settings.propertyID +
          '/subscribers/me/purchase/amazon/authorize',
        JSON.stringify(body)
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async payPost(username, plans, currentPlanID) {
    try {
      return await axios.post(
        '/v2/properties/' + settings.propertyID + '/subscribers/me/purchase',
        JSON.stringify({
          data: {
            id: '123',
            type: 'purchase',
            attributes: {
              store: 'stripe',
              external_entitlement_id: plans[currentPlanID].id,
              checkout: true,
              email: username,
              cancel: false,
              price: plans[currentPlanID].priceNumber,
              plan_type: plans[currentPlanID].type
            }
          }
        })
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async validateCoupon(couponData, checkoutData) {
    try {
      return await axios.post(
        '/v2/properties/' +
          settings.propertyID +
          '/subscribers/me/promovalidate',
        JSON.stringify({
          Code: couponData.coupon,
          PlanType: checkoutData.type,
          Price: parseFloat(checkoutData.priceNumber),
          Merchant: couponData.merchant
        })
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async submitPostPurchase(data) {
    try {
      return await axios.post(
        '/v2/properties/' + settings.propertyID + '/subscribers/me/purchase',
        JSON.stringify({
          data: {
            id: '12345',
            type: 'purchase',
            attributes: {
              store: data.store,
              external_entitlement_id: data.external_entitlement_id,
              checkout: true,
              email: data.email,
              cancel: false,
              promocode: data.promocode,
              payment_id: data.payment_id,
              price: parseFloat(data.price),
              plan_type: data.plan_type,
              clear_pending_paypal: data.clear_pending_paypal,
              zip: data.stripe_postal_code,
              phone_number: data.phone_number,
              address: data.address,
              city: data.city,
              state: data.state,
              postal_code: data.postal_code,
              link_id: data?.link_id,
              tenant_id: data?.tenant_id,
              source_id: data?.source_id
            }
          }
        })
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async submitUpgradeSubscription(data) {
    try {
      return await axios.post(
        '/v2/properties/' +
          settings.propertyID +
          '/subscribers/me/upgrade-subscription',
        data
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async submitAmazonPostPurchase(data, id) {
    try {
      return await axios.post(
        '/v2/properties/' +
          settings.propertyID +
          '/subscribers/' +
          id +
          '/purchase',
        JSON.stringify({
          data: {
            type: 'purchase',
            attributes: {
              store: 'amazon',
              external_entitlement_id: data.external_entitlement_id,
              checkout: true,
              email: data.email,
              cancel: false,
              promocode: data.promocode,
              payment_id: data.payment_id,
              price: data.price,
              plan_type: data.plan_type,
              data: data
            }
          }
        })
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async updatePaymentMethod(payload) {
    try {
      return await axios.post(
        '/v2/properties/' +
          settings.propertyID +
          '/subscribers/me/updatepaymentmethod',
        JSON.stringify(payload)
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async resumeSubscription(payload) {
    try {
      return await axios.post(
        '/v2/properties/' +
          settings.propertyID +
          '/subscribers/me/resumesubscription',
        JSON.stringify(payload)
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getPromocodeInformation(payload) {
    try {
      return await axios.get(
        '/v2/properties/' + settings.propertyID + `/promocodeinformation`,
        { params: payload }
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
