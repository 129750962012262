var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "home-container" },
        [
          _c("Banner", {
            staticClass: "banner_class",
            attrs: { page: "free-experience", location: "header" }
          }),
          _vm.featuredEntities.length
            ? _c(
                "div",
                [
                  _c("Row", {
                    attrs: {
                      title: "",
                      large: true,
                      "collection-entities": _vm.featuredEntity
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.collectionEntities, function(entity) {
            return _c("Row", {
              key: entity.id,
              attrs: {
                type: entity.type,
                title: entity.title,
                "collection-entities": entity.entities
              }
            })
          })
        ],
        2
      ),
      _c("PghModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }