<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TrainingEndText',
  props: {
    showBtn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['isSubscriber']),
    ...mapState({
      subscription: (state) => state.user.subscription
    })
  }
}
</script>

<template>
  <div class="flex flex-col items-center">
    <div class="text-center mb-10 text-2xl max-w-xl mx-5">
      Step into the VIP circle and transform your poker experience with
      unparalleled access to knowledge, strategy, and the poker community.
    </div>

    <div class="text-center mb-10 text-2xl max-w-xl mx-5">
      This is more than just a membership; it’s your ticket to mastering the
      game.
    </div>

    <router-link
      v-if="showBtn && !this.isSubscriber"
      :to="{
        name: 'Select a Plan',
        query: { plan: 'training_annual' }
      }"
      class="glowing-btn w-80 h-14 text-base mb-4"
    >
      Join PokerGO VIP
    </router-link>

    <router-link
      v-else-if="showBtn && this.subscription.store === 'stripe'"
      :to="{
        name: 'Upgrade',
        query: { plan: 'training_annual' }
      }"
      class="glowing-btn w-80 h-14 text-base mb-4"
    >
      Upgrade to PokerGO VIP
    </router-link>
  </div>
</template>

<style scoped lang="scss"></style>
