<template xmlns:v-model.trim="http://www.w3.org/1999/xhtml">
  <div class="upgrade-checkout centered">
    <promo-not-valid
      @close="showPromoInvalidModel = false"
      @ok="closePopup"
      v-show="showPromoInvalidModel"
    />
    <div class="side-menu">
      <plan-card
        v-for="upgradePlan in upgradePlans"
        :key="upgradePlan.id"
        :isSelected="upgradePlan.id === plan.id"
        :price="upgradePlan.price"
        :name="upgradePlan.name"
        :frequency="upgradePlan.frequency"
        @click="changePlan(upgradePlan)"
      />
    </div>
    <div>
      <p class="upgrade-desc">
        By confirming the subscription change below, you agree to the new price
        and billing period which will start today. Your account will be
        prorated/charged the difference.
      </p>

      <div class="promo-code" v-if="plan.country_group === 'default'">
        <span>Promo Code</span>
        <SwitchButton
          :checked="showPromo"
          @toggle="togglePromo"
          :showLabel="false"
        />
      </div>
      <div
        class="form-row"
        v-if="showPromo && plan.country_group === 'default'"
      >
        <div class="col">
          <label class="coupon-title">PROMO CODE</label>
          <div class="flex-row-center">
            <input
              v-model="coupon"
              class="input"
              type="text"
              :disabled="processing"
              @input="checkEmptyPromo"
            />
            <button
              class="register-button validate button"
              type="button"
              @click="couponChange"
              :disabled="processing"
            >
              Validate
            </button>
          </div>
          <div
            v-if="validated && coupon.length"
            class="payment-form-promo"
            :class="promo.Valid ? 'valid' : 'invalid'"
          >
            {{ discountMessage }}
          </div>
        </div>
      </div>
      <button
        class="glowing-btn w-full h-14 uppercase mt-3"
        @click="submit"
        :disabled="processing"
      >
        <span v-if="!processing">
          CHANGE SUBSCRIPTION {{ checkoutPrice.symbol
          }}{{ checkoutPrice.price }}
        </span>
        <span v-else>
          please wait...
          <font-awesome-icon icon="spinner" class="fa-pulse ml-5" />
        </span>
      </button>
    </div>

    <ConfirmationDialog :modelOptions="modelOptions" v-if="modelOptions.open" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import PlanCard from '@/components/PlanCard.vue'
import SwitchButton from '@/components/SwitchButton.vue'
import PromoNotValid from '../../register/components/PromoNotValidModal'
import { upgradablePlans } from '@/library/config/plans'

export default {
  name: 'UpgradeCheckout',
  components: {
    PromoNotValid,
    ConfirmationDialog,
    PlanCard,
    SwitchButton
  },
  data() {
    return {
      showPromoInvalidModel: false,
      upgradePlans: [],
      cardNumber: undefined,
      cardExpiry: undefined,
      cardCvc: undefined,
      coupon: '',
      elements: undefined,
      paymentClient: 'stripe',
      showPaypalModal: false,
      showPopup: false,
      stripe: undefined,
      validated: false,
      isValidCoupon: true,
      payload: {},
      showPromo: false,
      modelOptions: {
        open: false,
        header: '',
        description: '',
        type: 'success',
        onClose: () => {}
      }
    }
  },
  computed: {
    ...mapState({
      processing: (state) => state.checkout.controls.loading,
      promo: (state) => state.checkout.promo,
      plans: (state) => state.checkout.plans,
      selectedPlan: (state) => state.checkout.selectedPlan,
      subscription: (state) => state.user.subscription,
      promoCodeDetails: (state) => state.checkout.promoCodeDetails
    }),
    ...mapGetters({
      getCheckoutPrice: 'checkoutPrice'
    }),
    sku() {
      if (this.$route.query.sku) {
        return this.$route.query.sku.toLowerCase()
      } else {
        return this.$route.params.productType.toLowerCase()
      }
    },
    checkoutPrice() {
      if (!this.showPromo) {
        this.$store.commit('resetPromo')
      }
      return this.getCheckoutPrice(this.plan)
    },
    discountMessage() {
      if (!this.coupon.length || !this.validated) return
      var msg = ''
      if (this.validated) {
        if (
          this.promo.Message == 'Promo has expired' ||
          this.promo.Message == 'Promo has reached max redemptions'
        ) {
          msg = 'Promo code has expired'
        } else {
          msg = this.promo.Description
        }
      }
      return msg
    },
    productType() {
      return this.$route.params.productType
    },
    plan() {
      return this.planIDX > -1 ? this.plans[this.planIDX] : {}
    },
    planIDX() {
      return this.plans.findIndex((p) => this.productType === p.product_type)
    }
  },
  async mounted() {
    // check if user has a subscribed plan
    if (!this.subscription.interval) {
      this.$router.push('/account')
      return
    }

    // check if user already subscribed same plan (to change) or `annual` plan
    if (
      this.subscription.interval === this.sku ||
      this.subscription.interval === 'training_annual'
    ) {
      this.$router.push('/')
      return
    }

    if (this.promoCodeDetails) {
      this.showPromo = true
      this.coupon = this.promoCodeDetails.Code
      this.couponChange()
    }

    // check if there are plans
    if (!this.plans?.length) {
      await this.$store.dispatch('getPlans')
    }

    // re-init selectedPlan considering direct link
    this.$store.commit('selectPlan', this.plan)

    // init plans to show on left side
    this.upgradePlans = this.plans.filter((el) => {
      if (this.promoCodeDetails) {
        return el.product_type === this.promoCodeDetails.Interval
      } else {
        return upgradablePlans[this.subscription.interval].includes(
          el.product_type
        )
      }
    })
  },
  watch: {
    coupon() {
      this.validated = false
    }
  },
  methods: {
    closePopup() {
      this.showPromo = false
      this.coupon = ''
      this.showPromoInvalidModel = false
    },
    changePlan(upgradePlan) {
      this.$router.push(
        `/account/change-plan/checkout/${upgradePlan.product_type}`
      )
    },
    togglePromo() {
      this.showPromo = !this.showPromo
      this.coupon = ''
    },
    checkEmptyPromo() {
      if (!this.coupon || !this.coupon.length) {
        this.$store.commit('selectPromo', {})
      }
    },
    couponChange() {
      this.validated = false
      if (this.coupon) {
        this.validateCoupon()
      }
    },
    validateCoupon() {
      if (!this.validated) {
        this.coupon = this.coupon.trim()
        return this.$store
          .dispatch('checkPromo', {
            coupon: this.coupon.toUpperCase(),
            merchant: this.paymentClient,
            period: this.productType
          })
          .then((r) => {
            this.validated = true
            this.isValidCoupon = r?.Valid || false
          })
      }
    },
    async submit() {
      try {
        this.coupon = this.coupon.trim()
        if (this.coupon && this.showPromo) {
          await this.validateCoupon()
          if (!this.isValidCoupon) {
            this.showPromoInvalidModel = true
            return
          }
        }

        const payload = {
          coupon: this.coupon.toUpperCase() || undefined,
          product_type: this.plan.product_type
        }

        this.$store.commit('checkoutControls', { loading: true })
        this.$store
          .dispatch('SubmitUpgradeSubscription', payload)
          .then((resp) => {
            if (resp.status !== 200) {
              this.modelOptions = {
                open: true,
                header: 'ERROR',
                description:
                  resp.message ||
                  'The subscription cannot be upgraded due to network issue, please try again later.',
                type: 'error',
                onClose: () => {
                  this.modelOptions.open = false
                }
              }
            } else {
              this.modelOptions = {
                open: true,
                header: 'CHANGED',
                description:
                  'Your subscription plan has been successfully changed.',
                type: 'success',
                onClose: () => {
                  this.$router.push('/account')
                }
              }
            }
            this.$store.commit('checkoutControls', { loading: false })
            return resp.data.data
          })
      } catch (e) {
        console.log('purchase error: ', e)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.upgrade-checkout {
  width: 70%;
  max-width: 700px;
  min-width: 600px;
  margin: 3rem;
  position: relative;
  min-height: 330px;
}
@media screen and (max-width: 768px) {
  .upgrade-checkout {
    width: 80%;
    min-width: 400px;
  }
}
@media screen and (max-width: 400px) {
  .upgrade-checkout {
    width: 80%;
    min-width: 300px;

    .upgrade-desc {
      font-size: 20px;
    }
  }
}
.coupon-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
}
.coupon-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .validate-coupon-button {
    background-color: #9f26b5;
    width: 40%;
    line-height: 45px;
    height: 45px;
    font-size: 16px;
    color: white;
    outline: none;
    border: none;
    border-radius: 6px;
    margin-left: 10px;
    font-weight: bold;
  }
}
.upgrade-button {
  width: 100%;
  max-width: 100%;
  font-size: 20px;

  @media (max-width: 400px) {
    font-size: 17px;
  }
}
.payment-form-promo {
  font-size: 0.9em !important;
  text-align: center;
  font-family: 'UniSans-Bold';
  margin: auto;
  height: 2rem;
  padding-top: 1rem;
  &.valid {
    color: #62b212 !important;
  }
  &.invalid {
    color: #cc0829 !important;
  }
}
.side-menu {
  display: flex;
  justify-content: space-evenly;
  min-height: 130px;
  width: calc(50vw - 400px);
  max-width: 250px;
  transform: translateX(-270px) translateY(-165px);
  position: absolute;
  flex-direction: column;

  @media (max-width: 1030px) {
    width: 100%;
    max-width: 100%;
    position: static;
    transform: none;
  }
}
.promo-code {
  display: flex;
  justify-content: flex-end;
  height: 25px;
  line-height: 25px;
  padding-right: 5px;
  margin-bottom: 10px;
  span {
    line-height: 25px;
    height: 25px;
    font-size: 14px;
    letter-spacing: 0;
    margin-right: 2rem;
  }
}
.upgrade-desc {
  font-size: 24px;
}
</style>
