var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center " }, [
      _c("div", { staticClass: "w-10 md:w-auto mx-auto" }, [
        _c("img", {
          staticClass: "w-full",
          attrs: { src: require("@/assets/images/training/play-btn.png") }
        })
      ]),
      _c("div", { staticClass: "opacity-50 text-xs" }, [_vm._v(" Play Video ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }