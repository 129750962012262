<script>
import { mapState } from 'vuex'

export default {
  name: 'Banner',
  props: {
    page: {
      type: String,
      required: true
    },
    location: {
      type: String,
      required: true
    }
  },
  data() {
    const numberOfBanners = 8
    return {
      bannerIndex: Math.floor(Math.random() * numberOfBanners) + 1,
      deviceType: 'web'
    }
  },
  computed: {
    ...mapState({
      bannersList: (state) => state.other.bannersList
    }),
    currentBanner() {
      return this.bannersList.find(
        (el) => el.page === this.page && el.location === this.location
      )
    },
    currentBannerURL() {
      if (this.deviceType === 'web') {
        return this.currentBanner && this.currentBanner.desktop_url
      }
      return this.currentBanner && this.currentBanner.mobile_url
    },
    currentBannerLink() {
      return this.currentBanner && this.currentBanner.ad_link
    }
  },
  watch: {
    currentBanner: {
      handler(value) {
        if (value) {
          dataLayer.push({
            banner_name: value.name,
            event: 'banner_impression',
            conversionValue: 1
          })
          console.log(`Banner ${value.name} impression`)
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.updateDeviceType()
    window.addEventListener('resize', this.updateDeviceType)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateDeviceType)
  },
  methods: {
    updateDeviceType() {
      if (window.innerWidth < 971) {
        this.deviceType = 'mobile'
      } else {
        this.deviceType = 'web'
      }
    },
    handleBannerClick() {
      dataLayer.push({
        banner_name: this.currentBanner.name,
        conversionValue: 1,
        event: 'banner_click'
      })
      console.log(`Banner ${this.currentBanner.name} clicked`)
    }
  }
}
</script>

<template>
  <div class="banner_container" v-if="currentBannerURL">
    <a :href="currentBannerLink" target="_blank" @click="handleBannerClick">
      <img :src="currentBannerURL" alt="top_banner" />
    </a>
  </div>
</template>

<style scoped lang="scss">
.banner_container {
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
  }
}

@media (min-width: 971px) {
  .banner_container {
    img {
      width: 970px;
    }
  }
}
</style>
