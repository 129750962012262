<template>
  <div>
    <Header />
    <div>
      <slot />
    </div>
    <Footer />
    <notifications group="messages" position="bottom right" />
  </div>
</template>
<script lang="js">
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'DefaultLayout',
  components: { Header, Footer }
}
</script>
