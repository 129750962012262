<template>
  <div class="register-checkout centered">
    <promo-not-valid
      @close="showPromoInvalidModel = false"
      @ok="closePopup"
      v-show="showPromoInvalidModel"
    />
    <div class="side-menu">
      <div class="label">
        <span>SUBSCRIPTION</span>
      </div>
      <div class="plan-recall">
        <div class="inner">
          <span class="bold">{{ plan.name }}</span>
          <div class="money">
            <div class="usd-99">US ${{ plan.price }}</div>
            <div class="price">
              <span v-if="plan">
                <span v-if="plan.product_type != 'monthly'"> US </span>
                <span>{{ plan.priceDescription }}</span>
              </span>
            </div>
            <div class="flex justify-center">
              <router-link
                to="/select-plan"
                class="text-tw_primary-300 text-xs underline float-right hover:text-tw_primary-300"
                style="font-family: UniSans-SemiBold"
              >
                Change
              </router-link>
            </div>
          </div>
        </div>

        <div class="auto-renews">
          <span class="opacity-50">Plan automatically renews</span>
        </div>
      </div>
      <!--  -->
      <div class="external-payment">
        <div class="label">
          <span>PAYMENT</span>
        </div>
        <div class="payment-box">
          <div class="list-item">
            <div class="item" @click="selectPayment('stripe')">
              <img src="@/assets/images/credit cards-group.svg" width="120" />
              <RadioInput
                name="method"
                value="stripe"
                id="stripe"
                :isChecked="true"
                @change="selectPayment"
              />
            </div>
            <div
              class="item"
              @click="selectPayment('paypal')"
              v-if="
                plan.country_group === 'default' &&
                  !isTrackdeskUser &&
                  plan.product_type !== 'training_annual'
              "
            >
              <img src="@/assets/images/PayPal.svg" width="120" />
              <RadioInput
                name="method"
                value="paypal"
                id="paypal"
                @change="selectPayment"
              />
            </div>

            <div
              class="amazon-payment"
              id="AmazonPayButton"
              style="display: none !important"
            >
              <div class="amazon-pay-button" @click="triggerAmazonButton">
                <div class="round">
                  <input type="checkbox" />
                  <label for="AmazonPayButton"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="external-payments" style="display: none">
        <div class="creditcard-payment">
          <button @click="clickCreditCard" :disabled="isPaymentProcessing" />
        </div>
        <div class="amazon-payment">
          <div id="AmazonPayButton" class="amazon-pay-button"></div>
          <div id="Logout" class="amazon-pay-button"></div>
        </div>
        <div class="paypal-payment">
          <button
            class="paypal-button"
            @click="clickPaypal"
            :disabled="isPaymentProcessing"
          >
            <img
              src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_150x38.png"
              alt="PayPal"
            />
          </button>
        </div>
      </div>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <div v-show="paymentClient === 'stripe'">
        <form
          id="payment-form"
          class="register-form"
          @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
          @submit.prevent="handleSubmit(submit)"
          method="post"
        >
          <span class="experiment-form">
            <div class="form-row">
              <div class="col">
                <label>Phone Number</label>
                <ValidationProvider rules="phone" v-slot="{ errors }">
                  <input
                    class="input-field"
                    id="card-element-phone"
                    placeholder="Phone Number"
                    v-model="phone_number"
                    :disabled="isPaymentProcessing"
                    type="tel"
                  />
                  <span class="errors">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <label>Address</label>
                <input
                  class="input-field"
                  id="card-element-card-address"
                  placeholder="Address"
                  v-model="address"
                  :disabled="isPaymentProcessing"
                />
              </div>
            </div>
            <div class="form-row" :class="{ mobile_row: isMobile }">
              <div class="col">
                <input
                  class="input-field"
                  id="card-element-card-city"
                  placeholder="City"
                  v-model="city"
                  :disabled="isPaymentProcessing"
                />
              </div>
              <div class="col">
                <input
                  class="input-field"
                  id="card-element-card-state"
                  placeholder="State"
                  v-model="state"
                  :disabled="isPaymentProcessing"
                />
              </div>
              <div class="col">
                <input
                  class="input-field"
                  id="card-element-card-postal"
                  placeholder="Postal Code"
                  v-model="postal_code"
                  :disabled="isPaymentProcessing"
                />
              </div>
            </div>
          </span>
          <div class="form-row">
            <div class="col">
              <label>Credit Card information *</label>
              <div id="card-element-card-number" class="input"></div>
            </div>
          </div>
          <div class="form-row" :class="{ mobile_row: isMobile }">
            <div class="col">
              <div id="card-element-card-expiry" class="input"></div>
            </div>
            <div class="col">
              <div id="card-element-card-cvc" class="input"></div>
            </div>
            <div class="col">
              <ValidationProvider rules="required|zip" v-slot="{ errors }">
                <input
                  class="input-field"
                  id="Postal Code"
                  placeholder="Billing Postal Code"
                  v-model="zip"
                  :disabled="isPaymentProcessing"
                />
                <span class="errors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </div>
      <form class="register-form" @submit.prevent="handleSubmit(submit)">
        <div class="form-row" v-show="errorMessage">
          <div class="col">
            <div id="card-errors" class="error" role="alert">
              {{ errorMessage }}
            </div>
          </div>
        </div>
        <div class="promo-code" v-show="paymentClient !== 'amazon'">
          <span>Promo Code</span>
          <SwitchButton
            :checked="showPromo"
            @toggle="togglePromo"
            :showLabel="false"
          />
        </div>
        <div class="form-row" v-if="showPromo">
          <div class="col" style="width: 80%">
            <div class="form-control">
              <label>Promo Code</label>
              <div class="flex-row-center">
                <input
                  v-model="coupon"
                  class="input"
                  type="text"
                  :disabled="isPaymentProcessing"
                  @input="checkEmptyPromo"
                />
                <button
                  class="register-button validate button"
                  type="button"
                  @click="couponChange"
                  :disabled="isPaymentProcessing"
                >
                  Validate
                </button>
              </div>
              <div
                v-if="validated && coupon.length"
                class="payment-form-promo"
                :class="promo.Valid ? 'valid' : 'invalid'"
              >
                {{ discountMessage }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-row" v-show="paymentClient === 'stripe'">
          <div class="col">
            <button
              class="glowing-btn w-full h-12 mb-4"
              @click.stop="handleSubmit(submit)"
              :disabled="isPaymentProcessing"
            >
              <span v-if="!isPaymentProcessing">
                Subscribe
                <span>
                  {{ checkoutPrice.symbol }}{{ checkoutPrice.price }}
                </span>
              </span>
              <span v-else>
                <font-awesome-icon icon="spinner" class="fa-pulse" />
              </span>
            </button>
            <p class="payment-form-policy">
              By confirming your subscription, you allow PokerGO to charge your
              card for this payment and future payments in accordance with their
              terms.
            </p>
          </div>
        </div>
      </form>
      <div class="form-row" v-show="paymentClient === 'paypal'">
        <div class="col">
          <button
            class="glowing-btn w-full h-12 mb-4"
            @click.stop="shoeBankWarningModel"
            :disabled="isPaymentProcessing"
          >
            <span v-if="!isPaymentProcessing">
              Continue to PayPal
              <span> {{ checkoutPrice.symbol }}{{ checkoutPrice.price }} </span>
            </span>
            <span v-else>
              <font-awesome-icon icon="spinner" class="fa-pulse" />
            </span>
          </button>
          <p class="payment-form-policy">
            By confirming your subscription, you allow PokerGO to charge your
            card for this payment and future payments in accordance with their
            terms.
          </p>
        </div>
      </div>
      <div class="form-row" v-show="paymentClient === 'amazon'">
        <div class="col">
          <button
            class="register-button button"
            @click.stop="triggerAmazonButton"
            :disabled="isPaymentProcessing"
          >
            Continue to Amazon
            <span> {{ checkoutPrice.symbol }}{{ checkoutPrice.price }} </span>
          </button>
          <p class="payment-form-policy">
            By confirming your subscription, you allow PokerGO to charge your
            card for this payment and future payments in accordance with their
            terms.
          </p>
        </div>
      </div>
    </ValidationObserver>
    <paypal-pending-modal
      v-if="modalPending"
      @yes="waitModal"
      @no="continueSubmit"
      :pending="isPending"
    />
    <paypal-wait-modal v-if="modalWait" @ok="goToAccount" />
    <paypal-bank-warning-modal
      @cancel="closeBankWarningModel"
      @continue="completePaypal"
      v-if="showBankWarningModel"
    />
  </div>
</template>

<script>
import VueScript2 from 'vue-script2'
import { mapGetters, mapState } from 'vuex'
import { config, getWidgetsJsURL } from '@/views/checkout/amazon/config'
import PaypalPendingModal from '@/components/PaypalPendingModal.vue'
import PaypalWaitModal from '@/components/PaypalWaitModal.vue'
import RadioInput from '@/components/RadioInput.vue'
import SwitchButton from '@/components/SwitchButton.vue'
import PaypalBankWarningModal from '@/components/PaypalBankWarningModal.vue'
import { extend } from 'vee-validate'
import PromoNotValid from './PromoNotValidModal'

extend('phone', (value) => {
  if (value.length < 5 || value.length > 20) {
    return 'Phone Number should be 5 - 20 length'
  }
  const regex = new RegExp(/^[0-9- ]*$/)
  if (!regex.test(value)) {
    return 'Phone Number should be number'
  }

  return true
})

extend('zip', (value) => {
  if (value.length < 5 || value.length > 10) {
    return 'Zip code should be 5 - 10 length'
  }
  const regex = new RegExp(/^[a-zA-Z0-9 ]*$/)
  if (!regex.test(value)) {
    return 'Zip code is invalid'
  }

  return true
})

const stripeElementStyle = {
  base: {
    fontFamily: 'Uni Sans, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 'normal',
    iconColor: '#ffffff'
  },
  invalid: {
    color: '#ff0000b5' // $primary
  }
}

export default {
  name: 'RegisterCheckout',
  components: {
    PromoNotValid,
    PaypalPendingModal,
    PaypalWaitModal,
    RadioInput,
    SwitchButton,
    PaypalBankWarningModal
  },
  data() {
    return {
      cardNumber: undefined,
      cardExpiry: undefined,
      cardCvc: undefined,
      zip: undefined,
      address: undefined,
      phone_number: undefined,
      city: undefined,
      state: undefined,
      postal_code: undefined,
      coupon: '',
      elements: undefined,
      paymentClient: 'stripe',
      preventMultiSubmit: false,
      stripe: undefined,
      validated: false,
      isValidCoupon: true,
      couponPrice: 0,
      transaction: {},
      modalPending: false,
      modalWait: false,
      payload: {},
      showPromo: false,
      isPending: false,
      errorMessage: '',
      testEnabled: true,
      showBankWarningModel: false,
      showPromoInvalidModel: false,
      isTrackdeskUser: true
    }
  },
  computed: {
    code() {
      return this.$route.query.code || localStorage.getItem('promo')
    },
    sku() {
      if (this.$route.query.sku && this.$route.query.sku !== 'undefined') {
        return this.$route.query.sku
      }
      return null
    },
    ...mapState({
      processing: (state) => state.checkout.controls.loading,
      promo: (state) => state.checkout.promo,
      plans: (state) => state.checkout.plans,
      checkoutError: (state) => state.checkout.controls.error,
      promoCodeDetails: (state) => state.checkout.promoCodeDetails
    }),
    ...mapGetters({
      getCheckoutPrice: 'checkoutPrice',
      getPropertyId: 'getPropertyId',
      getEmail: 'getEmail'
    }),
    checkoutPrice() {
      if (!this.showPromo) {
        this.$store.commit('resetPromo')
      }
      return this.getCheckoutPrice(this.plan)
    },
    discountMessage() {
      if (!this.coupon.length || !this.validated) return
      var msg = ''
      if (this.validated) {
        if (
          this.promo.Message == 'Promo has expired' ||
          this.promo.Message == 'Promo has reached max redemptions'
        ) {
          msg = 'Promo code has expired'
        } else {
          msg = this.promo.Description
        }
      }
      return msg
    },
    productType() {
      return this.$route.params.productType
    },
    plan() {
      return this.planIDX > -1 ? this.plans[this.planIDX] : {}
    },
    planIDX() {
      return this.plans.findIndex((p) => this.productType === p.product_type)
    },
    property() {
      return this.getPropertyId
    },
    username() {
      return this.getEmail || ''
    },
    isPaymentProcessing() {
      return this.processing || this.preventMultiSubmit
    },
    isMobile() {
      return window.innerWidth <= 768
    }
  },
  async mounted() {
    console.log(this.plan)
    await Promise.all([this.fetchTransactions()])
    await VueScript2.load('https://js.stripe.com/v3/')
    this.stripe = window.Stripe(
      process.env.STRIPE_KEY || 'pk_test_kbodKK6lY1o02ZI3w9rOw1Ln00JU0YUHjz'
    )
    this.elements = this.stripe.elements({
      fonts: [{ cssSrc: 'https://use.typekit.net/wzs6wwk.css' }]
    })
    const elementClasses = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid'
    }
    this.cardNumber = this.elements.create('cardNumber', {
      style: stripeElementStyle,
      classes: elementClasses
    })
    this.cardNumber.mount('#card-element-card-number')

    this.cardExpiry = this.elements.create('cardExpiry', {
      style: stripeElementStyle,
      classes: elementClasses
    })
    this.cardExpiry.mount('#card-element-card-expiry')

    this.cardCvc = this.elements.create('cardCvc', {
      style: stripeElementStyle,
      classes: elementClasses
    })
    this.cardCvc.mount('#card-element-card-cvc')

    this.cardCvc.on('change', () => {
      this.errorMessage = ''
    })
    this.cardExpiry.on('change', () => {
      this.errorMessage = ''
    })
    this.cardNumber.on('change', () => {
      this.errorMessage = ''
    })

    // init coupon value with route query value
    if (this.promoCodeDetails) {
      this.coupon = this.promoCodeDetails.Code
      this.showPromo = true
      this.couponChange()
    }

    const trackdeskQuery = localStorage.getItem('trackdesk')
    this.isTrackdeskUser = !!trackdeskQuery
  },
  async beforeMount() {
    if (this.planIDX > -1) {
      await this.amazonPayment()
    }
  },
  watch: {
    coupon() {
      this.validated = false
    },
    checkoutError() {
      this.errorMessage = this.checkoutError

      if (this.processing === false && this.checkoutError !== undefined) {
        // if api call fails
        this.preventMultiSubmit = false
      }
    }
  },
  methods: {
    closePopup() {
      this.resetPromo()
      this.showPromo = false
      this.showPromoInvalidModel = false
    },
    selectPayment(val) {
      this.paymentClient = val
      this.resetPromo()
      this.showPromo = false
      document.getElementById(val).checked = true

      // reset error msg
      this.errorMessage = ''
    },
    triggerAmazonButton() {
      var x = document.getElementsByClassName('amazonpay-button-inner-image')
      x[0].click()
    },
    togglePromo() {
      this.showPromo = !this.showPromo
      if (!this.showPromo) {
        this.validated = false
        this.coupon = ''
      }
    },
    async fetchTransactions() {
      return await this.$store
        .dispatch('account/FetchTransactions')
        .then((rs) => {
          if (rs.status !== 200) {
            this.$toasted.error('Error please contact customer service')
            return
          }
          this.transaction = rs.data.data
          return rs
        })
    },
    async amazonPayment() {
      // Amazon Pay native triggers
      window.onAmazonLoginReady = () => {
        try {
          window.amazon.Login.setClientId(config.client_id)
          window.amazon.Login.setUseCookie(true)
        } catch (err) {
          alert(err)
        }
      }
      window.onAmazonPaymentsReady = () => {
        var authRequest
        const __this = this

        window.OffAmazonPayments.Button('AmazonPayButton', config.merchant_id, {
          type: 'PwA', // PwA, Pay, A, LwA, Login
          color: 'DarkGray', // Gold, LightGray, DarkGray
          size: 'medium', // small, medium, large, x-large
          language: 'en-GB', // for Europe/UK regions only: en-GB, de-DE, fr-FR, it-IT, es-ES
          authorization: () => {
            if (!__this.isPaymentProcessing) {
              const loginOptions = {
                scope:
                  'profile postal_code payments:widget payments:shipping_address',
                popup: true
              }
              authRequest = window.amazon.Login.authorize(
                loginOptions,
                'checkout/amazon?planName=' +
                  this.plan.product_type +
                  '&planIDX=' +
                  this.planIDX
              )
            }
          },
          onError: (error) => {
            // something bad happened
            console.log(error)
          }
        })
      }
      this.$unloadScript(getWidgetsJsURL(config)).finally(() => {
        this.$loadScript(getWidgetsJsURL(config))
          .then(() => console.log('loaded amazon pay script'))
          .catch(() => console.log('failed to load amazon pay script'))
      })
    },
    clickPaypal() {
      this.errorMessage = ''
      this.paymentClient = 'paypal'
      this.resetPromo()
    },
    clickCreditCard() {
      this.errorMessage = ''
      this.paymentClient = 'stripe'
      this.resetPromo()
    },
    async completePaypal(event) {
      this.showBankWarningModel = false
      if (this.coupon) {
        await this.validateCoupon()
        if (!this.isValidCoupon) {
          return
        }
      }
      if (!this.promo.Valid) {
        this.coupon = ''
      }

      this.payload = {
        client: 'paypal',
        coupon: this.coupon.toUpperCase(),
        id: event.id,
        externalID: this.plan.paypal_product_id,
        store: 'paypal',
        plan: this.plan
      }
      if (this.transaction.subscription_status === 'Pending') {
        this.modalPending = true
      } else {
        this.$emit('subscribe', this.payload)
      }
    },
    checkEmptyPromo() {
      if (!this.coupon || !this.coupon.length) {
        this.$store.commit('selectPromo', {})
      }
    },
    couponChange() {
      this.validated = false
      if (this.coupon) {
        this.validateCoupon()
      }
    },
    resetPromo() {
      this.coupon = ''
      this.isValidCoupon = false
      this.checkEmptyPromo()
    },
    validateCoupon() {
      if (!this.validated) {
        this.coupon = this.coupon.trim()
        return this.$store
          .dispatch('checkPromo', {
            coupon: this.coupon.toUpperCase(),
            merchant: this.paymentClient,
            period: this.productType
          })
          .then((r) => {
            this.validated = true
            this.isValidCoupon = r?.Valid || false
            this.couponPrice = r?.Price || 0
          })
      }
    },
    waitModal() {
      this.modalPending = false
      this.modalWait = true
    },
    goToAccount() {
      this.$router.push('/account')
    },
    async continueSubmit() {
      this.isPending = true
      this.$emit('subscribe', { ...this.payload, clear_pending_paypal: true })
    },
    async submit() {
      try {
        if (this.preventMultiSubmit) return
        if (this.showPromo && !this.isValidCoupon) {
          this.showPromoInvalidModel = true
          return
        }
        this.preventMultiSubmit = true

        this.errorMessage = ''

        // validate coupon code if any
        this.coupon = this.coupon.trim()
        if (this.coupon) {
          await this.validateCoupon()
          if (!this.isValidCoupon) {
            this.preventMultiSubmit = false // enable to submit
            return
          }
        }

        const { error, paymentMethod } = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.cardNumber,
          billing_details: {
            address: {
              postal_code: this.zip
            }
          }
        })
        if (error) {
          this.errorMessage = error.message
          this.preventMultiSubmit = false // enable to submit
          return error
        }

        this.payload = {
          coupon: this.coupon.toUpperCase(),
          id: paymentMethod.id,
          externalID: this.plan.stripe_product_id,
          store: 'stripe',
          plan: this.plan,
          phone_number: this.phone_number,
          address: this.address,
          city: this.city,
          state: this.state,
          postal_code: this.postal_code,
          stripe_postal_code: this.zip,
          couponPrice:
            this.isValidCoupon && this.couponPrice ? this.couponPrice : 0
        }
        console.log('payload', this.payload)
        if (this.transaction.subscription_status === 'Pending') {
          this.modalPending = true
        } else {
          this.$emit('subscribe', this.payload)
        }
      } catch (e) {
        this.preventMultiSubmit = false // enable to submit
        console.log('purchase error: ', e)
      }
    },
    closeBankWarningModel() {
      this.showBankWarningModel = false
    },
    shoeBankWarningModel() {
      if (this.showPromo && !this.isValidCoupon) {
        this.showPromoInvalidModel = true
        return
      }
      this.showBankWarningModel = true
    }
  }
}
</script>
<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #232a30 inset !important;
  -webkit-text-fill-color: #ffffff;
  opacity: 0.42;
}
#payment-form {
  z-index: 0;
}
.register-checkout {
  width: 70%;
  max-width: 700px;
  min-width: 600px;
  margin: 3rem;
  position: relative;
}
.amazon-payment {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 20px !important;
}
@media screen and (max-width: 768px) {
  .register-checkout {
    width: 80%;
    min-width: 400px;
  }
}

@media screen and (max-width: 400px) {
  .register-checkout {
    width: 80%;
    min-width: 300px;
  }
}
.side-menu {
  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    .ll {
      text-decoration: underline;
    }
    span {
      color: #ffffff;
      font-size: 14px;
      letter-spacing: 0;
      font-family: 'UniSans-Bold';
    }
    a {
      color: #9f26b5;
      font-size: 14px;
      letter-spacing: 0;
    }
  }
  @media (max-width: 1030px) {
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    position: static;
    transform: none;
  }

  .plan-recall {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    border: 1px solid $light-gray;
    margin-bottom: 2em;
    border-radius: 6px;
    background-color: #ffffff;
    @media (max-width: 1030px) {
      // width: fit-content;
      // .plan-recall {
      //   width: 100%;
      // }
    }
    span:first-child {
      font-size: 11px;
    }
    span.bold {
      font-size: 18px;
      color: #10181f;
      font-size: 18px;
      letter-spacing: 0;
      align-items: center;
      display: flex;
      font-family: 'UniSans-Bold';
    }
    .inner {
      display: flex;
      justify-content: space-between;
      .money {
        align-items: center;
        text-align: end;
        .usd-99 {
          color: #10181f;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 21px;
          font-family: 'UniSans-Bold';
          text-align: center;
        }
        .price {
          opacity: 0.5;
          color: #10181f;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 21px;
          text-align: right;
          span {
            font-family: 'UniSans-SemiBold';
          }
        }
      }
    }
    .auto-renews {
      color: #10181f;
      text-align: center;
      font-size: 13px;
      letter-spacing: 0;
      font-family: 'UniSans-SemiBold';
    }
  }
  .external-payments {
    height: 150px;

    @media (max-width: 1030px) {
      width: 200px;
    }

    .creditcard-payment {
      margin-bottom: 10px;

      button {
        width: 200px;
        background: url('../../../assets/images/creditcards-we-accept.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: 32px;
        cursor: pointer;
        border: none;

        &:hover {
          transform: translateY(-1px);
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .amazon-payment {
      &:hover {
        transform: translateY(-1px);
      }
      button {
        margin-bottom: 10px;
        width: 100%;
      }
    }

    .paypal-payment {
      width: 100%;
      height: 100%;

      .paypal-button {
        width: 100%;
        height: 40px;
        cursor: pointer;
        background: transparent;
        border: none;

        &:hover {
          transform: translateY(-1px);
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
#card-payment-form {
  background: #fff;
  border-radius: 9px;
}

.validate-box {
  display: inline-flex;
}
.validate-box .single-input-field {
  font-weight: normal;
  color: #333 !important;
}
.field.single-input-field {
  border-radius: 4px;
}
.input-field {
  width: 100%;
  box-shadow: none !important;
  border: none !important;
  height: 45px;
  padding-top: 7px;
  padding-left: 15px;
}
#card-element-card-number {
  border-bottom: none;
  border-radius: 5px 5px 0 0 !important;
}

#card-element-card-expiry {
  border-radius: 0 0 0 5px !important;
}
#card-element-card-cvc {
  border-radius: 0 0 5px 0 !important;
  border: none !important;
}
.flex-element {
  display: inline-flex;
  width: 100%;
}
.loading-msg {
  color: black;
  text-align: center;
}
.payment-form-title {
  font-size: 20px !important;
  font-weight: bold;
  color: #333;
  text-align: left;
}
.payment-form-policy {
  font-size: 0.8em !important;
  text-align: center;
  position: absolute;
  color: #ffffff;
}
.payment-form-promo {
  font-size: 0.9em !important;
  text-align: center;
  font-family: 'UniSans-Bold';
  margin: auto;
  min-height: 2rem;
  padding-top: 1rem;
  &.valid {
    color: #62b212 !important;
  }
  &.invalid {
    color: #cc0829 !important;
  }
}
#card-element {
  background-color: #44125d61;
}
.StripeElement {
  height: 45px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.submit-validate {
  margin-left: 10px;
  font-weight: bold;
  color: #fff;
  background-color: #9f26b5;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 0 14px 0 #ca9090;
}
.submit-payment {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: 40px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #fff;
  background-color: #9f26b5;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 0 14px 0 #ca9090;
}
.submit-payment:hover,
.submit-payment:active {
  opacity: 0.8;
}
.errors {
  height: 1em;
  font-size: 0.8em;
  color: $red;
  background-color: transparent;
}
#card-errors {
  color: $red;
}
#card-errors.card-success {
  color: #3e733b !important;
}
.card-element * {
  font-family: Quicksand, Open Sans, Segoe UI, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.card-element .fieldset {
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0 15px 30px;
  -ms-flex-pack: justify;
  border-style: none;
}

.card-element .field {
  width: 100%;
  padding: 10px 20px 11px;
  border: 1px solid #ccc;
}

.card-element .flex-element .field.half-width {
  width: 50%;
}

.card-element .field.third-width {
  width: calc(33% - (5px / 3));
}

.card-element .field.focus,
.card-element .field:focus {
  color: #424770;
  background-color: #f6f9fc;
}

.card-element .field.invalid.focus {
  background-color: #f6f9fc;
}

.card-element .field.focus::-webkit-input-placeholder,
.card-element .field:focus::-webkit-input-placeholder {
  color: #cfd7df;
}

.card-element .field.focus::-moz-placeholder,
.card-element .field:focus::-moz-placeholder {
  color: #cfd7df;
}

.card-element .field.focus:-ms-input-placeholder,
.card-element .field:focus:-ms-input-placeholder {
  color: #cfd7df;
}

.card-element input,
.card-element button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-style: none;
  outline: none;
}

.card-element input {
  color: #fff;
}

.card-element input::-webkit-input-placeholder {
  color: #9bacc8;
}

.card-element input::-moz-placeholder {
  color: #9bacc8;
}

.card-element input:-ms-input-placeholder {
  color: #9bacc8;
}

.card-element button {
  cursor: pointer;
}

.card-element button:active {
  background-color: #f5be58;
}

.card-element .error svg .base {
  fill: #fa755a;
}

.card-element .error svg .glyph {
  fill: #fff;
}

.card-element .error .message {
  color: #fff;
}

.card-element .success .icon .border {
  stroke: #fcd669;
}

.card-element .success .icon .checkmark {
  stroke: #fff;
}

.card-element .success .title {
  color: #fff;
}

.card-element .success .message {
  color: #9cabc8;
}

.card-element .success .reset path {
  fill: #fff;
}
.external-payment {
  margin-bottom: 1rem;
  .payment-box {
    list-style: none;
    border-radius: 6px;
    background: #ffffff;
    .list-item {
      .item {
        border-bottom: 2px solid rgba(159, 38, 181, 0.05);
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #9f26b5;
  border-color: #9f26b5;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
.promo-code {
  display: flex;
  justify-content: flex-end;
  height: 25px;
  line-height: 25px;
  padding-right: 5px;
  margin-bottom: 10px;
  span {
    line-height: 25px;
    height: 25px;
    font-size: 14px;
    letter-spacing: 0;
    margin-right: 2rem;
  }
}
.form-control {
  border: none !important;
  background-color: #10171f !important;
  label {
    color: #ffffff;
  }
}
.mobile_row {
  flex-direction: column;
  gap: 10px;
}
</style>
