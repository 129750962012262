var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.showPlayer
      ? _c("div", { staticClass: "relative overflow-hidden" }, [
          _c("img", {
            staticClass: "w-full object-cover",
            staticStyle: { height: "455px" },
            attrs: {
              src: require("@/assets/images/training/video-thumbnails/main-video-thumbnail.png")
            }
          }),
          _c("div", { staticClass: "gradient-banner" }),
          _c("div", { staticClass: "absolute top-40 w-full z-10" }, [
            _c("div", { staticClass: "flex flex-col items-center gap-3" }, [
              _c("div", { on: { click: _vm.handleClickPlayVideo } }, [
                _c("img", {
                  staticClass: "w-20 mb-1 mx-auto",
                  attrs: {
                    src: require("@/assets/images/training/play-btn.png")
                  }
                }),
                _c("div", { staticClass: "text-center text-sm opacity-60" }, [
                  _vm._v("Play Video")
                ])
              ]),
              _vm._m(0),
              _vm._m(1)
            ])
          ])
        ])
      : _c(
          "div",
          {
            staticClass: "mt-28 mb-10 z-10",
            staticStyle: { "min-height": "310px" }
          },
          [
            _vm.showPlayer
              ? _c("TrainingVideoPlayer", {
                  attrs: { showVideoInitially: true }
                })
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center text-4xl leading-[52px]" }, [
      _vm._v(" Elevate Your"),
      _c("br"),
      _vm._v("Game ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-xl text-center" }, [
      _vm._v(" Unlock your poker potential with our"),
      _c("br"),
      _vm._v(" new poker training package. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }