var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page" }, [
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column size-4 centered" },
        [
          _vm._t("default"),
          _c("notifications", {
            attrs: { group: "messages", position: "bottom right" }
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }