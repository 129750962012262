import api from '.'
import { instanceEvent } from '../plugins/axios'
import { settings } from '@/library/variables'

export const events = {
  async getProgress() {
    return instanceEvent.get('/subscribers/me/progress')
  },
  async getVideoProgress(videoID) {
    try {
      const videoProgress = await instanceEvent.get(
        '/subscribers/me/progress/' + videoID
      )
      return videoProgress.data
    } catch (err) {
      return api.response.error(err)
    }
  },
  async postEvent(payload) {
    try {
      return await instanceEvent.post('events', payload, {
        headers: {
          'Content-Type': 'application/json',
          'X-Odd-User-Agent': this.oddUserAgent
        }
      })
    } catch (err) {
      return api.response.error(err)
    }
  },
  async patchProgress(id, payload) {
    try {
      return await instanceEvent.post(
        '/properties/' + settings.propertyID + '/subscribers/me/progress/' + id,
        payload,
        {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          progress: false
        }
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
