<template>
  <div>
    <div class="section-games">
      <div class="inner">
        <div class="head">
          <div class="title">
            <p>GET IN THE GAME.</p>
            <p>GET FAMOUS.</p>
          </div>
          <div class="system">
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/pokergo-stream-poker-tv/id1235783484"
            >
              <div class="img">
                <img
                  src="../assets/images/holdem/download-apple-store.png"
                  alt=""
                />
              </div>
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.pokercentral.poker&hl=en_US&gl=US"
            >
              <div class="img">
                <img
                  src="../assets/images/holdem/download-google-play.png"
                  alt=""
                />
              </div>
            </a>
          </div>
          <div class="download-on">
            <div class="content">
              <p>Download on your computer</p>

              <!-- <h1>PC&emsp;&emsp;|&emsp;&emsp;MAC</h1> -->
              <a href="" class="pc">PC</a>|<a href="" class="mac">MAC</a>
            </div>
          </div>
        </div>
        <div>
          <div class="philvidwrap">
            <video autoplay="" muted="" loop="" class="philvid">
              <source
                src="https://content.jwplatform.com/videos/NXtUJUky-TZwR2iuw.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div class="social">
            <p>
              "The only social mobile Poker game you should be playing." ~ Phil
              Hellmuth, most poker bracelet wins ever!
            </p>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="list-item">
          <div class="item">
            <div class="item__picture">
              <img src="../assets/images/holdem/App_GetFamous.png" alt="" />
            </div>
            <p>
              Regular TV coverage of players on the top of the leaderboard! Get
              in the game and get noticed!
            </p>
          </div>
          <div class="item">
            <div class="item__picture">
              <img src="../assets/images/holdem/App_Realpros.png" alt="" />
            </div>
            <p>
              We have the biggest names in Poker. Phil Hellmuth, Daniel
              Negreanu, Maria Ho and Antonio Esfandiari and many more...come
              play vs them!
            </p>
          </div>
          <div class="item">
            <div class="item__picture">
              <img src="../assets/images/holdem/App_AuthPoker.png" alt="" />
            </div>
            <p>
              Always giving out free complimentary Poker chips to test out your
              skills.
            </p>
          </div>
        </div>
      </div>
      <div class="sign">
        <div class="inner-sign">
          <div class="left">
            <div class="sign-title">
              JOIN THE A LIST
            </div>
            <p>
              Give us your email to receive special offers, sponsor discounts,
              feature updates and more!
            </p>
            <div class="form">
              <input type="text" placeholder="Email" v-model="email" />
              <input type="text" placeholder="Age" v-model="age" />
              <a href="" @click.prevent="SendMail">Submit</a>
            </div>
          </div>
          <div class="right">
            <video autoplay="" muted="" loop="" class="ringvidwrap">
              <source
                src="https://content.jwplatform.com/videos/iZqTocfk-9cqarGOI.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      email: '',
      age: ''
    }
  },
  methods: {
    ...mapActions(['REQUEST_JOIN_GAME']),
    SendMail() {
      this.errorMessage = ''
      if (this.email !== '' && this.email !== '') {
        try {
          this.REQUEST_JOIN_GAME({ email: this.email, age: this.age }).then(
            (response) => {
              if (response.status === 200) {
                this.$notify({
                  group: 'messages',
                  text: response.data,
                  type: 'success',
                  duration: 10000
                })
              }
            }
          )
        } catch (err) {
          console.log('err', err)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.section-games {
  width: 100%;
  .inner {
    min-height: 550px;
    background: #1e1f21 url('../assets/images/holdem/header-bkg-lrg.jpg')
      no-repeat top center;
    background-size: cover;
    position: relative;

    .philvidwrap {
      position: absolute;
      width: 200px;
      right: 0;
      width: 400px;
      height: 400px;
      overflow: hidden;
      position: absolute;
      z-index: 0;
      right: 0;
      margin-right: 50px;
      border-radius: 220px;
      border: 16px double #86754d;
      bottom: -11rem;
      video {
        width: 100%;
      }
    }
    .head {
      width: 40%;
      margin: 0 auto;
      .title {
        text-align: center;
        padding: 30px 0px;
        padding-bottom: 4rem;
        p {
          font-family: 'UniSans-Bold-Italic';

          font-style: italic;
          font-size: 3.5vw;
          line-height: 1.2;
          color: #ffffff;
          margin: 0;

          text-transform: uppercase;
          text-align: center;
          text-shadow: 2px 3px #1e1f21;
        }
      }
      .system {
        padding: 5%;
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .img {
          max-width: 200px;
          img {
            width: 100%;
          }
        }
      }
      .download-on {
        padding: 5%;
        padding-top: 1%;
        width: 90%;
        margin: 0 auto;
        text-align: center;

        .content {
          background: #000000;
          border: 1px solid #000000;
          border-radius: 8px;
          width: 50%;
          margin: 0 auto;
          padding: 30px 0px;
          p {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 0;
          }
          a {
            font-size: 1.2rem;
            margin-bottom: 0;
            font-family: 'UniSans-Bold';
            color: #ffffff;
          }
          .pc {
            margin-right: 1rem;
          }
          .mac {
            margin-left: 1rem;
          }
        }
      }
    }
    .social {
      width: 100%;

      min-height: 60px;
      z-index: 3;
      position: absolute;
      p {
        text-align: center;
        background: linear-gradient(#50275d, #200d28);
        line-height: 60px;
        margin-top: 65px;
      }
    }
  }
  .content {
    width: 100%;
    background: linear-gradient(#1e1f21, #2d3f54) top;
    padding-bottom: 4rem;
    padding-top: 5rem;
    z-index: 2;
    position: relative;
    .list-item {
      display: flex;
      width: 90%;
      margin: 0 auto;

      justify-content: space-around;
      .item {
        width: 30%;
        border: 1px solid #000000;
        padding-bottom: 4rem;
        background: #1e1f21;
        border-radius: 4px;
        &__picture {
          width: 100%;
          img {
            width: 100%;
          }
        }

        p {
          font-size: 14px;
          margin-top: 1rem;
          font-size: 14px;
          margin: 0;
          padding: 20px;
          color: #ffffff;
        }
      }
    }
  }
  .sign {
    width: 100%;
    background: #000000;
    .inner-sign {
      display: flex;
      width: 95%;
      margin: 0 auto;
      justify-content: space-between;
      .right {
        width: 340px;
        video {
          width: 100%;
        }
      }
      .left {
        padding: 2rem 0px;
        width: 50%;
        .sign-title {
          font-size: 2rem;
          font-family: 'UniSans-Bold';
          color: #ffffff;
          margin: 0;

          text-transform: uppercase;
          padding-bottom: 2rem;
        }
        p {
          margin: 0;

          color: #ffffff;
        }
        .form {
          margin-top: 2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          input {
            width: 40%;
          }
          input[type='text'] {
            border: 1px solid #86754d;
            background: none;
            color: #86754d;
          }
          ::placeholder {
            color: #86754d;
          }
          a {
            padding: 10px 20px;
            background: #50275d;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>
