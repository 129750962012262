var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ChatHeader" },
    [
      _c("h4", [_vm._v("LIVE CHAT")]),
      _c("SwitchButton", {
        attrs: { checked: _vm.isChecked },
        on: {
          toggle: function($event) {
            return _vm.$emit("toggle")
          }
        }
      }),
      _c(
        "div",
        { staticClass: "icon__views" },
        [
          _c(
            "RouterLink",
            {
              staticClass: "flex items-center mr-1",
              attrs: { to: "/account" }
            },
            [
              _c("font-awesome-icon", {
                staticClass: "close-button",
                staticStyle: { "font-size": "20px" },
                attrs: { icon: "gear" },
                on: { click: _vm.hideChat }
              })
            ],
            1
          ),
          _vm.isChecked
            ? _c(
                "div",
                { staticClass: "icon__views-user" },
                [
                  _c("span", { staticClass: "dot" }),
                  _c("font-awesome-icon", { attrs: { icon: "user" } })
                ],
                1
              )
            : _vm._e(),
          _c("font-awesome-icon", {
            staticClass: "close-button",
            attrs: { icon: "close" },
            on: { click: _vm.hideChat }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }