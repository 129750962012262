<template>
  <div class="register-container centered">
    <form class="register-form" @submit.prevent="verifyCode">
      <div class="form-row">
        <div
          class="col relative"
          style="display: flex; justify-content: space-between"
        >
          <div>
            Please check your email <br />{{ email }}
            for a verification code.
          </div>
          <div
            :to="'/register/select-plan'"
            class="subscribe w-28 absolute right-0"
            @click="resendCode"
            v-if="!successMessage"
          >
            Resend Code
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-control">
            <input
              id="verificationCode"
              v-model="verificationCode"
              @input="error = ''"
              type="number"
              required
              placeholder="Verification Code"
              autocomplete="off"
            />
          </div>
          <p class="email-error" v-if="error">{{ error }}</p>
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <hr class="line" />
          <button
            :disabled="!isValidForm"
            class="glowing-btn w-full h-12 mt-1 disabled:cursor-not-allowed"
            type="submit"
          >
            Verify Email
          </button>
        </div>
      </div>
      <div style="color: green">
        {{ successMessage }}
      </div>
    </form>
  </div>
</template>

<script>
import { authentication } from '../../../api/authentication'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'VerifyEmailStep',
  watch: {
    verificationCode() {
      if (this.verificationCode.length > 7) {
        this.error = 'Max length is 7 numbers'
      }
    }
  },
  data() {
    return {
      verificationCode: '',
      error: '',
      successMessage: ''
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapState({
      plan: (state) => state.checkout.selectedPlan
    }),
    isValidForm() {
      return (
        this.verificationCode.length > 0 && this.verificationCode.length <= 7
      )
    },
    email() {
      return this.user.basic ? this.user.basic.email : ''
    }
  },
  methods: {
    async resendCode() {
      try {
        await authentication.requestEmailCode()
        this.successMessage = 'Email verification code resent.'
      } catch (e) {
        console.log(e)
      }
    },
    async verifyCode() {
      const response = await authentication.validateEmailCode({
        code: this.verificationCode
      })
      if (response.status === 200) {
        const loginRedirectURL = localStorage.getItem('loginRedirectURL')
        const redirectTriggerPath = localStorage.getItem('redirectTriggerPath')

        if (loginRedirectURL && redirectTriggerPath === '/register') {
          await this.$router.push({ path: loginRedirectURL })
          localStorage.removeItem('loginRedirectURL')
          localStorage.removeItem('redirectTriggerPath')
        } else if (this.plan.product_type) {
          this.$router.push({
            path: `/register/select-plan/${this.plan.product_type}`
          })
        } else {
          this.$router.push({
            path: `/select-plan`
          })
        }
      } else {
        this.error = response.message
      }
    }
  }
}
</script>
<style scoped lang="scss">
.register-container {
  width: 70%;
  max-width: 700px;
  min-width: 500px;
  margin: 3rem;
  @media (max-width: 768px) {
    width: 80%;
    max-width: 80%;
    min-width: 80%;
  }
  @media (max-width: 400px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

.eye-icon {
  position: relative;
  left: calc(100% - 40px);
  top: -32px;
  cursor: pointer;
}

.description {
  display: inline-block;
  margin-left: -20px;
  font-size: 14px;
  color: $light-gray-30;
}

.line {
  margin-top: 10px;
  margin-bottom: 10px;
  color: $light-gray-30;
  border: 1px solid;
}

@media screen and (max-width: 768px) {
  .form-row {
    .message {
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 400px) {
  .register-container {
    min-width: 100%;
  }
  .form-row {
    .message {
      font-size: 10px;
    }
  }
}
.form-control {
  padding: 0 !important;
  border: none !important;
  border-radius: 2.25rem;
}
.email-error {
  color: red;
  padding: 5px;
  margin: 0;
}
.input_checkbox {
  @media (max-width: 768px) {
    transform: scale(1.6);
    margin-left: 10px;
    margin-right: 8px;
  }
}
.subscribe {
  display: block;
  text-align: center;
  text-decoration: underline;
  color: #9f26b5;
  font-size: 14px;
  cursor: pointer;
}
</style>
