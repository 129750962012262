import api from '@/api'
export default {
  async GetDemandViewData(state, type) {
    if (type === 'grid') {
      let response = await api.demand.getViewData('shows')
      if (response.status === 200) {
        //console.log('responseViewDataGrid', response.data)
        state.commit('setViewDataGrid', response.data)
      }
      return response
    }
    if (type === 'list') {
      let response = await api.demand.getViewData(
        'e8562f4a-9ce3-40e5-9966-08176701bf84'
      )
      if (response.status === 200) {
        //console.log('responseViewDataList', response.data)
        state.commit('setViewDataList', response.data)
      }
      return response
    }
  }
}
