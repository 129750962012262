<template>
  <div class="page-login">
    <div class="login-inner">
      <!-- <copy-box /> -->

      <div class="login-box">
        <br />
        <router-link to="/" class="btn-home">
          <i class="fas fa-arrow-left"></i> <span>Home</span>
        </router-link>
        <form @submit.prevent="login" class="form">
          <div>
            <router-link to="/">
              <div class="logo-login">
                <img src="../assets/images/Logo-PKG-1.png" alt="" />
              </div>
            </router-link>
            <br />
            <br />
            <br />
            <h2>Start streaming today!</h2>
            <p>
              Login in now to enjoy the top poker streaming app in the world
            </p>
            <div class="text-field">
              <input
                id="username-input"
                v-model="user"
                placeholder="Email Address"
                type="email"
                required
              />
            </div>
            <div class="text-field">
              <input
                id="password-input"
                v-model="pass"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Password"
                required
              />
              <span class="eye-icon" @click="showPassword = !showPassword">
                <img
                  v-if="!showPassword"
                  src="@/assets/images/eye-solid.svg"
                  alt=""
                  style="height: 20px"
                />
                <img
                  v-if="showPassword"
                  src="@/assets/images/eye-slash-solid.svg"
                  alt=""
                  style="height: 20px"
                />
              </span>
            </div>
            <br />
            <span v-if="errorMessage !== ''" class="error-message">
              <font-awesome-icon icon="times" />
              {{ errorMessage }}
            </span>
          </div>
          <router-link :to="'/forgot-password'" class="forgot-password"
            >Forgot Password</router-link
          >
          <br />
          <br />
          <div class="line"></div>
          <br />
          <button class="glowing-btn h-12 w-full mt-1" type="submit">
            Submit
          </button>
          <br />
          <br />
          <span class="isAccount">Don't you have an account yet?</span>
          <router-link :to="'/register/select-plan'" class="subscribe">
            Subscribe
          </router-link>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="form-footer">
            <p>Customer Service is available to help!</p>
            <p>
              CALL US at 1-866-476-5374 | Monday - Friday, 7am to 5pm PT or
              email support@pokergo.com
            </p>
          </div>
        </form>
        <br />
      </div>
    </div>
  </div>
</template>
<script lang="js">
import { mapGetters, mapActions, mapState } from "vuex";
import { upgradablePlans } from "@/library/config/plans";

export default {
  name: 'Login',
  data () {
    return {
      user: '',
      pass: '',
      errorMessage: '',
      reload: false,
      showPassword: false
    }
  },
  computed: {
    ...mapState({
      plan: (state) => state.checkout.selectedPlan,
      subscription: (state) => state.user.subscription
    }),
    ...mapGetters(['isAuthenticated','isSubscriber']),
  },
  methods: {
    ...mapActions(["REQUEST_LOGIN", 'FETCH_ME']),
    login() {
      this.errorMessage = ''
      if (this.user !== "" && this.pass !== "") {
        try {
          this.REQUEST_LOGIN({ email: this.user, password: this.pass, code: this.$route.params.code }).then((response) => {
            if (response.status === 200 || response.status === 428) {
              if(this.$store.state.checkout.promoCodeDetails){
                if(!this.isSubscriber){
                  return this.$router.push({
                    path: `/register/select-plan/${this.plan.product_type}`
                  })
                } else if(upgradablePlans[this.subscription.interval].includes(this.plan.product_type)){
                  return this.$router.push({
                    path: `/account/change-plan/checkout/${this.plan.product_type}`
                  })
                }
              }
            }
            if (response.status === 200) {
              this.FETCH_ME()
              const loginRedirectURL = localStorage.getItem('loginRedirectURL')
              if(loginRedirectURL){
                this.$router.push({ path: loginRedirectURL })
                localStorage.removeItem('loginRedirectURL')
              }
              else if (this.$store.state.videoId && this.$store.state.videoId !== undefined)
                this.$router.push({ name: 'VideosId', params: { id: this.$store.state.videoId } })
              else
                this.$router.push({ path: '/schedule' })

            } else if (response.status === 428){
                this.FETCH_ME()
                this.$router.push({
                  path: `/register/verify-email`
                })
            }
            else if (response.statusCode === 401) {
              this.errorMessage = 'Username and/or password is incorrect.'
            } else {
              this.$toasted.error(
                response.message
              )
            }
          })
        } catch (err) {
          if (!err.response) {
            return
          }
          if (err.response.data.errors[0].code === 401) {
            this.errorMessage = 'Username and/or password is incorrect.'
          } else {
            console.log(err.response.data.errors[0].detail)
          }
        }
      } else {
        this.message = "A username and password must be present";
      }
    }
  },
  watch:{
    '$route'(to, from) {
      if(to.name != "Login"){
        localStorage.removeItem('loginRedirectURL')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.spacer-small {
  padding-right: 5px;
  padding-left: 5px;
}

.form {
  width: 80%;
  margin: 0 auto;
  padding-top: 10%;
}
.text-field {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 30px;
  right: 10px;
}
.page-login {
  background: url('../assets/images/home-bg.png') no-repeat;
  backdrop-filter: blur(5px);
  background-size: cover;
  height: 100%;
  width: 100%;
  .login-inner {
    width: 40%;
    height: 100%;
    .login-box {
      background: #10181f;
      max-width: 100%;
      height: 100%;
      .btn-home {
        width: 90%;
        margin: 0 auto;
        color: #ffffff;
        font-size: 16px;
        i {
          opacity: 0.4;
          font-size: 16px;
          margin-right: 10px;
        }
      }
      form {
        .logo-login {
          margin: 0 auto;
          width: 196.04px;
          img {
            width: 100%;
          }
        }
        h2,
        p {
          text-align: center;
        }
        h2 {
          font-size: 40px;
          margin-bottom: 2rem;
        }
        p {
          width: 65%;
          margin: 0 auto;
          font-size: 18px;
          opacity: 0.42;
          margin-bottom: 3rem;
        }
        .text-field {
          input[type='email'] {
            border-radius: 4px;
          }
          input[type='text'] {
            border-radius: 4px;
          }
          input[type='password'] {
            border-radius: 4px;
            position: relative;
          }
          .eye-icon {
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .forgot-password {
          text-decoration: underline;
          font-size: 12px;
          color: #9f26b5;
        }
        .line {
          width: 100%;
          opacity: 0.15;
          background-color: #ffffff;
          height: 1px;
        }
        .button {
          width: 100%;
          text-transform: capitalize;
          font-size: 14px;
          max-width: 100%;
        }
        .isAccount {
          display: block;
          text-align: center;
          color: #ffffff;
          font-size: 14px;
        }
        .subscribe {
          display: block;
          text-align: center;
          text-decoration: underline;
          color: #9f26b5;
          font-size: 14px;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #232a30 inset !important;
          -webkit-text-fill-color: #ffffff;
          opacity: 0.42;
        }
        .form-footer {
          margin-bottom: 50px;
          p {
            text-align: center;
            width: 80%;
            margin: 0 auto;
            padding: 0;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
