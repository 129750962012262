<script>
import Main from '../../../components/Marketing/Main.vue'
import FrequentlyAskedQuestion from '../../../components/Marketing/FrequentQuestion.vue'
import RegisterPlan from '../../register/components/RegisterPlan.vue'
import { mapState } from 'vuex'
import { SwiperSlide, Swiper } from 'vue-awesome-swiper'
import ArrowButton from '../../../components/Training/ArrowButton.vue'
import Banner from '@/components/Banner.vue'
import api from '@/api'

export default {
  name: 'Marketing',
  components: {
    Banner,
    Swiper,
    ArrowButton,
    SwiperSlide,
    FrequentlyAskedQuestion,
    RegisterPlan,
    Main
  },
  data() {
    return {
      faq: [],
      sections: [],
      devices: [
        require('@/assets/images/marketing/devices/Android.jpg'),
        require('@/assets/images/marketing/devices/Apple TV.jpg'),
        require('@/assets/images/marketing/devices/Fire TV.jpg'),
        require('@/assets/images/marketing/devices/iPad.jpg'),
        require('@/assets/images/marketing/devices/iPhone.jpg'),
        require('@/assets/images/marketing/devices/Roku.jpg')
      ],
      sixTilesDesign: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          300: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 6
          }
        }
      },
      threeTilesDesign: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          300: {
            slidesPerView: 1
          },
          1024: {
            slidesPerView: 3
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      plans: (state) => state.checkout.plans
    })
  },
  mounted() {
    this.$store.dispatch('getPlans', { code: this.$route.query.code })
    api.marketing.getMarketingData().then(({ data }) => {
      this.faq = data.data.faq
      this.sections = data.data.section
    })
  },
  methods: {
    selectPlan(plan) {
      this.$store.commit('selectPlan', plan)
      if (this.isAuthenticated) {
        this.$router.push({
          path: this.$route.path + '/' + plan.product_type,
          query: { code: this.$route.query.code }
        })
      } else {
        this.$router.push('/register')
      }
    },
    getSwiperOption(value) {
      if (value === 'design_3_tile') return this.threeTilesDesign
      return this.sixTilesDesign
    }
  }
}
</script>

<template>
  <div>
    <Banner class="banner_class" page="marketing" location="header" />
    <Main class="mt-20 md:mt-0" />
    <div class="page" style="margin-top: 0 !important;">
      <div class="w-full">
        <div
          class="my-10"
          v-for="(section, index) in sections"
          :key="`section_${index}`"
        >
          <div class="uppercase text-3xl" style="font-family: UniSans-Bold">
            {{ section.title }}
          </div>
          <div class="mt-1 text-sm">
            {{ section.description }} {{ sections.tiles }}
          </div>

          <div class="mt-2">
            <Swiper :options="getSwiperOption(section.design_type)">
              <SwiperSlide
                v-for="(tile, index) in section.tiles"
                :key="`tile_${section.title}_${index}`"
              >
                <img
                  class="w-full px-1"
                  :src="tile.image"
                  alt="daysOfLivePoker-img"
                />
              </SwiperSlide>

              <div class="swiper-button-prev" slot="button-prev">
                <ArrowButton direction="left" />
              </div>
              <div class="swiper-button-next" slot="button-next">
                <ArrowButton direction="right" />
              </div>
            </Swiper>
          </div>
        </div>

        <div class="my-10">
          <div
            class="uppercase text-3xl text-center"
            style="font-family: UniSans-Bold"
          >
            Select a plan
          </div>

          <div class="stretch flex flex-col md:flex-row  justify-center">
            <register-plan
              class="mx-auto"
              v-for="(p, i) in plans"
              :plan="p"
              :key="i"
              @selectPlan="selectPlan(p)"
            >
            </register-plan>
          </div>
        </div>

        <div class="my-10">
          <div
            class="uppercase text-3xl text-center"
            style="font-family: UniSans-Bold"
          >
            stream on your favorite devices
          </div>

          <div
            class="grid grid-cols-2 md:grid-cols-3 mt-4 gap-4 md:gap-24 text-2xl text-center"
          >
            <div v-for="(device, i) in devices" :key="`device_${i}`">
              <img class="w-full" :src="device" alt="on-demand-img" />
            </div>
          </div>
        </div>

        <div class="my-10">
          <div class="uppercase text-3xl text-center">
            frequently asked questions
          </div>

          <div class=" mt-4 flex flex-col gap-3">
            <FrequentlyAskedQuestion
              v-for="(item, index) in faq"
              :key="`faq_${index}`"
              :question="item.question"
              :answer="item.answer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner_class {
  margin: 40px 0;
  @media (max-width: 971px) {
    margin: 93px 0 -72px 0;
  }
}
</style>
