<template>
  <div>
    <div
      v-if="isVideoDataLoaded"
      class="video-player-container"
      :class="{ hide: reloadLive }"
      @click="navigate"
    >
      <div
        v-show="hideOverlayImage"
        id="videoPlayer"
        ref="videoPlayerRef"
        class="video-player-box"
      />
      <img
        :src="imagePreload"
        v-show="!hideOverlayImage"
        class="overlay"
        v-bind:class="{ 'overlay-top': isOverlayTop }"
      />
      <CountdownTimer
        v-if="!onAir && !hideOverlayImage"
        :airDate="airDateObject"
        :videoType="videoType"
      />
    </div>
    <!-- <SignupNotificationModal v-if="hideVideo" /> -->
  </div>
</template>

<script>
import UUID from 'uuid'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { settings } from '@/library/variables'
import { General } from '@/library/general'
import CountdownTimer from '@/components/CountdownTimer'
// import SignupNotificationModal from '@/components/SignupNotificationModal.vue'
// import { localstorage } from '@/services/storage/localStorageService'

export default {
  name: 'VideoPlayer',
  components: {
    CountdownTimer
    // SignupNotificationModal
  },
  props: {
    id: {
      required: true,
      type: String,
      default: ''
    },
    entitled: {
      default: true,
      type: Boolean
    },
    fluid: {
      default: true,
      type: Boolean
    },
    options: {
      type: Object,
      default: null
    },
    overlayImage: {
      type: String,
      default: null
    },
    videoData: {
      type: Object,
      default: null
    },
    trailerPlayering: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      reloadLive: null,
      player: undefined,
      defaultOptions: {
        title: '',
        type: 'application/x-mpegURL',
        src: '',
        poster: '',
        duration: 0,
        videoSessionID: UUID.v4(),
        videoSessionStarted: new Date(),
        oddUserAgent: 'platform[name]=web&build[version]=web'
      },
      streamInfo: {
        endDate: undefined,
        airDate: undefined
      },
      liveSource: {},
      source: '',
      title: '',
      initialPlayingPosition: 'unset',
      duration: 0,
      serverProgress: 0,
      TRACKING_VIDEO_EVENTS: process.env.TRACKING_VIDEO_EVENTS === 'true',
      VIDEO_EVENT_POST_DELAY: process.env.VIDEO_EVENT_POST_DELAY,
      VIDEO_PROGRESS_POST_DELAY: process.env.VIDEO_PROGRESS_POST_DELAY,
      TRACKING_PROGRESS: process.env.TRACKING_PROGRESS === 'true',
      lastProgressPosted: new Date(),
      lastEventPosted: new Date(),
      videoType: '',
      videoSessionID: UUID.v4(),
      videoSessionStarted: new Date(),
      lastReload: false,
      isOverlayTop: true,
      airDate: undefined,
      publishedDate: undefined,
      hideOverlayImage: false,
      preAirImages: [],
      images: [],
      patchProgressInterval: null,
      postedEventComplete: false,
      intervalArr: [],
      elapsedVideoPlay: 6,
      isVideoDataLoaded: false,
      hideVideo: false
    }
  },
  computed: {
    ...mapGetters([
      'getVideoProgress',
      'isAuthenticated',
      'getEmail',
      'userSubscriberID',
      'isPlayingPghModal'
    ]),
    userID() {
      return this.userSubscriberID ? this.userSubscriberID : 'anonymous'
    },
    email() {
      return this.getEmail ? this.getEmail : ''
    },
    trackingProgress() {
      return this.TRACKING_PROGRESS && this.isAuthenticated
    },
    onAir() {
      return this.airDate <= moment()
    },
    published() {
      return this.publishedDate <= moment()
    },
    imagePreload() {
      if (this.lastReload) {
        console.log('Trying live video stream, putting up starting soon')
        return '/startsoon.jpg'
      }
      return General.getOverlayImage(
        this.airDate,
        this.preAirImages,
        this.images
      )
    },
    airDateObject() {
      if (this.airDate) return new Date(this.airDate.valueOf())

      return null
    }
  },
  watch: {
    // hideVideo() {
    //   if (this.hideVideo) {
    //     this.$emit('show-msg-signup')
    //     clearInterval(this.checkHideVideoInterval)
    //     this.destroyPlayer()
    //   }
    // },
    $route() {
      if (this.isAuthenticated) {
        this.tickCurrentTime()
      }
    },
    trailerPlayering(val) {
      // play and pause according to trailerVideo playing
      if (this.player)
        if (val) this.player.pause()
        else this.player.play()
    },
    isPlayingPghModal(val) {
      if (this.player) {
        this.player.play(!val)
      }
    }
  },
  async mounted() {
    // this.hideVideo =
    //   !this.isAuthenticated && JSON.parse(localstorage.getWatchTimer())
    await this.fetchData()

    if (!this.entitled) {
      this.hideOverlayImage = false
      this.isOverlayTop = false
      return
    }
    if (!this.published) {
      this.isOverlayTop = true
      this.hideOverlayImage = false
      console.log('Mount: The video is not published')
      return
    }
    if (this.videoType === 'playlist') {
      this.hideOverlayImage = true
      this.initPlayer(
        `https://cdn.jwplayer.com/v2/playlists/${this.source}`,
        true
      )
    }
    if (this.videoType === 'vod' && this.onAir) {
      this.hideOverlayImage = true
      console.log('Mount: VOD File loaded')
      this.initPlayer(this.getVideoSource())
    }
    if (this.videoType === 'live' && this.onAir) {
      console.log('Mount: Live Event has started')
      this.hideOverlayImage = true
      this.startLive()
    } else if (this.videoType === 'live') {
      this.isOverlayTop = true
      this.hideOverlayImage = false
      console.log('Mount: Live Event has not started')
    }

    /** Comment this out for now */
    // this.checkHideVideoInterval = setInterval(() => {
    //   this.hideVideo =
    //     !this.isAuthenticated && JSON.parse(localstorage.getWatchTimer())
    // }, 10000)
  },
  destroyed() {
    //Stop reloading the live url
    if (this.reloadLive) {
      clearInterval(this.reloadLive)
    }
    this.destroyPlayer()
  },
  methods: {
    navigate() {
      if (this.hideOverlayImage) return

      this.$router.push('/videos/' + this.id)
    },
    getVideoSource() {
      let videoSource = 'https://cdn.jwplayer.com/v2/media/' + this.source
      if (this.videoType === 'live' || this.videoType === 'linear') {
        videoSource = [{ file: this.source }]
      }
      return videoSource
    },
    startLive() {
      const now = moment()
      const el = this

      if (this.videoType === 'live' && this.onAir) {
        setTimeout(() => {
          console.log('streamStarted')
          el.$emit('streamStarted')
        }, moment.duration(this.streamInfo.airDate.diff(now)).asSeconds() * 1000)

        setTimeout(() => {
          // console.log('streamEnded')
          // el.$emit('streamEnded')
        }, moment.duration(this.streamInfo.endDate.diff(now)).asSeconds() * 1000)

        try {
          this.initPlayer(this.getVideoSource())
        } catch (err) {
          console.log(err)
        }
      }
    },
    initPlayer(videoSource, repeat = false) {
      if (this.player !== undefined) {
        console.log('player undefined')
        return
      }

      // check if videoSource is available
      if (!this.source) {
        console.log('initPlayer video source is missing')
        this.hideOverlayImage = false
        return
      }

      // check if there is videoPlayer DOM
      if (document.getElementById('videoPlayer') === null) return

      this.player = window.jwplayer('videoPlayer')
      const onAir = this.airDate <= moment() && this.videoType === 'live'
      let autoStart = false
      if (this.videoType !== 'live') {
        autoStart =
          ['Schedule', 'Home', 'Watch-Home'].indexOf(this.$route.name) === -1 ||
          onAir
        //console.log(autoStart)
      } else {
        autoStart = onAir
      }

      try {
        console.log(videoSource, 'SOURCE')
        this.player.setup({
          playlist: videoSource,
          playbackRateControls: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
          autostart: autoStart,
          playlistIndex:
            this.videoType === 'playlist' ? Math.floor(Math.random() * 200) : 0,
          repeat
        })
        this.player.on('bufferChange', this.tickCurrentTime)
        this.player.on('play', this.onPlayerPlay)
        this.player.on('pause', this.onPlayerPause)
        this.player.on('seeked', this.onSeeked)
        this.player.on('setupError', this.onPlayerSetupError)
        this.player.on('error', this.onPlayerSetupError)
        this.player.on('complete', this.onPlayerEnded)
        this.player.on('time', this.onBeforeEnd)
      } catch (e) {
        console.log(e)
      }

      if (this.isPlayingPghModal) {
        this.player.pause(true)
      }
    },
    destroyPlayer() {
      console.log('destroyPlayer')
      if (this.player) {
        this.onPlayerPause()
        this.player.stop()
        this.player.remove()
        this.player = undefined
      }
    },
    tickCurrentTime(event) {
      if (event === undefined) {
        return
      }
      if (this.videoType !== 'vod') {
        return
      }
      if (this.player === undefined) return

      // get progress and calculate from which video should play
      const videoProgress = this.getVideoProgress(this.id)
      const playerPosition = this.player.getPosition()
      const serverProgress = this.serverProgress
      const maxProgress = Math.max(
        ...[videoProgress, playerPosition, serverProgress]
      )
      if (this.initialPlayingPosition === 'unset') {
        this.player.seek(maxProgress)
        this.initialPlayingPosition = 'set'
        this.onPlayerSessionStarted()
      }
    },
    noThrottleEvents(currentTime) {
      return currentTime - this.lastEventPosted > this.VIDEO_EVENT_POST_DELAY
    },
    noThrottleProgress(currentTime) {
      return (
        currentTime - this.lastProgressPosted > this.VIDEO_PROGRESS_POST_DELAY
      )
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n)
    },
    async fetchData() {
      let res = this.videoData
      if (res === null || Object.keys(res).length === 0) {
        res = await this.$store.dispatch('videos/fetchVideoDetails', this.id)
      }
      //console.log(res)
      const airDate = moment(res.data.data.aired_at)
      this.publishedDate = moment(res.data.data.published_at)
      this.airDate = airDate
      const endDate = moment(res.data.data.ended_at)
      this.streamInfo.endDate = endDate
      this.streamInfo.airDate = airDate
      const source = res.data.data.sources
      this.liveSource = source
      this.title = res.data.data.title
      this.duration = res.data.data.duration
      this.source = res.data.data.source
      this.videoType = res.data.data.type
      this.preAirImages = res.data.data.pre_air_images
      this.images = res.data.data.images

      this.isVideoDataLoaded = true

      // fetch progress. try catch blocked is required
      try {
        const videoProgress = await this.$store.dispatch(
          'getVideoProgress',
          this.id
        )
        this.serverProgress = videoProgress.data.progress || 0
      } catch (e) {}
    },
    onSeeked() {
      this.postProgressEvent('video:scrub')
      const onAir =
        this.streamInfo.airDate <= moment() && this.videoType === 'live'
      const autoStart =
        ['Schedule', 'Home', 'Watch-Home'].indexOf(this.$route.name) === -1 ||
        onAir
      if (!autoStart) {
        this.player.pause(true)
        this.clearTrackingInterval()
      }
    },

    postProgressEvent(action) {
      if (this.player === undefined) return

      let currentProgress = this.player.getPosition()
      if (currentProgress > this.duration && this.duration > 0) {
        currentProgress = this.duration
      }

      const now = new Date()
      const noThrottlePassed = this.noThrottleEvents(now)
      let elapsed = 0
      if (action === 'video:scrub' && currentProgress < 6) {
        return
      } else if (action === 'video:play') {
        elapsed = this.elapsedVideoPlay
      } else if (action === 'video:load') {
        elapsed = 0
      } else {
        elapsed = currentProgress
      }
      if (noThrottlePassed) {
        this.$store.dispatch('postEvent', {
          subscriber_id: this.userID,
          video_session_id: this.videoSessionID,
          action: action,
          content_id: this.id,
          elapsed: elapsed,
          content_type: this.videoType,
          platform: 'web',
          player_position: currentProgress,
          application_version: settings.applicationVersion
        })
        this.lastProgressPosted = now
        this.serverProgress = currentProgress
      }
    },
    // --------- Video Event Tracking --------
    // We will focus on tracking ui events, not sending data every second
    // but we will include timestamps so that we can recreate what user sessions and video sessions were like
    // Each sessionId is created and the initial time is recorded on the app loading
    // Each videoSessionID is created and it's initial time is recorded on the onPlayerLoadeddata event
    // Each time the play button is clicked or the video is scrubbed to a new location the video:play event is sent
    // this event includes the location of the scrub point and the videoSessionElapsed time
    // the delta between these two can be use to determine if someone has scrubbed forwards or backwards
    // the video:complete event will trigger when someone reaches the end of a video
    // and video:error should record error information
    onPlayerSessionStarted() {
      this.postProgressEvent('video:load')
    },
    onPlayerPlay() {
      console.log('OnPlayerPlay: ', this.player.getPlaylist())
      this.$emit('onPlayerPlay')
      if (this.reloadLive) {
        this.lastReload = false
        this.isOverlayTop = false
        clearInterval(this.reloadLive)
        this.reloadLive = null
      }
      // when player plays video, hide overlay image (or comingSoon image if live)
      this.hideOverlayImage = true

      if (this.player === undefined) return

      const now = new Date()
      const playerPosition = this.player.getPosition()
      let autostart = this.player.getConfig().autostart
      if (this.initialPlayingState === 'paused' && !autostart) {
        // it's the first time we've loaded the video
        this.onPlayerSessionStarted()
      }
      if (this.TRACKING_VIDEO_EVENTS && this.player) {
        // is the currentPosition greater than any previously saved progress?
        // if so then the user just scrubbed forward and it should be sent

        if (this.patchProgressInterval === null) {
          this.patchProgressInterval = setInterval(() => {
            //console.log('fire event video:play')
            this.postProgressEvent('video:play')
            this.lastProgressPosted = now
            this.serverProgress = playerPosition
          }, 6000)
          this.intervalArr.push(this.patchProgressInterval)
        }
      }
    },
    onPlayerPause() {
      this.clearTrackingInterval()

      if (this.player === undefined) return

      const playerPosition = this.player.getPosition()
      const now = new Date()
      if (
        this.TRACKING_VIDEO_EVENTS &&
        this.initialPlayingState !== 'paused' &&
        this.noThrottleEvents(now)
      ) {
        this.postProgressEvent('video:stop')
        this.lastProgressPosted = now
        this.serverProgress = playerPosition
      }
    },
    onPlayerEnded() {
      this.clearTrackingInterval()
      this.$emit('onPlayerEnded')
    },
    async onBeforeEnd(event) {},
    onPlayerSetupError(event) {
      console.log('player setup error ', event)

      // show overlayImage when player encounters an error
      this.hideOverlayImage = false

      if (this.videoType === 'live') {
        console.log('reloading live video')
        this.player = undefined
        this.isOverlayTop = true
        this.reloadLiveVideo()
        return
      }
      //this.postProgressEvent('video:error')
    },
    reloadLiveVideo() {
      this.lastReload = true

      // clear interval and create a new one
      if (this.reloadLive) {
        clearInterval(this.reloadLive)
      }

      this.reloadLive = setInterval(
        function() {
          this.player = undefined
          this.initPlayer(this.getVideoSource())
        }.bind(this),
        5000
      )
    },
    clearTrackingInterval() {
      //console.log('clear progress timer')
      this.intervalArr.forEach((item) => {
        clearInterval(item)
      })
      this.patchProgressInterval = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/app';

.overlay {
  width: 100%;
  height: 100%;
}
.overlay-top {
  z-index: 10;
}
.video-player-container {
  position: relative;
}
</style>

<style>
.video-player-container.hide #videoPlayer {
  display: none !important;
}
#videoPlayer.jwplayer.jw-reset.jw-state-error {
  display: none;
}
</style>
