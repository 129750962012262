<template>
  <div class="register flex-column-center">
    <h1>Change your plan</h1>
    <div>Current subscription - {{ currentUserPlan.name }}</div>
    <div class="stretch flex-row-center wrap">
      <RegisterPlan
        v-for="(p, i) in filteredUpgradePlans"
        :plan="p"
        :key="i"
        @selectPlan="selectPlan(p)"
      >
        <template v-slot:learn_more>
          <span
            class="underline cursor-pointer text-tw_primary-300"
            @click="handleClickLearnMore"
          >
            Learn More
          </span>
        </template>
      </RegisterPlan>
    </div>
    <div
      class="text-center mt-3"
      v-if="
        upgradePlans.length > 1 &&
          filteredUpgradePlans.length === 1 &&
          filteredUpgradePlans[0].product_type === 'training_annual'
      "
    >
      <span class="text-lg">Not interested in PokerGO VIP training?</span>
      <br />
      <span
        @click="showAllPlans"
        class="cursor-pointer text-tw_primary-300 underline"
      >
        click for other plans
      </span>
    </div>
    <div class="mt-20" v-if="showTrainingDetails" ref="trainingDetailsSection">
      <Stats />
      <ToughestPros class="mb-20" />
      <TrainingEndText :show-btn="false" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import RegisterPlan from '@/views/register/components/RegisterPlan.vue'
import TrainingEndText from '@/components/Training/TrainingEndText.vue'
import Stats from '@/components/Training/Stats.vue'
import ToughestPros from '@/components/Training/ToughestPros.vue'
import { upgradablePlans } from '@/library/config/plans'

export default {
  name: 'Upgrade',
  components: {
    ToughestPros,
    Stats,
    TrainingEndText,
    RegisterPlan
  },
  data() {
    return {
      showTrainingDetails: false,
      form: { email: '', first_name: '', last_name: '', password: '' },
      errorMsg: ''
    }
  },
  computed: {
    ...mapState({
      controls: (state) => state.checkout.controls,
      plan: (state) => state.checkout.selectedPlan,
      plans: (state) => state.checkout.plans,
      subscription: (state) => state.user.subscription
    }),
    ...mapGetters(['getPropertyId', 'getEmail', 'plansWithPromoDiscount']),
    currentUserPlan() {
      const index = this.plans.findIndex(
        (el) => el.product_type === this.subscription.interval
      )
      if (index > -1) {
        return this.plans[index]
      }
      return { name: '' }
    },
    currentStep() {
      const route = this.$route.path.split('/')
      if (
        this.$route.params &&
        this.$route.params.productType &&
        route[route.length - 2] === 'select-plan'
      ) {
        return 'checkout'
      } else {
        return route[route.length - 1]
      }
    },
    upgradePlans() {
      return this.plans.filter((el) =>
        upgradablePlans[this.subscription.interval].includes(el.product_type)
      )
    },
    filteredUpgradePlans() {
      if (this.$route.query.plan) {
        return this.plansWithPromoDiscount.filter(
          (el) => el.product_type === this.$route.query.plan
        )
      }
      return this.upgradePlans
    },
    destPath() {
      return this.$route.query.to || undefined
    },
    property() {
      return this.getPropertyId
    },
    username() {
      return this.getEmail || ''
    }
  },
  async mounted() {
    this.$store.dispatch('getPlans').then(() => {
      setTimeout(() => {
        dataLayer.push({ event: 'UpgradePlan-Land' })
        console.log('pushed event for UpgradePlan-Land')
      }, 2000)
    })
  },
  methods: {
    ...mapActions(['REQUEST_SIGNUP']),
    handleClickLearnMore() {
      this.showTrainingDetails = true
      this.$nextTick(() => {
        this.$refs.trainingDetailsSection.scrollIntoView({ behavior: 'smooth' })
      })
    },
    showAllPlans() {
      this.$router.push('/account/change-plan/checkout')
    },
    selectPlan(plan) {
      this.$store.commit('selectPlan', plan)
      this.$router.push({ path: this.$route.path + '/' + plan.product_type })
    }
  }
}
</script>
<style lang="scss">
.register {
  padding: 20px 0;
  min-height: calc(100vh - 320px);
  background-color: #10171f;
  color: #ffffff;

  h1 {
    text-align: center;
  }
  h5 {
    font-family: 'UniSans-Bold';
    font-size: 1.3rem;
    margin-top: 0;
    margin-bottom: 25px;
  }
  .stretch {
    align-items: stretch;
  }
  .wrap {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .register {
    h1 {
      font-size: 1.8rem;
    }
    .wrap {
      align-items: center;
    }
  }
}
</style>
