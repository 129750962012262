var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "switch" }, [
    _c("input", {
      attrs: { type: "checkbox" },
      domProps: { checked: _vm.checked },
      on: {
        click: function($event) {
          return _vm.$emit("toggle")
        }
      }
    }),
    _c("span", { staticClass: "slider round" }, [
      _c("p", { staticClass: "label__icon__on label__icon" }, [
        _vm._v(_vm._s(_vm.showLabel ? "ON" : ""))
      ]),
      _c("p", { staticClass: "label__icon__off label__icon" }, [
        _vm._v(_vm._s(_vm.showLabel ? "OFF" : ""))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }