var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-page" }, [
    _vm.updatedFirstName != ""
      ? _c("h1", { class: _vm.customClass }, [
          _vm._v(" " + _vm._s(_vm.updatedFirstName) + "'s Account ")
        ])
      : _vm._e(),
    _vm.updatedFirstName == ""
      ? _c("h1", { class: _vm.customClass }, [_vm._v("Account")])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "container height-stretch page-body" },
      [
        _vm.updateCard
          ? _c("UpdateCard", {
              attrs: { subscription: _vm.subscriptionToUpdate },
              on: {
                close: function($event) {
                  return _vm.toggleUpdateCardModal(false, {})
                },
                reload: _vm.fetchTransactions
              }
            })
          : _vm._e(),
        _c("div", { staticClass: "profile-section" }, [
          _vm._m(0),
          _c("div", { staticClass: "section-body" }, [
            _c("div", { staticClass: "info-group" }, [
              _c(
                "div",
                { staticClass: "avatar" },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/account-hover-icon.png"),
                      alt: "",
                      width: "70"
                    }
                  }),
                  _vm.editName
                    ? _c("font-awesome-icon", {
                        staticClass: "icon-edit",
                        attrs: { icon: ["fas", "pen"] },
                        on: { click: _vm.test }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "name-field bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.nameChangeSuccess === true
                          ? _vm.updatedNameString
                          : _vm.nameString
                      ) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "light" }, [_vm._v(_vm._s(_vm.email))])
              ])
            ]),
            _vm.editName == false
              ? _c(
                  "a",
                  {
                    staticClass: "action-btn",
                    on: {
                      click: function($event) {
                        _vm.editName = true
                        _vm.nameChangeSuccess = false
                      }
                    }
                  },
                  [_vm._v(" Edit ")]
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "edit-form" }, [
            _c("div", { staticClass: "form" }, [
              _c("div", { staticClass: "field-input name" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.updatedFirstName,
                      expression: "updatedFirstName"
                    }
                  ],
                  staticClass: "name-edit",
                  attrs: {
                    id: "firstname-edit",
                    tabindex: "1",
                    type: "text",
                    placeholder: "First Name",
                    readonly: !_vm.editName
                  },
                  domProps: { value: _vm.updatedFirstName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.updatedFirstName = $event.target.value
                    }
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.updatedLastName,
                      expression: "updatedLastName"
                    }
                  ],
                  staticClass: "name-edit",
                  attrs: {
                    id: "lastname-edit",
                    tabindex: "2",
                    type: "text",
                    placeholder: "Last Name",
                    readonly: !_vm.editName
                  },
                  domProps: { value: _vm.updatedLastName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.updatedLastName = $event.target.value
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "field-input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  attrs: { tabindex: "2", type: "text", readonly: "true" },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "field-input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.updatedUsername,
                      expression: "updatedUsername"
                    }
                  ],
                  attrs: {
                    id: "username-edit",
                    tabindex: "2",
                    type: "text",
                    maxlength: "20",
                    minlength: "5",
                    placeholder: "Nickname",
                    readonly: !_vm.editName
                  },
                  domProps: { value: _vm.updatedUsername },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.updatedUsername = $event.target.value
                    }
                  }
                }),
                !_vm.isValidUsername
                  ? _c("p", { staticClass: "err-msg" }, [
                      _vm._v(
                        " Alphabet and numbers are acceptable and at least 5 characters. No spaces allowed. "
                      )
                    ])
                  : _vm._e(),
                _vm.duplicateUsername
                  ? _c("p", { staticClass: "err-msg" }, [
                      _vm._v(" Username is already taken ")
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "field-input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.updatedPhone,
                      expression: "updatedPhone"
                    }
                  ],
                  attrs: {
                    id: "phone-edit",
                    tabindex: "2",
                    type: "text",
                    maxlength: "20",
                    minlength: "5",
                    placeholder: "Phone Number",
                    readonly: !_vm.editName
                  },
                  domProps: { value: _vm.updatedPhone },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.updatedPhone = $event.target.value
                    }
                  }
                }),
                !_vm.isValidPhoneNumber && _vm.editName
                  ? _c("p", { staticClass: "err-msg" }, [
                      _vm._v(
                        " Numbers are acceptable and at least 5 characters "
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm.editName == true
              ? _c("div", { staticClass: "action-button-group" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn-cancel",
                      on: { click: _vm.cancelNameChange }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      on: { click: _vm.saveNameChange }
                    },
                    [_vm._v(" Save ")]
                  )
                ])
              : _vm._e()
          ])
        ]),
        _vm.editName ? _c("div", { staticClass: "blur" }) : _vm._e(),
        _c("div", { staticClass: "login-section" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "section-body" },
            [
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "bold" }, [
                  _vm._v(
                    "Last updated " + _vm._s(_vm.pwdLastUpdateDuration) + " ago"
                  )
                ]),
                _c("div", { staticClass: "light" }, [_vm._v("Password")])
              ]),
              _c(
                "router-link",
                {
                  staticClass: "action-btn",
                  attrs: { to: "/account/changepassword" },
                  on: { click: _vm.test }
                },
                [_vm._v(" Change Password ")]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "subscription-section" }, [
          _vm._m(2),
          _vm.canResubscribe
            ? _c(
                "div",
                {
                  staticClass: "section-body",
                  staticStyle: {
                    "padding-top": "25px",
                    "padding-bottom": "25px"
                  }
                },
                [
                  _vm._m(3),
                  _c(
                    "div",
                    { staticClass: "btn-subscribe" },
                    [
                      _c("router-link", { attrs: { to: "/select-plan" } }, [
                        _vm._v(" Subscribe ")
                      ])
                    ],
                    1
                  )
                ]
              )
            : _c("div", { staticClass: "section-body horizontal" }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.getPlanName(_vm.transaction.interval)))
                    ]),
                    _c("div", { staticClass: "light" }, [_vm._v("Plan")])
                  ]),
                  !this.transaction.cancel_date
                    ? _c(
                        "div",
                        { staticClass: "inner__btn" },
                        [
                          _vm.canCancel
                            ? _c(
                                "a",
                                {
                                  staticClass: "cancel-btn",
                                  on: { click: _vm.cancelClicked }
                                },
                                [_vm._v(" Cancel ")]
                              )
                            : _vm._e(),
                          _vm.canResume
                            ? _c(
                                "a",
                                {
                                  staticClass: "action-btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleUpdateCardModal(
                                        true,
                                        _vm.transaction
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Update Card ")]
                              )
                            : _vm._e(),
                          this.transaction.store === "stripe" &&
                          this.transaction.interval !== "training_annual"
                            ? _c(
                                "a",
                                {
                                  staticClass: "action-btn",
                                  on: { click: _vm.upgrade }
                                },
                                [_vm._v(" Upgrade Plan ")]
                              )
                            : _vm._e(),
                          !_vm.isSubscriber
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "action-btn",
                                  attrs: { to: "/register" }
                                },
                                [_vm._v(" Subscribe ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.transaction.store))
                    ]),
                    _c("div", { staticClass: "light" }, [
                      _vm._v("Payment Method")
                    ])
                  ]),
                  _vm.canUpdatePaymentMethod
                    ? _c(
                        "a",
                        {
                          staticClass: "action-btn",
                          on: {
                            click: function($event) {
                              return _vm.toggleUpdateCardModal(
                                true,
                                _vm.transaction
                              )
                            }
                          }
                        },
                        [_vm._v(" Edit ")]
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "item price" }, [
                  _c("div", { staticClass: "item-group" }, [
                    _c("div", { staticClass: "info" }, [
                      _c("div", { staticClass: "bold" }, [
                        _vm._v("$ " + _vm._s(_vm.transaction.amount))
                      ]),
                      _c("div", { staticClass: "light" }, [_vm._v("Purchase")])
                    ]),
                    _c("div", { staticClass: "info" }, [
                      _c("div", { staticClass: "bold" }, [
                        _vm._v("$ " + _vm._s(_vm.transaction.renewal_price))
                      ]),
                      _c("div", { staticClass: "light" }, [_vm._v("Renewal")])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("span", { staticClass: "light" }, [
                    _vm._v("Purchase Date ")
                  ]),
                  _c("span", { staticClass: "date" }, [
                    _vm._v(_vm._s(_vm.getStartDate()))
                  ]),
                  _vm._m(4),
                  _c("span", { staticClass: "light" }, [
                    _vm._v(
                      _vm._s(
                        _vm.canCancel ? "Next Billing Date" : "Canceled Date"
                      )
                    )
                  ]),
                  _c("span", { staticClass: "date" }, [
                    _vm._v(_vm._s(_vm.getEndDate()))
                  ])
                ])
              ])
        ]),
        !this.transaction.cancel_date
          ? _c("div", { staticClass: "upgrade-section" }, [
              this.transaction.store === "stripe" &&
              (this.transaction.interval === "monthly" ||
                this.transaction.interval === "quarterly")
                ? _c("button", { on: { click: _vm.upgrade } }, [
                    _c("div", { staticClass: "bold" }, [
                      _vm._v("Upgrade to a PokerGO Annual Plan")
                    ]),
                    _c("div", { staticClass: "light" }, [
                      _vm._v("Get 58% off an Annual Subscription")
                    ])
                  ])
                : this.transaction.store === "stripe" &&
                  this.transaction.interval === "annual"
                ? _c("button", { on: { click: _vm.upgrade } }, [
                    _c("div", { staticClass: "bold" }, [
                      _vm._v("Upgrade to a PokerGO VIP Plan")
                    ])
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-title" }, [
      _c("span", [_vm._v("Profile")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-title" }, [
      _c("span", [_vm._v("Login")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-title" }, [
      _c("span", [_vm._v("Subscription")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "light" }, [
        _vm._v(
          " You no longer have a subscription. To resubscribe, please click Subscribe "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "circle" }, [
      _c("i", { staticClass: "fas fa-circle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }