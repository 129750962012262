<template>
  <div class="cancel-page">
    <div class="cancel-page__container">
      <div class="cancel-page__container--header">
        <h1>Subscription is canceled</h1>
        <p>
          You will no longer be able to watch premium content after
          {{ getEndDate() }}
        </p>
      </div>
      <p class="notification">
        We’ve sent confirmation of the cancellation to: <span>{{ email }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'CancelConfirm',
  data() {
    return {
      editName: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      modalClosed: true,
      passwordIncorrect: false,
      emtyPassword: false,
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      transaction: {}
    }
  },
  async mounted() {
    await Promise.all([this.fetchTransactions()])
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user']),
    email() {
      return this.user.basic ? this.user.basic.email : ''
    }
  },
  methods: {
    async fetchTransactions() {
      return await this.$store
        .dispatch('account/FetchTransactions')
        .then((rs) => {
          if (rs.status !== 200) {
            this.$toasted.error('Error please contact customer service')
            return
          }
          this.transaction = rs.data.data
          return rs
        })
    },
    getEndDate() {
      return moment(this.transaction.next_billing_date).format('MM-DD-YYYY')
    },
    redirect() {
      this.$router.push('/account')
    }
  }
}
</script>
<style lang="scss" scoped>
.notification {
  text-align: center;

  span {
    color: greenyellow;
  }
}
</style>
