<script>
import { mapGetters, mapState } from 'vuex'
import Home from '@/views/Watch/Home/Home.vue'
import Marketing from '@/views/Watch/Home/Marketing.vue'

export default {
  name: 'Home-Index',
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState({
      subscription: (state) => state.user.subscription
    }),
    CurrentView() {
      return this.isAuthenticated ? Home : Marketing
    }
  }
}
</script>

<template>
  <component :is="CurrentView" />
</template>
