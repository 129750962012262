<template>
  <div class="page">
    <div class="collection-container">
      <div class="back-home" v-if="collectionId">
        <router-link :to="`/collections/${collectionId}`">
          <font-awesome-icon :icon="['fas', 'arrow-left']" class="icon-back" />
          Back to collection
        </router-link>
      </div>
      <div class="header">
        <div class="header-text">
          <div class="body-header">
            <h1>{{ title }}</h1>
          </div>
          <p class="description">{{ description }}</p>
          <button v-if="loggedIn" class="my-list-button" @click="setFavorite">
            <font-awesome-icon class="circled" v-if="favorite" icon="check" />
            <font-awesome-icon class="circled" v-if="!favorite" icon="plus" />
            {{ favorite ? 'remove' : 'my list' }}
          </button>
        </div>
        <div class="header-img">
          <img :src="hdImage" />
        </div>
      </div>
      <div class="container">
        <Row
          v-for="(entity, idx) in collectionEntities"
          :key="idx + entity"
          :type="entity.type"
          :title="entity.title"
          :description="entity.description"
          :collection-entities="entity.entities"
          class="row-with-margin"
          @reloaddata="loadCollectionData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Row from '@/components/Row'
import { overlayImage } from '@/mixins'
import { mapGetters } from 'vuex'

export default {
  name: 'CollectionDetailView',
  mixins: [overlayImage],
  components: { Row },
  data() {
    return {
      title: '',
      description: '',
      airDate: '',
      end_date: '',
      type: '',
      publish_date: '',
      entities: [],
      collectionEntities: [],
      images: [],
      favorite: false,
      showSeasonDetails: true,
      collectionId: ''
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getPropertyId']),
    loggedIn() {
      return this.isAuthenticated
    },
    hdImage() {
      for (let i = 0; i < this.images.length; i++) {
        if (this.images[i].label === 'HD') {
          return this.images[i].url
        }
      }
      return ''
    },
    routeId() {
      return this.$route.params.id
    },
    property() {
      return this.getPropertyId
    }
  },
  async mounted() {
    await this.loadCollectionData()
  },
  watch: {
    description() {
      let metaDesc = document.querySelector('head meta[name="description"]')
      metaDesc.setAttribute('content', this.description)
      metaDesc = document.querySelector('head meta[property="og:description"]')
      metaDesc.setAttribute('content', this.description)
    },
    routeId(val) {
      if (val) this.loadCollectionData()
    },
    title() {
      document.title = this.title + ' | PokerGO'
    }
  },
  methods: {
    loadProgressData() {
      return this.$store.dispatch('home/GetProgress')
    },
    navigate(entity) {
      this.$router.push(`/${entity.type}s/` + entity.id)
    },
    async loadCollectionData(id) {
      this.loadProgressData().then(({ data }) => {
        if (data.data.total) {
          const progressVideos = data.data.videos
          this.$store.commit('setProgressVideos', progressVideos)
        }
      })

      let entityId = ''

      if (id !== undefined) {
        entityId = id
      } else {
        entityId = this.routeId
      }
      let payload = {
        entityType: 'collections',
        entityId: entityId
      }
      const res = await this.$store.dispatch(
        'collections/GetCollectionView',
        payload
      )
      if (res.status < 300) {
        const date = new Date(res.data.data.published_at)
        this.title = res.data.data.title
        this.description = res.data.data.description
        this.type = res.data.data.type
        this.images = res.data.data.images
        this.favorite = res.data.data.favorite
        this.collectionId =
          res.data.data?.parent_collection?.parent_collection_id
        this.publish_date =
          date.getMonth() + 1 + '-' + date.getDate() + '-' + date.getFullYear()
        const collectionEntities = res.data.data.collection_collection || []
        const videoEntities = res.data.data.collection_video || []
        videoEntities.forEach(
          (entity) => (entity.entities = entity.entities || [entity])
        )
        this.collectionEntities = [...videoEntities, ...collectionEntities]
      } else if (res.statusCode === 404) {
        this.$bugsnag.notify(new Error('Collection not found'))
      } else {
        this.$bugsnag.notify(new Error(`${res.statusCode}: ${res.title}`))
      }
    },
    setFavorite() {
      let payload = {
        type: 'collections',
        id: this.routeId
      }
      if (!this.favorite) {
        this.$store.dispatch('collections/PostFavorite', payload).then(() => {
          this.favorite = true
        })
      } else {
        this.$store.dispatch('collections/DeleteFavorite', payload).then(() => {
          this.favorite = false
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  @media (max-width: 786px) {
    z-index: 1;
  }
}
.collection-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
    .header-img {
      top: 0;
      left: 0;
      z-index: -2;
      width: 40%;
      margin-left: 2rem;
      @media (max-width: 768px) {
        width: 100%;
        margin: 0;
      }
      img {
        width: 100%;
      }
    }
    .header-text {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 1em;
      @media (max-width: 768px) {
        width: 100%;
        padding: 1rem;
        display: block;
      }
    }
  }
}
.latest-episode-container {
  margin-top: 45px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-top: 0;
    flex-direction: column;
    width: 100%;
  }
  .latest-episode {
    display: flex;
    flex-direction: column;
    width: 35%;
    @media (max-width: 768px) {
      width: 100%;
      padding: 0 1rem;
    }
    h2 {
      // font-family: "UniSans-Bold-Italic";
    }
    .watch-button {
      cursor: pointer;
      display: flex;
      width: fit-content;
      align-items: center;
      padding: 0.5rem 1rem;
      margin-bottom: 15px;
      background: #641473;
      color: #ffffff;
      font-weight: bold;
      font-size: 1em;
      height: 40px;
      border-radius: 5px;
      text-transform: uppercase;
      .play-icon {
        font-size: 1.5em;
        margin-right: 10px;
      }
    }
    .duration {
      margin-bottom: 15px;
    }
  }

  .season-episode-list {
    width: 60%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .season-title {
      text-transform: uppercase;
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      background: #641473;
      font-family: 'UniSans-Bold-Italic';
      line-height: 30px;
      cursor: pointer;
    }
    .list {
      padding: 0 1rem;
      .row {
        cursor: pointer;
        display: flex;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: 90px;
        margin-bottom: 1em;
        .entity-image {
          height: 100%;
          width: 180px;
          margin-right: 1em;
          background-size: cover;
        }
        .info {
          display: flex;
          align-items: center;
          max-width: calc(100% - 200px);
        }
      }
    }
  }
}

.duration {
  width: 150px;
  display: flex;
  justify-content: space-between;
  display: none;

  @media (max-width: 768px) {
    height: auto;
  }
}

.description {
  font-size: 1.3em;
}

.row-with-margin {
  margin-top: 20px;
  margin-bottom: 20px;
  .title {
    margin-bottom: 3px !important;
  }
}

.body-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  //  Not coherent with stylesheet
  font-style: normal !important;
}

.back-home {
  font-family: 'UniSans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 20px;
  @media (max-width: 768px) {
    margin: 10px 10px 10px 0px;
  }

  .icon-back {
    opacity: 0.4;
    margin-right: 0.5rem;
    font-size: 16px;
  }
}
</style>
