var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow && _vm.playerLoaded,
          expression: "isShow && playerLoaded"
        }
      ],
      staticClass: "modal-bg"
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.closeModal,
              expression: "closeModal"
            }
          ],
          staticClass: "content",
          class: { cta: !_vm.isPlaying }
        },
        [
          _vm.playerLoaded && _vm.isPlaying
            ? _c("div", { staticClass: "controls" }, [
                _c("div", { staticClass: "controls__select" }, [
                  _c("label", { staticClass: "checkbox-container" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.neverShow,
                          expression: "neverShow"
                        }
                      ],
                      attrs: { type: "checkbox", id: "do-not-show" },
                      domProps: {
                        checked: Array.isArray(_vm.neverShow)
                          ? _vm._i(_vm.neverShow, null) > -1
                          : _vm.neverShow
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.neverShow,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.neverShow = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.neverShow = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.neverShow = $$c
                          }
                        }
                      }
                    }),
                    _c("div", { staticClass: "checkmark" })
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "do-not-show-text",
                      attrs: { for: "do-not-show" }
                    },
                    [_vm._v("Do not show this again!")]
                  ),
                  _c("i", {
                    staticClass: "fas fa-times",
                    on: {
                      click: function($event) {
                        _vm.neverShowUntilNextDay = true
                      }
                    }
                  })
                ])
              ])
            : _vm._e(),
          _vm.isPlaying
            ? _c("div", {
                staticClass: "video-player-modal",
                attrs: { id: "videoPlayerModal" }
              })
            : _vm._e(),
          !_vm.isPlaying
            ? _c("div", { staticClass: "c-call-to-action call-to-action" }, [
                _vm._m(0),
                _vm._m(1),
                _c(
                  "a",
                  {
                    staticClass: "btn-cta btn-cta--trans",
                    attrs: { href: "#" },
                    on: { click: _vm.closeModal }
                  },
                  [_vm._m(2)]
                ),
                _c("div", { staticClass: "cta-pop-controls" }, [
                  _c("div", { staticClass: "cta-pop-controls__select" }, [
                    _c("label", { staticClass: "checkbox-container" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.neverShow,
                            expression: "neverShow"
                          }
                        ],
                        attrs: { type: "checkbox", id: "do-not-show" },
                        domProps: {
                          checked: Array.isArray(_vm.neverShow)
                            ? _vm._i(_vm.neverShow, null) > -1
                            : _vm.neverShow
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.neverShow,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.neverShow = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.neverShow = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.neverShow = $$c
                            }
                          }
                        }
                      }),
                      _c("div", { staticClass: "checkmark" })
                    ]),
                    _vm._m(3)
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: require("@/assets/images/Logo-pokerGOHoldem.svg") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn-cta",
        attrs: { href: "https://www.pokergoholdem.com", target: "_blank" }
      },
      [
        _c("div", { staticClass: "btn-cta__content" }, [
          _c("h6", [_vm._v("Play Now")]),
          _c("span", [_vm._v("Continue to PokerGO Holdem")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-cta__content" }, [
      _c("h6", [_vm._v("Watch Now!")]),
      _c("span", [_vm._v("Continue to PokerGO")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "do-not-show-text", attrs: { for: "do-not-show" } },
      [_c("span", [_vm._v("Do not show this again!")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }