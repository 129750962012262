import { account } from './account.js'
import { authentication } from './authentication.js'
import { checkout } from './checkout.js'
import { collections } from './collections.js'
import { demand } from './demand.js'
import { schedule } from './schedule.js'
import { search } from './search.js'
import { response } from './response.js'
import { home } from './home.js'
import { document } from './document.js'
import { events } from './events.js'
import { progress } from './progress.js'
import { ga } from './ga.js'
import { chat } from './chat.js'
import { training } from './training.js'
import { marketing } from './marketing.js'

const api = {
  account,
  authentication,
  checkout,
  collections,
  demand,
  schedule,
  search,
  response,
  home,
  document,
  events,
  progress,
  ga,
  chat,
  training,
  marketing
}
export default api
