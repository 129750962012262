var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cancel-page" }, [
    _c(
      "div",
      {
        staticClass: "cancel-page__container",
        staticStyle: { width: "568px" }
      },
      [
        _vm._m(0),
        _c("div", { staticClass: "cancel-page__container--form" }, [
          _c("p", { staticClass: "cancel-page__container--form--label" }, [
            _vm._v(" SELECT REASONS ")
          ]),
          _c(
            "form",
            { staticClass: "cancel-page__container--form--content" },
            [
              _vm._l(_vm.cancel_reasons, function(item, index) {
                return _c(
                  "div",
                  {
                    key: "reason_" + index,
                    staticClass: "form-item",
                    on: {
                      click: function($event) {
                        return _vm.toggleCheckbox(item.value)
                      }
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(item.label))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reason,
                          expression: "reason"
                        }
                      ],
                      staticClass:
                        "inline-block h-4 w-4 text-tw_primary-300 transition focus:ring-0 cursor-pointer",
                      staticStyle: { fill: "red" },
                      attrs: { type: "checkbox", id: "reason_" + item.value },
                      domProps: {
                        value: item.value,
                        checked: Array.isArray(_vm.reason)
                          ? _vm._i(_vm.reason, item.value) > -1
                          : _vm.reason
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.reason,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = item.value,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.reason = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.reason = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.reason = $$c
                          }
                        }
                      }
                    })
                  ]
                )
              }),
              _vm.reason.includes("Cancel Other")
                ? _c("div", { staticClass: "other-reason-input pt-3" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.other_reason,
                          expression: "other_reason"
                        }
                      ],
                      staticClass: "other-reason",
                      attrs: {
                        type: "text",
                        name: "other-reason",
                        rows: "5",
                        placeholder:
                          "Tell us the reason you're leaving PokerGO..."
                      },
                      domProps: { value: _vm.other_reason },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.other_reason = $event.target.value
                        }
                      }
                    })
                  ])
                : _vm._e()
            ],
            2
          ),
          _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))]),
          _c(
            "button",
            {
              staticClass: "glowing-btn w-full h-12 mb-1 mt-5",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.handleClickContinue()
                }
              }
            },
            [_vm._v(" Continue ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn-cancel h-12 mb-5",
              on: {
                click: function($event) {
                  return _vm.redirect()
                }
              }
            },
            [_vm._v(" Keep my subscription ")]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cancel-page__container--header" }, [
      _c("h1", [_vm._v("Select Reasons")]),
      _c("p", [_vm._v(" Select all that apply ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }