var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.entitled
      ? _c(
          "div",
          [
            _vm.fetched && _vm.onAir
              ? _c("video-player", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.trailerPlaying,
                      expression: "!trailerPlaying"
                    }
                  ],
                  key: _vm.$route.fullPath,
                  attrs: {
                    entitled: _vm.entitled,
                    id: _vm.videoId,
                    "overlay-image": _vm.overlayImage(),
                    autoplay: !_vm.hasTrailer,
                    "video-data": _vm.videoData,
                    trailerPlayering: _vm.trailerPlaying
                  },
                  on: {
                    streamEnded: function($event) {
                      _vm.streamLive = false
                    },
                    streamStarted: function($event) {
                      _vm.streamLive = true
                    }
                  }
                })
              : _vm._e(),
            !_vm.onAir && !_vm.trailerPlaying
              ? _c("img", {
                  staticClass: "overlay",
                  attrs: { src: _vm.thumbnailImage }
                })
              : _vm._e(),
            !_vm.onAir && _vm.fetched && !_vm.trailerPlaying
              ? _c("CountdownTimer", {
                  attrs: { airDate: _vm.airDate, videoType: _vm.type }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    !_vm.entitled
      ? _c("div", { staticClass: "login-wrapper" }, [
          _c("img", {
            staticClass: "background-image",
            attrs: { src: _vm.thumbnailImage }
          }),
          !_vm.isAuthenticated
            ? _c(
                "form",
                {
                  staticClass: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.login($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "login-box-container" }, [
                    _c("h2", [_vm._v("Sign In")]),
                    _c("div", { staticClass: "text-field" }, [
                      _c("label", [_vm._v("Email Address")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email"
                          }
                        ],
                        attrs: {
                          id: "username-input",
                          type: "email",
                          required: ""
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "text-field" }, [
                      _c("label", [_vm._v("Password")]),
                      (_vm.showPassword ? "text" : "password") === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pass,
                                expression: "pass"
                              }
                            ],
                            attrs: {
                              id: "password-input",
                              required: "",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.pass)
                                ? _vm._i(_vm.pass, null) > -1
                                : _vm.pass
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.pass,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.pass = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.pass = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.pass = $$c
                                }
                              }
                            }
                          })
                        : (_vm.showPassword ? "text" : "password") === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pass,
                                expression: "pass"
                              }
                            ],
                            attrs: {
                              id: "password-input",
                              required: "",
                              type: "radio"
                            },
                            domProps: { checked: _vm._q(_vm.pass, null) },
                            on: {
                              change: function($event) {
                                _vm.pass = null
                              }
                            }
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pass,
                                expression: "pass"
                              }
                            ],
                            attrs: {
                              id: "password-input",
                              required: "",
                              type: _vm.showPassword ? "text" : "password"
                            },
                            domProps: { value: _vm.pass },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.pass = $event.target.value
                              }
                            }
                          }),
                      _c(
                        "span",
                        {
                          staticClass: "eye-icon",
                          on: {
                            click: function($event) {
                              _vm.showPassword = !_vm.showPassword
                            }
                          }
                        },
                        [
                          !_vm.showPassword
                            ? _c("img", {
                                staticStyle: { height: "20px" },
                                attrs: {
                                  src: require("@/assets/images/eye-solid.svg"),
                                  alt: ""
                                }
                              })
                            : _vm._e(),
                          _vm.showPassword
                            ? _c("img", {
                                staticStyle: { height: "20px" },
                                attrs: {
                                  src: require("@/assets/images/eye-slash-solid.svg"),
                                  alt: ""
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    ]),
                    _c("br"),
                    _vm.errorMessage !== ""
                      ? _c(
                          "span",
                          { staticClass: "error-message" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "times" }
                            }),
                            _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "button",
                    { staticClass: "button", attrs: { type: "submit" } },
                    [_vm._v("Submit")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "button btn-signup",
                      attrs: {
                        href: _vm.siteUrl + "/register",
                        target: "__blank"
                      }
                    },
                    [_vm._v(" Signup ")]
                  )
                ]
              )
            : _vm._e(),
          _vm.isAuthenticated
            ? _c("div", { staticClass: "content-area" }, [
                _c("p", { staticClass: "notation-title" }, [
                  _vm._v(" SUBSCRIBE TO UNLOCK VIDEO ")
                ]),
                _c("p", { staticClass: "notation-desc" }, [
                  _vm._v(
                    " Watch 100+ days a year of LIVE poker tournaments, including the WSOP and enjoy exclusive, NEW poker shows such as No Gamble, No Future & High Stakes Poker! "
                  )
                ]),
                _c("div", { staticClass: "btn-wrapper" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn-subscribe",
                      attrs: {
                        href: _vm.siteUrl + "/select-plan",
                        target: "__blank"
                      }
                    },
                    [_vm._v(" SUBSCRIBE NOW ")]
                  )
                ])
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }