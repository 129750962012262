import api from '@/api'

const state = {}

const actions = {
  getVideoProgress({ commit }, videoId) {
    return api.progress
      .getVideoProgress(videoId)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw err
      })
  },
  postEvent({ commit }, payload) {
    return api.progress
      .postEvent(payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw err
      })
  }
}
export default {
  state,
  actions
}
