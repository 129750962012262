//import Vue from 'vue'

export const response = {
  error(err) {
    const errMsg = {}

    if (err?.hasOwnProperty('response') && err?.response != undefined) {
      if (
        err.response.hasOwnProperty('data') &&
        err.response.data.hasOwnProperty('Message') &&
        err.response.data.Message
      ) {
        errMsg.message = err.response.data.Message
      }
      if (
        err.response.hasOwnProperty('data') &&
        err.response.data.hasOwnProperty('errors')
      ) {
        errMsg.message = err.response.data.errors[0].detail
        errMsg.statusCode =
          err.response.data.errors[0].statusCode ||
          err.response.data.errors[0].code
        errMsg.title = err.response.data.errors[0].title
      }

      if (err.response.hasOwnProperty('data') && !err.response.data) {
        errMsg.message = err.message || 'An error occurred'
        errMsg.statusCode = err.response.status
        errMsg.title = err.response.statusText || 'Server error..'
      }
    } else {
      errMsg.message = 'An error occurred'
      errMsg.statusCode = 500
      errMsg.title = 'Server error...'
    }

    if (Object.keys(errMsg).length === 0) {
      errMsg.message = err
    }

    if (err.response) {
      errMsg.status = err.response.status
      if (err.response.status === 428) {
        errMsg.data = err.response.data
      }
    }

    return errMsg
  }
}
