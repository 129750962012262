<template>
  <div
    class="group h-10 flex justify-between items-center pl-4 pr-5 hover:bg-tw_primary-100"
  >
    <label
      class="inline-block w-full font-unisans-regular text-sm text-black cursor-pointer leading-10 group-hover:text-tw_primary-300"
      :for="id"
    >
      {{ label }}
    </label>
    <input
      class="inline-block h-4 w-4 text-tw_primary-300 transition focus:ring-0 cursor-pointer"
      @change="handleInput"
      type="checkbox"
      :name="groupName"
      :id="id"
      :value="value"
      :checked="isChecked"
    />
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    groupName: {
      type: String,
      default: 'checkbox'
    },
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    isChecked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('select-checkbox', e.target.checked)
    }
  }
}
</script>
