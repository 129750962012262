<template>
  <div
    v-touch:swipe.left="pageRight"
    v-touch:swipe.right="pageLeft"
    class="row-wrapper"
  >
    <h2 v-if="title" class="title">
      {{ title }}
    </h2>
    <div v-if="type === 'video'" style="width: 100%;">
      <long-card
        v-for="(entity, idx) in shownEntities"
        :key="idx + entity.id"
        :entity-id="entity.id"
        :entity-type="entity.type"
        :entity-data="entity.images ? entity : undefined"
        :style="{
          left: String((100 / numVisible) * -currentIdx) + '%'
        }"
        :large="large"
        :video="idx === currentIdx"
        :show-title="false"
        :no-reload="true"
        style="position: relative;"
      />
    </div>
    <div
      @mouseover="rowHovering = true"
      @mouseleave="rowHovering = false"
      v-if="type === 'collection' || type === 'event'"
      class="row"
      :style="large ? 'justify-content: center;' : ''"
    >
      <small-card
        v-for="(entity, idx) in shownEntities"
        :key="idx + entity.id"
        :entity-id="entity.id"
        :entity-type="entity.type"
        :entity-data="entity.images ? entity : undefined"
        :style="{
          left: String((100 / numVisible) * -currentIdx) + '%'
        }"
        :type="large ? 'large' : type === 'event' ? 'event' : 'small'"
        :video="idx === currentIdx"
        :show-title="showCardTitles"
        :duration="entity.duration"
        :author="entity.author"
        :no-reload="true"
        style="position: relative;"
        @updatedata="loadParentData"
      />
      <div
        v-if="
          rowHovering && shownEntities.length > numVisible && currentIdx !== 0
        "
        @click="pageLeft()"
        :class="{ large: large }"
        class="left-button"
      >
        <span class="icon">
          <font-awesome-icon
            :icon="['fa', 'chevron-circle-left']"
            class="carosel-button"
          />
        </span>
      </div>
      <div
        v-if="
          shownEntities.length > numVisible &&
            currentIdx !== shownEntities.length - numVisible
        "
        @click="pageRight()"
        :class="{ large: large }"
        class="right-button"
      >
        <span class="icon" style="width: 2em; height: 2em;">
          <font-awesome-icon
            :icon="['fa', 'chevron-circle-right']"
            class="carosel-button"
          />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import SmallCard from '@/components/SmallCard.vue'
import LongCard from '@/components/LongCard.vue'
import { mapGetters } from 'vuex'
// These values also need to be set in smallcard.vue to make breakpoints work properly
// TODO: Move these into props so that we can set breakpoints from the same place
// >980px 4 cards
// 550-980px 3 Cards
// <550px 1 card

export default {
  name: 'Row',
  components: { SmallCard, LongCard },
  props: {
    collectionEntities: {
      type: Array,
      default: () => [],
      required: true
    },
    type: {
      type: String,
      default: 'collection'
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    large: {
      type: Boolean,
      default: () => false
    },
    showCardTitles: {
      default: true,
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      currentIdx: 0,
      rowHovering: false,
      windowWidth: 0
    }
  },
  computed: {
    ...mapGetters(['getPropertyId']),
    property() {
      return this.getPropertyId
    },
    visibleCards() {
      return this.shownEntities.slice(
        this.currentIdx,
        this.currentIdx + this.numVisible
      )
    },
    shownEntities() {
      return this.collectionEntities
    },
    numVisible() {
      if (this.large) {
        return 1
      }
      if (this.windowWidth > 768) {
        return 4
      } else if (this.windowWidth > 480) {
        return 3
      } else {
        return 2
      }
    }
  },
  watch: {
    currentIdx() {
      if (this.currentIdx < 0) {
        this.currentIdx = 0
      }
      if (this.currentIdx >= this.shownEntities.length) {
        this.currentIdx = this.shownEntities.length - 1
      }
    },
    numVisible() {
      if (this.numVisible === this.shownEntities.length) {
        this.currentIdx = 0
      }
    }
  },
  async mounted() {
    this.windowWidth = window.innerWidth
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })
  },
  methods: {
    pageLeft() {
      // check if the number of rest cards is 1 or 2
      if (this.currentIdx < this.numVisible) {
        this.currentIdx = 0
        return
      }
      this.currentIdx = this.currentIdx - this.numVisible
    },
    pageRight() {
      // check if the number of rest cards is 1 or 2
      if (
        this.shownEntities.length - this.currentIdx - this.numVisible <
        this.numVisible
      ) {
        this.currentIdx = this.shownEntities.length - this.numVisible
        return
      }
      this.currentIdx = this.currentIdx + this.numVisible
    },
    cardVisible(idx) {
      return idx < this.currentIdx + this.numVisible && idx >= this.currentIdx
    },
    loadParentData(id) {
      console.log('row emitted to reload data', id)
      this.$emit('reloaddata', id)
    }
  }
}
</script>
<style lang="scss" scoped>
.row-wrapper {
  width: 100%;
  margin-top: 60px;
  margin-left: 15px;
  @media (max-width: 786px) {
    margin: 0;
    margin-bottom: 2rem;
  }
}

.title {
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'UniSans-Bold-Italic';
  line-height: 2em;
}

.row {
  position: relative;
  display: flex;
  flex-flow: row;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

.left-button {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  color: white;
  cursor: pointer;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(50, 50, 50, 0.05),
    rgba(111, 111, 111, 0.1)
  );

  &.large {
    height: 95%;
    background-image: none;
  }
}
.left-button:hover {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(50, 50, 50, 0.1),
    rgba(111, 111, 111, 0.2)
  );
}
.right-button {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  color: white;
  cursor: pointer;
  &.large {
    height: 95%;
    background-image: none;
  }
}

.carosel-button {
  width: 2em;
  height: 2em;
  opacity: 0.7;
}
.carosel-button:hover {
  width: 2em;
  height: 2em;
  opacity: 0.4;
}
.arrow {
  display: inline-block;
  padding: 3px;
  border: solid $white;
  border-width: 0 3px 3px 0;
}

.up {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.down {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
