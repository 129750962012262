var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register flex-column-center" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentStep === "register" && _vm.code,
              expression: "currentStep === 'register' && code"
            }
          ]
        },
        [_c("register-promo-banner")],
        1
      ),
      _c("h6", [_vm._v("UNLOCK POKERGO")]),
      _c("register-steps", { attrs: { currentStep: _vm.currentStep } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.controls.loading && _vm.currentStep !== "checkout",
              expression: "controls.loading && currentStep !== 'checkout'"
            }
          ],
          staticClass: "spinner"
        },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-pulse fa-3x fa-fw",
            attrs: { icon: "spinner" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentStep === "register",
              expression: "currentStep === 'register'"
            }
          ],
          staticClass: "flex-column-center"
        },
        [
          _c("register-form", {
            attrs: { error: _vm.errorMsg },
            on: { login: _vm.login },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentStep === "select-plan",
              expression: "currentStep === 'select-plan'"
            }
          ]
        },
        [_c("SelectPlanStep", { on: { selectPlan: _vm.selectPlan } })],
        1
      ),
      _vm.currentStep === "checkout"
        ? _c(
            "div",
            { staticClass: "flex-column-center" },
            [
              _c("RegisterCheckout", {
                key: _vm.$route.fullPath,
                on: { subscribe: _vm.subscribe }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.currentStep === "amazon"
        ? _c(
            "div",
            { staticClass: "flex-column-center" },
            [_c("AmazonPayElement")],
            1
          )
        : _vm._e(),
      _vm.currentStep === "verify-email"
        ? _c(
            "div",
            { staticClass: "flex-column-center" },
            [_c("VerifyEmailStep")],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }