import Vue from 'vue'
import axios from 'axios'
import { settings } from '@/library/variables'
import { localstorage } from '../services/storage/localStorageService'
import store from '@/store'
import { General } from '@/library/general'
import jwtDecode from 'jwt-decode'
// import { authentication } from '../api/authentication'
const instance = axios.create({
  baseURL: settings.baseURL
})
const gameMailingList = axios.create({
  baseURL: settings.gameMailingList
})
const chatInstance = axios.create({
  baseURL: settings.chatURLStatus
})

const subscriberInstance = axios.create({
  baseURL: settings.subscriberBaseURL
})

const instanceEvent = axios.create({
  baseURL: settings.baseEventURL
})

const videoProgressInstance = axios.create({
  baseURL: settings.videoProgressBaseURL
})

chatInstance.interceptors.request.use(
  (config) => {
    const token = localstorage.getToken()
    let authToken = `Bearer ${token || process.env.DEVICE_TOKEN}`

    if (
      config.url.includes('/sign-in') // dot not include the token when sign-in
    ) {
      authToken = undefined
    }
    if (authToken) {
      config.headers['Authorization'] = authToken
      config.headers['X-Redirect-Host'] = process.env.SITE_URL
      config.headers['X-odd-user-agent'] = JSON.stringify({
        agentstring: navigator.userAgent,
        platform: 'web'
      })
      // config.headers['User-Agent'] = JSON.stringify({ 'agentstring': navigator.userAgent, 'platform': 'web' })
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

instance.interceptors.request.use(
  (config) => {
    const token = localstorage.getToken()
    let authToken = `Bearer ${token || process.env.DEVICE_TOKEN}`

    if (
      config.url.includes('/sign-in') // dot not include the token when sign-in
    ) {
      authToken = undefined
    }
    if (authToken) {
      config.headers['Authorization'] = authToken
      config.headers['X-Redirect-Host'] = process.env.SITE_URL
      config.headers['X-odd-user-agent'] = JSON.stringify({
        agentstring: navigator.userAgent,
        platform: 'web'
      })
      // config.headers['User-Agent'] = JSON.stringify({ 'agentstring': navigator.userAgent, 'platform': 'web' })
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
instance.interceptors.response.use(
  (response) => {
    const token = response.headers['authtoken']
    //console.log('axios response header: ', response)
    if (token) {
      try {
        var decoded = jwtDecode(token)
        if (decoded.scope) {
          try {
            window.userBroadcasterForeignID = decoded && decoded.sub
            window.userInitialName = decoded && decoded.user_name
          } catch (e) {
            console.log(e)
          }

          store.commit('setAuthenticated', token)
          store.dispatch('fetchAndSetBanners')
        }
      } catch (e) {
        console.log('RESPONSE:', e)
      }
    }
    return response
  },
  function(error) {
    if (
      //response.url.includes('') &&
      (error.response && error.response.status === 401) ||
      error.response.status === 452
    ) {
      const token = error.response.headers['authtoken']
      if (token == 'LOGOUT') {
        console.log('logging user out')
        localstorage.clearAuth()
        //await this.$router.push({ name: 'HomeView' })
        window.location.reload()
      }
    }
    return Promise.reject(error)
  }
)

subscriberInstance.interceptors.request.use(
  (config) => {
    const token = localstorage.getToken()
    let authToken = `Bearer ${token || process.env.DEVICE_TOKEN}`

    if (
      config.url.includes('/sign-in') // dot not include the token when sign-in
    ) {
      authToken = undefined
    }
    if (authToken) {
      config.headers['Authorization'] = authToken
      config.headers['X-Redirect-Host'] = process.env.SITE_URL
    }
    config.headers['X-odd-user-agent'] = JSON.stringify({
      agentstring: navigator.userAgent,
      platform: 'web',
      web_version: process.env.WEB_VERSION
    })
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

videoProgressInstance.interceptors.request.use(
  (config) => {
    const token = localstorage.getToken()
    let authToken = `Bearer ${token || process.env.DEVICE_TOKEN}`

    if (authToken) {
      config.headers['Authorization'] = authToken
      config.headers['X-Redirect-Host'] = process.env.SITE_URL
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

instanceEvent.interceptors.request.use(
  (config) => {
    const token = localstorage.getToken()
    let authToken = `Bearer ${token || process.env.DEVICE_TOKEN}`

    if (
      config.url.includes('/sign-in') // dot not include the token when sign-in
    ) {
      authToken = undefined
    }
    if (authToken) {
      config.headers['Authorization'] = authToken
      config.headers['X-Redirect-Host'] = process.env.SITE_URL
    }
    if (General.getWithExpiry('paypal-checkout-performed')) {
      config.headers['X-Paypal-Checkout'] = new Date().toISOString()
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
instanceEvent.interceptors.response.use(
  (response) => {
    return response
  },
  function(error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 452)
    ) {
      // return authentication.reauth().then((response) => {
      //   if (response.status === 200) {
      //     const token = response.data.meta.token
      //     store.commit('localAuthenticated', {
      //       token
      //     })
      //     const config = error.config
      //     config.headers['Authorization'] = `Bearer ${token}`
      //     return new Promise((resolve, reject) => {
      //       axios
      //         .request(config)
      //         .then((response) => {
      //           resolve(response)
      //         })
      //         .catch((error) => {
      //           reject(error)
      //         })
      //     })
      //   }
      // })
      localstorage.clearToken()
      store.commit('localAuthenticated')
    }
    return Promise.reject(error)
  }
)
Vue.prototype.$axios = instance
Vue.prototype.$settings = settings
Vue.prototype.$localstorage = localstorage
Vue.prototype.$eventAxios = instanceEvent
Vue.prototype.$axiosSubscriberInstance = subscriberInstance

export {
  instance,
  instanceEvent,
  subscriberInstance,
  videoProgressInstance,
  chatInstance,
  gameMailingList
}
